import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-dynamic-project-service-field',
  templateUrl: './dynamic-project-service-field.component.html',
  styleUrls: ['./dynamic-project-service-field.component.scss']
})
export class DynamicProjectServiceFieldComponent implements OnInit {

  @Input() field: any;
  @Input() form: UntypedFormGroup;
  get isValid() { return this.form.controls[this.field.serviceId].valid; }

  constructor() { }

  ngOnInit() {
  }

}
