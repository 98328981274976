import { TaskboardComponent } from "./taskboard.component";
import { JobReviewComponent } from "./components/jobs/job-review/job-review.component";
import { formPath, param } from "../core/routing/helpers";
import { TaskboardListComponent } from "./taskBoardList/taskBoardList.component";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, Routes } from "@angular/router";
import { NewTaskComponent } from "./components/tasks/new-task/new-task.component";
import { NewRequestComponent } from "./components/requests/new-request/new-request.component";
import { TaskReviewComponent } from "./components/tasks/task-review/task-review.component";
import { RequestReviewComponent } from "./components/requests/request-review/request-review.component";
import { DpaEditorComponent } from "./components/dpa/dpaEditor/dpaEditor.component";
import { TYPE, MODE, ID, T_QUERY, REVIEW, NEW, TASKS, REQUESTS, DPA, JOBS, BY_ME, FOR_ME, FOR_MY_GROUP, TRANSFERED, PARTNER, DPAAPT, GROUPDPA, INHERIT, COMMENT_ID } from "./taskboard.help";
import { PartnerEditorComponent } from './components/partner-editor/partner-editor.component';

export const redirectToPath = () => formPath(TASKS, BY_ME);

export const TASKBOARD_ROUTES: Routes = [
  {
    path: '',
    component: TaskboardComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: redirectToPath(),
      },
      {
        path: formPath(TASKS, NEW),
        component: NewTaskComponent,
      },
      {
        path: formPath(REQUESTS, NEW),
        component: NewRequestComponent,
      },
      {
        path: formPath(REQUESTS, NEW, INHERIT, param(ID), param(COMMENT_ID)),
        component: NewRequestComponent,
      },
      {
        path: formPath(PARTNER, param(ID)),
        component: PartnerEditorComponent,
      },
      {
        path: formPath(param(TYPE)),
        component: TaskboardListComponent,
        resolve: {
          tableType: 'tableTypeProvider'
        }
      },
      {
        path: formPath(param(TYPE), param(MODE)),
        component: TaskboardListComponent,
        resolve: {
          tableType: 'tableTypeProvider'
        }
      },
      {
        path: formPath(TASKS, REVIEW, param(ID)),
        component: TaskReviewComponent,
      },
      {
        path: formPath(REQUESTS, REVIEW, param(ID)),
        component: RequestReviewComponent,
      },
      {
        path: formPath(JOBS, REVIEW, param(ID)),
        component: JobReviewComponent,
      },
      {
        path: formPath(DPA, REVIEW, param(ID)),
        component: DpaEditorComponent,
      },
      {
        path: formPath(GROUPDPA, REVIEW, param(ID)),
        component: DpaEditorComponent,
      },
    ]
  }
];

export const tableTypeProvider = {
  provide: 'tableTypeProvider',
  useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot, router: Router) => {

    const m = { ...route.params, ...route.queryParams };
    if (!m || !m.type) {
      throw new Error("Empty model options not allowed");
    }
    switch (m.type) {
      case TASKS:
        if (m.p) {
          return "partnerTasks";
        }
        if (m.u) {
          return "userTasks";
        }
        if (!m.p && !m.u && (!m.mode || m.mode == BY_ME)) {
          return "myTasks";
        }

        switch (m.mode) {
          case FOR_ME: return "tasksForMe";
          case FOR_MY_GROUP: return "tasksForMyGroup";
          case TRANSFERED: return "transferedTasks";
        }
        break;
      case REQUESTS:
        if (m.p) {
          return "partnerRequests";
        }
        if (m.u) {
          return "userRequests";
        }
        if (!m.mode && !m.p) {
          return "requestsForMe";
        } else if (m.mode) {
          switch (m.mode) {
            case FOR_ME: return "requestsForMe";
            case FOR_MY_GROUP: return "requestsForMyGroup";
            case TRANSFERED: return "transferedRequests";
          }
        }
        break;
      case JOBS:
        if (m.p) {
          return "partnerJobs"
        }
        if (m.u) {
          return "userJobs"
        }
        return "myJobs";
      case DPA:
        if (m.p) {
          return "partnerDpa"
        }
        if (m.u) {
          return "userDpa"
        }
        return "myDpa";
      case GROUPDPA:
        return "myGroupDpa";
      case DPAAPT:
        return m.p ? "partnerDpaApt" : "myDpaApt";
    }
    return "";
  }
};
