
import { tap } from 'rxjs/operators';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TaskReview } from '../../../models/task-review';
import { TasksBackend } from '../../../../backend/tasks.backend';
import { PersonsBackend } from '../../../../backend/persons.backend';
import { Subscription } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { CalendarCustomHeaderComponent } from '../../../../components/filters/date-interval-alt/calendarCustomHeader.component';

@Component({
  selector: 'transfer-form',
  templateUrl: 'transfer-form.component.html',
  styleUrls: ['./transfer-form.component.scss']
})
export class TransferFormComponent implements OnDestroy {

  private selectedPerson;
  text;
  transferData;
  _isInserting: boolean;
  personsSource: Function;
  suggestionMapper: Function;
  subs: Subscription[] = [];

  isDisabled: any;
  _isInserted: any;
  dateStart: any = new Date();

  calendarCustomHeaderComponent = CalendarCustomHeaderComponent;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<TransferFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: TaskReview,
    private _tasksBackend: TasksBackend,
    private _personsBackend: PersonsBackend,
    public permissionsService: NgxPermissionsService,
  ) {

    this.transferData = data;
    this.suggestionMapper = (row) => row.name;
    this.personsSource = () => this._personsBackend.getEmployees();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  dateChanged(el) {
    el.close && el.close();
  }

  personSelected(event) {
    this.selectedPerson = event ? event.id : event;
  }

  _transfer() {
    if (this.data && this.data.id) {
      this._isInserting = true;
      const updateObj: any = { comment: this.text, newExecutorId: this.selectedPerson };
      const date = new Date(this.dateStart);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      this.permissionsService.hasPermission('change_startdate') ? updateObj.dateStart = date : null;
      this.subs.push(this._tasksBackend.transferTask(this.data.id, updateObj).subscribe(res => {
        this._isInserting = false;
        swal.fire({ title: res.success ? 'Perduota sėkmingai' : 'Perdavimas nesėkmingas', timer: 2000, showConfirmButton: false, icon: res.success ? 'success' : 'error' });
        this.dialogRef.close(res);
      }, (err) => {
        this._isInserting = false;
        if (err.status === 409) {
          console.log('err', err);
          return swal.fire({ title: err.error && err.error.error, timer: 2000, showConfirmButton: false, icon: 'error' });
        }
        swal.fire({ title: 'Įvyko klaida', timer: 2000, showConfirmButton: false, icon: 'error' });
      }));
    } else {
      swal.fire({ title: 'Įvyko klaida', timer: 2000, showConfirmButton: false, icon: 'error' });
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
