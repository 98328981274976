import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { coalesceNumber } from "../../../../core/time/helpers";

export enum Level {
  HAPPY = 1,
  NORM = 2,
  ANGRY = 3
}

@Component({
  selector: 'smile',
  templateUrl: './smile.component.html',
  styleUrls: ['./smile.component.scss'],
  host: { 'class': 'smile' }
})
export class SmileComponent implements OnInit {

  constructor() { }

  @HostBinding('class.unchecked') @Input() inactive = false;

  @HostBinding('class.angry') protected get angry(): boolean {
    return this._type == Level.ANGRY;
  }
  @HostBinding('class.norm') protected get normal(): boolean {
    return this._type == Level.NORM;
  }
  @HostBinding('class.happy') protected get happy(): boolean {
    return this._type == Level.HAPPY;
  }

  private _type;
  @Input() set type(val: Level) {
    this._type = coalesceNumber(val);
  }

  @Input() showCommentOnHover = false;
  @Input() comment;
  @Input() tooltipPosition = 'above';

  get tooltipText(): string {
    if (this.showCommentOnHover) {
      return this.comment;
    }
    switch (this._type) {
      case Level.ANGRY: return 'Blogai';
      case Level.NORM: return 'Gerai';
      case Level.HAPPY: return 'Puiku';
    }
  }

  ngOnInit() {
  }

}
