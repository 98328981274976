import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, pluck } from 'rxjs/operators';
import { NewsBackend } from '../../backend/news.backend';
import { NEWS_STATUS_PUBLISHED, NEWS_STATUS_NOT_PUBLISHED } from '../../content/constants';
import { SHORT_DATE } from '../../core/time';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsDetailsComponent implements OnInit, OnDestroy {

  faCalendarAlt = faCalendarAlt;

  _id;
  _news;
  shortDate = SHORT_DATE;

  constructor(private _route: ActivatedRoute, private _newsBackend: NewsBackend, private chg: ChangeDetectorRef) {

  }

  ngOnInit() {

    this._id = this._route.params.pipe(pluck('id'));
    this._id.pipe(switchMap((id) => this._newsBackend.getNews(id)))
      .subscribe((r: any) => {
        this._news = r.entity;
        try {
          this.chg.detectChanges();
        } catch (err) { }
      });
  }

  _getStatus(status) {
    switch (status) {
      case NEWS_STATUS_PUBLISHED.status.toString(): return 'Taip'
      case NEWS_STATUS_NOT_PUBLISHED.status.toString(): return 'Ne';
      default: return null;
    }
  }

  _getPrivate(isPrivate: any) {
    return isPrivate ? 'Taip' : 'Ne';
  }

  ngOnDestroy() {

  }

}