import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Observable, of, Subject, Subscription, combineLatest } from 'rxjs';
import { UserBackend } from '../../backend/user.backend';
import { NgxPermissionsService } from 'ngx-permissions';
import { DashboardService } from '../dashboard.service';
import { takeLast } from 'rxjs/operators';

@Component({
  selector: 'violation-chart-menu',
  templateUrl: './violation-chart-menu.component.html',
  styleUrls: ['./violation-chart-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViolationChartMenuComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() userSelected = new EventEmitter();

  selectedCount = 0;
  maxSelectedCount = 4;
  maxReached = false;
  showItems = false;
  menuUsersReady = false;
  menuUsersReady$ = new Subject();

  searchField = '';

  users: any = [];

  _subscriptions: Subscription[] = [];


  protected _mapType = (t) => t.name;

  constructor(private userBackend: UserBackend, private chg: ChangeDetectorRef, protected permissionsService: NgxPermissionsService, private dashboardService: DashboardService) {
  }

  ngOnInit() {
    if (this.permissionsService.getPermission('crm_manager') || this.permissionsService.getPermission('crm_director')) {

      this.dashboardService.getUsersByGroup((this.permissionsService.getPermission('crm_director') ? true : false));
      this._subscriptions.push(
        this.dashboardService.usersByGroup$.subscribe((users) => {
          this.users = [];
          (<any>users).forEach(element => {
            this.users.push({ ...element });
          });
          this.removeUngotUsersFromLocalstorage();
          this.markSelectedFromLocalstorage();
          this.menuUsersReady = true;
          this.menuUsersReady$.next(true);
          try {
            this.chg.detectChanges();
          } catch (e) { }
        }));
    } else {
      this.menuUsersReady = true;
      this.menuUsersReady$.next(true);
      try {
        this.chg.detectChanges();
      } catch (e) { }
    }
  }

  removeUngotUsersFromLocalstorage() {
    this.dashboardService.violationChartUsersIdsList.forEach((localstorageUser) => {
      if (!this.users.find((el) => { return el.userId == localstorageUser })) {
        this.dashboardService.setViolationChartUsersLocalstorage(localstorageUser, false);
      }
    })
  }

  markSelectedFromLocalstorage() {
    this.dashboardService.violationChartUsersList = [];
    this.users.forEach(element => {
      if (this.dashboardService.violationChartUsersIdsList.find((el) => { return el == element.userId })) {
        this.selectedCount++;
        if (this.selectedCount >= this.maxSelectedCount) {
          this.maxReached = true;
        } else {
          this.maxReached = false;
        }
        element.selected = true;
        this.dashboardService.violationChartUsersList.push(element);
      }
    });
  }

  userselected(user, isSelected) {
    user.selected = isSelected;
    if (isSelected) {
      this.selectedCount++;
    } else {
      this.selectedCount--;
    }
    if (this.selectedCount >= this.maxSelectedCount) {
      this.maxReached = true;
    } else {
      this.maxReached = false;
    }
    this.dashboardService.setViolationChartUsersLocalstorage(user.userId, isSelected);
    this.dashboardService.userSelectedViolationCharts$.next({ user, isSelected });
  }

  showMenuItems() {
    this.showItems = true;
    try {
      this.chg.detectChanges();
    } catch (err) { }
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }


}
