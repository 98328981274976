import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NewsBackend } from '../../../backend/news.backend';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-news',
  templateUrl: './new-news.component.html',
  styleUrls: ['./new-news.component.scss']
})
export class NewNewsComponent implements OnInit {

  isLoading = false;

  constructor(private _newsBackend: NewsBackend, private router: Router, private chg: ChangeDetectorRef) {

  }

  ngOnInit() {
  }

  save(newObj) {
    this.isLoading = true;
    this._newsBackend.createNews(newObj).subscribe((r: any) => {
      this.isLoading = false;
      try {
        this.chg.detectChanges();
      } catch (err) { }
      if (r.success) {
        swal.fire({
          title: 'Išsaugota sėkmingai',
          timer: 2000,
          showConfirmButton: false,
          icon: 'success'
        });
        this.router.navigate(['/content/news']);
      } else {
        if (r.errorCode === 417) {
          swal.fire({
            title: 'Nėra užpildyti Santrumpa arba Tekstas laukai',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          })
        } else {
          swal.fire({
            title: 'Išsaugoti nepavyko',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          })
        }
      }
    });
  }

  cancel() {
    this.router.navigate(['/content/news']);
  }
}
