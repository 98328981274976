import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from "ngx-image-cropper";
import { UserBackend } from "../../backend/user.backend";
import { MatDialogRef } from "@angular/material/dialog";
import { fromEvent, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-photo-changer',
  templateUrl: './photo-changer.component.html',
  styleUrls: ['./photo-changer.component.scss']
})
export class PhotoChangerComponent implements OnInit, AfterViewInit {

  protected _imageChangedEvent: any = '';
  protected _croppedImage: any;
  @ViewChild(ImageCropperComponent) _cropper: ImageCropperComponent;
  @ViewChild('fileInput') _fileInput: ElementRef;
  protected _cropperVisible = true;
  protected _loading = false;

  constructor(private _userBackend: UserBackend,
    private authService: AuthService,
    private _chg: ChangeDetectorRef,
    private _dialogRef: MatDialogRef<PhotoChangerComponent>) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let event = new MouseEvent('click', { bubbles: true });
    this._fileInput.nativeElement.dispatchEvent(event);
  }

  protected _fileChangeEvent(event: any): void {
    this._imageChangedEvent = event;
  }

  protected _imageCropped(event: ImageCroppedEvent) {
    this._croppedImage = event.base64;
  }

  protected _imageLoaded() {
  }

  protected _loadImageFailed() {
  }

  public clearImage() {
    this._dialogRef.close();
  }

  protected _changePhoto() {
    if (this._croppedImage && !this._loading) {
      this._loading = true;
      this._userBackend.changePhoto(this._croppedImage).subscribe(res => {
        this._loading = false;
        this._refreshAllImages();
      },
        (err) => {
          this._dialogRef.close();
        }
      );
    }
  }

  protected _selectPhotoClicked(e: HTMLInputElement) {
    e.click();
  }

  private _refreshAllImages() {
    this._userBackend.refreshPhoto(null).subscribe(() => { this._dialogRef.close(); });
    this._userBackend.refreshPhoto(this.authService.user.usersId).subscribe();
  }

}
