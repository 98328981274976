import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { getJobParams, TaskboardSidebarService } from "../../../services/taskboardSidebar.service";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { LIST } from "../../../../services/lists/lists.data.service";
import { JobsBackend } from "../../../../backend/jobs/jobs.backend";
import swal, { SweetAlertResult } from "sweetalert2";
import { JOB_ENTERED, JOB_UPDATED } from "../../../../state/state";
import { StateService } from "../../../../services/state/stateService";
import { Time } from "../../../../core/time/types";
import { isSmallerTime, minutesToTime, parseTime, rangeMins, subtractTime, timeToString } from "../../../../core/time/helpers";
import { JobFormMode, JobFormModel } from "../../../models/job-form";
import { ResponseBase } from "../../../../core/api/response/response-base";
import { UploadFileComponent } from "../../../../template/shared/components/upload-file/upload-file.component";
import { Subscription, Subject, ReplaySubject } from 'rxjs';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../../services/auth.service';
import { CalendarCustomHeaderComponent } from '../../../../components/filters/date-interval-alt/calendarCustomHeader.component';
import { EMPTY_PARTNER } from './../../../../app.constants';
import { TasksBackend } from '../../../../backend/tasks.backend';
import { PersonsBackend } from '../../../../backend/persons.backend';

@Component({
  selector: 'job-form',
  templateUrl: './job-form.component.html',
  styleUrls: ['./job-form.component.scss']
})
export class JobFormComponent implements OnInit, AfterViewInit, OnDestroy {

  faInfoCircle = faInfoCircle;

  @ViewChild(UploadFileComponent) filesUploaderCmp: UploadFileComponent;

  _formGroup: UntypedFormGroup;

  _serviceHourlyRate;
  lastDurationView;
  changingDuration = false;
  changingFromTo = false;

  _isPaid: boolean;
  _model: JobFormModel;
  durationCopy = false;
  get _toDpa(): boolean {
    return this._model.addToDpa;
  }

  //Lists
  _partners = LIST.PARTNERS;
  _services: any = [];
  // _products = LIST.PRODUCTS;

  _initialServiceSelector = _ => false;
  _initialPartnerSelector;

  _loading: boolean;
  _loaded: boolean;

  _confirmBtnText: string = 'works';
  _firstServiceSelect = false;
  get isReview(): boolean {
    // return this._model.formMode == JobFormMode.REVIEW_JOB || this._model.formMode == JobFormMode.REVIEW_NOT_OWNED;
    return this._model.formMode == JobFormMode.REVIEW_JOB;
  }

  // get isNotOwned(): boolean {
  //   return this._model.formMode == JobFormMode.REVIEW_NOT_OWNED;
  // }

  selectedService: any = null;

  productsSource = () => this.productsList;
  getPersons;
  private productsList: Subject<any> = new ReplaySubject(1);
  showOriginalDescription = false;
  disableAutoSetOriginalDescription = false;

  private _subscriptions: Array<Subscription> = [];
  calendarCustomHeaderComponent: any = CalendarCustomHeaderComponent;

  constructor(private _fb: UntypedFormBuilder,
    private _jobsBackend: JobsBackend,
    private _stateService: StateService,
    private _sidebarService: TaskboardSidebarService,
    private _chg: ChangeDetectorRef,
    private authService: AuthService,
    private taskBackend: TasksBackend,
    private personsBackend: PersonsBackend,
  ) {
  }

  ngOnInit(): void {
    this.getPersons = () => this.personsBackend.getEmployees();
    this.durationCopy = this.authService.jobsFormDurationCopy;
    const model = getJobParams();
    // serviceId is 1 only for requests. If thats the case make it null so user to have mannually select it in job form to save job
    if (model.serviceId == 1) {
      model.serviceId = null;
      model.job.serviceId = null;
    }
    if (model.originalDescription && model.originalDescription !== ' ' && model.originalDescription !== '') {
      this.showOriginalDescription = true;
    }
    //userId control
    model.userId = model.job?.userId || this.authService.user.usersId;
    const partnerId = model.partnerId;
    if (partnerId) {
      this._initialPartnerSelector = p => p.id == partnerId;
    }
    this._model = model;
    // if (this._model.formMode == JobFormMode.EDIT_JOB || this._model.formMode == JobFormMode.REVIEW_JOB || this._model.formMode == JobFormMode.REVIEW_NOT_OWNED) {
    if (this._model.formMode == JobFormMode.EDIT_JOB || this._model.formMode == JobFormMode.REVIEW_JOB) {
      this._services = LIST.SERVICES_ALL;
    } else if (this._model.formMode == JobFormMode.TASK_JOB || this._model.formMode == JobFormMode.COMMENT_JOB || this._model.formMode == JobFormMode.TIMER_JOB) {
      this._services = LIST.SERVICES;
    } else if (this._model.formMode == JobFormMode.FREE_JOB) {
      this._services = LIST.SERVICES_ALL;
    }
    this._formGroup = model.buildForm(this._fb);
    if (model.serviceId && (model.hourlyRate || model.hourlyRate === 0)) {
      this._firstServiceSelect = true;
    }
    this._subscriptions.push(this._formGroup.controls.isPaid.valueChanges.subscribe((event) => {
      this._model.isPaid = event;
      this._isPaidChange(event);
    }));
    this._subscriptions.push(this._formGroup.controls.hourlyRate.valueChanges.subscribe((event) => {
      this._model.hourlyRate = event;
    }));
    this._subscriptions.push(this._formGroup.controls.visibleSum.valueChanges.subscribe((event) => {
      this._model.visibleSum = event;
      this._checkToDisableFileds();
    }));
    this._checkToDisableFileds();
    this._model.durationView = timeToString(minutesToTime(rangeMins(parseTime(this._model.timeFrom), parseTime(this._model.timeTo))));
    this.lastDurationView = parseTime(this._model.durationView);
    //when creating job from comment its id is set so we have to check this._model.formMode == JobFormMode.COMMENT_JOB
    if ((!this._model.job || (this._model.job && !this._model.job.id) || (this._model.formMode == JobFormMode.COMMENT_JOB) || (this._model.formMode == JobFormMode.TASK_JOB)) && this.durationCopy) {
      this._model.duration = this._model.durationView;
    }
  }

  ngAfterViewInit() {
    if (this.filesUploaderCmp) {
      this._model.filesKey = this.filesUploaderCmp.key;
    }
  }

  showOriginalDescriptionChange() {
    if (this.showOriginalDescription && !this.disableAutoSetOriginalDescription) {
      if (this.selectedService?.code === 'AP99') {
        this._model.originalDescription = 'Situacijos analizė';
      }
    } else {
      this._model.originalDescription = null;
    }
  }

  removeParticipantsCheck() {
    return this.selectedService?.code === 'AP99' && this._model?.extras?.isCrmUserDelegate;
  }

  private _loadProducts(sp) {
    if (!sp || sp.id == EMPTY_PARTNER) {
      this.productsList.next([]);
    } else {
      //TODO 1
      this.taskBackend.getRequestProductsByPartner(sp.id)
        // .subscribe((res) => this.productsList.next(res.map(el => { return { ...el, isClientProduct: el.id % 2 == 0 ? true : false } })));
        .subscribe((res) => this.productsList.next(res));
    }
  }

  dateChanged(el) {
    el.close && el.close();
  }

  _checkToDisableFileds() {
    if (this._formGroup && this._formGroup.controls && !this.isReview) {
      if (this._formGroup.controls.visibleSum.value) {
        this._formGroup.controls.sumNoVat.enable();
        this._formGroup.controls.hourlyRate.disable();
      } else {
        this._formGroup.controls.sumNoVat.disable();
        this._formGroup.controls.hourlyRate.enable();
      }
    }
  }

  _isPaidChange(event) {
    if (!event) {
      this._model.hourlyRate = 0;
      this._model.travelPrice = 0;
      this._model.sumNoVat = 0;
      this._formGroup.controls.hourlyRate.setValue(0);
      this._formGroup.controls.travelPrice.setValue(0);
      this._formGroup.controls.sumNoVat.setValue(0);
    } else {
      this._model.hourlyRate = this._serviceHourlyRate;
      this._formGroup.controls.hourlyRate.setValue(this._serviceHourlyRate);
    }
    this._checkToDisableFileds();
  }

  _serviceSelected(obj: any) {
    this.selectedService = obj;
    if (!obj) {
      this._model.hourlyRate = 0;
      this._model.serviceId = undefined;
      this._serviceHourlyRate = 0;
      this._formGroup.controls.hourlyRate.setValue(this._serviceHourlyRate);
      return;
    }
    if (this.selectedService?.code === 'AP99') {
      if (!this._firstServiceSelect) {
        this._formGroup.controls.isPaid.setValue(true);
        this.showOriginalDescription = true;
        this._model.originalDescription = 'Situacijos analizė';
      }
    }
    if (this._firstServiceSelect) {
      this._firstServiceSelect = false;
      this._serviceHourlyRate = obj.price; //TODO mark1
    } else {
      this._model.hourlyRate = obj.price;
      this._model.serviceId = obj.id;
      this._serviceHourlyRate = obj.price;
      this._formGroup.controls.hourlyRate.setValue(this._serviceHourlyRate);
    }
  }

  _timeChanged(what: 'durationView' | 'from' | 'to' | 'duration', time: Time) {
    const now = parseTime(new Date());
    const t = timeToString(time);
    switch (what) {
      case "durationView":
        this._model.durationView = t;
        if (this.durationCopy) {
          this._model.duration = t;
        }
        if (this.changingFromTo) {
          return;
        }
        this.changingDuration = true;
        const op1 = isSmallerTime(now, time);
        if (op1 !== 1) {
          // now is smaller than or equal to duration
          this._model.timeFrom = timeToString({ hours: 0, minutes: 0 });
          this._model.timeTo = t;
        }
        if (op1 === 1) {
          // now is greater then duration
          this._model.timeFrom = timeToString(subtractTime(now, time));
          this._model.timeTo = timeToString(now);
        }
        setTimeout(() => {
          this.changingDuration = false;
        }, 0);
        this.lastDurationView = time;
        return;
      case "from":
        this._model.timeFrom = t;
        if (this.changingDuration) {
          return;
        }
        this.changingFromTo = true;
        this._model.durationView = timeToString(minutesToTime(rangeMins(parseTime(this._model.timeFrom), parseTime(this._model.timeTo))));
        setTimeout(() => {
          this.changingFromTo = false;
        }, 0);
        if (this.durationCopy) {
          this._model.duration = this._model.durationView;
        }
        return;
      case "to":
        this._model.timeTo = t;
        if (this.changingDuration) {
          return;
        }
        this.changingFromTo = true;
        this._model.durationView = timeToString(minutesToTime(rangeMins(parseTime(this._model.timeFrom), parseTime(this._model.timeTo))));
        setTimeout(() => {
          this.changingFromTo = false;
        }, 0);
        if (this.durationCopy) {
          this._model.duration = this._model.durationView;
        }
        return;
      case "duration": this._model.duration = t; return;
    }
  }

  durationCopyChange() {
    const durationCopy = this.durationCopy;
    if (this.durationCopy) {
      this._model.duration = this._model.durationView;
    }
    this._subscriptions.push(this._jobsBackend.setjobsFormDurationCopy(durationCopy).subscribe(() => {
      this.authService.setjobsFormDurationCopy(durationCopy);
    }));
  }

  _submit(_form: UntypedFormGroup) {
    if (this._loading) {
      return;
    }
    this._loading = true;
    this._subscriptions.push(this._model.submitFn(this._jobsBackend).subscribe(
      (res: ResponseBase<any>) => {
        this._loading = false;
        try {
          this._chg.detectChanges();
        } catch (err) { }
        if (!res.success) {
          this._showError(res.error);
          return;
        }
        if (this._model.formMode == JobFormMode.EDIT_JOB) {
          this._stateService.notify(JOB_UPDATED, res.entity);
          this._showSuccess('Darbas paredaguotas sėkmingai')
            .then(() => {
              this._sidebarService.close();
            });
        } else {
          this._stateService.notify(JOB_ENTERED, res.entity);
          this._showSuccess('Darbas sukurtas sėkmingai')
            .then(() => {
              this._sidebarService.close();
            });
        }
      }, (err) => {
        this._loading = false;
        try {
          this._chg.detectChanges();
        } catch (err) { }
        this._showError('Įvyko klaida įrašant darbą');
      }
    ));
  }

  private _showSuccess(msg: string): Promise<SweetAlertResult> {
    return swal.fire({
      title: msg,
      timer: 2000,
      showConfirmButton: false,
      icon: 'success'
    });
  }

  private _showError(msg: string) {
    return swal.fire({
      title: msg,
      timer: 3000,
      showConfirmButton: false,
      icon: 'error'
    });
  }

  private sumNoVatChange(event) {
    this._model.visibleSum = true;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }
}
