import { SharedModule } from './../template/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentComponent } from './content.component';
import { ContentMenuComponent } from './contentMenu/contentMenu.component';
import { ContentListComponent } from './content-list/content-list.component';
import { NewNewsComponent } from './news/new-news/new-news.component';
import { EditNewsComponent } from './news/edit-news/edit-news.component';
import { NewsFormComponent } from './news/news-form/news-form.component';


const CONTENT_ROUTE = [
  {
    path: '', component: ContentComponent,
    children: [
      {
        path: ':type',
        component: ContentListComponent,
      },
      {
        path: 'news/new',
        component: NewNewsComponent,
      },
      {
        path: 'news/:id',
        component: EditNewsComponent,
      },
    ]
  },
];

@NgModule({
    declarations: [
        ContentComponent,
        ContentMenuComponent,
        ContentListComponent,
        NewNewsComponent,
        EditNewsComponent,
        NewsFormComponent,
    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(CONTENT_ROUTE)
    ]
})
export class ContentModule { }
