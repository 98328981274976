import { TASK_STATE_CANCELED, TASK_STATE_COMPLETED, TASK_STATE_FINISHED } from "../../../core/constants";

//Array utils
export function zip<T1, T2, T3>(a: Array<T1>, b: Array<T2>, z: (T1, T2) => T3): Array<T3> {
  if (a.length != b.length) {
    throw "zip: Arrays must be of same length!";
  }
  const r: Array<T3> = [];
  for (let i = 0; i < a.length; i++) {
    r.push(z(a[i], b[i]));
  }
  return r;
}

export function remove(a: Array<any>, el: any) {
  let i = a.indexOf(el);
  if (i < 0) {
    return a;
  }
  return a.slice(0, i).concat(a.slice(i + 1));
}


//Object utils
export function flatMap(o: Object): Object {
  const res = {};
  for (let key in o) {
    if (o[key] instanceof Object) {
      const o1 = o[key];
      for (let oo in o1) {
        res[oo] = o1[oo];
      }
    } else {
      res[key] = o[key];
    }
  }
  return res;
}

export function trimObject(o: Object, l = 1, ignoreSpaces = false) {
  const obj = {};
  for (const key of Object.keys(o)) {
    const p = o[key];
    if (typeof p == "string") {
      obj[key] = strOrNull(p, l, ignoreSpaces);
    } else {
      obj[key] = p;
    }
  }
  return obj;
}

//String utils
export function replaceAt(str: string, replacement: string, index: number): string {
  return str.substr(0, index) + replacement + str.substr(index + replacement.length);
}

export function strOrNull(str: string, l = 1, ignoreSpaces = false) {
  if (!str) {
    return null;
  }
  if ((ignoreSpaces ? str : str.trim()).length >= l) {
    return str;
  }
  return null;
}

export function arrToString(arr: Array<string>) {
  const str = arr.filter(s => !!s && s.trim() != '').reduce((acc, curr) => acc += curr + ', ', '');
  if (str.endsWith(', ')) {
    return str.substr(0, str.length - 2);
  }
  return str;
}

//Debugging utils
export function log(tag: string, ...msg: any[]) {
  _log.apply(this, ['BLACK', tag].concat(...msg));
}

export function logC(tag: string, ...msg: any[]) {
  _log.apply(this, ['GREEN', tag].concat(...msg));
}

export function logE(tag: string, ...msg: any[]) {
  _log.apply(this, ['RED', tag].concat(...msg));
}

export function logW(tag: string, ...msg: any[]) {
  _log.apply(this, ['YELLOW', tag].concat(...msg));
}

function _log(color: string, tag: string, ...msg: any[]) {
  // console.log.apply(this, [`%c${tag}:`, `color:${color}`].concat(msg));
}

export function printObj(obj: any) {
  if (!obj) {
    logC('---- UNDEFINDED ----');
    return;
  }
  for (const prop in obj) {
    logC(prop, obj[prop]);
  }
}

export function notNull(x: any) {
  return x != null && x != undefined;
}

export function mailTo(recepient, opts) {
}

export function generatePassword(length: number) {
  const charset = "abcdefghijkmnopqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ0123456789";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

//Date
export function toDate(d: any): Date {
  let date: Date;
  try {
    date = d ? new Date(d) : null;
  } catch (e) { }
  return date;
}

export function prepareDate(date: Date) {
  return date.toLocaleDateString('LT');
}

export function compareToToday(dateP: any) {
  if (!dateP) return false;
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0);
  const todayEnd = new Date();
  todayEnd.setHours(23, 59, 59, 999);
  const date = new Date(dateP);
  if (date.getTime() < todayStart.getTime()) return -1;
  if (date.getTime() >= todayStart.getTime() && date.getTime() <= todayEnd.getTime()) return 0;
  if (date.getTime() > todayEnd.getTime()) return 1;
}

export function isEditableTask(stateId: any) {
  return stateId !== TASK_STATE_COMPLETED && stateId !== TASK_STATE_FINISHED && stateId !== TASK_STATE_CANCELED;
}
