import { NgxPermissionsService } from 'ngx-permissions';
import { UserService } from './../../../services/user/user.service';
import { Component, ElementRef, HostListener, OnInit, ChangeDetectorRef } from "@angular/core";
import { GlobalState } from "../../app.state";
import { ConfigService } from "../../shared/services/config/config.service";
import { redirectToPath as redirectToPathTaskboard } from '../../../taskboard/taskboard.routing.module';
import { redirectToPath as redirectToPathProjects } from '../../../projects/projects.routing.module';
import { redirectToPath as redirectToPathMarketing } from '../../../marketing/marketing.routing.module';
import { redirectToPath as redirectToPathSales } from '../../../sales/sales.routing.module';

type MenuEntry = { name: string, routerLink: any, icon: string };

@Component({
  selector: "app-sidebar",
  templateUrl: "./left-sidebar.component.html",
  styleUrls: ["./left-sidebar.component.scss"]
})
export class LeftSidebarComponent implements OnInit {
  public scrollbarOptions = {
    axis: "y",
    theme: "minimal",
    scrollInertia: 0,
    mouseWheel: { preventDefault: true }
  };
  _expanded: boolean = false;

  _menu: Array<MenuEntry> = [];

  private smallScreen;

  constructor(public config: ConfigService,
    private _elementRef: ElementRef,
    private _state: GlobalState,
    private _userService: UserService,
    private _permissionsService: NgxPermissionsService) {
    this._state.subscribe("app.isCollapsed", isCollapsed => {
      this.config.appLayout.isApp_SidebarLeftCollapsed = isCollapsed;
    });
  }

  ngOnInit() {
    if (window.innerWidth < 992) {
      this.smallScreen = true;
    }
    if (window.innerWidth > 992) {
      this.smallScreen = false;
    }
    this.loadMenu();
    this._userService.permissionsLoaded.subscribe(() => {
      this.loadMenu();
    })
  }

  loadMenu() {
    const menu = []
    const showAdmin = this._permissionsService.getPermission('administrate_crm');
    const showContent = this._permissionsService.getPermission('news_editor');
    menu.push({
      name: 'Skydelis',
      routerLink: ['/dashboards'],
      icon: 'zmdi-apps',
    });
    menu.push({
      name: 'Aptarnavimas',
      routerLink: ['/taskboard'],
      icon: 'zmdi-view-dashboard',
      resetPageLink: '/taskboard/' + redirectToPathTaskboard(),
    });
    menu.push({
      name: 'Projektai',
      routerLink: ['/projects'],
      icon: 'zmdi-case',
      resetPageLink: '/projects/' + redirectToPathProjects(),
    });
    menu.push({
      name: 'Marketingas',
      routerLink: ['/marketing'],
      icon: 'zmdi-store',
      resetPageLink: '/marketing/' + redirectToPathMarketing(),
    });
    menu.push({
      name: 'Pardavimai',
      routerLink: ['/sales'],
      icon: 'zmdi-balance',
      resetPageLink: '/sales/' + redirectToPathSales(),
    });
    if (showAdmin) {
      menu.push({
        name: 'Administravimas',
        routerLink: ['/admin/users'],
        icon: 'zmdi-settings-square',
        resetPageLink: '/admin/users',
      });
    }
    if (showContent) {
      menu.push({
        name: 'Turinio valdymas',
        routerLink: ['/content/news'],
        icon: 'zmdi-tune',
        resetPageLink: '/content/news',
      });
    }
    this._menu = menu;
  }

  resetPageSessionStorage(menu) {
    if (menu.resetPageLink) {
      sessionStorage.removeItem('currentPage_' + menu.resetPageLink);
    }
  }

  toggleMenuSideabar() {
    this.config.appLayout.isApp_SidebarLeftCollapsed = !this.config.appLayout.isApp_SidebarLeftCollapsed;
    this._state.notifyDataChanged("app.isCollapsed", this.config.appLayout.isApp_SidebarLeftCollapsed);
    return false;
  }

  @HostListener("window:resize")
  public onWindowResize(): void { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 992) {
      this.smallScreen = true;
    }
    if (event.target.innerWidth > 992) {
      this.smallScreen = false;
    }
  }

  toggleIfSmallScreen() {
    if (this.smallScreen) {
      this.config.appLayout.isApp_MobileSidebarLeftOpen = false;
      this.config.appLayout.isApp_BackdropVisible = false;
      this._state.notifyDataChanged('app.isApp_MobileSidebarLeftOpen', this.config.appLayout.isApp_MobileSidebarLeftOpen);
      this._state.notifyDataChanged('app.isApp_BackdropVisible', this.config.appLayout.isApp_BackdropVisible);
    }
  }

}
