
import { toArray, filter, mergeMap } from 'rxjs/operators';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TasksBackend } from "../../../../backend/tasks.backend";
import { UntypedFormBuilder } from "@angular/forms";
import { TaskReview } from "../../../models/task-review";
import { ListsBackend } from '../../../../backend/lists.backend';
import { ProjectsBackend } from '../../../../backend/projects.backend';
import { Subscription } from 'rxjs';
import { TASKTYPE } from '../../../../projects/projects.component';
import swal from 'sweetalert2';

@Component({
  selector: 'update-project-stage-modal',
  templateUrl: './update-project-stage-modal.component.html',
  styleUrls: ['./update-project-stage-modal.component.scss']
})
export class UpdateProjectStageComponent implements OnInit, OnDestroy {

  currentStage: any;
  stagesList: any;
  subs: Subscription[] = [];
  initialStageSelector: any;
  isDisabled: boolean = true;
  selectedStage;
  submitting = false;
  submitted = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private tasksBackend: TasksBackend,
    private projectsBackend: ProjectsBackend,
    public dialogRef: MatDialogRef<UpdateProjectStageComponent>,
    private chg: ChangeDetectorRef,
    private _formBuilder: UntypedFormBuilder) {
    // this._taskProducts = () => listsBackend.getProducts().pipe(mergeMap(p => <any>p), filter((p: any) => p.id != data.productId), toArray());
    // this._initialProduct = data.productId;
    // this._initialProductSelector = (p) => p.id == data.productId;

  }

  ngOnInit() {
    this.initialStageSelector = (p) => p.stageId == this.data.projectDetails.stageId;
    this.stagesList = () => this.projectsBackend.getProjectStages(TASKTYPE).pipe(mergeMap(p => <any>p), filter((p: any) => p.stageId != this.data.stageId), toArray());
  }


  protected submit() {
    this.submitting = true;
    this.subs.push(this.projectsBackend.updateProjectStage(this.data.projectDetails.projectId, this.selectedStage).subscribe(res => {
      this.submitting = false;
      this.submitted = true;
      this.dialogRef.close(res);
    }, (err) => {
      this.submitting = false;
      swal.fire({
        title: 'Įvyko klaida',
        timer: 2000,
        showConfirmButton: false,
        icon: 'error'
      });
    }));
  }

  protected stageSelected(stage) {
    this.isDisabled = !stage;
    this.selectedStage = stage;
  }

  protected cancel() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
