import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-partner',
  templateUrl: './edit-partner.component.html',
  styleUrls: ['./edit-partner.component.scss']
})
export class EditPartnerComponent implements OnInit, OnDestroy {

  _subscriptions: Subscription[] = [];
  partnerId: number;

  constructor(private _route: ActivatedRoute) { }

  ngOnInit() {
    this._subscriptions.push(this._route.params.subscribe(params => {
      if (params['id']) {
        try {
          this.partnerId = parseInt(params['id']);
        } catch (err) {
          console.log(err);
        }
      }
    }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subs) => {
      subs.unsubscribe();
    });
  }

}
