
import { skip } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { SelectedPartnerService } from "../../services/partner/selectedPartner.service";
import { EMPTY_PARTNER } from "../../app.constants";
import { ActivatedRoute, Router } from "@angular/router";
import { BY_ME, DPA, FOR_ME, FOR_MY_GROUP, JOBS, REQUESTS, TASKS, TRANSFERED, PARTNER as PARTNER_URL, DPAAPT, GROUPDPA } from "../taskboard.help";
import { formPath } from "../../core/routing/helpers";
import { RoutingService } from "../../template/shared/proto/services/routing.service";
import { flatMap } from "../../core/helpers";
import { StorageOptions } from '../../template/shared/proto/storage/StorageOptions';
import { StorageService } from '../../template/shared/proto/storage/storage.service';

type SecondMenu = Array<{ title: string, entries: Array<{ title: string, routerLink: string, queryParams?: any }> }>;
const NO_PARTNER = 'noPartner';
const PARTNER = 'partner';
const USER = 'user';
// const NO_USER = 'noUser';
const NO_PARTNER_MENU: any = [
  {
    title: 'MANO ĮVESTOS',
    icon: 'zmdi zmdi-account-add',
    menuId: NO_PARTNER,
    entries: [
      {
        title: 'Užduotys',
        isActive: false,
        routerLink: formPath(TASKS, BY_ME),
      },
      {
        title: 'Darbai',
        isActive: false,
        routerLink: JOBS,
      },
      {
        title: 'DPA',
        isActive: false,
        routerLink: DPA
      },
    ]
  },
  {
    title: 'MAN SKIRTOS',
    icon: 'zmdi zmdi-account',
    menuId: NO_PARTNER,
    entries: [
      {
        title: 'Užduotys',
        isActive: false,
        routerLink: formPath(TASKS, FOR_ME)
      },
      {
        title: 'Užklausos',
        isActive: false,
        routerLink: formPath(REQUESTS, FOR_ME)
      }
    ]
  },
  {
    title: 'MANO GRUPEI SKIRTOS',
    icon: 'zmdi zmdi-accounts',
    menuId: NO_PARTNER,
    entries: [
      {
        title: 'Užduotys',
        isActive: false,
        routerLink: formPath(TASKS, FOR_MY_GROUP)
      },
      {
        title: 'Užklausos',
        isActive: false,
        routerLink: formPath(REQUESTS, FOR_MY_GROUP)
      },
      {
        title: 'DPA',
        isActive: false,
        routerLink: GROUPDPA
      },
      {
        title: 'Procesų DPA',
        isActive: false,
        routerLink: DPAAPT
      },
    ]
  },
  {
    title: 'MANO PERDUOTOS',
    icon: 'zmdi zmdi-accounts-list',
    menuId: NO_PARTNER,
    entries: [
      {
        title: 'Užduotys',
        isActive: false,
        routerLink: formPath(TASKS, TRANSFERED)
      },
      {
        title: 'Užklausos',
        isActive: false,
        routerLink: formPath(REQUESTS, TRANSFERED)
      },
    ]
  }
];
let PARTNER_MENU: any = [
  {
    title: 'PARTNERIO',
    icon: 'zmdi zmdi-account-box-o',
    menuId: PARTNER,
    entries: [
      {
        title: 'Užklausos',
        isActive: false,
        routerLink: formPath(REQUESTS),
      },
      {
        title: 'Užduotys',
        isActive: false,
        routerLink: formPath(TASKS),
      },
      {
        title: 'Darbai',
        isActive: false,
        routerLink: formPath(JOBS),
      },
      {
        title: 'DPA',
        isActive: false,
        routerLink: formPath(DPA),
      },
      {
        title: 'Procesų DPA',
        isActive: false,
        routerLink: formPath(DPAAPT)
      },
      {
        title: 'Partnerio Informacija',
        isActive: false,
        routerLink: formPath(PARTNER_URL),
      },
    ]
  },
];

const USER_MENU: any = [
  {
    title: 'VARTOTOJO',
    icon: 'zmdi zmdi-account-o',
    menuId: USER,
    entries: [
      {
        title: 'Užklausos',
        isActive: false,
        routerLink: formPath(REQUESTS, FOR_ME),
      },
      {
        title: 'Užduotys',
        isActive: false,
        routerLink: formPath(TASKS, FOR_ME),
      },
      {
        title: 'Darbai',
        isActive: false,
        routerLink: formPath(JOBS),
      },
      {
        title: 'DPA',
        isActive: false,
        routerLink: formPath(DPA),
      },
    ]
  },
];

@Component({
  selector: "taskboard-menu",
  templateUrl: "./taskBoardMenu.component.html",
  styleUrls: ["./taskBoardMenu.component.scss"]
})
export class TaskBoardMenuComponent implements OnInit, OnDestroy {

  partnerMode: boolean;
  menu: Observable<any> = new BehaviorSubject([]);
  showMenu: boolean = false;
  prevMenu: any;
  private _subscriptions: Array<Subscription> = [];
  private currentMenu;
  private currentPartner;
  private currentUser;
  private _currentEntry;
  private currentMenuInst: any;
  private _pageSavingOptions: StorageOptions = {
    popState: true,
    refresh: true
  };

  constructor(private selectedPartner: SelectedPartnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private chg: ChangeDetectorRef,
    private _storageService: StorageService,
    private routingService: RoutingService) {
  }

  private _currentUrl: string;
  private _prevUrl;

  setPartnerMenu(id) {
    PARTNER_MENU = [
      {
        title: 'PARTNERIO',
        icon: 'zmdi zmdi-account-box-o',
        menuId: PARTNER,
        entries: [
          {
            title: 'Informacija',
            isActive: false,
            routerLink: formPath(PARTNER_URL, id),
          },
          {
            title: 'Užklausos',
            isActive: false,
            routerLink: formPath(REQUESTS),
          },
          {
            title: 'Užduotys',
            isActive: false,
            routerLink: formPath(TASKS),
          },
          {
            title: 'Darbai',
            isActive: false,
            routerLink: formPath(JOBS),
          },
          {
            title: 'DPA',
            isActive: false,
            routerLink: formPath(DPA),
          },
          {
            title: 'Procesų DPA',
            isActive: false,
            routerLink: formPath(DPAAPT),
          },
        ]
      },
    ];
  }

  ngOnInit() {
    this._subscriptions.push(this.selectedPartner.selectedPartner.subscribe((partner) => {
      // if (sp.id == EMPTY_PARTNER && this.currentUser == null) {
      const sp = partner.partner;
      if (sp.id == EMPTY_PARTNER) {
        if (partner.isEmpty) {
          this.currentPartner = null;
          this.currentUser = null
          this.currentMenu = NO_PARTNER;
          (<Subject<SecondMenu>>this.menu).next(NO_PARTNER_MENU);
        } else if (partner.isUser) {
          this.currentUser = null;
          this.currentMenu = NO_PARTNER;
          (<Subject<SecondMenu>>this.menu).next(NO_PARTNER_MENU);
        } else if (!partner.isUser) {
          this.currentPartner = null;
          this.currentMenu = NO_PARTNER;
          (<Subject<SecondMenu>>this.menu).next(NO_PARTNER_MENU);
        }
      } else {
        if (partner.isUser) {
          this.currentUser = sp;
          this.currentMenu = USER;
          const menuInst = <any>USER_MENU.map((m) => {
            m.entries = m.entries.map((e) => {
              e['queryParams'] = { u: sp.id };
              e.isActive = this._currentEntry && this._currentEntry.title == e.title ? this._currentEntry.isActive : false;
              return e;
            });
            return m
          });
          (<Subject<SecondMenu>>this.menu).next(menuInst);
        } else if (!partner.isUser) {
          this.currentPartner = sp;
          this.setPartnerMenu(this.currentPartner.id);
          this.currentMenu = PARTNER;
          const menuInst = <any>PARTNER_MENU.map((m) => {
            m.entries = m.entries.map((e) => {
              e['queryParams'] = { p: sp.id };
              e.isActive = this._currentEntry && this._currentEntry.title == e.title ? this._currentEntry.isActive : false;
              return e;
            });
            return m
          });
          (<Subject<SecondMenu>>this.menu).next(menuInst);
        }

      }

      this.showMenu = true;
    }));

    // this._subscriptions.push(this.selectedPartner.selectedUser.subscribe((usr) => {
    //   // if (usr.id == EMPTY_PARTNER && this.currentPartner == null) {
    //   if (usr.id == EMPTY_PARTNER) {
    //     this.currentUser = null;
    //     this.currentMenu = NO_PARTNER;
    //     (<Subject<SecondMenu>>this.menu).next(NO_PARTNER_MENU);
    //     // } else if (this.currentPartner == null) {
    //   } else {
    //     this.currentUser = usr;
    //     // this.setPartnerMenu(this.currentUser.id);
    //     this.currentMenu = USER;
    //     const menuInst = <any>USER_MENU.map((m) => {
    //       m.entries = m.entries.map((e) => {
    //         e['queryParams'] = { u: usr.id };
    //         e.isActive = this._currentEntry && this._currentEntry.title == e.title ? this._currentEntry.isActive : false;
    //         return e;
    //       });
    //       return m
    //     });
    //     (<Subject<SecondMenu>>this.menu).next(menuInst);
    //   }
    //   console.log('selected user');
    //   this.showMenu = true;
    // }));

    this._subscriptions.push(this.menu.pipe(skip(1)).subscribe((m) => {
      if (m && m.length > 0 && this.prevMenu && this.prevMenu !== m[0].menuId) {
        this.router.navigate([m[0].entries[0].routerLink], { relativeTo: this.activatedRoute, queryParams: m[0].entries[0].queryParams })
      }
      this.prevMenu = m[0].menuId;
    }));

    this._subscriptions.push(this.menu.subscribe((m) => {
      this.currentMenuInst = m;
    }));

    this._subscriptions.push(this.routingService.currentRoute.subscribe((cr) => {
      this._prevUrl = this._currentUrl;
      try {
        let currentUrl = cr.url.split('/taskboard/')[1];
        if (currentUrl.indexOf('/v/') < 0) {
          const parts = currentUrl.split('?');
          if (parts.length > 1) {
            currentUrl = parts[0];
          }
          // const current = flatMap(cr.queryParams.p ? PARTNER_MENU : NO_PARTNER_MENU, m => m.entries).find(e => e.routerLink == currentUrl);
          let current;
          if (cr.queryParams.p) {
            this.selectedPartner.reemitSelectedPartner();
            current = flatMap(PARTNER_MENU, m => m.entries).find(e => e.routerLink == currentUrl);
          } else if (cr.queryParams.u) {
            this.selectedPartner.reemitSelectedUser();
            current = flatMap(USER_MENU, m => m.entries).find(e => e.routerLink == currentUrl);
          } else {
            current = flatMap(NO_PARTNER_MENU, m => m.entries).find(e => e.routerLink == currentUrl);
          }
          if (this._currentEntry) {
            this._currentEntry.isActive = false;
          }
          if (current) {
            // const menuEntries = flatMap(cr.queryParams.p ? PARTNER_MENU : NO_PARTNER_MENU, m => m.entries);

            let menuEntries;
            if (cr.queryParams.p) {
              menuEntries = flatMap(PARTNER_MENU, m => m.entries);
            } else if (cr.queryParams.u) {
              menuEntries = flatMap(USER_MENU, m => m.entries);
            } else {
              menuEntries = flatMap(NO_PARTNER_MENU, m => m.entries);
            }
            if (menuEntries && menuEntries.length) {
              menuEntries.forEach(item => item.isActive = false);
            }
            current.isActive = true;
          }
          this._currentEntry = current;
        }

      } catch (e) {
        this._currentUrl = '';
      }
      try {
        this.chg.detectChanges();
      } catch (e) { }
    }));
  }

  resetPageSessionStorage() {
    this._storageService.store('currentPage', 0, this._pageSavingOptions);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sp) => sp.unsubscribe());
  }
}
