import { Observable } from "rxjs";

type VisibleTime = { h: number, m: number, s: number };

export enum TimerState {
  FINISHED = -1,
  PAUSED = 0,
  RUNNING = 1
}

export interface Timer {
  id: number
  taskId: number
  time: number
  allTime: number
  visibleTime?: string
  title: string
  state: TimerState
  jobId?: number
}

const padNum = (n: number) => {
  if (n < 10) {
    return '0' + (n || 0);
  } else {
    return '' + n;
  }
};

export const msToVisibleTime: (number) => VisibleTime = (ms: number) => <VisibleTime>({
  h: Math.trunc(ms / (1000 * 60 * 60)),
  m: Math.trunc((ms / (1000 * 60)) % 60),
  s: Math.trunc((ms / 1000) % 60)
});

export const visibleTimeToString = (vs: VisibleTime) => padNum(vs.h) + ':' + padNum(vs.m) + ':' + padNum(vs.s);

export const msToTimeString: (ms: number) => string = (ms) => visibleTimeToString(msToVisibleTime(ms));
