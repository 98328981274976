export type ConverterArgs = {
  throw?: boolean,
  error?: string,
  default?: any
};

export type StringConverterArgs = {
  emptyStringToUndefined?: boolean,
  pattern?: RegExp,
  patternError?: string,
} & ConverterArgs;

export type NumberConverterArgs = {
  float?: boolean
} & ConverterArgs;

export type MoneyConverterArgs = {
  separator?: string,
  decimals?: number
} & ConverterArgs;

export type DateConverterArgs = {
  fromNumber: boolean
} & ConverterArgs;

export type IdConverterArgs = NumberConverterArgs;

function verifyResult(res: any, args: ConverterArgs, val) {
  if (res == null && (args.error || args.throw)) {
    if (args && args.error) {
      throw new Error(args.error);
    } else {
      //TODO: prideti parametra tipui
      throw new Error('Cannot cast property value to boolean: ' + val);
    }
  }
  return res;
}

export function toBool(val: any) {
  switch (typeof val) {
    case "string":
      const normalized = val.toLowerCase().replace(' ', '');
      switch (normalized) {
        case "true": return true;
        case "false": return false;
        case "1": return true;
        case "0": return false;
        default:
          return undefined;
      }
    case "boolean": return val;
    case "number": return val > 0;
    case "object":
    case "function":
      return undefined;
  }
}

export function stringOrUndefined(val: any, nonEmpty = true): string | undefined {
  if (typeof val === 'string') {
    if (nonEmpty) {
      return val.replace(/\s/g, '').length > 0 ? val : undefined;
    } else {
      return val;
    }
  }
  return undefined;
}

export function toNumber(val: any, toFloat?: boolean): number | undefined {
  switch (typeof val) {
    case 'number': return val;
    case 'string':
      const n = val.length > 0 && val[0] == '.' ? ('0' + val) : val;
      return toFloat ? parseFloat(n) : parseInt(n);
    default: return undefined;
  }
}

export function toFloat(val: any) {

}

export function toDate(val: any) {
  if (val instanceof Date) {
    return val;
  }
  switch (typeof val) {
    case 'number':
    case 'string': return new Date(val);
  }
}

export const converters = {
  toBoolean: (val: any, args?: ConverterArgs) => {
    return verifyResult(toBool(val), args, val);
  },
  toString: (val: any, args?: StringConverterArgs) => {
    const str: string = verifyResult(stringOrUndefined(val, args && args.emptyStringToUndefined), args, val);
    if (args && args.pattern && str) {
      if (!str.match(args.pattern)) {
        throw new Error(args.patternError || `String ${str} does not matches pattern: ${args.pattern}`);
      }
    }
    return str;
  },
  toFloat: (val: any, args?: StringConverterArgs) => {

  },
  toNumber: (val: any, args: NumberConverterArgs) => {
    return verifyResult(toNumber(val, args.float), args, val);
  },
  toDate: (val: any, args?: DateConverterArgs) => {
    return verifyResult(toDate(val), args, val);
  },
  toMoney: (val: any, args?: MoneyConverterArgs) => {
    const money: number = converters.toNumber(val, args);
    if (!money) {
      return '0';
    }
    if (!args) {
      return money.toFixed(2);
    } else {
      let m = money;
      if (args.decimals) {
        m = <any>m.toFixed(args.decimals);
      }
      if (args.separator) {
        m = <any>(m + '').replace('.', args.separator);
      }
      return m;
    }
  },
  toId: (val: any, args?: IdConverterArgs) => {
    const id = converters.toNumber(val, args);
    if (id <= 1) {
      return undefined;
    }
    return id;
  }
};


