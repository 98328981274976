import { Component } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "select-options",
  templateUrl: "./select-options.component.html",
  styleUrls: ["./select-options.component.scss"]
})
export class SelectOptionsComponent {

  //SELECTS

  priorities: any[];
  partners: any[];
  products: any[];
  services: any[];

  floatPlaceholder: string = "auto";
  topHeightCtrl = new UntypedFormControl(0);
  selectTheme = "primary";

  servicesRequired = false;
  servicesDisabled = false;
  currentService: string;

  productsRequired = false;
  productsDisabled = false;
  currentProduct: string;

  partnersRequired = false;
  partnersDisabled = false;
  currentPartner: string;

  prioritiesRequired = false;
  prioritiesDisabled = false;
  currentPriority: string;

  availableThemes = [
    { value: "primary", name: "Primary" }
  ];
}

