export const SELECTED_TASK_TIMER = 'selectedTaskTimer';
export const EDITABLE_JOB = 'editableJob';
export const COMMENT_JOB = 'commentJob';
export const JOB_ENTERED = 'jobEntered';
export const TASK_JOB_ENTERED = 'jobEntered';
export const JOB_UPDATED = 'jobUpdated';
export const JOB_ENTER_FAILED = 'jobEnterFailed';
export const TOGGLE_LEFT_MENU = 'toggleLeftMenu';
export const CLOSE_LEFT_MENU = 'closeLeftMenu';
export const OPEN_JOBS_TAB = 'openJobsTab';
export const GROUP_SELECTED = 'groupSelected';
export const PARTNER_JOB = 'partnerJob';
export const UPDATE_TASKBOARD_LIST = 'updateTaskboardList';
