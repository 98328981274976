import { ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TbSubmitDirective } from "./tb-submit.directive";
import { TbEditDirective } from "./tb-edit.directive";
import { TbCancelDirective } from "./tb-cancel.directive";
import { LengthListenerDirective } from "../../directives/input/length-listener.directive";

@Component({
  selector: 'text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  // providers: [TextBoxComponent]
})
export class TextBoxComponent implements OnInit {

  protected _submit: TbSubmitDirective;
  protected _edit: TbEditDirective;
  protected _cancel: TbCancelDirective;
  protected _editVisible = true;
  protected _submitVisible = false;
  protected _cancelVisible = false;
  public editable = false;
  protected _length: number = 0;
  protected _submitted = false;
  protected get availableSymbols(): number {
    return Math.max(this.maxLength - this._length, 0);
  }

  @ContentChild('text') _textEl: ElementRef;
  @ViewChild('lengthListener') lengthListener: LengthListenerDirective;

  get previewElement(): HTMLElement {
    return this._textEl ? this._textEl.nativeElement : null;
  }

  get previewText(): string {
    const el = this.previewElement;
    if (el) {
      return el.innerText;
    }
    return '';
  }

  set previewText(val: string) {
    const el = this.previewElement;
    if (el) {
      el.innerText = val;
    }
  }

  protected _text;

  @Input() submitting = false;
  @Input() maxLength = 0;
  @Output() onSubmit = new EventEmitter<string>();

  constructor(private _chg: ChangeDetectorRef) { }

  ngOnInit() {
  }

  public addSubmitDirective(submitDir: TbSubmitDirective) {
    this._submit = submitDir;
  }

  public addEditDirective(editDir: TbEditDirective) {
    this._edit = editDir;
  }

  public addCancelDirective(cancelDir: TbCancelDirective) {
    this._cancel = cancelDir;
  }

  protected _submitClicked() {
    this.onSubmit.emit(this.lengthListener.text);
  }

  public toggleEditMode() {
    this._text = this.previewText;
    this._editVisible = false;
    this._cancelVisible = true;
    this._submitVisible = true;
    this.editable = true;
  }

  protected _cancelClicked() {
    this._editVisible = true;
    this._cancelVisible = false;
    this._submitVisible = false;
    this.editable = false;
  }

  protected _lengthChanged(e) {
    if (e > this.maxLength) {
      const txt = this.previewText;
      this.previewText = txt.substring(0, this.maxLength);
      this._length = this.maxLength;
      return;
    }
    this._length = e;
  }

  public submitted(success: boolean) {
    if (success) {
      this._text = this.lengthListener.text;
      this._submitted = true;
      this._cancelClicked();
      try {
        this._chg.markForCheck();
        this._chg.detectChanges();
      } catch (err) { }
    }
  }
}
