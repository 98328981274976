import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ViewChild, HostListener } from '@angular/core';
import { KpisBackend } from '../../backend/kpis.backend';
import { Subscription, Subject, Observable } from 'rxjs';
import { DashboardService } from '../dashboard.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ChartOptions, LabelItem } from "chart.js";
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'violation-chart',
  styleUrls: ['violation-chart.component.scss'],
  templateUrl: 'violation-chart.component.html'
})

export class ViolationChartComponent implements OnInit, OnDestroy {

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  // data: Chartist.IChartistData;
  data: any;
  options: any;
  dataReady = false;
  dataReady$ = new Subject();

  avarageData$ = new Subject();
  currentUserData$ = new Subject();
  userData$ = new Subject();

  cheight = '180';

  @Input() showDays: boolean;

  // public lineChartData: ChartDataset<'bar'>[] = [];
  public lineChartData: any[] = [];
  //public lineChartLabels: Label[] = [];
  public lineChartLabels: LabelItem[] = [];

  public lineChartOptions: ChartOptions<'line'>;

  // public lineChartColors = [
  //     {
  //       borderColor: '#EC407A',
  //       backgroundColor: '#EC407A',
  //       pointHitRadius: 10
  //     },
  //     {
  //       borderColor: '#5867C3',
  //       backgroundColor: '#5867C3',
  //       pointHitRadius: 10
  //     },
  //     {
  //       borderColor: '#FD7E14',
  //       backgroundColor: '#FD7E14',
  //       pointHitRadius: 10
  //     },
  //     {
  //       borderColor: '#007BFF',
  //       backgroundColor: '#007BFF',
  //       pointHitRadius: 10
  //     },
  //     {
  //       borderColor: '#6C757D',
  //       backgroundColor: '#6C757D',
  //       pointHitRadius: 10
  //     },
  //     {
  //       borderColor: '#DC3545',
  //       backgroundColor: '#DC3545',
  //       pointHitRadius: 10
  //     },
  //   ];

  public lineChartPlugins = [];

  private _subscriptions: Array<Subscription> = [];

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]);

  constructor(private kpisBackend: KpisBackend, private dashboardService: DashboardService, private breakpointObserver: BreakpointObserver, private chg: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.setLineChartOptions();
    this._subscriptions.push(this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        this.cheight = '280';
        this.lineChartOptions.plugins.legend.position = 'bottom';
      } else {
        this.cheight = '180';
        this.lineChartOptions.plugins.legend.position = 'right';
      }
    }));
    // this.getCurrentUserData();
    this._subscriptions.push(this.currentUserData$.subscribe((currentUserData) => {
      this.lineChartData = [(<any>currentUserData).data];
      this.lineChartLabels = (<any>currentUserData).date;
      // this.dataReady = true;
      // this.dataReady$.next(true);
      this._subscriptions.push(this.userData$.subscribe((userData) => {
        this.lineChartData.push((<any>userData).data);
        this.lineChartData = [...this.lineChartData];
      }));
    }));
    this._subscriptions.push(this.dashboardService.userSelectedViolationCharts$.subscribe((event: any) => {
      if (event.isSelected) {
        this.addToChart(event.user);
      } else {
        this.removeFromChart(event.user);
      }
    }));

    this._subscriptions.push(this.dashboardService.violationChartGetInitData$.subscribe((event) => {
      this.getInitUserData();
    }));
  }

  @HostListener("window:resize", ["$event"])
  canvasUpdate() {
    this.chart.render();
  }

  setLineChartOptions() {
    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 10,
          right: 10,
          top: 14,
          bottom: 10
        }
      },
      plugins: {
        legend: {
          position: 'right',
          align: 'start',
          onClick: function (e) {
            e.native.stopPropagation();
          }
        },
        colors: {
          forceOverride: true,
        }
      },
      scales: {
        y:
        {
          beginAtZero: true,
          min: 0,
          ticks: {
            // stepSize: 20
            //   min: 0,
            //   max: 100,
            precision: 0,
          },
          title: { text: this.showDays ? 'Vėluojamų dienų sk.' : 'Vėluojamų užklausų sk.', display: true }
        },
        x:
        {
          title: { text: 'Dienos', display: true }
        },

      },
    };
  }

  getInitUserData() {
    const idsList = [...this.dashboardService.violationChartUsersIdsList];
    if (idsList.indexOf(this.dashboardService.authService.user.usersId) === -1) {
      idsList.push(this.dashboardService.authService.user.usersId);
    }
    this._subscriptions.push(this.kpisBackend.getBulkViolationKpis(idsList, this.showDays).subscribe((res) => {
      const currentUsersData = res.find((el) => { return el.userId === this.dashboardService.authService.user.usersId });
      let hasData = false;
      if (currentUsersData) {
        hasData = true;
        this.currentUserData$.next({ data: { data: currentUsersData.lateDays, label: 'Mano', lineTension: 0, fill: false, id: 0 }, date: currentUsersData.date });
      }
      this.dashboardService.violationChartUsersList.forEach((user) => {
        hasData = true;
        const usersData = res.find((el) => { return el.userId === user.userId });
        this.userData$.next({ data: { data: usersData.lateDays, label: user.name, lineTension: 0, fill: false, id: user.userId }, date: usersData.date });
      });
      if (hasData) {
        this.dataReady = true;
        this.dataReady$.next(true);
      }
      try {
        this.chg.detectChanges();
      } catch (e) { }
    }));
  }

  getCurrentUserData() {
    this._subscriptions.push(this.kpisBackend.getBulkViolationKpis([this.dashboardService.authService.user.usersId], this.showDays).subscribe((res) => {
      this.currentUserData$.next({ data: { data: (<any>res).lateDays, label: 'Mano', lineTension: 0, fill: false, id: 0 }, date: (<any>res).date });
    }));
  }

  addToChart(user) {
    this._subscriptions.push(this.kpisBackend.getBulkViolationKpis([user.userId], this.showDays).subscribe((res) => {
      this.userData$.next({ data: { data: (<any>res[0]).lateDays, label: user.name, lineTension: 0, fill: false, id: user.userId }, date: (<any>res[0]).date });
    }));
  }

  removeFromChart(user) {
    const found = this.lineChartData.findIndex((item) => item.id === user.userId);
    if (found !== -1) {
      this.lineChartData.splice(found, 1);
      this.chart.render();
      // recursion because its possible to add user to chart 2 times
      this.removeFromChart(user);
    }
  }

  // getAvarageData() {
  //   this._subscriptions.push(this.kpisBackend.getViolationKpis(this.showDays, 1).subscribe((res) => {
  //     this.avarageData$.next({ data: { data: (<any>res).lateDays, label: 'Vidurkis', lineTension: 0, fill: false, id: 1 }, date: (<any>res).date })
  //   }));
  // }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

}
