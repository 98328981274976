import { TaskboardSidebarService } from './services/taskboardSidebar.service';
import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Subscription } from "rxjs"
import { MatSidenav } from "@angular/material/sidenav";
import { GlobalState } from "../template/app.state"
import { ActivatedRoute, Router } from "@angular/router";
import { SelectedPartnerService } from "../services/partner/selectedPartner.service";
import { StateService } from "../services/state/stateService";
import { JOB_ENTERED, TOGGLE_LEFT_MENU } from "../state/state";
import { PreloaderService } from "../template/shared/services/preloader/preloader.service";
import { SpinnerService } from "../template/shared/services/spinner/spinner.service";

@Component({
  selector: ".content_inner_wrapper",
  templateUrl: "./taskboard.component.html",
  styleUrls: ["./taskboard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskboardComponent implements OnInit, OnDestroy {
  //TEMPLATES
  @ViewChild("dpaCodeTpl", { static: true }) dpaCodeTpl: TemplateRef<any>;
  @ViewChild("leftSidenav2", { static: true }) leftSidenav2: MatSidenav;

  navMode = "side";
  private _subscriptions: Array<Subscription> = [];

  constructor(private state: GlobalState,
    private _spinner: SpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private selectedPartnerService: SelectedPartnerService,
    private stateService: StateService,
    private sidebarService: TaskboardSidebarService
  ) {

  }

  ngOnInit() {
    if (window.innerWidth < 992) {
      this.navMode = "over";
      this.leftSidenav2.opened = false;
    }
    if (window.innerWidth > 992) {
      this.navMode = "side";
      this.leftSidenav2.open();
    }
    this._subscriptions.push(this.stateService.getStream(TOGGLE_LEFT_MENU).subscribe((t) => this.leftSidenav2.toggle()));
    this._subscriptions.push(this.stateService.subscribe(JOB_ENTERED, (res) => {
      if (res && res.jobId) {
        this.sidebarService.close();
      }
    }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (event.target.innerWidth < 992) {
      this.navMode = "over";
      this.leftSidenav2.close();
    }
    if (event.target.innerWidth > 992) {
      this.navMode = "side";
      this.leftSidenav2.open();
    }
  }

  ngAfterViewInit() {
    PreloaderService.load().then(values => {
      this._spinner.hide();
    });
  }

  toggleIfSmallScreen() {
    if (this.navMode === "over") {
      this.leftSidenav2.toggle();
    }
  }
}
