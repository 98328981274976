import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ValueProvider } from '../common/ValueProvider';
import { VALUE_PROVIDER } from '../proto';
import { MatCheckbox } from '@angular/material/checkbox';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface FlagState {
  id: string,
  state: boolean
}

@Directive({
  selector: '[protoFlag]',
  providers: [
    {
      provide: VALUE_PROVIDER, useExisting: forwardRef(() => ProtoFlagDirective),
    }
  ]
})
export class ProtoFlagDirective implements OnInit, AfterContentInit, OnDestroy, ValueProvider<boolean> {
  @Input() protoFlag: string;
  @Input() weight: string;

  private _value: boolean;
  private _checked: boolean;
  private _valueChanges: Subject<boolean> = new Subject();
  @Input() set checked(val: boolean) {
    if (this._setFn) {
      this._setFn(val);
    } else {
      this.checkBox.checked = val;
    }
    this._checked = val;
    this._valueChanges.next(val);
  }

  @Input() inputChecked;

  get checked(): boolean {
    return this._checked;
  }

  aboutToChange: boolean;

  private chkBox: MatCheckbox;
  @Output() stateAboutToChange: EventEmitter<FlagState> = new EventEmitter();

  private subscriptions: Array<Subscription> = [];
  private checkBox: HTMLInputElement;
  private _getFn: Function;
  private _setFn: Function;
  private _registerChange: Function;
  private _valAccessor: ControlValueAccessor;
  private _emitOnChange: boolean = true;

  constructor(private input: ElementRef,
    @Inject(NG_VALUE_ACCESSOR) @Optional() @Host() @Self() private _valProvider: ControlValueAccessor) {
    this.checkBox = input.nativeElement;
    if (_valProvider) {
      if (_valProvider instanceof Array && _valProvider.length > 0) {
        let vp: ControlValueAccessor = _valProvider[0];

        this._setFn = (val) => { this._checked = val; vp.writeValue(val); };
        this._getFn = () => this._checked;
        this._valAccessor = vp;

      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputChecked) {
      this.setValue(changes.inputChecked.currentValue);
    }
  }

  ngOnInit() {
    this.setValue(this.inputChecked);
  }

  ngAfterContentInit() {
    if (this._valAccessor) {
      this._valAccessor.registerOnChange((v) => {
        this._emitOnChange ? this.stateAboutToChange.emit(({ id: this.protoFlag, state: v })) : null;
      })
    } else {
      this.checkBox.onclick = ($event) => {
        this._emitOnChange ? this.stateAboutToChange.emit(({ id: this.protoFlag, state: (<HTMLInputElement>$event.target).checked })) : null
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.checkBox.onclick = undefined;
    this._checked = null;
  }

  public getState(): FlagState {
    return {
      id: this.protoFlag,
      state: this._checked
    }
  }

  public getValueChanges() {
    return this._valueChanges;
  }

  public getValue(): boolean {
    return this._checked;
  }

  public setValue(val: boolean) {
    this._emitOnChange = false;
    this.checked = val;
    this._emitOnChange = true;
  }

}
