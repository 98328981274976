// import {Time} from "../../template/shared/components/time-picker/time-picker.component";

const INACTIVE_STATES = [5, 20, 40];

export function isActiveState(stateId: number) {
  return !INACTIVE_STATES.includes(typeof stateId == "string" ? parseInt(stateId) : stateId);
}

export function onlyCrmUsers(arr: Array<any>): Array<any> {
  if (arr && arr.length > 0 && typeof arr.filter == 'function') {
    return arr.filter((p) => p.isCrmUser == '1');
  }
  return arr;
}

export function mainPerson(p: any): boolean {
  return p && (p.main == '11' || p.main == '10');
}

export function dateToString(date: Date) {
  return '' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
}

export function floatToTime(f: number | string) {
  try {
    let nstr: string;
    if (typeof f == "number") {
      nstr = '' + f;
    } else if (typeof f == "string") {
      nstr = f;
    } else {
      throw new Error('Trying to convert wrong parameter type: ' + f + ". Only strings and numbers are allowed.");
    }
    let hs, ms: string;
    if (nstr.indexOf(',')) {
      [hs, ms] = nstr.split(',');
    } else if (nstr.indexOf('.')) {
      [hs, ms] = nstr.split(',');
    } else {
      hs = nstr;
      ms = '0';
    }
    let h = parseInt(hs);
    let m = Math.round(parseInt(ms) / 100 * 60) || 0;
    return ('' + (h > 9 ? h : ('0' + h))) + ':' + ('' + (m > 9 ? m : ('0' + m)));
  } catch (e) {

  }
  return '00:00';
}

export function floatToStr(f, decimals = 2) {
  f = f || 0;
  if (typeof f == 'string') {
    return parseFloat(f.replace(',', '.')).toFixed(decimals);
  }
  return parseFloat('' + f).toFixed(decimals);
}

// function converts Float number to time string
export function convertNumToTime(number) {
  let sign = (number >= 0) ? 1 : -1;
  number = number * sign;
  let hour = Math.floor(number);
  let decpart = number - hour;
  let min = 1 / 60;
  decpart = min * Math.round(decpart / min);
  let minute = Math.floor(decpart * 60) + '';
  if (minute.length < 2) {
    minute = '0' + minute;
  }
  let signR = sign == 1 ? '' : '-';
  let time = signR + hour + ':' + minute;
  return time;
}

export function removeLastNewLineIfNeeded(text: string) {
  if (text && text.length >= 1) {
    if (text.slice(-1) == '\n') {
      return text.substring(0, text.length - 1);
    }
  }
  return text;
}
