
import { tap } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { Observable, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { UserBackend } from '../backend/user.backend';
import { User } from '../models/User';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { MatDialog } from "@angular/material/dialog";
import { PhotoChangerComponent } from "./photo-changer/photo-changer.component";
import { environment } from '../../environments/environment';
import { DEFAULT_CONSULTANT_PHOTO_URL } from '../core/constants';
import { AuthService } from '../services/auth.service';
import { EMAIL_PATTERN_REGEXP } from '../app.constants';
import { SelectedPartnerService } from '../services/partner/selectedPartner.service';

@Component({
  selector: '.content_inner_wrapper',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  // protected _user: Observable<User>;
  protected _changeUserSettings: UntypedFormGroup;
  private _isLoading: boolean;
  user: any;
  pages = [];
  homepage;
  public fileBasePath = environment.apiUrl;
  userPhoto;

  hompageChangeSubs: Subscription;
  userSubs: Subscription;
  unsub: Subscription[] = [];

  default_url = DEFAULT_CONSULTANT_PHOTO_URL;

  constructor(private _userService: UserService,
    private _userBackend: UserBackend,
    protected _domSanitizer: DomSanitizer,
    private _formBuilder: UntypedFormBuilder,
    private _dialog: MatDialog,
    private authService: AuthService,
    private chg: ChangeDetectorRef,
    private selectedPartnerService: SelectedPartnerService
  ) {

  }

  ngOnInit() {
    this.selectedPartnerService.resetPartner();
    this.selectedPartnerService.resetUser();
    this.user = this.authService.user;
    this._buildForm();
    this._setPages();
    // this.userSubs = this._user.subscribe((u) => {
    //   this.homepage = u.parameters;
    // })
    this.getUserPicture();
  }

  _setPages() {
    this.pages.push({ url: '/taskboard', name: 'Aptarnavimas' });
    this.pages.push({ url: '/dashboards', name: 'Skydelis' });
    this.pages.push({ url: '/taskboard/t/a', name: 'Mano įvestos užduotys' });
    this.pages.push({ url: '/taskboard/t/b', name: 'Man skirtos užduotys' });
    this.pages.push({ url: '/taskboard/r/b', name: 'Man skirtos užklausos' });
    this.pages.push({ url: '/taskboard/t/c', name: 'Mano grupei skirtos užduotys' });
    this.pages.push({ url: '/taskboard/r/c', name: 'Mano grupei skirtos užklausos' });
    this.pages.push({ url: '/', name: 'Pagal nutylėjimą: Skydelis' });
  }

  homepageChanged(event) {
    if (this.hompageChangeSubs) {
      this.hompageChangeSubs.unsubscribe();
    }
    this.hompageChangeSubs = this._userBackend.changeUserHomepage(event).subscribe((res) => {
      if (res && res.success) {
        swal.fire({
          title: 'Išsaugota sėkmingai',
          timer: 2000,
          showConfirmButton: false,
          icon: 'success'
        });
      } else {
        swal.fire({
          title: 'Nepavyko išsaugoti',
          timer: 2000,
          showConfirmButton: false,
          icon: 'error'
        });
      }
    });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener('load', () => {
      this.userPhoto = reader.result;
      setTimeout(() => {
        try {
          this.chg.detectChanges()
        } catch (err) { }
      }, 0);
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getUserPicture() {
    this.unsub.push(this._userBackend.getUserPicture(null).subscribe((res) => {
      if (res.body.type === 'text/plain' && res.body.size === 14) {
        this.userPhoto = DEFAULT_CONSULTANT_PHOTO_URL;
      } else {
        const blob = res.body;
        this.createImageFromBlob(blob);
      }
    },
      (err) => {
        if (err.status === 404) {
          this.userPhoto = DEFAULT_CONSULTANT_PHOTO_URL;
        } else {
          console.log('get photo error', err);
        }
      }
    ));
  }

  _openPhotoChanger() {
    this.unsub.push(this._dialog.open(PhotoChangerComponent, { width: '458px', height: '484px' }).afterClosed().subscribe(() => {
      this.getUserPicture();
    }));
  }

  private _passwordMatcher = (f: UntypedFormGroup) => {
    const oldPswrd = f.get('oldPassword');
    if (!oldPswrd.value) {
      oldPswrd.setErrors(['Old password must be provided'])
    }
    const email = f.get('email');
    if (email.value) {
      Validators.email(email)
    }
    const newPswrd = f.get('newPassword');
    if (newPswrd.value) {
      const confirm = f.get('confirmPassword');
      const oldPswrd = f.get('oldPassword');
      const confErrors = [];
      if (!confirm.value) {
        confErrors.push('New password must be confirmed');
      }
      if (confirm.value != newPswrd.value) {
        confErrors.push('Passwords does not match');
      }
      if (confErrors.length > 0) {
        confirm.setErrors(confErrors);
      }
    }
  };

  private _buildForm() {
    this._changeUserSettings = this._formBuilder.group({
      email: [this.user.email, [Validators.required, Validators.pattern(EMAIL_PATTERN_REGEXP)]],
      oldPassword: [''],
      newPassword: [''],
      confirmPassword: ['']
    }, {
      validator: this._passwordMatcher
    })
  }

  _commitSettings(form: UntypedFormGroup) {
    if (form.valid && !this._isLoading) {
      this._userBackend.changeCredentials(form.value).pipe(
        tap((_) => this._isLoading = true))
        .subscribe((res) => {
          this._isLoading = false;
          if (res.success) {
            this.authService.updateUserEmail(form.controls.email.value);
            this.user = this.authService.user;
            swal.fire({
              title: "Nustatymai pakeisti sėkmingai",
              timer: 2000,
              showConfirmButton: false,
              icon: 'success'
            })
          }
        },
          (err) => {
            if (err.error && err.error.error === 'Incorrect password') {
              this._changeUserSettings.get('oldPassword').setErrors(['Wrong password']);
              swal.fire({
                title: "Įvestas blogas slaptažodis",
                timer: 2000,
                showConfirmButton: false,
                icon: 'error'
              })

            } else if (err.error && err.error.error === 'No email') {
              this._changeUserSettings.get('email').setErrors(['No email']);
              swal.fire({
                title: "Neįvestas elektroninis paštas",
                timer: 2000,
                showConfirmButton: false,
                icon: 'error'
              })
            }
            else {
              swal.fire({
                title: "Nustatymų pakeisti nepavyko",
                timer: 2000,
                showConfirmButton: false,
                icon: 'error'
              })
            }
          }
        )
    }
  }

  deletePhoto() {
    this.unsub.push(this._userBackend.deleteUserPicture().subscribe(resp => {
      this.userPhoto = DEFAULT_CONSULTANT_PHOTO_URL;
    }, err => {
      console.log(err);
    }));
  }

  ngOnDestroy() {
    if (this.hompageChangeSubs) {
      this.hompageChangeSubs.unsubscribe();
    }
    if (this.userSubs) {
      this.userSubs.unsubscribe();
    }
    this.unsub.forEach(u => u.unsubscribe());
  }
}
