import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TasksBackend } from '../../../../../backend/tasks.backend';
import { Subscription } from 'rxjs';
import { downloadFile } from '../../../../../functions/utils/files';


@Component({
  selector: 'comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModalComponent implements OnInit, OnDestroy {

  taskId: any;
  subs: Subscription[] = [];
  task: any;
  comments: [] = [];

  _downloadFile = downloadFile;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dataPar: any,
    public dialogRef: MatDialogRef<CommentsModalComponent>,
    private tasksBackend: TasksBackend,
  ) {
    this.taskId = dataPar.taskId;
  }

  ngOnInit() {
    this.subs.push(this.tasksBackend.getTaskDetails(this.taskId, false).subscribe(task => {
      this.task = task;
      this.comments = (this.task.comments || []).filter(el => !el.private).sort((t1, t2) => new Date(t1.date).getTime() < new Date(t2.date).getTime() ? 1 : -1).slice(0, 3);
    }))
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subs.forEach((s => s.unsubscribe()));
  }

}
