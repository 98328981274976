import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "loader-button",
  templateUrl: "./loaderButton.component.html",
  styleUrls: ["./loaderButton.component.scss"],
})
export class LoaderButtonComponent {
  @Input() loading;
  @Input() complete;
  @Input() error;
  @Input() disableWhileLoading = true;
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  showLoader = false;
  loaderClass = '';
  private _isLoading;
  private _isComplete;

  private _disabled;

  get disabled() {
    return this._disabled;
  }

  @Input() set disabled(val) {
    this._disabled = val;
  }

  _clicked($event) {
    this.clicked.emit($event);
  }

}
