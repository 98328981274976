/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';


@Component({
  selector: 'pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagerComponent {

  _count: any;
  _page: any;
  _size: any;
  pages: any;

  @Input() pagerLeftArrowIcon;
  @Input() pagerRightArrowIcon;
  @Input() pagerPreviousIcon;
  @Input() pagerNextIcon;
  @Input() set size(val) {
    this._size = val;
    this.pages = this.calcPages();
  };
  @Input() set count(val) {
    this._count = val;
    this.pages = this.calcPages();
  };
  @Input() set page(val) {
    this._page = val;
    this.pages = this.calcPages();
  };
  @Output() change = new EventEmitter();

  constructor() {
    this._count = 0;
    this._page = 1;
    this._size = 0;
  }

  get size() {
    return this._size;
  }

  get count() {
    return this._count;
  }

  get page() {
    return this._page;
  }

  get totalPages() {
    const count = this.size < 1 ? 1 : Math.ceil(this.count / this.size);
    return Math.max(count || 0, 1);
  }

  canPrevious() {
    return this.page > 1;
  }

  canNext() {
    return this.page < this.totalPages;
  }

  prevPage() {
    this.selectPage(this.page - 1);
  }

  nextPage() {
    this.selectPage(this.page + 1);
  }

  selectPage(page) {
    if (page > 0 && page <= this.totalPages && page !== this.page) {
      this.page = page;
      this.change.emit({
        page
      });
    }
  }

  calcPages(page?) {

    const pages = [];

    let startPage = 1;

    let endPage = this.totalPages;

    const maxSize = 5;

    const isMaxSized = maxSize < this.totalPages;
    page = page || this.page;
    if (isMaxSized) {
      startPage = page - Math.floor(maxSize / 2);
      endPage = page + Math.floor(maxSize / 2);
      if (startPage < 1) {
        startPage = 1;
        endPage = Math.min(startPage + maxSize - 1, this.totalPages);
      }
      else if (endPage > this.totalPages) {
        startPage = Math.max(this.totalPages - maxSize + 1, 1);
        endPage = this.totalPages;
      }
    }
    for (let num = startPage; num <= endPage; num++) {
      pages.push({
        number: num,
        text: (/** @type {?} */ (((/** @type {?} */ (num)))))
      });
    }
    return pages;
  }
}
