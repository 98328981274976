import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EMAIL_PATTERN_REGEXP } from '../../app.constants';
import { UserService } from '../../services/user/user.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  emailform: UntypedFormGroup;
  isInserting = false;
  isCompleted = false;
  constructor(private formBuilder: UntypedFormBuilder, private userService: UserService) { }

  ngOnInit() {
    this._buildForm();
  }

  private _buildForm() {
    this.emailform = this.formBuilder.group({
      email: [null, [Validators.required, Validators.pattern(EMAIL_PATTERN_REGEXP)]]
    });
  }

  recoverPassword() {
    this.isInserting = true;
    this.userService.recoverPassword(this.emailform.controls.email.value).subscribe((resp) => {
      this.isInserting = false;
      if (resp.status === 200) {
        this.isCompleted = true;
        swal.fire({
          title: 'Slaptažodžio atkūrimo instrukcijos išsiųstos nurodytu elektroniniu paštu',
          timer: 2000,
          showConfirmButton: false,
          icon: 'success'
        });
      }
    },
      (err) => {
        this.isInserting = false;
        if (err.status === 404) {
          swal.fire({
            title: 'Vartotojas nerastas',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          });
        } else {
          swal.fire({
            title: 'Įvyko klaida',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          });
        }
      })
  }

}
