import { NativeDateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

export const AppMonthFormat = {
  parse: {
    dateInput: 'yyyy-MM'
  },
  display: {
    dateInput: 'yyyy-MM',
    monthYearLabel: 'y-m',
    dateA11yLabel: 'y mm - dd',
    monthYearA11yLabel: 'mm',
  }
}

@Injectable()
export class CustomMonthAdapter extends NativeDateAdapter {

  constructor(matDateLocale: string, platform: Platform) {
    super(matDateLocale, platform);
  }

  parse(value: any): Date | null {

    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  format(date: Date, displayFormat: Object): string {
    return date.toLocaleDateString('LT', { year: 'numeric', month: '2-digit' });
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return [
      'SAUSIS',
      'VASARIS',
      'KOVAS',
      'BALANDIS',
      'GEGUŽĖ',
      'BIRŽELIS',
      'LIEPA',
      'RUGPJŪTIS',
      'RUGSĖJIS',
      'SPALIS',
      'LAPKRITIS',
      'GRUODIS'
    ];
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}
