import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TaskReview } from "../../../models/task-review";
import { TasksBackend } from "../../../../backend/tasks.backend";
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { CalendarCustomHeaderComponent } from '../../../../components/filters/date-interval-alt/calendarCustomHeader.component';

export interface NearestActionFormConstructor {
  task: any;
  text?: string;
  date?: any;
}

@Component({
  selector: 'nearest-action-form',
  templateUrl: 'nearestActionForm.component.html',
  styleUrls: ['./nearestActionForm.component.scss']
})
export class NearestActionFormComponent implements OnInit, OnDestroy {

  protected _nearestActionForm: UntypedFormGroup;
  protected _isInserting: boolean;
  protected _inserted: boolean;
  private _subscriptions: Array<Subscription> = [];
  calendarCustomHeaderComponent: any = CalendarCustomHeaderComponent;

  constructor(
    public dialogRef: MatDialogRef<NearestActionFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NearestActionFormConstructor,
    private _formBuilder: UntypedFormBuilder,
    private _tasksBackend: TasksBackend
  ) {
  }

  ngOnInit(): void {
    this._buildForm();
  }

  _enterNearestAction(f: UntypedFormGroup) {
    this._isInserting = true;
    this._subscriptions.push(this._tasksBackend.addNearestAction(this.data.task.id, { text: f.value.text, date: <Date>f.value.date.toLocaleString() }).subscribe((res) => {
      this._isInserting = false;
      this._inserted = true;
      this.dialogRef.close(res)
    }, (err) => {
      this._isInserting = false;
      swal.fire({
        title: 'Įvyko klaida',
        timer: 2000,
        showConfirmButton: false,
        icon: 'error'
      });
    }));
  }

  dateChanged(el) {
    el.close && el.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  private _buildForm() {
    this._nearestActionForm = this._formBuilder.group({
      date: [this.data.date ? new Date(this.data.date) : null, Validators.required],
      text: [this.data.text, Validators.required],
    });
  }

  public static formInput(task: any, text?: string): NearestActionFormConstructor {
    return { task: task, text: text };
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }
}
