
import { map, filter } from 'rxjs/operators';
import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonDropdownComponent } from './../../../template/shared/components/dropdowns/button-dropdown/button-dropdown.component';
import { StorageService } from './../../../template/shared/proto/storage/storage.service';
import { LIST, ListsDataService } from "../../../services/lists/lists.data.service";
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { VALUE_PROVIDER } from '../../../template/shared/proto/proto';
import { ValueProvider } from '../../../template/shared/proto/common/ValueProvider';

@Component({
  selector: 'groups-list',
  template: `
        <button-dropdown
            [options]="groups | async"
            [map]="suggestionMapper"
            [itemClass]="'btn-default btn-sm'"
            (itemClick) ="_groupSelected($event)">
        </button-dropdown>
    `,
  styleUrls: ['./groupsList.component.scss'],
  providers: [
    { provide: VALUE_PROVIDER, useExisting: forwardRef(() => GroupsListComponent) }
  ]
})
export class GroupsListComponent implements OnInit, ValueProvider<String>, OnDestroy {
  @Input() placeholder = 'Įveskite partnerio pavadinimą';
  @Output() groupSelected: Subject<any> = new Subject();
  suggestionMapper = (p) => p.name;
  protected groups: Subject<Array<any>> = new BehaviorSubject([]);
  private _chgListener: (v: any) => void;
  private _currentGroupId;
  private _subscriptions = new Array<Subscription>();
  @ViewChild(ButtonDropdownComponent) button: ButtonDropdownComponent<any>;
  constructor(private dataService: ListsDataService,
    private _storageService: StorageService,
    private _chg: ChangeDetectorRef) {

  }

  ngOnInit() {
    const stored = this._storageService.get('selectedGroup', null);
    this._subscriptions.push(this.dataService.loadList(LIST.GROUPS).subscribe((g) => {
      this.groups.next(g);
      if (stored != null) {
        const s = (<Array<any>>g).find((a) => a.groupId == stored.groupId);
        if (s) {
          setTimeout(() => {
            this.button.title = s.name;
            this._groupSelected(s);
            try {
              this._chg.detectChanges();
            } catch (e) { }
          }, 0);

        }

      } else {
        if (g.length > 0) {
          this._groupSelected(g[0]);
        }
      }
    }));
  }

  _groupSelected(group) {
    this._storageService.store('selectedGroup', group);
    this._currentGroupId = group.groupId;
    if (this._chgListener) {
      this._chgListener(this._currentGroupId);
    }
    this.groupSelected.next(group);
  }

  getValueChanges() {
    return this.groupSelected.pipe(filter((g) => g != null), map((g) => g.groupId));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}
