import { Directive, Input, ViewContainerRef, } from "@angular/core";

@Directive({
  selector: "[tableRow]",
})
export class PTableRowDirective {
  private _row;
  private _box;
  private _type;
  @Input('tableRow') set row(row) {
    this._row = row;
  };

  @Input('box') set box(box) {
    this._box = box;
  }

  @Input('type') set type(t) {
    this._type = t;
  }

  constructor(public viewContainerRef: ViewContainerRef) {

  }

}


