export type TimeBarier = { hours: number, minutes?: number, except?: string, only?: string }
export const WARNINGS: TimeBarier[] = [
  { hours: 11, minutes: 40 },
  { hours: 16, minutes: 40, except: 'friday' },
  { hours: 15, minutes: 40, only: 'friday' }
];

export const STOP: TimeBarier[] = [
  { hours: 19 }
];
