import { Component, ElementRef, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { SelectedPartnerService } from '../services/partner/selectedPartner.service';
import { GlobalState } from '../template/app.state';
import { ConfigService } from '../template/shared/services/config/config.service';
import { DashboardService } from './dashboard.service';

@Component({
  selector: '.content_inner_wrapper',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DashboardsComponent implements OnInit {
  title: string = 'Skydelis';
  blocksSettings = {
    delayed_requests: true,
    overdue: true,
    current_works: true,
    news: true,
    evaluation_quality: true,
    bad_rated_requests: true
  }

  constructor(
    public config: ConfigService,
    private _elementRef: ElementRef,
    private _state: GlobalState,
    private selectedPartnerService: SelectedPartnerService,
    public dashboardService: DashboardService
  ) {

  }

  ngOnInit() {
    this.selectedPartnerService.resetPartner();
    this.selectedPartnerService.resetUser();
    if (localStorage.getItem('dashboard_setting')) {
      this.blocksSettings = JSON.parse(localStorage.getItem('dashboard_setting'));
    }
  }

  blockCheck() {
    // this.blocksSettings[key] = event.checked;
    localStorage.setItem('dashboard_setting', JSON.stringify(this.blocksSettings));
  }
}

function Watch(): PropertyDecorator & MethodDecorator {

  function isOnChanges(val: OnChanges): val is OnChanges {
    return !!(val as OnChanges).ngOnChanges;
  }


  return (target: any, key: string | symbol, propDesc?: PropertyDescriptor) => {
    if (propDesc) //MethodDecorator
    {
      const orig = propDesc.value;
      propDesc.value = function (...args) {
        return orig.apply(this, ...args);
      };
      return propDesc;
    }
    let privateKey = '_' + key.toString();
    let isNotFirstChangePrivateKey = '_' + key.toString() + 'IsNotFirstChange';
    let initialValKey = '_' + key.toString() + 'InitialVal';
    propDesc = propDesc || {
      configurable: true,
      enumerable: true,
    };
    propDesc.get = propDesc.get || (function (this: any) {
      return this[privateKey];
    });

    const originalSetter = propDesc.set || (function (val: any) {
      console.log('Original setter', this, val, privateKey, isNotFirstChangePrivateKey);
      this[privateKey] = val;
      if (!this[isNotFirstChangePrivateKey]) {
        this[initialValKey] = val;
      }
    });

    propDesc.set = function (this: any, val: any) {
      const oldValue = this[key];
      if (val != oldValue) {
        originalSetter.call(this, val);
        const isNotFirstChange = this[isNotFirstChangePrivateKey];
        this[isNotFirstChangePrivateKey] = true;
        if (isOnChanges(this)) {
          const changes: SimpleChanges = {
            [key]: new SimpleChange(oldValue, val, !isNotFirstChange)
          };
          this.ngOnChanges(changes);
        }
      }
    };
    return propDesc;
  };
}

function IsDirty(): PropertyDecorator & MethodDecorator {
  return (target: any, key: string | symbol, propDesc?: PropertyDescriptor) => {

  };
}

function TestMethodDecorator(): PropertyDecorator & MethodDecorator {
  return (target: any, key: string | symbol, propDesc?: PropertyDescriptor) => {
    // console.log(target, key);
  };
}
