
import { map, pluck, withLatestFrom, filter, tap } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableModel } from '../../template/shared/proto/dataTable/serverTable/TableModel';
import { TOGGLE_LEFT_MENU } from '../../state/state';
import { StateService } from '../../services/state/stateService';
import { Subscription, Subject, of, from } from 'rxjs';
import { StorageService } from '../../template/shared/proto/storage/storage.service';
import { QueryProviderDirective } from '../../template/shared/proto/common/query/queryProvider.directive';
import { ColumnResized, ServerTableComponent } from '../../template/shared/proto/dataTable/serverTable/serverTable.component';
import { StorageOptions } from '../../template/shared/proto/storage/StorageOptions';
import { MatDialog } from '@angular/material/dialog';
import { DeleteModalComponent } from '../../template/shared/components/modals/delete-modal/delete-modal.component';
import { UserBackend } from '../../backend/user.backend';
import { NewsBackend } from '../../backend/news.backend';
import { NEWS_STATUS_PUBLISHED, NEWS_STATUS_NOT_PUBLISHED } from '../constants';
import { DATE_WITH_TIME, SHORT_DATE } from '../../core/time';
import { faBars, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

const mapToRes = (res: Array<any>) => {
  const a = ({ count: res.length > 0 && res[0].count ? res[0].count : res.length, data: res });
  return a;
};

const applyCurrentPage = (q: any) => {
  if (q.recordsPerPage > 0) {
    return {
      ...q,
      startFrom: (q.page) * q.recordsPerPage
    };
  } else {
    q.recordsPerPage = 99999999;
    return {
      ...q,
      startFrom: 0
    };
  }
};
const defaultParams = (query: any, p: any = {}) => applyCurrentPage(({ ...query, ...p }));
const noAddition = (query: any) => defaultParams(query);
const withAddition = (paramsToAdd: any) => (query: any) => defaultParams(query, paramsToAdd);
const withCondition = (condition: (params: any) => any) => (query: any) => defaultParams(query, condition(query));


@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss']
})
export class ContentListComponent implements OnInit, AfterViewInit, OnDestroy {

  loading: any;

  @ViewChild('searchField') _searchField: ElementRef;
  @ViewChild(QueryProviderDirective) _queryProvider: QueryProviderDirective;
  @ViewChild(ServerTableComponent) _serverTable: ServerTableComponent;
  @ViewChild('statusTpl', { read: TemplateRef, static: true }) _statusTpl: TemplateRef<any>;
  @ViewChild('createdTpl', { read: TemplateRef, static: true }) _createdTpl: TemplateRef<any>;
  @ViewChild('showFromTpl', { read: TemplateRef, static: true }) _showFromTpl: TemplateRef<any>;
  @ViewChild('isPrivateTpl', { read: TemplateRef, static: true }) _isPrivateTpl: TemplateRef<any>;

  dateWithTime = DATE_WITH_TIME;
  shortDate = SHORT_DATE;

  protected _sortingMapper = (v) => {
    let col = v.prop ? this._tm.columns.find((c) => c.prop == v.prop) : this._tm.columns.find((c) => c.sortable !== false);
    return col ? ({ orderId: col.orderId, orderUpId: v.order == 'asc' ? 0 : 1 }) : v;
  };

  private makeQuery = (query: string) => {
    return (additionalParams: (q: any) => any) => {
      return (params: any) => {
        return this._newsBackend.getNewsList(additionalParams(params)).pipe(map((entities: any) => {
          this.reloadPageOnCondition(additionalParams(params), entities);
          return {
            data: entities,
            count: entities && entities[0] ? entities[0].recordsCount : 0,
          }
        }),
          tap(this._applySelected));
      }
    }
  };
  protected _selectedRows: Array<any> = [];
  private _lastId: any;
  private _subscriptions: Array<Subscription> = [];
  private __tm;
  private _colSettings: Array<any> = [];
  protected ctm;
  protected _isVisible: boolean = false;
  protected _tm: TableModel;
  protected _currentPage: number = 3;
  private _rowClicks: Subject<any> = new Subject();
  private _pageSavingOptions: StorageOptions = { popState: true, refresh: true };
  protected _showSearchClear = false;
  private _flags;


  private _tModels: Array<TableModel>;

  private _applySelected = (res) => {
    if (!res || !res.data || res.data.length < 1) {
      return;
    }
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].id == this._lastId) {
        this._selectedRows.push(res.data[i]);
        return;
      }
    }
  };

  private _tableConstructors = {
    news: ({
      new: ['../news/new'],
      id: 'news',
      hasButton: true,
      title: 'Naujienos',
      idProp: 'newsId',
    }),
  };

  faBars = faBars;
  faPlusSquare = faPlusSquare;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _stateService: StateService,
    private _storageService: StorageService,
    private _chg: ChangeDetectorRef,
    private _newsBackend: NewsBackend,
    private _userBackend: UserBackend,
    public dialog: MatDialog) {

  }

  ngOnInit() {
    this._tModels = [
      {
        name: 'news',
        onRowClick: (r: any) => { },
        columns:
          [
            { name: 'Pavadinimas', prop: 'title', width: 550, sortable: false },
            { name: 'Autorius', prop: 'userName', width: 190, sortable: false },
            { name: 'Sukūrimo Laikas', prop: 'created', cellTemplate: this._createdTpl, width: 190, orderId: 74, sortable: true, sorted: { order: 'desc' } },
            { name: 'Nuo Kada Rodyti', prop: 'showFrom', cellTemplate: this._showFromTpl, width: 190, sortable: false },
            { name: 'Statusas', prop: 'status', cellTemplate: this._statusTpl, width: 120, orderId: 76, sortable: true },
            { name: 'Vidinis', prop: 'isPrivate', cellTemplate: this._isPrivateTpl, width: 90, orderId: 94, sortable: true },
          ],
        getData: (q) => (this.makeQuery('')(noAddition)(q))
      },
    ];
  }

  reloadPageOnCondition(params, entities) {
    if (entities.length === 0 && params.page !== 0 && params.page !== undefined && params.page !== null) {
      this._pageChanged(0);
      location.reload();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  private _toggleLeftMenu() {
    this._stateService.notify(TOGGLE_LEFT_MENU);
  }


  ngAfterViewInit(): void {
    this._subscriptions.push(
      this._rowClicks.pipe(
        withLatestFrom(
          this._route.params.pipe(pluck('type')).pipe(
            withLatestFrom(
              this._route.queryParams.pipe(pluck('id')),
              (p, id) => ({
                params: p,
                id
              })
            )
          ),
          (row, t) => {
            return ({
              row: row,
              t: t
            })
          })
        , filter((rt) => rt.t != null && (<any>(rt.t)).params && this._tableConstructors[<string>(<any>(rt.t)).params] && rt.row[this._tableConstructors[<string>(<any>(rt.t)).params].idProp])
      )
        .subscribe((rt) => {
          this._router.navigate(['../', (<any>(rt.t)).params, rt.row[this._tableConstructors[<string>(<any>(rt.t)).params].idProp]], { relativeTo: this._route });
        }));

    this._subscriptions.push(this._route.params.pipe(pluck('type')).subscribe((d: any) => {

      this._isVisible = false;
      this._selectedRows = [];
      //Parenkamas atitinkamas konstruktorius
      this.ctm = this._tableConstructors[d];
      //Atstatomas pasirinktas eilutes ID, tam, kad matytusi ant kurios eilutes buvo paspausta
      this._lastId = this._storageService.get(this.ctm.id, null, { popState: true, static: true });
      //Pakraunami pasirinkti stulpeliai ir ju plociai
      let colSettings = this._storageService.get(this.ctm.id + 'colSettings') || [];
      let sortSettings = this._storageService.get(this.ctm.id + 'sort');
      //Gaunamas reikiamas TableModel
      let __tm = this._tModels.find((tm) => tm.name == d);

      //Aplungiami TableModel nustatymai, su issaugotais atmintyje
      for (let i = 0; i < __tm.columns.length; i++) {
        let cs = colSettings.find((s) => s.name == __tm.columns[i].name);
        if (cs) {
          __tm.columns[i].visible = cs.visible != undefined ? cs.visible : true;
          __tm.columns[i].width = cs.width || __tm.columns[i].width || 200;
          __tm.columns[i].index = cs.index || i;
        } else {
          __tm.columns[i].width = __tm.columns[i].width || 200;
          __tm.columns[i].index = i;
        }
      }
      if (sortSettings && sortSettings.prop) {
        let _c = __tm.columns.find((c) => c.prop == sortSettings.prop);
        if (_c) {
          let _dc = __tm.columns.find((c) => c.sorted && true);
          delete _dc['sorted'];
          _c.sorted = { order: sortSettings.order };
        }
      }
      this._colSettings = colSettings;
      this._tm = __tm;
      //Perkraunama lentele
      try {
        this._chg.detectChanges();
      } catch (err) { }
      this._isVisible = true;
      this._currentPage = this._storageService.get('currentPage', 0, this._pageSavingOptions);
      try {
        this._chg.detectChanges();
      } catch (err) { }
      this._searchField.nativeElement.value = this._storageService.get(this.ctm.id + '.search', undefined, { popState: true, refresh: true }) || '';
      this._searchField.nativeElement.value != '' ? this._searchField.nativeElement.dispatchEvent(new Event('keyup')) : {};

      let sortingQp = this._serverTable.getSortingQueryPart();
      this._queryProvider.registerQueryPart(sortingQp);

      const pagingQp = this._serverTable.getPagingQueryPart();
      this._queryProvider.registerQueryPart(pagingQp);

      this._queryProvider.active = true;
      //Trigerinama paieska
      this._queryProvider.emit('page');
      this._queryProvider.emit('sort');
      this._queryProvider.emit('search');

    }));

  }

  _getStatus(row) {
    switch (row.status) {
      case NEWS_STATUS_PUBLISHED.status.toString(): return NEWS_STATUS_PUBLISHED.name
      case NEWS_STATUS_NOT_PUBLISHED.status.toString(): return NEWS_STATUS_NOT_PUBLISHED.name;
      default: return null;
    }
  }

  _addNewClicked() {
    this._isVisible = false;
    this._router.navigate(this.ctm.new, { relativeTo: this._route });
  }

  private _pageChanged(page) {
    this._storageService.store('currentPage', page, this._pageSavingOptions);
  }

  private _search(val) {
    this._storageService.store(this.ctm.id + '.search', val, { popState: true, refresh: true });
    document.getElementById('content_header_search').blur();
    this._queryProvider.emit('search');
  }

  _onSort(e) {
    //let col = this._tm.columns.find((c) => c.prop == e.prop);
    this._storageService.store(this.ctm.id + 'sort', { prop: e.prop, order: e.order });
  }

  private _columnResized(e: ColumnResized) {
    let cs = this._colSettings.find((cs) => cs.name == e.name);
    if (!cs) {
      this._colSettings.push({ name: e.name, width: e.newWidth });
    } else {
      cs.width = e.newWidth;
    }
    this._storageService.store(this.ctm.id + 'colSettings', this._colSettings);
  }

  _delete(userInfo) {
    this.dialog.open(DeleteModalComponent, { data: userInfo });
  }

  private _clearSearch() {
    this._searchField.nativeElement.value = '';
    this._searchField.nativeElement.value == '' ? this._searchField.nativeElement.dispatchEvent(new Event('keyup')) : {};
    this._storageService.store(this.ctm.id + '.search', undefined, {
      popState: true,
      refresh: true
    });
    this._queryProvider.emit('search');
  }

  private _detectSearchVal(val) {
    val.length > 0 ? this._showSearchClear = true : this._showSearchClear = false;
  }

  _rowClicked(row: any) {
    this._storageService.store(this.ctm.id, row.id, { popState: true, static: true });
    this._rowClicks.next(row);
  }

  protected _activateUser(user) {
    if (!user.activating) {
      user.activating = true;
      this._userBackend.activateUser(user.id).subscribe(res => {
        if (res) {
          user.isActive = '1';
          user.activating = false;
          try {
            this._chg.detectChanges();
          } catch (e) {

          }
        }
      });
    }
  }

}
