import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilesBackend } from '../../../../../backend/files.backend';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {

  _url: string;
  photo: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ImageModalComponent>,
    private filesBackend: FilesBackend,
    private chg: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    const file = this.data;
    if (typeof file != 'string' && file.fileId) {
      this._url = 'files/' + file.fileId;
    } else if (typeof file == 'string') {
      this._url = 'service/f/download/' + file;
    } else if (file.dString) {
      this._url = 'service/f/download/' + file.dString;
    } else {
      console.error('Cannot show file');
    }

    this.getPicture();
  }

  close(): void {
    this.dialogRef.close();
  }

  getPicture() {
    if (this.data && this.data.fileId) {

      this.filesBackend.getImage(this.data.fileId).subscribe((res) => {
        const blob = res.body;
        this.createImageFromBlob(blob);
      },
        (err) => {
        }
      )
    } else if (this.data && this.data.dString) {
      this.filesBackend.getImageByDstring(this.data.dString).subscribe((res) => {
        const blob = res.body;
        this.createImageFromBlob(blob);
      },
        (err) => {
        }
      )
    }
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener('load', () => {
      this.photo = reader.result;
      try {
        this.chg.detectChanges();
      } catch (err) { }
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

}
