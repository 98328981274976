import { UserService } from '../../../../services/user/user.service';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { StateService } from "../../../../services/state/stateService";
import { TaskboardSidebarService } from '../../../services/taskboardSidebar.service';
import { TaskReviewBase } from '../../../common/taskReviewBase';
import { TasksBackend } from '../../../../backend/tasks.backend';
import { TaskboardDataService } from "../../../services/taskboard-data.service";
import { TimerService } from "../../../../template/shared/services/timer/timer-service";
import { TaskReview } from "../../../models/task-review";
import { AuthService } from '../../../../services/auth.service';
import { T_EXECUTOR, T_OWNER } from './../../../../app.constants';
import { filter, of, Subscription } from 'rxjs';
import { ProjectsBackend } from '../../../../backend/projects.backend';
import { REQUESTS, REVIEW, TASKS } from '../../../../taskboard/taskboard.help';
import { TASK_STATE_COMPLETED, TASK_STATE_FINISHED } from '../../../../core/constants';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { EditTaskDescriptionComponent } from '../../modals/edit-task-description/edit-task-description.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { compareToToday } from '../../../../template/shared/proto/helpers';
import { SalesBackend } from '../../../../backend/sales.backend';
import { UPDATE_TASKBOARD_LIST } from '../../../../state/state';

@Component({
  selector: '.content_inner_wrapper',
  templateUrl: './task-review.component.html',
  styleUrls: ['./task-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskReviewComponent extends TaskReviewBase implements OnInit, AfterViewInit, OnDestroy {

  protected ownerTextEditing = false;

  public tree;
  visibleTimers = true;
  visibleAllTimers = true;
  childTasksTabActive = false;
  hasFiles: boolean = false;

  protected participants: any[];
  protected participantsCount: number = 0;
  projectServiceTimes: any;
  projectTimesView: any;
  _subscriptions: Subscription[] = [];
  updateTabsOrderSubs: Subscription;
  data: any;
  text: string;

  TASK_STATE_COMPLETED = TASK_STATE_COMPLETED;
  TASK_STATE_FINISHED = TASK_STATE_FINISHED;

  @ViewChild('tabSet') tabset: TabsetComponent;

  jobsFiltering = {
    flagsGroup: '11',
    rules: {
      '00': '11'
    },
    flags: [
      {
        flag: 'sentDpa',
        label: 'Išsiųstas DPA'
      },
      {
        flag: 'notSentDpa',
        label: 'Neišsiųstas DPA'
      }
    ]
  }

  initJobList = [];
  filteredJobList = [];
  filterJobsState: any;
  compareToToday = compareToToday;

  salesRelationsList: any[] = [];
  visiblesalesRelationsList = true;

  constructor(
    protected route: ActivatedRoute,
    public dialog: MatDialog,
    protected state: StateService,
    protected sidebarService: TaskboardSidebarService,
    protected router: Router,
    protected tasksBackend: TasksBackend,
    protected userService: UserService,
    protected authService: AuthService,
    protected chg: ChangeDetectorRef,
    protected taskboardDataService: TaskboardDataService,
    protected timerService: TimerService,
    protected elementRef: ElementRef,
    private _chg: ChangeDetectorRef,
    private projectsBackend: ProjectsBackend,
    public permissionsService: NgxPermissionsService,
    private salesBackend: SalesBackend,
  ) {
    super(dialog,
      route,
      tasksBackend,
      state,
      sidebarService,
      router,
      chg,
      taskboardDataService,
      timerService,
      elementRef.nativeElement,
      userService,
      authService);
  }

  editOwnerNoteClicked(description: any, rq: TaskReview) {
    this._subscriptions.push(this.tasksBackend.editOwnersText(rq.id, description.text, description.textHtml).subscribe(res => {
      if (res.success) {
        this.task.ownerNote = description.text;
        rq.ownerNote = description.text;
        this.task.ownerNoteHtml = description.textHtml;
        rq.ownerNoteHtml = description.textHtml;
      }
      try {
        this.chg.detectChanges();
      } catch (e) { console.log(e) }
    }));
  }

  editDescriptionClick() {
    const modalRef = this.dialog.open(EditTaskDescriptionComponent, { ...{}, data: { descriptionHTML: this.task.ownerNoteHtml, description: this.task.ownerNote, task: this.task }, minWidth: 600 });
    this._subscriptions.push(modalRef.beforeClosed().subscribe((data) => {
      if (data) {
        this.editOwnerNoteClicked(data, this.task);
      }
    }));
  }

  refreshfilterJobs() {
    this.filterJobs(this.filterJobsState);
  }

  filterJobs(state) {
    this.filterJobsState = state;
    let tmpList = this.initJobList;
    this.filterJobsState?.forEach(st => {
      if (st.flag == 'sentDpa' && !st.checked) {
        tmpList = tmpList.filter(job => !job.lastSendDate);
      }
      if (st.flag == 'notSentDpa' && !st.checked) {
        tmpList = tmpList.filter(job => job.lastSendDate);
      }
    })
    this.filteredJobList = tmpList;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._subscriptions.push(this.route.queryParams.subscribe((queryParams) => {
      this.childTasksTabActive = queryParams.childTasks;
    }));
    // this.getParticipants();
    // console.log('oninit', this.task);
    this._subscriptions.push(this.personsChanged.subscribe(() => {
      this.getParticipants();
    }));
    this._subscriptions.push(this._hasTimers$.subscribe(() => {
      this.sortTabs();
    }));
    // this.getFiles();
    this._subscriptions.push(this._loadedTaskObs().subscribe((t) => {
      // console.log('this.task', this.task);
      this.getFiles();
      this.getParticipants();
      // console.log('new task Obs fire');
      if (this.updateTabsOrderSubs) this.updateTabsOrderSubs.unsubscribe();
      this.updateTabsOrderSubs = this.task.updateTabsOrder$.subscribe(() => {
        // console.log('updateOrder Obs fire');
        this.getFiles();
      })
      this._subscriptions.push(this.salesBackend.getSalesRelations(this.task.id).subscribe((resp) => {
        this.salesRelationsList = resp;
        try {
          this.chg.detectChanges();
        } catch (e) { }
      }))
    }))
    this._data.subscribe(task => {
      this.data = task;
      this.initJobList = this.data?.data?.jobs || [];
      this.refreshfilterJobs();
    })
  }

  ngAfterViewInit(): void {
    // setTimeout(_ => {
    //   try {
    //     this.chg.detectChanges()
    //   } catch (err) { }
    // }, 5000);
    this.sortTabs();
    this._subscriptions.push(this.state.getStream(UPDATE_TASKBOARD_LIST).subscribe((fn) => {
      this.salesRelationsTabOpened();
    }));
  }

  hasPermissionToEditDeadline() {
    return this.permissionsService.hasPermission('crm_negotiator') || this.permissionsService.hasPermission('crm_manager')
  }

  salesRelationsTabOpened() {
    this._subscriptions.push(this.salesBackend.getSalesRelations(this.task.id).subscribe((resp) => {
      this.salesRelationsList = resp;
      try {
        this.chg.detectChanges();
      } catch (e) { }
    }))
  }

  refreshTask() {
    this._subscriptions.push(this._updateTask(this.task).subscribe());
  }

  textChanged(text) {
    this.text = text;
  }

  private getFiles() {
    // const subs = this._loadedTaskObs().subscribe((t) => {
    // console.log('getFiles subs')
    this._subscriptions.push(this.tasksBackend.getFilesList(this.task.id).subscribe((filesList) => {
      this.hasFiles = filesList.length > 0;
      try {
        this.chg.detectChanges();
      } catch (e) { }
      this.sortTabs();
    }))
    //   subs.unsubscribe();
    // }, err => {
    //   subs.unsubscribe();
    // });
  }

  tabChanged(event) {
    this.currentTabId = event.id;
  }

  sortTabs() {
    this.tabset?.tabs.sort((first: TabDirective, second: TabDirective) => { return (first.id > second.id) ? 1 : -1 });
    // this.tabset?.tabs.forEach(tab => {
    //   if (tab.id === this.currentTabId) {
    //     tab.active = true;
    //   }
    // });
    try {
      this.chg.detectChanges();
    } catch (e) { }

  }

  _changed(e) {
  }

  public _childTree(event) {
    this.tree = event;
  }

  private getParticipants() {
    // const subs = this._loadedTaskObs().subscribe((t) => {
    this._subscriptions.push(this.tasksBackend.getTaskPersonsByTask(this.task.id).subscribe((persons) => {
      this.participants = persons;
      this.participantsCount = 0;
      this.participants.forEach((participant) => {
        participant.typeId && participant.typeId !== T_OWNER && participant.typeId !== T_EXECUTOR ? this.participantsCount++ : null;
      });
      try {
        this.chg.detectChanges();
      } catch (e) { }
      this.getPersons = () => { return of(this.participants) };
      this.sortTabs();
    }))

    //   subs.unsubscribe();
    // }, err => {
    //   subs.unsubscribe();
    // });
  }

  timersRefresh() {
    setTimeout(() => {
      this.visibleTimers = false;
      try {
        this._chg.detectChanges();
      } catch (err) { }
      this.visibleTimers = true;
      try {
        this._chg.detectChanges();
      } catch (err) { }
    }, 0);
  }

  allTimersRefresh() {
    setTimeout(() => {
      this.visibleAllTimers = false;
      try {
        this._chg.detectChanges();
      } catch (err) { }
      this.visibleAllTimers = true;
      try {
        this._chg.detectChanges();
      } catch (err) { }
    }, 0);
  }

  projectEstimationTabOpened(task) {
    this._subscriptions.push(this.projectsBackend.getProjectsServicesTime(task.id).subscribe((resp) => {
      this.projectServiceTimes = resp.projectsTaskServicesList;
      this.projectTimesView = resp.projectTimesView;
      try {
        this.chg.detectChanges();
      } catch (e) { }
    }))
  }

  gotoProject(task) {
    this.router.navigate(['/projects', task.isRequest ? REQUESTS : TASKS, REVIEW, task.id]);
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    if (this.updateTabsOrderSubs) this.updateTabsOrderSubs.unsubscribe();
  }

}
