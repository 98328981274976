import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EMAIL_PATTERN_REGEXP } from '../../../../../app.constants';


@Component({
  selector: 'send-dpa-modal',
  templateUrl: './send-dpa-modal.component.html',
  styleUrls: ['./send-dpa-modal.component.scss']
})
export class SendDpaModalComponent implements OnInit {

  public _email: string;
  public _subject: string;
  public _emailBody: string;
  public _dpaNr: string;
  public _dpaemailform: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this._buildForm();
  }

  private _buildForm() {
    this._dpaemailform = this.formBuilder.group({
      email: [this._email, [Validators.required, Validators.pattern(EMAIL_PATTERN_REGEXP)]],
      subject: this._subject,
      emailBody: this._emailBody
    });
  }

  send() {
    this.dialogRef.close({
      email: this._dpaemailform.controls.email.value,
      subject: this._dpaemailform.controls.subject.value,
      emailBody: this._dpaemailform.controls.emailBody.value,
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }



}
