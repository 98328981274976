
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../core/api/api.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class KpisBackend {
  constructor(private _api: ApiService) {
  }

  // getViolationKpis(showDays, userId): Observable<any[]> {
  //   return <any>this._api.getAuth('kpis/violations?showDays=' + (showDays ? '1' : '0') + '&userId=' + userId + '&days=14').pipe(map((res: any) => res.body.entity));
  // }

  // getMyViolationKpis(id, showDays): Observable<any[]> {
  //   // return <any>this._api.getAuth('kpis/violations' + '?showDays=' + (showDays ? '1' : '0') + '&days=14').pipe(map((res: any) => res.body.entity));
  //   return <any>this._api.postAuth('kpis/violations' + '?showDays=' + (showDays ? '1' : '0') + '&days=14', [id]).pipe(map((res: any) => res.body.entities));
  // }

  getBulkViolationKpis(idsList, showDays): Observable<any[]> {
    // return <any>this._api.postAuth('kpis/violationsBulk' + '?showDays=' + (showDays ? '1' : '0') + '&days=14', idsList).pipe(map((res: any) => res.body.entity));
    return <any>this._api.postAuth('kpis/violations' + '?showDays=' + (showDays ? '1' : '0') + '&days=14', idsList).pipe(map((res: any) => res.body.entities));
  }

  getWorkTimeByTaskTypeKpis(userId): Observable<any[]> {
    return <any>this._api.getAuth('kpis/workTimeByTaskType/' + userId).pipe(map((res: any) => res.body.entity));
    // return of({
    //   body:
    //     { "entity": { "date": ["2020-12-16", "2020-12-17", "2020-12-18", "2020-12-21", "2020-12-22"], "minutes": { "code:VT_0": [0, 0, 0, 0, 0], "code:VT_1": [0, 0, 0, 0, 0], "code:VT_2": [0, 0, 0, 0, 0], "code:VT_3": [0, 0, 0, 0, 0], "code:VT_4": [0, 0, 0, 0, 0], "code:VT_5": [0, 0, 0, 0, 0], "code:Not_assigned": [0, 0, 0, 0, 0] }, "labels": [{ "id": 3691314, "name": "Nepaskirstyta", "code": "VT_0" }, { "id": 3691315, "name": "Aptarnavimas", "code": "VT_1" }, { "id": 3691316, "name": "Projektinė", "code": "VT_2" }, { "id": 3691317, "name": "Pardavimas", "code": "VT_3" }, { "id": 3691318, "name": "Vidinė", "code": "VT_4" }, { "id": 4060036, "name": "Marketingas", "code": "VT_5" }], "dayOfWeek": [3, 4, 5, 1, 2] }, "success": true, "responseType": 2, "error": null, "statusCode": 0 }
    // }
    // ).pipe(map((res: any) => res.body.entity));
  }

  geEmployeeWorkSummaryKpis(): Observable<any[]> {
    return <any>this._api.getAuth('kpis/workSummary').pipe(map((res: any) => res.body.entities));
  }

}
