import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'form',
  host: { 'autocomplete': 'off' }
})
export class PreventFormSubmitDirective {

  constructor() {
  }

  @HostListener('keydown', ['$event'])
  protected _keyDown(event: KeyboardEvent) {
    if (event.key === "Enter" && ['TEXTAREA'].indexOf((<HTMLElement>event.target).tagName) < 0) {
      event.preventDefault();
    }
  }
}
