
import { map } from 'rxjs/operators';
import { QueryPart as IQueryPart } from "./Query"
import { Observable, ReplaySubject, Subscription, Subject } from "rxjs";

export class QueryPart<T> implements IQueryPart<T> {
  waitFirst: boolean;
  autoEmit: boolean;
  value: Observable<T> = new ReplaySubject<T>(1);
  queryPart: string;
  mapValue: (val: any) => T;
  private _value: Observable<T>;
  private _subscriptions: Array<Subscription> = [];


  constructor(qp: string, val: Observable<T>, waitFirst: boolean, autoEmit: boolean, mapValue?: (val: any) => T) {
    this._value = val;
    this.mapValue = mapValue;
    this._subscriptions.push(this._value.pipe(map((v) => this.mapValue ? this.mapValue(v) : v)).subscribe(<Subject<T>>this.value));
    this.queryPart = qp;
    this.waitFirst = waitFirst;
    this.autoEmit = autoEmit;
  }

  emit(val: T) {
    (<Subject<T>>this.value).next(val);
  }

  clear() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }
}
