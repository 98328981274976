import { SharedModule } from './../template/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SALES_ROUTES } from './sales.routing.module';
import { TaskboardSharedModule } from '../taskboard/taskboard.shared.module';
import { SalesComponent } from './sales.component';
import { SalesMenuComponent } from './sales-menu/sales-menu.component';
import { SalesListComponent } from './sales-list/sales-list.component';

@NgModule({
  declarations: [
    SalesComponent,
    SalesMenuComponent,
    SalesListComponent,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  imports: [
    CommonModule,
    SharedModule,
    TaskboardSharedModule,
    RouterModule.forChild(SALES_ROUTES),
    // TaskboardModule,
  ]
})
export class SalesModule { }