import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { isChar } from "../../proto/lib/keyboard/functions";

@Directive({
  selector: '[onLengthChanged]',
  exportAs: 'lengthListener'
})
export class LengthListenerDirective implements OnInit, AfterViewInit {
  @Input() maxLength = 0;
  @Output() onLengthChanged = new EventEmitter<number>();

  private _currentLength = -1;

  get length(): number {
    const t = this.text;
    return t.length;
  }

  get text(): string {
    return this.element[this._textProp] || '';
  }

  private _textProp = 'innerText';
  set text(val: string) {
    const el = this.element;
    if (el) {
      el[this._textProp] = val;
    }
  }

  get element(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  lastText: string;

  constructor(protected _elementRef: ElementRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (['TEXTAREA', 'INPUT'].indexOf(this.element.tagName) > -1) {
      this._textProp = 'value';
    } else {
      this._textProp = 'innerText';
    }
    this.onLengthChanged.emit(this._currentLength = this.length);
  }

  @HostListener('keypress', ['$event'])
  protected _keyPress(e: KeyboardEvent) {
    this._check(e);
  }

  @HostListener('keydown', ['$event'])
  protected _keydown(e: KeyboardEvent) {
    this._check(e);
  }

  @HostListener('keyup', ['$event'])
  protected _keyup(e: KeyboardEvent) {
    this._check(e);
  }

  @HostListener('compositionend', ['$event'])
  protected _compositionend(e) {
    this._check(e);
  }

  @HostListener('paste', ['$event'])
  protected _paste(e: ClipboardEvent) {
    setTimeout(_ => this._check(e));
  }

  public caretToEnd() {
    const range = document.createRange();
    const lastNode = this.element.childNodes[Math.max(this.element.childNodes.length - 1, 0)];
    range.setStart(lastNode, ('' || lastNode.textContent).length);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  private _check(e: Event) {
    const curr = this.length;
    const max = this.maxLength;
    if (max > 0 && curr > max) {
      this._preventInput(e);
      this._currentLength = max;
      return;
    }
    this._updateAndEmit();
  }

  private _preventInput(e: Event) {
    //Jei nera simbolis, preventinti nereikia.
    if (e instanceof KeyboardEvent && !isChar(e)) {
      setTimeout(_ => {
        this._updateAndEmit();
      });
      return;
    }
    e.preventDefault();
  }

  private _updateAndEmit() {
    const l = this.length;
    const curr = this._currentLength;
    if (l != curr) {
      this._currentLength = l;
      this.onLengthChanged.emit(l);
    }
  }
}
