import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectedPartnerService } from '../../services/partner/selectedPartner.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'content-menu',
  templateUrl: './contentMenu.component.html',
  styleUrls: ['./contentMenu.component.scss']
})
export class ContentMenuComponent implements OnInit, OnDestroy {
  protected _menu = [
    {
      title: 'Turinys',
      icon: 'zmdi zmdi-collection-text',
      entries: [
        {
          title: 'Naujienos',
          routerLink: 'news'
        },
      ]
    }
  ];
  private _subscriptions: Array<Subscription> = [];

  constructor(private selectedPartnerService: SelectedPartnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {

  }

  ngOnInit() {
    this.selectedPartnerService.resetPartner();
    this.selectedPartnerService.resetUser();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sp) => sp.unsubscribe());
  }
}
