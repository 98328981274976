import { AfterContentInit, AfterViewInit, Directive, Input, OnInit, Renderer2, ViewContainerRef } from "@angular/core";

export const LOCAL = 'local';
export const SESSION = 'session';
export const UNTIL_BACK = 'back';

@Directive({
  exportAs: 'loader',
  selector: '[loader]',
})
export class LoadingDirective implements OnInit, AfterViewInit, AfterContentInit {
  private _isLoading;
  @Input() set loader(val: boolean) {
    if (this._loaderEl) {
      this._renderer.setStyle(this._loaderEl, 'visibility', val ? 'visible' : 'hidden');
    }
    this._isLoading = val;
  }
  get loader() {
    return this._isLoading;
  }
  @Input() loaderClass;

  private _loaderEl;
  constructor(private _viewRef: ViewContainerRef, private _renderer: Renderer2) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

  }

  ngAfterContentInit(): void {

  }
}
