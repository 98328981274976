import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'select-child-task-modal',
  templateUrl: './select-child-task-modal.component.html',
  styleUrls: ['./select-child-task-modal.component.scss']
})
export class SelectChildTaskModalComponent implements OnInit {

  taskId: any;
  selectedTask: any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) { }

  ngOnInit() {
    this.taskId = this.data.taskId;
  }

  submit() {
    this.dialogRef.close(this.selectedTask);
  }

  taskChildSelected(task) {
    this.selectedTask = task;
  }

  cancel(): void {
    this.dialogRef.close();
  }



}
