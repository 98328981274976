import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'new-delegate',
  templateUrl: './new-delegate.component.html',
  styleUrls: ['./new-delegate.component.scss']
})
export class NewDelegateComponent implements OnInit {


  constructor() { }

  ngOnInit() {
  }


}
