import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  protected _createUserForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateUserComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    private _formBuilder: UntypedFormBuilder,
  ) {
  }

  ngOnInit() {
    this._buildForm();
  }

  protected _createUser(createUserForm) {

  }

  private _buildForm() {
    this._createUserForm = this._formBuilder.group({

    });
  }
}
