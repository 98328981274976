
import { of as observableOf, Observable, throwError } from 'rxjs';

import { toArray, map, mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ActivityLogEntry } from "../models/ActivityLogEntry";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ActivityLogBackend {
  constructor(private http: HttpClient) {

  }

  public getActivityLog(): Observable<any> {
    return this.http.get('activityLog').pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }), mergeMap((al: any) => al),
      map((al) => new ActivityLogEntry(al)),
      toArray())

  }

}
