import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'partner-debts',
  templateUrl: './partnerDebts.component.html',
  styleUrls: ['./partnerDebts.component.scss'],
})
export class PartnerDebtsComponent implements OnInit {
  @Input() debts;

  private numberOfDebts: number = 0;




  ngOnInit(): void {
  }




}
