import { RequestReviewComponent } from '../taskboard/components/requests/request-review/request-review.component';
import { ID, MARKETING_CAMPAIGNS, ALL_MARKETING_TASKS, SELECTED_MARKETING_TASKS, REQUESTS, REVIEW, TASKS, TYPE, NEW_MARKETING_TASK, SALES } from '../taskboard/taskboard.help';
import { formPath, param } from '../core/routing/helpers';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { TaskReviewComponent } from '../taskboard/components/tasks/task-review/task-review.component';
import { NewTaskComponent } from '../taskboard/components/tasks/new-task/new-task.component';
import { SalesComponent } from './sales.component';
import { SalesListComponent } from './sales-list/sales-list.component';

export const redirectToPath = () => formPath(SALES);
// export const redirectToPath = () => '';

export const SALES_ROUTES: Routes = [
  {
    path: '',
    component: SalesComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: redirectToPath(),
      },
      {
        path: formPath(SALES),
        component: SalesListComponent,
        data: { tableType: 'marketingCampaigns' }
      },
      {
        path: formPath(TASKS, REVIEW, param(ID)),
        component: TaskReviewComponent,
      },
      {
        path: formPath(REQUESTS, REVIEW, param(ID)),
        component: RequestReviewComponent,
      },
      {
        path: formPath(NEW_MARKETING_TASK),
        component: NewTaskComponent,
        data: { sourceRoute: 'sales' }
      },
    ]
  }
];
