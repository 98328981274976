import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { KpisBackend } from '../../backend/kpis.backend';
import { AuthService } from '../../services/auth.service';

const today = (today, d) => d.getFullYear() == today.getFullYear() && d.getMonth() == today.getMonth() && d.getDate() == today.getDate();

@Component({
  selector: 'employee-work-summary-view',
  templateUrl: './employee-work-summary-view.component.html',
  styleUrls: ['./employee-work-summary-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeWorkSummaryViewComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('defaultHeader', { read: TemplateRef, static: true }) defaultHeaderTpl: TemplateRef<any>;
  @ViewChild('default', { read: TemplateRef, static: true }) defaultTpl: TemplateRef<any>;

  summaryList: any;
  private _subscriptions: Array<Subscription> = [];

  columns;
  sorts = [{ prop: 'name', dir: 'desc' }];

  dataReady = false;
  currentUserFullName: string;

  constructor(public chg: ChangeDetectorRef, private kpisBackend: KpisBackend, private dashboardService: DashboardService, private router: Router, private authService: AuthService) {
  }

  ngOnInit() {
    this.initColumns();
    this.geEmployeeWorkSummaryKpis();
    this.currentUserFullName = '' + this.authService.user && (this.authService.user.name + ' ' + this.authService.user.lastName);
  }

  initColumns() {
    this.columns = [
      {
        name: 'Darbuotojas',
        tooltip: 'Darbuotojas',
        prop: 'name',
        width: 220,
      }, {
        name: 'Užpildymo d.d. %',
        tooltip: ' Užpildymo % paskutinės darbo dienos',
        prop: 'dayProcInt',
        width: 120,
      }, {
        name: 'Užpildymo sav. %',
        tooltip: 'Užpildymo % pagal nutylėjimą paskutinių 5 darbo dienų',
        prop: 'weekProcInt',
        width: 120,
      }, {
        name: 'Viso trukmė',
        tooltip: 'Visa darbo trukmė paskutinių 5 darbo dienų',
        prop: 'time',
        width: 110,
      }, {
        name: 'Fakt. laikas',
        tooltip: 'Apmokestinamas kliento laikas paskutinių 5 darbo dienų',
        prop: 'factTime',
        width: 100,
      }, {
        name: 'Suma (be PVM)',
        tooltip: 'Suma (be PVM)',
        prop: 'sumWithoutVat',
        width: 120,
      },
    ];
  }

  ngAfterViewInit() {
  }

  geEmployeeWorkSummaryKpis() {
    this._subscriptions.push(this.kpisBackend.geEmployeeWorkSummaryKpis().subscribe((resp) => {

      this.summaryList = this.sortSummary(resp).map(s => {
        return { ...s, time: (s.time && s.time[0] === ':' ? '00' + s.time : s.time) };
      });
      this.dataReady = true;
      try {
        this.chg.detectChanges();
      } catch (err) { }
    }))
  }

  getRowClass(row) {
    return row.active ? 'row-current-user' : '';
  }

  sortSummary(arr) {
    let result;
    const sorted = arr.sort(this.sortByWeekProcInt).sort((x, y) => { return x.name == this.currentUserFullName ? -1 : y.name == this.currentUserFullName ? 1 : 0; });
    if (sorted[0] && sorted[0].name === this.currentUserFullName) {
      sorted[0].active = true;
    }
    return sorted;
  }

  sortByWeekProcInt(a, b) {
    if (a.weekProcInt < b.weekProcInt) {
      return -1;
    }
    if (a.weekProcInt > b.weekProcInt) {
      return 1;
    }
    return 0;
  }

  sort(prop) {
    const sorts: any = [{}];
    if (this.sorts[0].prop == prop) {
      sorts[0].prop = prop;
      sorts[0].dir = this.sorts[0].dir === 'asc' ? 'desc' : 'asc';
    } else {
      sorts[0].prop = prop;
      sorts[0].dir = 'desc';
    }
    this.sorts = sorts;
  }

  sortFn(propA, propB) {
    if (propA < propB) {
      return -1;
    }
    if (propA > propB) {
      return 1;
    }
    return 0;
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }


}
