
import { map } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { DatePipe } from "@angular/common";
import { TasksBackend } from "../../../backend/tasks.backend";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { TreeComponent, TreeModel, TreeNode } from "angular-tree-component";
import { TASK_CLIENT_COMMENTED, TASK_EMPLOYEE_COMMENTED, TASK_STATE_CHANGED } from "../../common/constants";
import { TaskboardDataService } from '../../../taskboard/services/taskboard-data.service';
import { TASK_STATE_FINISHED as CORE_TASK_STATE_FINISHED, TASK_STATE_COMPLETED as CORE_TASK_STATE_COMPLETED } from '../../../core/constants';
import { DATE_WITH_TIME, SHORT_DATE } from '../../../core/time';

@Component({
  selector: 'child-tasks',
  templateUrl: './child-tasks.component.html',
  styleUrls: ['./child-tasks.component.scss']
})
export class ChildTasksComponent implements OnInit, OnDestroy {
  @ViewChild('tree') treeComponent: TreeComponent;
  @Input() taskId: any;
  @Input() triggerUpdate: number;
  @Input() triggerEmitForSingleTask: boolean = false;
  @Output() taskChanged = new EventEmitter<any>();
  _node: Subject<any> = new ReplaySubject(1);
  tree: any;
  @Output() taskTree = new EventEmitter<any>();
  taskStateChangedEmitterSubs: Subscription;
  getTasksTreeSubs: Subscription;
  datePipe: DatePipe;

  dateWithTime = DATE_WITH_TIME;
  shortDate = SHORT_DATE;

  constructor(private _backend: TasksBackend, private taskboardDataService: TaskboardDataService, datePipe: DatePipe) {
    this.datePipe = new DatePipe('en-US');
  }

  ngOnInit() {
    this.onInitSetup();
  }

  onInitSetup() {
    if (this.getTasksTreeSubs) {
      this.getTasksTreeSubs.unsubscribe();
    };
    this.getTasksTreeSubs = this._backend.getTasksTree(this.taskId).pipe(
      map(arr => arr.map(el => {
        return ({
          id: el.taskId,
          name: el.code + ' (' + el.executor + ') - ' + '(' + el.state + ') - ' + ((<string>el.ownersText) || '').substr(0, 100),
          parentId: el.parentTaskId, ...el,
          stateForUser: el.stateForUser,
        })
      })),
      map((res: any[]) => this._tableToNode(res)))
      .subscribe(r => {
        this.tree = r;
        this.taskTree.emit(this.tree);
        this._node.next([r]);
        setTimeout(_ => this._selectCurrent())
      });
  }

  ngOnChanges(changes) {
    if (changes.triggerUpdate) {
      this.onInitSetup();
    }
  }

  _updateTreeChild(children, task, state) {
    if (children && children.length > 0) {
      for (let child of children) {
        if (child.id === task.id.toString()) {
          child.name = task.code + ' (' + task.executor + ') - ' + '(' + state.state + ') - ' + (state.endDate ? '(' + this.datePipe.transform(state.endDate, this.dateWithTime) + ') - ' : '') + ((<string>task.ownerNote) || '').substr(0, 100);
          child.stateForUser = task.stateForUser;
          child.stateId = state.stateId ? state.stateId.toString() : null;
        }
        this._updateTreeChild(child.children, task, state);
      }
    }
  }

  _nodeSelected(n: TreeNode, m: TreeModel) {
    const data = n.data;
    if (this.taskId == data.id && !this.triggerEmitForSingleTask) {
      return;
    }
    this.taskChanged.emit(data);
  }

  private _selectCurrent() {
    const tm: TreeModel = this.treeComponent.treeModel;
    if (tm) {
      const n = tm.getNodeById(this.taskId);
      if (n) {
        n.setActiveAndVisible();
      }
    }
  }

  private _tableToNode(arr: any[]): any {
    if (!(arr && arr.length > 0)) {
      return;
    }
    const obj = {};
    for (const n of arr) {
      const lvl = parseInt(n.level);
      const id = n.id;
      const parentId = n.parentId;
      const prevLevels = obj[lvl - 1];
      n.hasChildren = false;
      n.isExpanded = false;
      if (prevLevels) {
        const parent = prevLevels[parentId];
        if (parent) {
          if (parent.children) {
            parent.children.push(n);
          } else {
            parent.children = [n];
          }
          parent.hasChildren = true;
          parent.isExpanded = true;
        }
      }
      if (obj[lvl]) {
        obj[lvl][id] = n;
      } else {
        obj[lvl] = { [id]: n };
      }
    }
    const root = obj[1];
    if (!root) {
      return;
    }
    return root[Object.getOwnPropertyNames(root)[0]];
  }

  _wasCommented(stateForUser) {
    return stateForUser > 0 && (stateForUser & (TASK_CLIENT_COMMENTED | TASK_EMPLOYEE_COMMENTED)) > 0;
  }

  _stateChanged(stateForUser) {
    return stateForUser > 0 && (stateForUser & TASK_STATE_CHANGED) > 0;
  }

  _newUnseenTask(stateForUser) {
    return stateForUser < 0;
  }

  _completed(laUzduociuBusenosId) {
    return laUzduociuBusenosId === CORE_TASK_STATE_FINISHED.toString() || laUzduociuBusenosId === CORE_TASK_STATE_COMPLETED.toString();
  }

  ngOnDestroy() {
    if (this.taskStateChangedEmitterSubs) {
      this.taskStateChangedEmitterSubs.unsubscribe();
    }
    if (this.getTasksTreeSubs) {
      this.getTasksTreeSubs.unsubscribe();
    }
  }
}
