import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners-list',
  templateUrl: './partners-list.component.html',
  styleUrls: ['./partners-list.component.scss']
})
export class PartnersListComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }
}
