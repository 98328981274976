
import { filter } from 'rxjs/operators';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { GlobalState } from "../../app.state";
import { ConfigService } from "../../shared/services/config/config.service";
import { ThemesService } from '../../shared/services/themes/themes.service';
import { UserService } from "../../../services/user/user.service"
import { SelectedPartnerService } from "../../../services/partner/selectedPartner.service";
import { Partner } from "../../../models/Partner";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs"
import { UserBackend } from "../../../backend/user.backend";
import { DomSanitizer } from '@angular/platform-browser';
import { TemplateService } from "../../shared/services/template.service";
import { TimerService } from "../../shared/services/timer/timer-service";
import { AuthService } from '../../../services/auth.service';
import { DEFAULT_UNKNOWN_USER_PHOTO_URL } from '../../../core/constants';
import { faIdBadge as faIdBadgeReg, faUser as faUserReg } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: "app-header",
  templateUrl: "./top-navbar.component.html",
  styleUrls: ["./top-navbar.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})

export class TopNavbarComponent implements OnInit, OnDestroy {

  faIdBadge = faIdBadgeReg;
  faUser = faUserReg;

  protected selectedPartner: Observable<Partner>;
  protected selectedUser: Observable<Partner>;
  private _sidebarState: Subject<boolean> = new BehaviorSubject(false);
  protected name;
  protected lastName;
  private pictureUrl;
  private _showSearchFlag = false;
  public readonly rightSidebarState: Observable<boolean> = this._sidebarState.asObservable();
  protected _unseen: Observable<number>;
  public userPhoto: any;
  partnersLoader: Function;
  @ViewChild('search') search;
  currentTheme: any;
  subs: Subscription[] = [];

  constructor(
    public _DomSanitizer: DomSanitizer,
    public config: ConfigService,
    private _elementRef: ElementRef,
    private _state: GlobalState,
    private templateService: TemplateService,
    @Inject(SelectedPartnerService) public selectedPartnerService: SelectedPartnerService,
    // public themes: ThemesService,
    private userService: UserService,
    private userBackend: UserBackend,
    public _timersService: TimerService,
    public authService: AuthService,
    private chg: ChangeDetectorRef
  ) {
    this._state.subscribe('app.isApp_MobileSidebarLeftOpen', (isApp_MobileSidebarLeftOpen) => {
      this.config.appLayout.isApp_MobileSidebarLeftOpen = isApp_MobileSidebarLeftOpen;
    });
    this._state.subscribe('app.isApp_BackdropVisible', (isApp_BackdropVisible) => {
      this.config.appLayout.isApp_BackdropVisible = isApp_BackdropVisible;
    });
    this._state.subscribe('app.isApp_SidebarRightOpen', (isApp_SidebarRightOpen) => {
      this.config.appLayout.isApp_SidebarRightOpen = isApp_SidebarRightOpen;
    });
    this.selectedPartner = this.selectedPartnerService.selectedPartner;
    // this.selectedUser = this.selectedPartnerService.selectedUser;
  }

  ngOnInit() {
    this.templateService.registerTopNavBar(this);
    this.subs.push(this.userService.user$.pipe(filter((user) => user.getPictureUrl() != null)).subscribe((user) => {
      this.name = user.getName();
      this.lastName = user.getLastName();
      this.pictureUrl = user.getPictureUrl();
    }));
    this.getUserPicture();
  }

  ngOnDestroy(): void {
    this.templateService.unregisterTopNavBar();
    this.subs.forEach((s) => s.unsubscribe());
  }

  setTheme() {
    // this.themes.setTheme(this.currentTheme);
  }

  toggleAppMobileLeftMenuSidebar() {
    this.config.appLayout.isApp_MobileSidebarLeftOpen = !this.config.appLayout.isApp_MobileSidebarLeftOpen;
    this.config.appLayout.isApp_BackdropVisible = !this.config.appLayout.isApp_BackdropVisible;
    this._state.notifyDataChanged('app.isApp_MobileSidebarLeftOpen', this.config.appLayout.isApp_MobileSidebarLeftOpen);
    this._state.notifyDataChanged('app.isApp_BackdropVisible', this.config.appLayout.isApp_BackdropVisible);
    return false;
  }

  resetPartner() {
    this.search.deselect();
  }

  resetUser() {
    this.search.deselect();
  }

  setPartner() {
    this.selectedPartnerService.isPartner = true;
  }

  setUser() {
    this.selectedPartnerService.isPartner = false;
  }

  private _showSearch(show: boolean) {
    this._showSearchFlag = show;
  }

  logout() {
    this.userService.logOut(false);
  }

  toggleAppRightSidebar() {
    this.config.appLayout.isApp_SidebarRightOpen = !this.config.appLayout.isApp_SidebarRightOpen;
    this.config.appLayout.isApp_BackdropVisible = !this.config.appLayout.isApp_BackdropVisible;
    this._state.notifyDataChanged('app.isApp_SidebarRightOpen', this.config.appLayout.isApp_SidebarRightOpen);
    this._state.notifyDataChanged('app.isApp_BackdropVisible', this.config.appLayout.isApp_BackdropVisible);
    this._sidebarState.next(this.config.appLayout.isApp_SidebarRightOpen);
    return false;
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener('load', () => {
      this.userPhoto = reader.result;
      try {
        this.chg.detectChanges();
      } catch (err) { }
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getUserPicture() {
    if (this.authService.user) {
      this.subs.push(this.userBackend.getUserPicture(null).subscribe((res) => {
        if (res.body.type === 'text/plain' && res.body.size === 14) {
          this.userPhoto = DEFAULT_UNKNOWN_USER_PHOTO_URL;
        } else {
          const blob = res.body;
          this.createImageFromBlob(blob);
        }
      },
        (err) => {
          if (err.status === 404) {
            this.userPhoto = DEFAULT_UNKNOWN_USER_PHOTO_URL;
          } else {
          }
        }
      ))
    } else {
      setTimeout(() => {
        this.getUserPicture();
      }, 1000)
    }
  }
}
