import { SharedModule } from './../template/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PROJECTS_ROUTES, tableTypeProvider } from './projects.routing.module';
import { ProjectsComponent } from './projects.component';
import { ProjectsMenuComponent } from './projects-menu/projects-menu.component';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { TaskboardSharedModule } from '../taskboard/taskboard.shared.module';

@NgModule({
    declarations: [
        ProjectsComponent,
        ProjectsMenuComponent,
        ProjectsListComponent,
    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
        tableTypeProvider,
    ],
    imports: [
        CommonModule,
        SharedModule,
        TaskboardSharedModule,
        RouterModule.forChild(PROJECTS_ROUTES),
        // TaskboardModule,
    ]
})
export class ProjectsModule { }
