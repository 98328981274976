import { Injectable } from "@angular/core";
@Injectable()
export class ConfigurationService {
  constructor(
  ) { }

  loadPermissions(authService, permissionsService, apiService, logsBackend) {
    return new Promise((resolve, reject) => {
      if (authService.isLoggedIn()) {
        const logObj = {
          eventId: 'permissionsRequest',
          message: 'Non error',
          userId: authService.user ? authService.user.usersId : null,
          token: authService.idToken
        };
        const subs = logsBackend.saveLog(logObj).subscribe(() => {
          subs.unsubscribe();
        }, () => {
          subs.unsubscribe();
        })
        apiService.getAuthNoUnauthRedirect('users/permissions').subscribe((resp) => {
          if (resp.status === 200) {
            const permissions = new Array();
            const respPerm = (<any>resp.body).entities;
            respPerm.forEach(element => {
              permissions.push(element.name);
            });
            permissionsService.loadPermissions(permissions);
            authService.permissionsLoaded = true;
            resolve(true);
          }
        },
          (err) => {
            resolve(true);
          });
      } else {
        resolve(true);
      }
    });
  }
}
