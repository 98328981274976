
import { filter, map, tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ApiService } from "../core/api/api.service";

@Injectable()
export class ListsBackend {
  constructor(private _api: ApiService) {
  }

  getAllPartners() {
    return this._api.getAuth('lists/partners').pipe(map((res: any) => res.body.entities));
  }

  getAllEmplyees() {
    return this._api.getAuth('lists/employees').pipe(map((res: any) => res.body.entities));
  }

  getProducts() {
    // partnerId = 1 grazina visus produktus
    return <any>this._api.getAuth('lists/products/1').pipe(map((res: any) => res.body.entities));
  }

  public getServices(taskTypeId) {
    const queryParams: any = {};
    if (taskTypeId) queryParams.taskTypeId = taskTypeId;
    return this._api.getAuth('lists/services', queryParams).pipe(
      map((x: any) => x.body && x.body.entities)
    );
  }

  public getTaskPriorities() {
    return this._api.getAuth('lists/taskPriorities').pipe(
      map((x: any) => x.body && x.body.entities)
    );
  }

  public getTaskTypes() {
    return this._api.getAuth('lists/taskTypes/request').pipe(map((res: any) => res.body.entities));
  }

  public getRequestTypes() {
    return this._api.getAuth('lists/taskTypes/request').pipe(map((res: any) => res.body.entities));
  }
  public getRequestTypesMarketing() {
    return this._api.getAuth('lists/taskTypes/request').pipe(map((res: any) => res.body.entities.filter(t => t.code == 'VT_5' || t.code == 'VT_3')));
  }

  public getPersons() {
    return this._api.getAuth('lists/persons').pipe( //TODO nr2 mark1 not used method
      map((x: any) => x.body && x.body.entities)
    );
  }

  public getCrmPersons() {
    return this._api.getAuth('lists/crmPersons').pipe(
      map((x: any) => x.body && x.body.entities)
    );
  }

  public getGroups() {
    return this._api.getAuth('lists/groups').pipe(
      map((x: any) => x.body && x.body.entities)
    );
  }
}
