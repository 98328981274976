import { Observable } from "rxjs";
import { InjectionToken } from "@angular/core";

export interface QueryPart<T> {
  waitFirst: boolean;
  autoEmit: boolean;
  value: Observable<T>;
  queryPart: string;
  emit(val: T);
  mapValue: (val: any) => T
}

export interface QueryProvider {
  flatMap: boolean;
  active: boolean;
  registerQueryPart<QT>(qp: QueryPart<QT>);
}

export const QUERY_PART = new InjectionToken<QueryPart<any>>('QUERY_PART');
export const QUERY_PROVIDER = new InjectionToken<QueryProvider>('QUERY_PROVIDER');