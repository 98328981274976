import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CalendarCustomHeaderComponent } from '../../../components/filters/date-interval-alt/calendarCustomHeader.component';

@Component({
  selector: 'employees-form',
  templateUrl: './employees-form.component.html',
  styleUrls: ['./employees-form.component.scss']
})
export class EmployeesFormComponent implements OnInit {

  newEmployessForm: UntypedFormGroup;
  protected isInserting = false;
  calendarCustomHeaderComponent: any = CalendarCustomHeaderComponent;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.buildForm();
  }

  ngOnInit() {
  }

  dateChanged(el) {
    el.close && el.close();
  }

  insertEmployess(f) {
    this.isInserting = true;
  }

  buildForm() {
    this.newEmployessForm = this.formBuilder.group({
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      birthDate: [null, Validators.required],
      email: ['', [Validators.required, Validators.email]],
      town: ['', Validators.required],
      address: ['', Validators.required],
      mobilePhone: ['', Validators.required],
      shortMobilePhone: ['', Validators.required],
      internalPhone: ['', Validators.required],
      shortInternalPhone: ['', Validators.required],
      duties: ['', Validators.required]
    });
  }

  cancel() { }

}
