import { AsDate } from "../../core/decorators/type-checking";
import { JobsListEntry } from "./jobs-list-entry";
import { TaskCommented, TaskPriorityChanged, TaskStateChanged, TaskTaken, TaskProductChanged } from "../../backend/tasks.backend";
import {
  TASK_STATE_NEW,
  TASK_STATE_CANCELED,
  TASK_STATE_POSTPONED,
  TASK_STATE_EXECUTING,
  TASK_STATE_COMPLETED,
  TASK_STATE_FINISHED
} from "../../core/constants";
import { Subject } from "rxjs";
import { isEditableTask } from "../../template/shared/proto/helpers";

export enum TaskPersonType {
  NONE,
  OWNER,
  DELEGATE,
  WATCHER,
  EXECUTOR,
  OWNERANDEXECUTOR
}

export enum TaskCodesAllowedToUpdateDeadline {
  NOTASSIGNED = 'VT_0',
  SERVICE = 'VT_1',
  PROJECT = 'VT_2',
  SALES = 'VT_3',
  INTERNAL = 'VT_4',
  MARKETING = 'VT_5'
}

export class TaskReview {
  id: number;
  code: string;
  title: string;
  stateId: number;
  state: string;
  @AsDate() dateEntered: Date;
  @AsDate() dateStart: Date;
  @AsDate() dateDeadline: Date;
  @AsDate() dateEnd?: Date;
  @AsDate() nearestActionDate?: Date;
  nearestActionText: string;
  owner: string;
  ownerNote: string;
  ownerNoteHtml: string;
  executor: string;
  executorId: number;
  executorNote: string;
  delegateId: number;
  delegate: string;
  delegateEmail: string;
  delegateMobTel: string;
  delegateTel1: string;
  delegateTel2: string;
  delegateTel3: string;
  partnerId: number;
  partner: string;
  serviceId: number;
  service: string;
  productId: number;
  product: string;
  priorityId: number;
  priority: string;
  priorityOrder: number;
  taskTypeId: number;
  taskType: string;
  expectedConsuming?: number;
  lastTransferText: string;
  evaluation: number;
  evaluationComment: number;
  isRequest: boolean;
  reactionTime: number;
  @AsDate() lastStateChange: Date;
  isTree: boolean;
  isEditable: boolean;
  taskTypeCode: string;
  userTypeInTask: TaskPersonType;
  get crmUserIsOwner(): boolean {
    return (this.userTypeInTask == TaskPersonType.OWNER) || (this.userTypeInTask == TaskPersonType.OWNERANDEXECUTOR);
  }

  get allowTaskDeadlineChangeDependingOnTaskTypeCode(): boolean {
    return (this.taskTypeCode == TaskCodesAllowedToUpdateDeadline.MARKETING) || (this.taskTypeCode == TaskCodesAllowedToUpdateDeadline.SALES) || (this.taskTypeCode == TaskCodesAllowedToUpdateDeadline.PROJECT) || (this.taskTypeCode == TaskCodesAllowedToUpdateDeadline.INTERNAL);
  }

  get allowTaskDeadlineChangeDependingOnRequestTypeCode(): boolean {
    return this.taskTypeCode == TaskCodesAllowedToUpdateDeadline.PROJECT || this.taskTypeCode == TaskCodesAllowedToUpdateDeadline.SALES;
  }

  get crmUserIsExecutor(): boolean {
    return (this.userTypeInTask == TaskPersonType.EXECUTOR) || (this.userTypeInTask == TaskPersonType.OWNERANDEXECUTOR);
  }

  get crmUserIsDelegate(): boolean {
    return (this.userTypeInTask == TaskPersonType.DELEGATE);
  }

  files: File[];
  get hasFiles(): boolean {
    return this.files && this.files.length > 0
  }
  comments: TaskComment[];
  transfers: TaskTransfer[];
  stateChanges: TaskStateChange[];
  jobs: JobsListEntry[];
  deadLineChanges: TaskDeadLineChange[];
  serviceChanges: TaskServiceChange[];
  // public taskTimers: any[];
  nearestActions: NearestAction[];
  typeChanges: TaskTypeChange[];
  stageHistory: any;

  private _taskEvents: TaskEvent[];
  get taskEvents(): TaskEvent[] {
    if (!this._taskEvents) {
      const comments = this.comments || [];
      const transfers = this.transfers && this.transfers.map(el => { return { ...el, textHtml: el.text, private: true } }) || [];
      const deadLineChanges = this.deadLineChanges && this.deadLineChanges.map(el => { return { ...el, textHtml: el.text, private: true, isDeadLineChange: true } }) || [];
      const serviceChanges = this.serviceChanges && this.serviceChanges.map(el => { return { ...el, textHtml: el.text, private: true, isServiceChange: true } }) || [];
      const stateChanges = this.stateChanges && this.stateChanges.map(el => { return { ...el, textHtml: el.text, private: true } }) || [];
      const typeChanges = this.typeChanges && this.typeChanges.map(el => { return { ...el, textHtml: el.text, private: true } }) || [];
      const stagesHistory = this.stageHistory && this.stageHistory.map((el) => { return { ...el, textHtml: el.text, isStageHistory: true, private: true } }) || [];
      const all = [...comments, ...transfers, ...stateChanges, ...typeChanges, ...stagesHistory, ...deadLineChanges, ...serviceChanges];
      this._taskEvents = all.sort((t1, t2) => new Date(t1.date).getTime() < new Date(t2.date).getTime() ? 1 : -1)
    }
    return this._taskEvents;
  }

  updateTabsOrder$: Subject<any> = new Subject();

  addTransfer(transfer: TaskTransfer) {
    const transfers = this.transfers || [];
    this.transfers = [...transfers, transfer];
    this._taskEvents = undefined;
  }

  constructor(data: any) {
    if (data == null) {
      throw new Error('Cannot construct task review without data!');
    }
    if (typeof data != 'object') {
      throw new Error('Trying to construct task review with wrong object: ' + typeof data);
    }
    Object.assign(this, data);
  }

  addComment(comment: TaskComment) {
    if (!this.comments) {
      this.comments = [comment];
    } else {
      this.comments = [...this.comments, comment];
    }
    this._taskEvents = undefined;

  }

  addTyppeChange(typeChange: TaskTypeChange) {
    if (!this.typeChanges) {
      this.typeChanges = [typeChange];
    } else {
      this.typeChanges = [...this.typeChanges, typeChange];
    }
    this._taskEvents = undefined;
  }

  addStateChange(stateChange: TaskStateChange) {
    if (!this.stateChanges) {
      this.stateChanges = [stateChange];
    } else {
      this.stateChanges = [...this.stateChanges, stateChange];
    }
    this._taskEvents = undefined;
  }

  addNearestAction(nearestAction: NearestAction) {
    if (this.nearestActions) {
      this.nearestActions = [nearestAction, ...this.nearestActions].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      this.nearestActionDate = <any>this.nearestActions[0].date;
      this.nearestActionText = this.nearestActions[0].text;
    } else {
      this.nearestActionDate = <any>nearestAction.date;
      this.nearestActionText = nearestAction.text;
      this.nearestActions = [nearestAction];
    }
  }

  taskTaken(taskTaken: TaskTaken) {
    this.executor = taskTaken && taskTaken.executor;
    this.userTypeInTask = taskTaken && taskTaken.userTypeInTask;
    if (taskTaken && taskTaken.transfers) {
      this.addTransfer(taskTaken.transfers);
    }
  }

  taskCommented(taskCommented: TaskCommented) {
    this.addComment(taskCommented.comment);
    this.state = taskCommented.state;
    this.stateId = taskCommented.stateId;
    this.updateTabsOrder$.next(true);
  }

  taskStateChanged(taskStateChanged: TaskStateChanged) {
    this.addStateChange(taskStateChanged.change);
    this.state = taskStateChanged.state;
    this.stateId = taskStateChanged.stateId;
    this.isEditable = isEditableTask(this.stateId);
  }

  taskPriorityChanged(taskPriorityChanged: TaskPriorityChanged) {
    this.priorityId = taskPriorityChanged.priorityId;
    this.priority = taskPriorityChanged.priority;
  }

  taskProductChanged(taskPproductChanged: TaskProductChanged) {
    this.productId = taskPproductChanged.productId;
    this.product = taskPproductChanged.product;
  }

  nearestActionInserted(nearestAction: NearestAction) {
    if (this.nearestActions) {
      this.nearestActions = [nearestAction, ...this.nearestActions];
    } else {
      this.nearestActions = [nearestAction];
    }
  }

  jobUpdated(job: JobsListEntry) {
    if (!this.jobs) {
      this.jobs = [job];
      this.updateTabsOrder$.next(true);
    } else {
      this.jobs = [...this.jobs.map(j => j.id == job.id ? job : j)];
    }
  }

  jobEntered(job: JobsListEntry) {
    if (!this.jobs) {
      this.jobs = [job];
      this.updateTabsOrder$.next(true);
    } else {
      this.jobs = [job, ...this.jobs];
    }
  }
}

export interface NearestAction {
  id: number;
  userId: number;
  date: string;
  created: string;
  text: string;
}

export interface TaskEvent {
  id: number;
  userId: number;
  userName: string;
  date: string;
  text: string;
  isComment: boolean;
  isStateChange: boolean;
  isTransfer: boolean;
  isTypeChange: boolean;
  isDeadLineChange: boolean;
  isServiceChange: boolean;
}

export interface TaskComment extends TaskEvent {
  person: string;
  isClient: boolean;
  files: File[];
  rating: number;
  ratingComment: string;
}

export interface TaskTransfer extends TaskEvent {
  oldExecutor: string;
  newExecutor: string;
  person: string;
}

export interface TaskStateChange extends TaskEvent {
  oldState: string;
  newState: string;
}
export interface TaskDeadLineChange extends TaskEvent {
  oldDeadLine: string;
  newDeadLine: string;
}
export interface TaskServiceChange extends TaskEvent {
  oldService: string;
  newService: string;
}

export interface TaskTypeChange extends TaskEvent {
  oldType: string;
  newType: string;
}
