import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'delegate-table',
  templateUrl: './delegate-table.component.html',
  styleUrls: ['./delegate-table.component.scss']
})
export class DelegateTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
