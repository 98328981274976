import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs'
import { TopNavbarComponent } from "../../layout/top-navbar/top-navbar.component";
import { ConfigService } from "./config/config.service";
import { GlobalState } from "../../app.state";

@Injectable()
export class TemplateService {
  private topNavbar: TopNavbarComponent;
  private _activityLogState: Subject<boolean> = new BehaviorSubject(false);
  public readonly activityLogState: Observable<boolean> = this._activityLogState.asObservable();
  private activityLogStateSubscription: Subscription;


  constructor(private _config: ConfigService, private _state: GlobalState) {
    this._state.subscribe('app.isApp_SidebarRightOpen', (isApp_SidebarRightOpen) => {
      this._activityLogState.next(isApp_SidebarRightOpen);
    });
  }

  public siteLoaded() {

  }

  public registerTopNavBar(topNavbar: TopNavbarComponent) {
    this.topNavbar = topNavbar;
    this.activityLogStateSubscription = this.topNavbar.rightSidebarState.subscribe(this._activityLogState);
  }

  public unregisterTopNavBar() {
    this.topNavbar = null;
    if (this.activityLogStateSubscription && !this.activityLogStateSubscription.closed) {
      this.activityLogStateSubscription.unsubscribe();
      this.activityLogStateSubscription = null;
    }
  }

  public toggleActivityLog() {
    if (this.topNavbar) {
      this.topNavbar.toggleAppRightSidebar();
    }
  }

}


