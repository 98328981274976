
import { merge as observableMerge, Observable, Subject, Subscription } from 'rxjs';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { map, pluck, withLatestFrom, filter, tap, switchMap } from 'rxjs/operators';
import { PartnersBackend } from '../../backend/partners.backend';

@Component({
  selector: 'partner-info',
  templateUrl: './partnerInfo.component.html',
  styleUrls: ['./partnerInfo.component.scss']
})
export class PartnerInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() partnerId;
  protected _partnerInfo: Subject<any> = new Subject();
  protected _partnerDelegates: Subject<any> = new Subject();
  private _subscriptions: Array<Subscription> = [];
  protected isLoading = true;

  constructor(private partnersBackend: PartnersBackend) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.partnerId && changes.partnerId.currentValue > 0 && changes.partnerId.currentValue != changes.partnerId.previousValue) {
      this._loadData(this.partnerId)
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  private _loadData(partnerId: any) {
    this._subscriptions.push(observableMerge(
      this.partnersBackend.getPartnerInfo(partnerId).pipe( //TODO nr3 tested
        map((res: any) => res.entity ? res.entity : null)
        , tap((res) => { this._partnerInfo.next(res) })
      ),
      this.partnersBackend.getAllPartnerDelegates(this.partnerId).pipe( //TODO nr3 tested
        map((res: any) => res ? res : [])
        , tap((res) => this._partnerDelegates.next(res))
      )
    )
      .subscribe(() => this.isLoading = false));
  }
}
