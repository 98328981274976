import { Component, Input, ContentChild, AfterViewInit, ChangeDetectorRef, OnInit, ElementRef, ViewChild, OnDestroy, NgZone, ViewChildren, QueryList } from "@angular/core";
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { TasksViewComponent } from '../tasks-view/tasks-view.component';
import { TasksTabsComponent } from '../tasks-tabs/tasks-tabs.component';
import { DashboardService } from '../dashboard.service';
import { ViolationChartComponent } from '../violation-chart/violation-chart.component';
import { ViolationChartMenuComponent } from '../violation-chart-menu/violation-chart-menu.component';
import { WorkTimeByTypeChartMenuComponent } from '../work-time-by-type-chart-menu/work-time-by-type-chart-menu.component';
import { WorkTimeByTypeChartComponent } from '../work-time-by-type-chart/work-time-by-type-chart.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: "stacked-card",
  templateUrl: "./stacked-cards.component.html",
  styleUrls: ["./stacked-cards.component.scss"]
})
export class StackedCardsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ContentChild(TasksViewComponent) taskViewCmp: TasksViewComponent;
  @ContentChild(TasksTabsComponent) taskTabsCmp: TasksTabsComponent;
  @ContentChild(ViolationChartComponent) violationChartCmp: ViolationChartComponent;
  @ContentChild(ViolationChartMenuComponent) violationChartMenuCmp: ViolationChartMenuComponent;
  @ContentChild(WorkTimeByTypeChartMenuComponent) workTimeByTypeChartMenuCmp: WorkTimeByTypeChartMenuComponent;
  @ContentChild(WorkTimeByTypeChartComponent) workTimeByTypeChartCmp: WorkTimeByTypeChartComponent;

  @ViewChild('header') header: ElementRef

  faMinus = faMinus;
  faPlus = faPlus;
  small = false;

  @Input() title: string = 'title';
  @Input() bodyheight: string = '180px';
  @Input() bodyOverflowAuto = false;
  @Input() todayButton = false;
  @Input() resizeable = false;
  cardheight: string;
  headerheight: string = '40px';
  minimized: boolean = false;
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]);
  _subscriptions: Subscription[] = [];
  observer: any;

  currentHeight: string;
  idSeed: string;

  @ViewChildren('card') private card: QueryList<ElementRef>;

  constructor(private chg: ChangeDetectorRef, private dashboardService: DashboardService,
    // private host: ElementRef, 
    private zone: NgZone,
    private breakpointObserver: BreakpointObserver) {

  }

  ngOnInit() {
    this.idSeed = 'id' + Math.floor(Math.random() * 1000);
    this._subscriptions.push(this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        this.small = true;
        if (this.header) {
          this.headerheight = '' + this.header.nativeElement.offsetHeight + 'px';
        }
        this.bodyheight = '280px';
        this.cardheight = 'calc(' + this.bodyheight + ' + ' + this.headerheight + ')';
      } else {
        this.small = false;
        this.bodyheight = '180px';
        this.headerheight = '40px';
        this.cardheight = 'calc(' + this.bodyheight + ' + ' + this.headerheight + ')';
      }
    }));
    this.cardheight = 'calc(' + this.bodyheight + ' + ' + this.headerheight + ')';

    // if (this.header) {
    //   this.calcHeights();
    // }
  }

  calcHeights() {
    // if (this.small) {
    this.headerheight = '' + this.header.nativeElement.offsetHeight + 'px';
    this.cardheight = 'calc(' + this.bodyheight + ' + ' + this.headerheight + ')';
    // }
  }

  minimize() {
    if (!this.resizeable) {
      this.minimized = !this.minimized;
    }
  }

  ngAfterViewInit() {

    if (this.card && this.resizeable) {
      this.observer = new ResizeObserver(entries => {
        this.zone.run(() => {
          this.currentHeight = entries[0].contentRect.height + 'px';
          this.bodyheight = this.currentHeight;
          this.calcHeights();
        });
      });
      this.observer.observe(this.card.first.nativeElement);
    }

    if (this.header) {
      setTimeout(() => {
        this.calcHeights();
      }, 0);
    }
    if (this.taskViewCmp) {
      this._subscriptions.push(this.taskTabsCmp.tabSelected.subscribe((i) => {
        this.tasksTab(i);
      }));
    }

    // wait to get users
    if (this.violationChartMenuCmp) {
      if (this.violationChartMenuCmp.menuUsersReady) {
        this.dashboardService.violationChartGetInitData$.next(true);
      } else {
        this._subscriptions.push(this.violationChartMenuCmp.menuUsersReady$.subscribe((event) => {
          this.dashboardService.violationChartGetInitData$.next(true);
        }));
      }
    }

    if (this.violationChartCmp) {
      this._subscriptions.push(this.dashboardService.userSelectedViolationCharts$.subscribe((event) => {
        this.calcHeights();
      }));
      if (this.violationChartCmp.dataReady) {
        if (this.violationChartMenuCmp) {
          this.violationChartMenuCmp.showMenuItems();
        }
        this.calcHeights();
      }
      this._subscriptions.push(this.violationChartCmp.dataReady$.subscribe((event) => {
        if (this.violationChartMenuCmp) {
          this.violationChartMenuCmp.showMenuItems();
        }
        this.calcHeights();
      }));
    }
    if (this.workTimeByTypeChartCmp && this.workTimeByTypeChartMenuCmp) {
      this._subscriptions.push(this.workTimeByTypeChartMenuCmp.userSelected.subscribe((event) => {
        this.userSelectedWorkTimeByTypeChart(event);
        this.calcHeights();
      }));
      if (this.workTimeByTypeChartCmp.dataReady) {
        this.workTimeByTypeChartMenuCmp.showMenuItems();
        this.calcHeights();
      }
      this._subscriptions.push(this.workTimeByTypeChartCmp.dataReady$.subscribe((event) => {
        this.workTimeByTypeChartMenuCmp.showMenuItems();
        this.calcHeights();
      }));
      // wait to get users
      if (this.workTimeByTypeChartMenuCmp.menuUsersReady) {
        this.workTimeByTypeChartCmp.getCurrentUserData();
      } else {
        this._subscriptions.push(this.workTimeByTypeChartMenuCmp.menuUsersReady$.subscribe((event) => {
          this.workTimeByTypeChartCmp.getCurrentUserData();
        }));
      }
    }
  }

  // tasksDay() {
  //   if (this.taskViewCmp) {
  //     const i = this.dashboardService.currentTaskDay === 1 ? 2 : 1;
  //     this.dashboardService.currentTaskDay = i;
  //     this.taskViewCmp.currentDay = i;
  //     this.taskViewCmp.setCurrentTable();
  //   }
  // }

  tasksTab(i) {
    if (this.taskViewCmp) {
      this.dashboardService.setCurrentTaskTypesTabLocalstorage(i);
      this.taskViewCmp.currentTab = i;
      this.taskViewCmp.setCurrentTable();
    }
  }

  userSelectedWorkTimeByTypeChart(user) {
    if (this.workTimeByTypeChartCmp) {
      this.workTimeByTypeChartCmp.changeUserChart(user);
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subs) => {
      subs.unsubscribe();
    });
    if (this.card && this.resizeable) {
      this.observer.unobserve(this.card.first.nativeElement);
    }
  }
}
