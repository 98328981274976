
import { map } from 'rxjs/operators';
import { EventEmitter, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs'
import swal from 'sweetalert2';
import { DpaBackend } from '../../../../../backend/dpa.backend';
import { SHORT_DATE } from '../../../../../core/time';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import { StorageService } from '../../../../../template/shared/proto/storage/storage.service';
import { ColumnModel } from '../../../../../template/shared/proto/dataTable/Table';

@Component({
  selector: 'dpa-jobs',
  templateUrl: './dpaJobs.component.html',
  styleUrls: ['./dpaJobs.component.scss'],
})
export class DpaJobsComponent implements OnInit, OnDestroy {
  private _dpaJobs: Array<any>;
  private shortDate = SHORT_DATE;
  @Input() set dpaJobs(jobs: Array<any>) {
    this._dpaJobs = jobs ? jobs.map((j) => ({
      ...j,
      inDpa: true,
      date: this.datePipe.transform(j.date, this.shortDate),
      sumNoVat: this.decimalPipe.transform(j.sumNoVat, '1.2-2')
    })) : [];
  }
  get dpaJobs() {
    return this._dpaJobs;
  }
  @Input() dpaId: any;
  private _isUpdating: boolean = false;
  @Input() isEditable: boolean = false;
  @Input() isNonDpa: boolean = false;
  @Output() refreshDPA: EventEmitter<any> = new EventEmitter();

  @ViewChild('checkTpl', { static: true }) checkTpl;
  @ViewChild('toDpaTpl', { static: true }) toDpaTpl;
  @ViewChild('taskTpl', { static: true }) taskTpl;

  // protected _jobs: Subject<JobsListEntry[]> = new BehaviorSubject([]);
  protected _columns;
  _visible = true;
  private _colSettings: Array<any> = [];
  private get dpaColSettingsKey() {
    return this.isNonDpa ? 'nonDPAJobs' : 'DPAJobs';
  }
  private allColumnsOrder: Array<string>;
  protected _sorts = [{ prop: 'date', dir: 'desc' }];

  constructor(
    private _dpaBackend: DpaBackend,
    private _chg: ChangeDetectorRef,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private router: Router,
    private _storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this._columns = !this.isNonDpa ? [
      { name: 'Data', prop: 'date', width: 120, sortable: true },
      { name: 'Laikas', prop: 'time', width: 125, sortable: true },
      //duration and pricedTime fields have to be as stated below ('pricedTime': shows durationView from job form, and 'duration' shows 'Kliento laikas')
      { name: 'Trukmė', prop: 'duration', width: 120 },
      { name: 'Kliento laikas', prop: 'pricedTime', width: 130 },
      { name: 'Darbo ID', prop: 'jobId', width: 130 },
      { name: 'Užduoties ID', prop: 'taskId', width: 125, cellTemplate: this.taskTpl },
      { name: 'Vykdytojas', prop: 'executor', width: 230, sortable: true },
      { name: 'Produktas', prop: 'productName', width: 120, sortable: true },
      { name: 'Paslauga', prop: 'serviceName', width: 200, sortable: true },
      { name: 'Pastaba', prop: 'text', width: 400, sortable: true },
      { name: 'Suma', prop: 'sumNoVat', width: 100, sortable: true },
      { name: 'Priklauso DPA', prop: 'inDpa', width: 125, cellTemplate: this.checkTpl }
    ] : [
      { name: 'Data', prop: 'date', width: 120, sortable: true },
      { name: 'Laikas', prop: 'time', width: 125, sortable: true },
      { name: 'Trukmė', prop: 'duration', width: 120 },
      { name: 'Kliento laikas', prop: 'pricedTime', width: 130 },
      { name: 'Darbo ID', prop: 'jobId', width: 130 },
      { name: 'Užduoties ID', prop: 'taskId', width: 125, cellTemplate: this.taskTpl },
      { name: 'Vykdytojas', prop: 'executor', width: 230, sortable: true },
      { name: 'Produktas', prop: 'productName', width: 120, sortable: true },
      { name: 'Paslauga', prop: 'serviceName', width: 200, sortable: true },
      { name: 'Pastaba', prop: 'text', width: 400, sortable: true },
      { name: 'Suma', prop: 'sumNoVat', width: 100, sortable: true },
      { name: 'Perkelti', prop: 'toDpa', width: 125, cellTemplate: this.toDpaTpl }
    ]
    this.collectColumnsSettings();
    this.applyColumnWidths();
  }

  ngOnDestroy(): void {

  }

  collectColumnsSettings() {
    let cols: Array<ColumnModel> = [];
    const allColumns = [];
    for (let i = 0; i < this._columns.length; i++) {
      allColumns.push(this._columns[i].name);
    }
    let orderWithInvisibles = <Array<string>>JSON.parse(localStorage.getItem(this.dpaColSettingsKey + '_COL_ORDER') || JSON.stringify(allColumns));
    this.allColumnsOrder = [...orderWithInvisibles];
    for (let i = 0; i < this._columns.length; i++) {
      const col = this._columns[i];
      if (col.visible == false) {
        orderWithInvisibles = orderWithInvisibles.filter(item => item !== col.name);
      }
    }
    const order = orderWithInvisibles;
    for (let i = 0; i < this._columns.length; i++) {
      const col = this._columns[i];
      if (col.sorted) {
        this._sorts = [{
          prop: col.prop,
          dir: col.sorted.order
        }];
      }
      const oi = order.indexOf(col.name);
      if (oi > -1 && this._columns.length > oi) {
        cols[oi] = col;
      } else {
        cols.push(col);
      }

    }
    if (!this._sorts) {
      const sortables = this._columns.filter(c => c.sortable !== false && c.visible !== false);
      this._sorts = [{
        prop: sortables && sortables[0] ? sortables[0].prop : null,
        dir: 'desc'
      }];
      this.setSort(this._sorts[0].prop, this._sorts[0].dir);
      if (this._sorts && this._sorts[0] && this._sorts[0].prop) {
        this._storageService.store(this.dpaColSettingsKey + '.sort', {
          prop: this._sorts[0].prop,
          order: this._sorts[0].dir
        });
      }
    }
    cols = cols.filter(c => c != undefined);
    this._columns = cols;
  }

  public setSort(prop, order) {
    if (this._columns && prop) {
      for (let i = 0; i < this._columns.length; i++) {
        delete this._columns[i].sorted;
      }
      for (let i = 0; i < this._columns.length; i++) {
        if (this._columns[i].prop === prop) {
          this._columns[i].sorted = { order: order };
        }
      }
    }
  }

  applyColumnWidths() {
    const colSettings = this._storageService.get(this.dpaColSettingsKey + '.colSettings') || [];
    let sortSettings = this._storageService.get(this.dpaColSettingsKey + '.sort');
    for (let i = 0; i < this._columns.length; i++) {
      let cs = colSettings.find((s) => s.name == this._columns[i].name);
      if (cs) {
        this._columns[i].visible = cs.visible != undefined ? cs.visible : true;
        this._columns[i].width = cs.width || this._columns[i].width || 200;
        this._columns[i].index = cs.index || i;
      } else {
        this._columns[i].width = this._columns[i].width || 200;
        this._columns[i].index = i;
      }
    }
    this._colSettings = colSettings;
    if (sortSettings && sortSettings.prop) {
      let _c = this._columns.find((c) => c.prop == sortSettings.prop);
      if (_c) {
        let _dc = this._columns.find((c) => !!c.sorted);
        if (_dc) {
          delete _dc['sorted'];
        }
        _c.sorted = { order: sortSettings.order };
      }
      this._sorts = [{ prop: sortSettings.prop, dir: sortSettings.order }];
    }
  }

  private _checked(checked: boolean, id: any) {
    if (!this._isUpdating) {
      let msg = checked ? (!this.isNonDpa ? 'Priskirti darbą šiam dpa?' : 'Perkelti darbą į šį DPA?') : 'Atkabinti darbą nuo šio DPA?';
      let params = checked ? { jobId: id, dpaId: this.dpaId, deleteFromDpa: this.isNonDpa ? 0 : 1 } : { jobId: id, dpaId: this.dpaId, deleteFromDpa: 1 };
      swal.fire({ title: msg, showConfirmButton: true, showCancelButton: true })
        .then((r) => {
          if (r.value) {
            this._isUpdating = true;
            this._updateDpa(params).subscribe((res) => {
              this._isUpdating = false;
              this._updateCheckBox(checked, id);
              this.refreshDPA.emit(this.dpaJobs.length);
            })
          }
        });
    }
  }

  _columnsReordered($event) {

    const oldId = $event.prevValue;
    const newId = $event.newValue;
    const vc = this._columns;
    const oldCol = vc[oldId].name;
    const newCol = vc[newId].name;
    let allColumns = [];
    for (let i = 0; i < this.allColumnsOrder.length; i++) {
      allColumns.push(this.allColumnsOrder[i]);
    }
    const oldColInd = allColumns.indexOf(oldCol);
    const newColInd = allColumns.indexOf(newCol);

    allColumns.splice(oldColInd, 1);
    allColumns = [...allColumns.slice(0, newColInd), oldCol, ...allColumns.slice(newColInd)];

    const rstr = JSON.stringify(allColumns);
    this.allColumnsOrder = [...allColumns];
    localStorage.setItem(this.dpaColSettingsKey + '_COL_ORDER', rstr);

    let temp = this._columns[oldId];
    this._columns.splice(oldId, 1);
    this._columns = [...this._columns.slice(0, newId), temp, ...this._columns.slice(newId)];

  }

  _columnResized(event) {
    const e = {
      name: event.column?.name,
      newWidth: event.newValue
    };

    let cs = this._colSettings.find((cs) => cs.name == e.name);
    if (!cs) {
      this._colSettings.push({
        name: e.name,
        width: e.newWidth
      });
    } else {
      cs.width = e.newWidth;
    }
    this._storageService.store(this.dpaColSettingsKey + '.colSettings', this._colSettings);
  }

  private _updateCheckBox(checked: boolean, jobId: any) {
    if (this._dpaJobs && this.dpaJobs.length > 0) {
      let job = this._dpaJobs.find((j) => j.jobId == jobId);
      if (job) {
        job.inDpa = checked;
        try {
          this._chg.detectChanges();
        } catch (e) { }
      }
    }
  }

  _onSort($event) {
    this._sorts = $event.sorts;
    this._storageService.store(this.dpaColSettingsKey + '.sort', {
      prop: this._sorts[0].prop,
      order: this._sorts[0].dir
    });
  }

  private _updateDpa(params): Observable<any> {
    return this._dpaBackend.updateDpaJob(params.dpaId, params).pipe(map((res: any) => res && res.entity > 0 ? res.entity : -1)) //TODO nr4
  }

  clicked(jobId) {
    this.router.navigate(['/taskboard/j/v/' + jobId]);
  }
}
