import { Injectable } from '@angular/core';
import { UserBackend } from '../backend/user.backend';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class DashboardService {

  currentTaskTypesTab = 1;
  currentTaskDay = 1;

  public workTimeByTypeUserId: any;
  workTimeByTypeUserKey = 'workTimeByTypeUser';
  violationChartUsersKey = 'violationChartUsers';
  taskTypeTabKey = 'taskTypeTab';
  public violationChartUsersIdsList = [];
  public violationChartUsersList = [];

  public makeCallToGroupsUsers$;

  public violationChartGetInitData$ = new Subject();

  public ratingSelect$ = new BehaviorSubject(3);
  public monthSelect$ = new BehaviorSubject(new Date(Date.now()));
  public monthSelectRating$ = new BehaviorSubject(new Date(Date.now()));

  public usersByGroup$ = new ReplaySubject(1);
  public userSelectedViolationCharts$ = new Subject();

  public disableTasksTabs = true;

  constructor(private userBackend: UserBackend, private permissionsService: NgxPermissionsService, public authService: AuthService) {
    this.getWorkTimeByTypeUserFromLocalstorage();
    this.getViolationChartUsersLocalstorage();
    this.getCurrentTaskTypesTabLocalstorage();
    if (this.authService.justLoggedIn) {
      this.setWorkTimeByTypeUserLocalstorage(null);
    }
    this.authService.loggedIn$.subscribe(() => {
      this.resetMakeCallToGroupUsers();
      this.setWorkTimeByTypeUserLocalstorage(null);
    });
  }



  getUsersByGroup(isDirector: boolean) {
    if (!this.makeCallToGroupsUsers$) {
      this.makeCallToGroupsUsers$ = new Subject();
      const subs2 =
        this.makeCallToGroupsUsers$.subscribe(() => {
          const subs1 =
            this.userBackend.getGoupsUsers(isDirector).subscribe((users) => {
              this.usersByGroup$.next(users);
              subs1.unsubscribe();
            });
          subs2.unsubscribe();
        });
      this.makeCallToGroupsUsers$.next(true);
    }
  }

  resetMakeCallToGroupUsers() {
    this.makeCallToGroupsUsers$ = null;
    this.usersByGroup$.next([]);
  }

  getWorkTimeByTypeUserFromLocalstorage() {
    try {

      if (localStorage.getItem(this.workTimeByTypeUserKey)) {
        const user = JSON.parse(localStorage.getItem(this.workTimeByTypeUserKey));
        if (user.currentAuthUserId === this.authService.user.usersId) {
          this.workTimeByTypeUserId = user.currentUserId;
        } else {
          this.workTimeByTypeUserId = this.authService.user.usersId;
          localStorage.removeItem(this.workTimeByTypeUserKey);
        }
      } else {
        this.workTimeByTypeUserId = this.authService.user.usersId;
      }
    } catch (err) {
      console.log(err);
    }
  }

  setWorkTimeByTypeUserLocalstorage(currentUserId) {
    this.workTimeByTypeUserId = currentUserId;
    localStorage.setItem(this.workTimeByTypeUserKey, JSON.stringify({ currentAuthUserId: this.authService.user.usersId, currentUserId: currentUserId }));
  }

  setViolationChartUsersLocalstorage(currentUserId, isAdded) {
    try {
      if (localStorage.getItem(this.violationChartUsersKey)) {
        const usersList = JSON.parse(localStorage.getItem(this.violationChartUsersKey)).currentUsersIdsList;
        if (isAdded) {
          if (usersList.indexOf(currentUserId) === -1) {
            usersList.push(currentUserId);
          }
        } else {
          if (usersList.indexOf(currentUserId) > -1) {
            usersList.splice(usersList.indexOf(currentUserId), 1);
          }
        }
        this.violationChartUsersIdsList = usersList;
        localStorage.setItem(this.violationChartUsersKey, JSON.stringify({ currentAuthUserId: this.authService.user.usersId, currentUsersIdsList: usersList }));
      } else {
        if (isAdded) {
          localStorage.setItem(this.violationChartUsersKey, JSON.stringify({ currentAuthUserId: this.authService.user.usersId, currentUsersIdsList: [currentUserId] }));
          this.violationChartUsersIdsList = [currentUserId];
        } else {
          localStorage.setItem(this.violationChartUsersKey, JSON.stringify({ currentAuthUserId: this.authService.user.usersId, currentUsersIdsList: [] }));
          this.violationChartUsersIdsList = [];
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  getViolationChartUsersLocalstorage() {
    try {
      if (localStorage.getItem(this.violationChartUsersKey)) {
        const user = JSON.parse(localStorage.getItem(this.violationChartUsersKey));
        if (user.currentAuthUserId === this.authService.user.usersId) {
          this.violationChartUsersIdsList = user.currentUsersIdsList;
        } else {
          this.violationChartUsersIdsList = [];
          localStorage.removeItem(this.violationChartUsersKey);
        }
      } else {
        this.violationChartUsersIdsList = [];
      }
    } catch (err) {
      console.log(err);
    }
  }

  getCurrentTaskTypesTabLocalstorage() {
    try {
      if (localStorage.getItem(this.taskTypeTabKey)) {
        const taskTypeTab = JSON.parse(localStorage.getItem(this.taskTypeTabKey));
        if (taskTypeTab.currentAuthUserId === this.authService.user.usersId) {
          this.currentTaskTypesTab = taskTypeTab.currentTaskTypeTab;
        } else {
          this.currentTaskTypesTab = 1;
          localStorage.removeItem(this.taskTypeTabKey);
        }
      } else {
        this.currentTaskTypesTab = 1;
      }
    } catch (err) {
      console.log(err);
    }
  }

  setCurrentTaskTypesTabLocalstorage(currentTab) {
    this.currentTaskTypesTab = currentTab;
    localStorage.setItem(this.taskTypeTabKey, JSON.stringify({ currentAuthUserId: this.authService.user.usersId, currentTaskTypeTab: currentTab }));
  }

}
