import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ImageModalComponent } from '../../../../../template/shared/components/modals/image-modal/image-modal.component';
import { MatDialog } from "@angular/material/dialog";
import { BsDropdownState } from "ngx-bootstrap/dropdown";
import { isImage } from "../../../../../functions/utils/files";

@Component({
  selector: "button-dropdown",
  templateUrl: "./button-dropdown.component.html",
  styleUrls: ["./button-dropdown.component.scss"],
})
export class ButtonDropdownComponent<T> implements OnInit, AfterViewInit, OnChanges {

  private _options: Array<T>;
  private _titleLoader: any;
  @Input() options: Array<T>;
  @Input() map: (t: T) => string;
  @Input() title: string;
  @Input() filesType: (t: T) => string;
  @Input() itemClass: string = '';
  @Input() limitDropdownHeight: string = null;
  @Input() showEyeIcon: boolean = false;
  @Input() titleLoader: (t: Array<T>) => string;
  @Output() itemClick: EventEmitter<T> = new EventEmitter();
  @ViewChild(BsDropdownState) dropDownMenu: BsDropdownState;
  @Input() containerBody: any;
  @Input() menuClass: any;

  private image;
  constructor(private chg: ChangeDetectorRef,
    public dialog: MatDialog,) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.titleLoader) {
      this.title = this.titleLoader(this.options);
    } else if (this.map && this.options && this.options.length > 0) {
      this.title = this.map(this.options[0]);
    }
  }

  _itemClicked(item) {
    if (this.map && !this.titleLoader) {
      this.title = this.map(item);
    }
    this.itemClick.next(item);
  }

  _isImage(opt) {
    return opt && (opt.name || opt.fileName) ? isImage(opt) : false;
  }

  showImage(opt) {
    this.dialog.open(ImageModalComponent, { data: opt });
  }

  select(predicate) {
    if (this.options && typeof this.map == 'function') {
      for (const option of this.options) {
        if (predicate(option)) {
          this.title = this.map(option);
          break;
        }
      }
    }
  }

  getDropdownStyles() {
    if (this.limitDropdownHeight) {
      return { 'overflow': 'auto', 'max-height': this.limitDropdownHeight };
    } else {
      return null;
    }

  }

}
