import { RequestReviewComponent } from '../taskboard/components/requests/request-review/request-review.component';
import { ALLPROJECTS, ID, MODE, MYPROJECTS, PROJECTS, REQUESTS, REVIEW, TASKS, TYPE } from '../taskboard/taskboard.help';
import { formPath, param } from '../core/routing/helpers';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { ProjectsComponent } from './projects.component';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { TaskReviewComponent } from '../taskboard/components/tasks/task-review/task-review.component';

export const redirectToPath = () => formPath(MYPROJECTS);

export const PROJECTS_ROUTES: Routes = [
  {
    path: '',
    component: ProjectsComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: redirectToPath(),
      },
      {
        path: formPath(param(TYPE)),
        component: ProjectsListComponent,
        resolve: {
          tableType: 'tableTypeProvider'
        }
      },
      {
        path: formPath(REQUESTS, REVIEW, param(ID)),
        component: RequestReviewComponent,
      },
      {
        path: formPath(TASKS, REVIEW, param(ID)),
        component: TaskReviewComponent,
      },
    ]
  }
];

export const tableTypeProvider = {
  provide: 'tableTypeProvider',
  useValue: (route: ActivatedRouteSnapshot) => {

    const m = { ...route.params, ...route.queryParams };
    if (!m || !m.type) {
      throw new Error('Empty model options not allowed');
    }
    switch (m.type) {
      case MYPROJECTS: return 'myProjects';
        break;
      case ALLPROJECTS: return 'allProjects';
        break;
    }
    return '';
  }
};
