import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TplService {
  public descriptionTpl: TemplateRef<any>;
  public textTpl: TemplateRef<any>;
  public dpaCodeTpl: TemplateRef<any>;
  public isPaidTpl: TemplateRef<any>;
  public stateTpl: TemplateRef<any>;
  public priorityTpl: TemplateRef<any>;
  public titleTpl: TemplateRef<any>;
  public jobButtonsTpl: TemplateRef<any>;
  public reactionTpl: TemplateRef<any>;
  public lastStateChangeTpl: TemplateRef<any>;
  public delegateEmailTpl: TemplateRef<any>;
  public nearestActionTpl: TemplateRef<any>;
  public isDpaFinishedTpl: TemplateRef<any>;
  public dpaOptionsTpl: TemplateRef<any>;
  public deadlineTpl: TemplateRef<any>;
  public stateForUserTpl: TemplateRef<any>;
  public taskCodeTpl: TemplateRef<any>;
  public dateTpl: TemplateRef<any>;
  public endDateTpl: TemplateRef<any>;
  public projectEndDateTpl: TemplateRef<any>;
  public startDateTpl: TemplateRef<any>;
  public shortDateTpl: TemplateRef<any>;
  public dateEnteredTpl: TemplateRef<any>;
  public dateEnteredShortTpl: TemplateRef<any>;
  public lastStateChangeCellTpl: TemplateRef<any>;
  public projectsLinkTpl: TemplateRef<any>;
  public sumWithoutVATDpaListTpl: TemplateRef<any>;
  public sumWithoutVATProjectsListTpl: TemplateRef<any>;
  public isFavoriteTpl: TemplateRef<any>;
  public isFavoriteCampaignsTpl: TemplateRef<any>;
  public DPAMailStateTpl: TemplateRef<any>;
  public undoSeenTpl: TemplateRef<any>;
  public executorTpl: TemplateRef<any>;
  public executorEditableTpl: TemplateRef<any>;
  public gotoCampaignsEditTpl: TemplateRef<any>;
  public participansButtonsTpl: TemplateRef<any>;
  public taskTypeTpl: TemplateRef<any>;
  public partnerTpl: TemplateRef<any>;
  public isRequestTpl: TemplateRef<any>;
  public ownersTextTpl: TemplateRef<any>;
  public dateStartTpl: TemplateRef<any>;
  public jobsDescriptionAltTpl: TemplateRef<any>;
  public actualConsumptionTpl: TemplateRef<any>;
  public isFavoriteHeaderTpl: TemplateRef<any>;
  public isProjectHeaderTpl: TemplateRef<any>;
  public undoSeenHeaderTpl: TemplateRef<any>;
  public stateForUserHeaderTpl: TemplateRef<any>;
  constructor() { }
}
