
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../template/shared/proto/services/routing.service';
import { Subscription } from 'rxjs';
import { MARKETING_CAMPAIGNS, ALL_MARKETING_TASKS } from '../../taskboard/taskboard.help';
import { SelectedPartnerService } from '../../services/partner/selectedPartner.service';
import { StorageOptions } from '../../template/shared/proto/storage/StorageOptions';
import { StorageService } from '../../template/shared/proto/storage/storage.service';

const MARKETING_MENU: any = [
  {
    title: 'MARKETINGAS',
    icon: 'zmdi zmdi-store',
    entries: [
      {
        title: 'Kampanijos',
        isActive: false,
        routerLink: MARKETING_CAMPAIGNS,
      },
      {
        title: 'Visos užduotys',
        isActive: false,
        routerLink: ALL_MARKETING_TASKS,
      },
    ]
  },
];

@Component({
  selector: 'marketing-menu',
  templateUrl: './marketing-menu.component.html',
  styleUrls: ['./marketing-menu.component.scss']
})
export class MarketingMenuComponent implements OnInit, OnDestroy {

  showMenu: boolean = false;
  prevMenu: any;
  currentMenu: any;
  private _subscriptions: Array<Subscription> = [];
  private _pageSavingOptions: StorageOptions = {
    popState: true,
    refresh: true
  };

  constructor(
    private selectedPartnerService: SelectedPartnerService,
    private _storageService: StorageService,
  ) {
  }

  ngOnInit() {
    this.selectedPartnerService.resetPartner();
    this.selectedPartnerService.resetUser();
    this.currentMenu = MARKETING_MENU.map((el) => { return { ...el, isActive: false } });
    this.showMenu = true;
  }

  resetPageSessionStorage() {
    this._storageService.store('currentPage', 0, this._pageSavingOptions);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sp) => sp.unsubscribe());
  }
}
