
import { Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { TaskReview } from '../../../models/task-review';
import { TplService } from '../../../services/tpl.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'sales-relations-list',
  templateUrl: './sales-relations-list.component.html',
  styleUrls: ['./sales-relations-list.component.scss']
})
export class SalesRelationsListComponent implements OnInit, OnDestroy {

  @Input() task: TaskReview;
  @Input() salesRelationsList: any;
  @ViewChild('datatable') datatable: DatatableComponent;
  columns;
  subscriptions = new Array<Subscription>();
  chg: ChangeDetectorRef;

  constructor(
    private _chg: ChangeDetectorRef,
    private tplService: TplService,
    private router: Router,
    private _route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.chg = this._chg;
    if (!this.task) {
      console.error('Task not provided!');
    } else {
    }
    this.columns = [
      { name: 'Vykdymo data', width: 140, prop: 'dateStart', sortable: true, cellTemplate: this.tplService.dateStartTpl },
      { name: 'Kodas', width: 130, prop: 'code', sortable: true },
      { name: 'Partneris', width: 190, prop: 'partner', sortable: true },
      { name: 'Aprašymas', width: 190, prop: 'ownerNote', sortable: true },
      { name: 'Vykdytojas', width: 190, prop: 'executor', sortable: true },
      { name: 'Terminas', width: 130, prop: 'deadline', sortable: true, cellTemplate: this.tplService.deadlineTpl },
      { name: 'Veiklos tipas', width: 190, prop: 'taskType', sortable: true },
      { name: 'Būsena', width: 100, prop: 'state', sortable: true, headerClass: 'center', cellTemplate: this.tplService.stateTpl },

    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refreshTable();
  }

  public refreshTable() {
    if (this.salesRelationsList) {
      this.salesRelationsList = [...this.salesRelationsList];
      try {
        this._chg.detectChanges();
      } catch (err) { }
    }
  }

  rowClicked(event) {
    if (event.isRequest) {
      this.router.navigate(['../../../r/v/', event.id], { relativeTo: this._route });
    } else {
      this.router.navigate(['../../../t/v/', event.id], { relativeTo: this._route });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
