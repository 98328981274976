import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Sanitizer,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CommentTask, TaskCommented, TasksBackend } from '../../../backend/tasks.backend';
import { ReplaySubject, Subject, Subscription, defer } from 'rxjs';
import { error, Loadable, loaded, loading } from '../../../template/shared/proto/common/loadable/loadable';
import { downloadFile } from '../../../functions/utils/files';
import { StateService } from "../../../services/state/stateService";
import { JOB_ENTERED } from "../../../state/state";
import { TaskReview } from "../../models/task-review";
import { EntityCreated } from "../../../core/api/response/entity-created";
import { UploadFileComponent } from "../../../template/shared/components/upload-file/upload-file.component";
import swal from 'sweetalert2';
import { MatSelect } from '@angular/material/select';
import { MatCheckbox } from '@angular/material/checkbox';
import { JobsBackend } from "./../../../backend/jobs/jobs.backend";
import { AUTO_JOBS_SERVICE_ID } from './../../../app.constants';
import { parseTime, setTimeToDate, subtractTime, timeToString } from './../../../core/time/helpers';
import { dateToString, removeLastNewLineIfNeeded } from './../../../taskboard/common/functions';
import { TASK_STATE_COMPLETED, TASK_STATE_FINISHED } from '../../../core/constants';
import anchorme from 'anchorme';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})

export class CommentsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() task: TaskReview;
  @Input() commentingEnabled = true;
  @Input() placeholder = 'Įveskite komentarą';
  @Input() loadComments = true;
  @Output() addCommentJobClicked: EventEmitter<any> = new EventEmitter();
  @Output() addJobWithCommentClicked: EventEmitter<CommentTask> = new EventEmitter();
  @Output() commented: EventEmitter<TaskCommented> = new EventEmitter();
  @Output() refreshTask: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(UploadFileComponent) filesUploader: UploadFileComponent;
  @ViewChild('changeState') changeState: MatCheckbox;
  @ViewChild('commentInputPresetDropdown') commentInputPresetDropdown: MatSelect;

  _data: Subject<Loadable<Array<any>>> = new ReplaySubject(1);

  _commentSubmitting: boolean;
  _commentSubmitted: boolean;
  _jobCommentSubmitting: boolean;
  _jobCommentSubmitted: boolean;
  text = '';
  isInternalComment = false;
  commentInputPreset: any;

  commentTemplates: any[] = [];

  TASK_STATE_COMPLETED = TASK_STATE_COMPLETED;
  TASK_STATE_FINISHED = TASK_STATE_FINISHED;

  _downloadFile = downloadFile;

  private _subscriptions = new Array<Subscription>();
  commentWithLinks: string;
  plainTextComment: string;

  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        // ['code-block'],
        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ font: ['Sans', 'monospace'] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        // ['link'],
        //['link', 'image', 'video']  
      ],
    },
    // autoLinks: true,
  }

  constructor(private tasksBackend: TasksBackend,
    public chg: ChangeDetectorRef,
    private _stateService: StateService,
    private jobsBackend: JobsBackend,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.formCommentTemplates();
    this.commentInputPreset = this.commentTemplates[0];
    this.insertTemplate(this.commentInputPreset);
  }

  onContentChanged(event) {
    // console.log(event);
    // below commented code has problems with new lines
    // console.log(event?.html?.replace(/<[^>]*>/g, ''))
    this.plainTextComment = event?.text || null;
    // console.log(this.removeLastNewLineIfNeeded(this.plainTextComment))
    this.commentWithLinks = event && event.html ? anchorme(event.html) : null;
    // this.commentWithLinks = event && event.html ? event.html : null;
  }

  formCommentTemplates() {
    this.commentTemplates = [
      {
        autoCreateJob: 0,
        title: 'Įveskite komentarą',
        text: ``,
      },
      {
        autoCreateJob: false,
        title: 'Gauta skubi užklausa',
        text: `<p>Laba diena,</p> 
<p>Jūsų užklausa yra užregistruota. Galimybę paslaugas suteikti skubos tvarka (per 4 d. val.) patvirtinsime atskiru pranešimu.</p> `,
      },
      {
        autoCreateJob: true,
        title: 'Tvirtinti skubią užklausą',
        text: `<p>Laba diena,</p> 
<p>Jūsų Skubi užklausa patvirtinta. Konsultantas su Jumis netrukus susisieks. Primename: bus taikomas papildomas vienkartinis skubos mokestis pagal turimą priežiūros sutartį.</p> `,
      },
      {
        autoCreateJob: false,
        title: 'Atmesti skubią užklausą',
        text: `<p>Laba diena,</p> 
<p>Apgailestaujame, tačiau dėl resursų apkrovimo šiuo metu nėra laisvo konsultanto galinčio skubos tvarka išnagrinėti Jūsų užklausą. Jūsų užklausa lieka užregistruota, ji bus peržiūrėta ir išnagrinėta įprasta, priežiūros sutartyje numatyta tvarka.</p>`,
      },
      //       {
      //         autoCreateJob: false,
      //         title: 'Klientams su sutartimi',
      //         text: `Sveiki, situacijos išsiaiškinimui reikia jungtis ir atlikti situacijos analizę. Jei paaiškės, jog problemos priežastis neteisingas duomenų įvedimas ar naudojimas, paslauga bus apmokestinta. 65 EUR/VAL.
      // Pastaba: panaudotas laikas iki vienos valandos mokama už pilną valandą, nuo antros valandos laikas apvalinamas pusvalandžio tikslumu, taikant tą patį tarifą.
      // Jeigu tai klaidingas programos veikimas, ar klaida susijusi su garantiniais darbais, t.y. klaidingu programos veikimu, mokėti nereikės.
      // Parašykite, ar sutinkate.`
      //       },
      {
        autoCreateJob: false,
        title: 'Klientams su sutartimi',
        text: `<p>Iš aprašytos situacijos nėra aišku, dėl kokios priežasties tai galėjo atsitikti. Reikalingi analizės darbai, kurių kaina 70 Eur be PVM/ val pagal faktinį laiką. Prašome patvirtinti.</p>
<p>Jei situacija susidarė dėl klaidos, nurodytos nemokamų darbų sąraše, už analizės darbus mokėti nereikės.</p>`
      },
      //       {
      //         autoCreateJob: false,
      //         title: 'Klientams be sutarties',
      //         text: `Sveiki, situacijos išsiaiškinimui reikia jungtis ir atlikti situacijos analizę. Jei paaiškės, jog problemos priežastis neteisingas duomenų įvedimas ar naudojimas, paslauga bus apmokestinta. Kadangi su jumis neturime sudarę priežiūros sutarties, paslaugos įkainis 85 EUR/VAL.
      // Pastaba: panaudotas laikas iki vienos valandos mokama už pilną valandą, nuo antros valandos laikas apvalinamas pusvalandžio tikslumu, taikant tą patį tarifą.
      // Jeigu tai klaidingas programos veikimas, ar klaida susijusi su garantiniais darbais, t.y. klaidingu programos veikimu, mokėti nereikės.
      // Parašykite, ar sutinkate.`
      //       }
      {
        autoCreateJob: false,
        title: 'Klientams be sutarties',
        text: `<p>Iš aprašytos situacijos nėra aišku, dėl kokios priežasties tai galėjo atsitikti. Reikalingi analizės darbai, kurių kaina 85 Eur be PVM/ val pagal faktinį laiką. Prašome patvirtinti.</p>
<p>Jei situacija susidarė dėl klaidos, nurodytos nemokamų darbų sąraše, už analizės darbus mokėti nereikės.</p>`
      }
    ]
  }

  insertTemplate(commentTemplate) {
    this.text = commentTemplate.text;
    if (commentTemplate.autoCreateJob) {
      this.changeState.checked = true;
      this.isInternalComment = false;
    }
  }

  ngAfterViewInit(): void {
    this._subscriptions.push(this._stateService.getStream(JOB_ENTERED).subscribe(
      res => {
        if (res && res.commented) {
          this.clear();
        }
      }
    ));
    this._detectChanges();
    // setTimeout(() => {
    //   this.commentInputPresetDropdown && this.commentInputPresetDropdown.options && this.commentInputPresetDropdown.options.first && this.commentInputPresetDropdown.options.first.select();
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.loadComments && changes['id'] && changes['id'].currentValue != changes['id'].previousValue) {
      return;
    } else if (!this.loadComments) {
      this._data.next(loaded([]));
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  _postCommentDialog(text: string, textHtml: string, changeState: boolean, filesKey: string) {
    if (this.task?.stateId !== TASK_STATE_FINISHED) {
      if (!this.commentInputPreset.autoCreateJob) {
        (<any>document.activeElement).blur();
        swal.fire({
          title: 'Ar norite papildomai sukurti darbą?',
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Ne',
          confirmButtonText: 'Taip',
          // confirmButtonClass: 'btn-primary',
          customClass: {
            confirmButton: 'btn-primary'
          },
          // buttonsStyling: false
        })
          .then((r) => {
            if (r.value) {
              this._addJobWithComment(text, textHtml, changeState, filesKey, null, null);
            } else if (r && <any>r.dismiss == 'cancel') {
              this._postComment(text, textHtml, changeState, filesKey);
            }
          });
      } else {
        const message = this.task.priorityOrder == 70 ? 'Užklausos skubos mokestis' : text;
        const messageHtml = this.task.priorityOrder == 70 ? 'Užklausos skubos mokestis' : textHtml;
        this._addJobWithComment(message, messageHtml, changeState, filesKey, this.task.priorityOrder == 70 ? text : null, this.task.priorityOrder == 70 ? textHtml : null);
      }
    } else {
      this._postComment(text, textHtml, changeState, filesKey);
    }
  }

  _postComment(textPar: string, textHtml: string, changeState: boolean, filesKey: string) {
    const text = removeLastNewLineIfNeeded(textPar);
    const taskId = this.task.id;
    this._commentSubmitting = true;
    try {
      this.chg.detectChanges();
    } catch (err) { }
    //TODO needs + html part
    this.tasksBackend.commentTask({ taskId, text, textHtml, changeState, filesKey, isInternalComment: this.isInternalComment })
      .subscribe((res: any) => {
        this._commentSubmitting = false;
        this._commentSubmitted = true;
        this.clear();
        if (res.success) {
          this.commented.emit(res.entity);
        }
      }, (err) => {
        this._commentSubmitting = false;
        swal.fire({
          title: 'Įvyko klaida įrašant komentarą',
          timer: 3000,
          showConfirmButton: false,
          icon: 'error'
        })
      });
  }

  isInternalCommentCheckboxClick() {
    this.changeState.checked = false;
  }

  changeStateCommentCheckboxClick() {
    this.isInternalComment = false;
  }

  _addJobWithComment(textPar: string, textHtml: string, changeState: boolean, filesKey: string, additionalTextForComment: string, additionalTextForCommentHTML: string) {
    const text = removeLastNewLineIfNeeded(textPar);
    if (this.commentInputPreset.autoCreateJob) {
      const createObj: any = {};
      const textHtmlModified = removeLastNewLineIfNeeded(textHtml);
      const additionalTextForCommentHTMLModified = removeLastNewLineIfNeeded(additionalTextForCommentHTML);
      createObj.comment = { taskId: this.task.id, changeState, filesKey, isInternalComment: this.isInternalComment, text: additionalTextForComment ? additionalTextForComment : text, textHtml: additionalTextForCommentHTML ? additionalTextForCommentHTMLModified : textHtmlModified };
      createObj.sumNoVat = (<any>this.task).priorityHourCost || 0;
      createObj.serviceId = AUTO_JOBS_SERVICE_ID;
      createObj.description = text;
      createObj.taskId = this.task.id;
      createObj.filesKey = filesKey;
      const nowTime = parseTime(new Date());
      const now = new Date();
      createObj.timeFrom = dateToString(new Date(setTimeToDate(now, subtractTime(nowTime, { hours: 0, minutes: 10 }))));
      createObj.timeTo = dateToString(now);
      createObj.updateSumNoVat = true;
      createObj.visibleSum = 1;
      createObj.isPaid = 1;
      createObj.userId = this.authService.user.usersId;
      //TODO needs + html part
      this._subscriptions.push(this.jobsBackend.finishJob(createObj).subscribe((resp) => {
        swal.fire({ title: 'Darbas automatiškai sukurtas', timer: 2000, showConfirmButton: false, icon: 'success' });
        this.clear();
        this.refreshTask.next(true);
      }, (err) => {
        if (err.status === 500 && err.error == 'SumNoVat is 0') {
          swal.fire({ title: 'Darbo nepavyko sukurti, nes nepavyko nustatyti kainininko', timer: 2000, showConfirmButton: false, icon: 'error' });
        } else {
          swal.fire({ title: 'Darbo sukurti nepavyko', timer: 2000, showConfirmButton: false, icon: 'error' });
        }
      }));
    } else {
      this.addJobWithCommentClicked.emit({ taskId: this.task.id, text: text, textHtml, changeState: changeState, filesKey: filesKey, isInternalComment: this.isInternalComment });
    }
  }

  _addCommentJobClicked(comment) {
    // needs to emmit only text comment
    this.addCommentJobClicked.emit(comment);
  }

  private _detectChanges() {
    try {
      this.chg.detectChanges();
    } catch (e) { }
  }

  clear() {
    try {
      this.text = '';
      this.commentInputPreset = this.commentTemplates[0];
      this.insertTemplate(this.commentInputPreset);
      if (this.filesUploader) {
        this.filesUploader.refresh();
      }
      this.chg.detectChanges();
    } catch (e) {

    }
  }

  refresh() {
  }

}
