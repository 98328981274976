import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfigService } from '../shared/services/config/config.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from './../../services/user/user.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class LayoutComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];

  constructor(public config: ConfigService, public authService: AuthService, private userService: UserService, private chg: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.subs.push(this.userService.triggerMenuCheck$.subscribe(() => {
      try {
        this.chg.detectChanges();
      } catch (e) { }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
