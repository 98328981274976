import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { TextBoxComponent } from "./text-box.component";

@Directive({
  selector: '[tbCancel]'
})
export class TbCancelDirective {

  constructor(public _viewCntRef: ViewContainerRef, public _templateRef: TemplateRef<any>, public _textBox: TextBoxComponent) {
    if (_textBox) {
      _textBox.addCancelDirective(this);
    }
  }

}
