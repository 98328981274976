import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of, throwError, EMPTY } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LogsBackend } from '../backend/logs.backend';

// injector needed because some requests like Lists use store observables in whitch old id tocken is pressent, but using interceptor token gets refreshed
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, private router: Router, private logsBackend: LogsBackend) { }

  private handleAuthError(err: any, request: HttpRequest<any>): Observable<any> {
    if (err && err.url && err.url.indexOf('/api/logs') !== -1) {
      console.log('Error on creating log. Stopped writing logs.', err);
      return EMPTY;
    }
    if (err && ((err.status && err.status != 404) || (!err.status))) {
      const logObj = {
        eventId: 'httpError',
        message: JSON.stringify(err),
        userId: this.auth.user ? this.auth.user.usersId : null,
        token: this.auth.idToken
      };
      const subs = this.logsBackend.saveLog(logObj).subscribe(() => {
        subs.unsubscribe();
      }, () => {
        subs.unsubscribe();
      })
    }
    if ((err.status === 401 || err.status === 403) && !(request.headers.get('noriderect') === 'true')) {
      this.auth.clearIdToken();
      this.auth.clearUser();
      this.router.navigateByUrl(`/login`);
      setTimeout(() => {
        location.reload();
      }, 0);

      return throwError(err);
    }
    return throwError(err);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.auth.idToken && request.headers.get('Authorization')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.idToken}`
        }
      });
    }
    return <any>next.handle(request).pipe(catchError((x: any) => { return this.handleAuthError(x, request) }));;
  }
}
