import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { LIST } from "../../../services/lists/lists.data.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TasksBackend } from "../../../backend/tasks.backend";
import { Subscription } from "rxjs";
import swal from 'sweetalert2';

@Component({
  selector: 'app-sales-fields-modal',
  templateUrl: './sales-fields-modal.component.html',
  styleUrls: ['./sales-fields-modal.component.scss']
})
export class SalesFieldsModalComponent implements OnInit, OnDestroy {
  protected _updateTypeForm: UntypedFormGroup;
  protected _clientNeeds = LIST.SALES_CLIENTS_NEEDS_LIST;
  protected _clientTypes = LIST.MARKETING_CLIENT_TYPES_LIST;
  protected _saleTypes = LIST.SALES_TYPES_LIST;
  protected _sources = LIST.MARKETING_SOURCES_LIST;
  protected _campaigns = LIST.MARKETING_CAMPAIGNS_LIST
  protected _initialTypeSelector;
  protected _updating = false;
  protected _updated = false;
  private _subscription: Subscription;

  _isInserted: any;

  constructor(private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) protected _data,
    private _tasksBackend: TasksBackend,
    private _dialogRef: MatDialogRef<SalesFieldsModalComponent>) {
  }

  ngOnInit() {
    this._buildForm();
  }

  cancel() {
    this._dialogRef.close();
  }

  protected _submit(fg: UntypedFormGroup) {
    if (!fg.valid) {
      return;
    }
    this._updating = true;
    let propName;
    this._subscription = this._tasksBackend.updateTaskSalesFields(this._data.id, fg.value)
      .subscribe(res => {
        this._updating = false;
        if (res) {
          this._updated = true;
        }
        this._dialogRef.close(res);
      }, (err) => {
        this._updating = false;
        swal.fire({
          title: 'Įvyko klaida',
          timer: 2000,
          showConfirmButton: false,
          icon: 'error'
        });
      });
  }

  private _buildForm() {
    this._updateTypeForm = this._formBuilder.group({
      klNeed: [null],
      klType: [this._data.clientTypeId !== 1 ? this._data.clientTypeId : null, Validators.required],
      saleType: [null],
      sourceId: [this._data.sourceId !== 1 ? this._data.sourceId : null],
      campaignsId: [this._data?.campaignInfo && this._data?.campaignInfo[0]?.ecrm_CampaignsId !== 1 ? this._data.campaignInfo[0]?.ecrm_CampaignsId : null],
      pCost: [null],
    });
  }

  ngOnDestroy(): void {
    if (this._subscription && !this._subscription.closed) {
      this._subscription.unsubscribe();
    }
  }
}
