
import { toArray, filter, mergeMap } from 'rxjs/operators';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TasksBackend } from "../../../../backend/tasks.backend";
import { UntypedFormBuilder } from "@angular/forms";
import { TaskReview } from "../../../models/task-review";
import { ListsBackend } from '../../../../backend/lists.backend';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { LIST } from '../../../../services/lists/lists.data.service';

@Component({
  selector: 'update-task-partner',
  templateUrl: './update-task-partner.component.html',
  styleUrls: ['./update-task-partner.component.scss']
})
export class UpdateTaskPartnerComponent implements OnDestroy {

  protected _taskPriorities: any;
  protected _isDisabled: boolean = true;
  protected _initialPriority: number;
  protected _initialPrioritySelector;
  protected _selectedPriority;
  protected _submitting = false;
  protected _submitted = false;
  subs: Subscription[] = [];

  protected selectPartner: Function;
  protected selectedPartner: any;

  protected partners: LIST = LIST.PARTNERS;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TaskReview,
    private tasksBackend: TasksBackend,
    public dialogRef: MatDialogRef<UpdateTaskPartnerComponent>,
    private listsBackend: ListsBackend,
    private _formBuilder: UntypedFormBuilder) {
    // this._taskPriorities = () => listsBackend.getTaskPriorities().pipe(mergeMap((p: any) => p), filter((p: any) => p.id != data.priorityId), toArray());
    // this._initialPriority = data.priorityId;
    // this._initialPrioritySelector = (p) => p.id == data.priorityId;
    // this.selectPartner = (p) => p.id == data.partnerId;
  }

  protected partnerSelected(partner) {
    this._isDisabled = !partner;
    this.selectedPartner = partner;
  }

  protected _submit() {
    this._submitting = true;
    this.subs.push(this.tasksBackend.updatePartner(this.data.id, this.selectedPartner.id).subscribe(resR => {
      const res = resR.body;
      this._submitted = true;
      this._submitting = false;
      this.dialogRef.close(res);
    }, (err) => {
      this._submitting = false;
      swal.fire({
        title: 'Įvyko klaida',
        timer: 2000,
        showConfirmButton: false,
        icon: 'error'
      });
    }));
  }

  protected _cancel() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
