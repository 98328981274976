
import { toArray, filter, mergeMap } from 'rxjs/operators';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TasksBackend } from "../../../../backend/tasks.backend";
import { UntypedFormBuilder } from "@angular/forms";
import { TaskReview } from "../../../models/task-review";
import { ListsBackend } from '../../../../backend/lists.backend';
import { PartnersBackend } from '../../../../backend/partners.backend';

@Component({
  selector: 'update-delegate-modal',
  templateUrl: './update-delegate-modal.component.html',
  styleUrls: ['./update-delegate-modal.component.scss']
})
export class UpdateDelegateModalComponent {

  protected taskDelegates: any;
  protected taskDelegatesList: any;
  protected isDisabled: boolean = true;
  protected selectedDelegate;
  protected initialDelegate;
  protected submitting = false;
  protected submitted = false;
  protected partnerId: any;
  protected initialDelegateSelector: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: TaskReview,
    private tasksBackend: TasksBackend,
    private partnersBackend: PartnersBackend,
    public dialogRef: MatDialogRef<UpdateDelegateModalComponent>,
    private _formBuilder: UntypedFormBuilder) {
    this.partnerId = data.partnerId;
    // this._taskProducts = () => listsBackend.getProducts().pipe(mergeMap(p => <any>p), filter((p: any) => p.id != data.productId), toArray());
    this.taskDelegatesList = () => this.partnersBackend.getPartnerDelegatesByPartner(this.partnerId).pipe(mergeMap(p => <any>p), filter((p: any) => p.isCrmUser), toArray());
    this.initialDelegate = data.delegateId;
    // this.initialDelegateSelector = (p) => p.id == data.delegateId;
  }

  protected delegateSelected(delegate) {
    this.isDisabled = !delegate;
    this.selectedDelegate = delegate;
  }

  protected submit() {
    this.submitting = true;
    const updateTaskDelegate = 0;
    this.tasksBackend.updateTaskDelegate(this.data.id, this.selectedDelegate, updateTaskDelegate).subscribe(res => {
      this.submitted = true;
      this.dialogRef.close({ success: true });
    }, (err) => {
      this.dialogRef.close({ success: false, errorMessage: err.message });
    });
  }

  protected cancel() {
    this.dialogRef.close();
  }
}
