import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from "@angular/material/dialog";
import { DEFAULT_MODAL_OPTS } from "../common/constants";

@Injectable()
export class ModalsService {

  constructor(private _dialog: MatDialog) {

  }

  public openDialog(cmp, data, opts: MatDialogConfig = DEFAULT_MODAL_OPTS): MatDialogRef<any> {
    return this._dialog.open(cmp, { ...opts, data: data });
  }
}
