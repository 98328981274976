import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'date-btn-interval',
  templateUrl: './dateBtnInterval.component.html',
  styleUrls: ['./dateBtnInterval.component.scss']
})
export class DateBtnIntervalComponent implements OnInit {

  protected btnFilterState: number = null;
  private todayDate: Date = new Date();
  private time: Date;
  private date: string;

  @Output() dateEvent = new EventEmitter<string>();


  constructor() { }

  ngOnInit() {

  }

  toogle(v) {
    this.btnFilterState = v;
    this.todayDate = new Date();
    if (v == 0) {
      this.date = null;
    } else {
      this.time = new Date(this.todayDate.setMonth(this.todayDate.getMonth() - this.btnFilterState));
      this.date = this.time.toString();
    }
    this.dateEvent.emit(this.date);
  }

}
