import {
  ConverterArgs,
  converters,
  DateConverterArgs,
  MoneyConverterArgs,
  NumberConverterArgs,
  StringConverterArgs
} from "../converters";

const DEFAULT_PROP_DESC = {
  configurable: true,
  enumerable: true
};
const pkAndDesc = (key: string | symbol, propDesc: PropertyDescriptor) => ({
  privateKey: '_' + key.toString(), propDesc: propDesc || {
    configurable: true,
    enumerable: true
  }
});

export function AsId(args?: NumberConverterArgs) {
  return makePropTypeConverter('Id', args);
}

export function AsNumber(args?: NumberConverterArgs) {
  return makePropTypeConverter('Number', args);
}

export function AsMoney(args?: MoneyConverterArgs) {
  return makePropTypeConverter('Money', args);
}

export function AsBoolean(args?: ConverterArgs): PropertyDecorator {
  return makePropTypeConverter('Boolean', args);
}

export function AsDate(args?: DateConverterArgs): PropertyDecorator {
  return makePropTypeConverter('Date', args);
}

export function AsString(args?: StringConverterArgs): PropertyDecorator {
  return makePropTypeConverter('String', args);
}

export function makePropTypeConverter(type: string, args: ConverterArgs): PropertyDecorator {
  return function (target: Object, key: string | symbol, propertyDescriptor?: PropertyDescriptor) {
    const { privateKey, propDesc } = pkAndDesc(key, propertyDescriptor);
    propDesc.get = propDesc.get || function () {
      return this[privateKey];
    };
    const origSetter = propDesc.set || function (val: boolean) {
      this[privateKey] = val;
    };
    const fnName = 'to' + type;
    const fn = converters[fnName];
    if (!fn || typeof fn != 'function') {
      throw new Error('Unknown converter type');
    }
    if (!args) {
      args = { throw: false };
    }
    function simpleSet(this: any, val: any) {
      if (args.throw && !args.error) {
        args.error = `Cannot assign ${type} property '${key.toString()}' value of '${val}'`;
      }
      origSetter.call(this, fn(val, args));
    }
    propDesc.set = simpleSet;
    return propDesc;
  }
}
