import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../template/shared/shared.module';
import { NewsDetailsComponent } from './news-details/news-details.component';

const NEWS_ROUTE: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/dashboards', },
  { path: ':id', component: NewsDetailsComponent },
  { path: '**', redirectTo: '/dashboards' }
];

@NgModule({
  declarations: [
    NewsDetailsComponent
  ],
  imports: [
    RouterModule.forChild(NEWS_ROUTE),
    SharedModule,
  ],
  providers: [
  ],
  exports: [
  ]

})

export class NewsModule { }
