export function getDpaDownloadLink(id: number) {
  return '/dpa/download/' + id;
}

export const DPA_NAME = 'DPA';

export function getDpaEmailText(id: number) {
  return `
  Sveiki, %0D%0A%0D%0A
  Prie laiško prisegame atliktų darbų DPA${id}. Prašome pasirašyti aktą ir atsiųsti el.paštu dpa@prototechnika.lt %0D%0A%0D%0A
  Detali informacija apie atliktus darbus nurodyta darbų priėmimo akte (DPA). Papildomai DPA saugomas ir visada Jums pasiekiamas prie Jūsų užsakytų ir mūsų atliktų darbų crm sistemoje.%0D%0A
  UŽSAKOVUI per 2 (dvi) darbo dienas nuo šio akto išrašymo dienos parašu nepatvirtinus akto ir nepateikus VYKDYTOJUI pretenzijų ar nesutikimo motyvų raštu, darbai laikomi priimtais bei aktas laikomas patvirtintu ir UŽSAKOVAS privalo atsiskaityti už šiame akte nurodytus darbus.%0D%0A%0D%0A%0D%0A
  Dėkojame!%0D%0A%0D%0A
  Pagarbiai%0D%0A
  UAB 'Prototechnika' aptarnavimo komanda%0D%0A
  8 5 213 6777%0D%0A
  aptarnavimas@prototechnika.lt%0D%0A
    `;
}

export function getDpaEmailTextV2(id: number, isAptDPA: boolean) {
  if (!isAptDPA) {

    return `Sveiki,
    
Prie laiško prisegame atliktų darbų DPA${id}. Prašome pasirašyti aktą ir atsiųsti el.paštu dpa@prototechnika.lt

Detali informacija apie atliktus darbus nurodyta darbų priėmimo akte (DPA). Papildomai DPA saugomas ir visada Jums pasiekiamas prie Jūsų užsakytų ir mūsų atliktų darbų crm sistemoje.
UŽSAKOVUI per 2 (dvi) darbo dienas nuo šio akto išrašymo dienos parašu nepatvirtinus akto ir nepateikus VYKDYTOJUI pretenzijų ar nesutikimo motyvų raštu, darbai laikomi priimtais bei aktas laikomas patvirtintu ir UŽSAKOVAS privalo atsiskaityti už šiame akte nurodytus darbus.


Dėkojame!

Pagarbiai
UAB 'Prototechnika' aptarnavimo komanda
8 5 213 6777
aptarnavimas@prototechnika.lt
`;
  } else {
    return `Sveiki,
    
Šiuo laišku siunčiame darbų aktą (DPA${id}) už per praeitą mėnesį suteiktas programos priežiūros paslaugas, registruotas Jūsų įmonės vardu ECRM paslaugų portale. Paslaugos, viršijančios priežiūros sutartyje numatytą nemokamai teikiamų paslaugų laiką (išskyrus paslaugas "07: Duomenų analizė, darbai dėl programos klaidos" ir "08: Paslaugos neatitikties korekcija"), yra apmokestintos nustatytu įkainiu. Sąskaita už šias paslaugas bus išsiųsta lygiagrečiai šiam pranešimui.
 
Detalią informaciją apie suteiktas paslaugas (atliktus darbus, atsakymus į klausimus) galite peržiūrėti atitinkamoje ECRM paslaugų portale registruotoje Jūsų užklausoje.
  
UŽSAKOVUI per 2 (dvi) darbo dienas nuo šio akto išrašymo dienos parašu nepatvirtinus akto ir nepateikus VYKDYTOJUI pretenzijų ar nesutikimo motyvų raštu, darbai laikomi priimtais bei aktas laikomas patvirtintu ir UŽSAKOVAS privalo atsiskaityti už šiame akte nurodytus darbus.
  
  
Dėkojame!
  
Pagarbiai
UAB 'Prototechnika' aptarnavimo komanda
8 5 213 6777
aptarnavimas@prototechnika.lt
`;
  }
}

export function printDpa(id) {
  window.open(window.location.origin + '/dpa/' + id + '?print=1');
}