import { ChangeDetectorRef, Component, HostListener, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Observable, Subscription } from "rxjs"
import { MatSidenav } from "@angular/material/sidenav";
import { ComponentPortal } from '@angular/cdk/portal';
import { getJobParams, JOB_PARAMS, TaskboardSidebarService } from "../services/taskboardSidebar.service";
import { JobFormComponent } from "../components/jobs/job-form/job-form.component";

@Component({
  selector: "taskboard-sidebar",
  templateUrl: "taskboardSidebar.component.html",
  providers: [
    { provide: JOB_PARAMS, useFactory: getJobParams }
  ]
})
export class TaskboardSidebarComponent implements OnInit, OnDestroy {

  @ViewChild(MatSidenav) sidenav: MatSidenav;
  private subscriptions: Array<Subscription> = [];
  jobFormVisible: Observable<boolean>;
  private component: ComponentPortal<any>;
  constructor(private sidebarService: TaskboardSidebarService,
    private injector: Injector,
    private chg: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(this.sidebarService.jobFormVisible.subscribe((v) => {
      try {
        this.chg.detectChanges();
      } catch (err) { }
    }));
    this.sidebarService.registerSideBar(this.sidenav);
    this.jobFormVisible = this.sidebarService.jobFormVisible;
    const params = new WeakMap([
      [JOB_PARAMS, { test: 'WOOORKS' }]
    ]);

    this.component = new ComponentPortal<any>(JobFormComponent, undefined, this.injector);
  }

  @HostListener('document:keyup.esc')
  onkeyup() {
    this.sidenav && this.sidenav.close();
  }

  ngOnDestroy(): void {
    this.sidebarService.unregisterSideBar();
    this.subscriptions.forEach((s) => {
      s.unsubscribe()
    })
  }
}
