import { AfterContentInit, Directive, ElementRef, forwardRef, Host, Inject, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { log } from '../../helpers';
import { VALUE_PROVIDER } from '../../proto';
import { ValueProvider } from '../ValueProvider';
import { QUERY_PART, QueryPart } from './Query';

const inputTypeToVal = {
  text: {
    event: 'onkeyup',
    val: 'value'
  },
  checkbox: {
    event: 'onchange',
    val: 'checked'
  }
};

@Directive({
  selector: '[queryPart]',
  providers: [
    { provide: QUERY_PART, useExisting: forwardRef(() => QueryPartDirective) }
  ]
})
export class QueryPartDirective implements OnInit, AfterContentInit, OnDestroy, QueryPart<any> {

  private subscriptions: Array<Subscription> = [];
  @Input() queryPart: string;
  @Input() waitFirst: boolean = true;
  private _emitWhen: any;
  @Input() set emitWhen(val: any) {
    this._emitWhen = val;
  }
  get emitWhen(): any {
    return this._emitWhen;
  }

  private _autoEmit: boolean = true;
  @Input() set autoEmit(val: boolean) {
    this._autoEmit = val;
  }
  get autoEmit(): boolean {
    return this._autoEmit;
  }

  @Input() mapValue: (val: any) => any;

  public value: Observable<any>;
  private readonly _pushVal = (val: any) => {
    log(this.constructor.name + ' -- ' + val);
    if (this.queryPart === 'search' && val && val.trim() == '') {
      val = '';
    }
    (<Subject<any>>this.value).next(val);
  };

  private _inputElement: HTMLInputElement;

  constructor(@Optional() @Host() @Self() @Inject(NG_VALUE_ACCESSOR) private _valueAccessor: ControlValueAccessor,
    @Optional() @Host() @Self() @Inject(VALUE_PROVIDER) private _valueProvider: ValueProvider<any>,
    private element: ElementRef) {
    if (!(_valueAccessor || _valueProvider)) {
      if (this.element.nativeElement instanceof HTMLInputElement) {
        this._inputElement = this.element.nativeElement;
      } else {
        throw "QueryPartDirective can be applied on one of: 'ValueProvider', 'HTMLInputElement', 'ControlValueAccessor'"
      }
    }
  }

  getValue(): any {

  }

  ngOnInit() {
  }

  emit(val: any) {
    throw new Error("Method not implemented.");
  }

  ngAfterContentInit() {
    if (this._valueProvider) {
      this.value = this._valueProvider.getValueChanges();
    } else if (this._valueAccessor) {

      this.value = new ReplaySubject(1);
      this._valueAccessor.registerOnChange(this._pushVal);
    } else if (this._inputElement) {
      let itv = inputTypeToVal[this._inputElement.type];
      if (!itv) {
        throw "QueryPartDirective: Applied on unknown input type. Currently supported types are: text, checkbox";
      }
      this.value = new ReplaySubject(1);
      this._inputElement[itv.event] = (e) => this._pushVal((<any>e.target)[itv.val]);
    } else {
      throw "QueryPartDirective: Invalid state! Applied on neither of: 'ValueProvider', 'HTMLInputElement', 'ControlValueAccessor'"
    }
  }

  public emmit(): void {

  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe())
  }

}
