import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MarketingCampaignBackend } from '../../../../backend/marketing-campaign.backend';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-marketing-participans-list',
  templateUrl: './marketing-participans-list.component.html',
  styleUrls: ['./marketing-participans-list.component.scss']
})
export class MarketingParticipansListModalComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];
  participantsList: any[] = [];
  campaignsId;

  constructor(
    public dialogRef: MatDialogRef<MarketingParticipansListModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    private marketingCampaignBackend: MarketingCampaignBackend,
  ) {
    this.campaignsId = data.campaignsId;
  }

  ngOnInit(): void {
    this.subs.push(this.marketingCampaignBackend.getCampaignsParticipantList(this.campaignsId).subscribe(resp => {
      this.participantsList = resp;
    }));
  }

  closeClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
