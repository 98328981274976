import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminRouteGuard } from './routeGuards/admin.route.guard';
import { ContentRouteGuard } from './routeGuards/content.route.guard';
import { LoggedInRouteGuard } from './routeGuards/logged-in.route.guard';
import { TaskboardModule } from './taskboard/taskboard.module';
import { ProfileModule } from './profile/profile.module';
import { AdminModule } from './admin/admin.module';
import { ContentModule } from './content/content.module';
import { DashboardsModule } from './dashboards/dashboards.module';
import { NewsModule } from './news/news.module';
import { AuthModule } from './auth/auth.module';
import { ProjectsModule } from "./projects/projects.module";
import { FeedbackModule } from "./feedback/feedback.module";
import { MarketingModule } from "./marketing/marketing.module";
import { SalesModule } from "./sales/sales.module";

const APP_ROUTES: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "dashboards",
  },
  {
    path: 'taskboard',
    // loadChildren: () => import('./taskboard/taskboard.module').then(m => m.TaskboardModule),
    loadChildren: () => TaskboardModule,
    canActivate: [LoggedInRouteGuard]
  },
  // {
  //   path: 'lists',
  //   loadChildren: () => import('./lists/lists.module').then(m => m.ListsModule),
  //   canActivate: [LoggedInRouteGuard]
  // },
  {
    path: 'profile',
    // loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    loadChildren: () => ProfileModule,
    canActivate: [LoggedInRouteGuard]
  },
  {
    path: 'admin',
    // loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    loadChildren: () => AdminModule,
    canActivate: [LoggedInRouteGuard, AdminRouteGuard]
  },
  {
    path: 'content',
    // loadChildren: () => import(''./content/content.module').then(m => m.ContentModule),
    loadChildren: () => ContentModule,
    canActivate: [LoggedInRouteGuard, ContentRouteGuard]
  },
  {
    path: 'dashboards',
    // loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule),
    loadChildren: () => DashboardsModule,
    canActivate: [LoggedInRouteGuard]
  },
  {
    path: 'news',
    // loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
    loadChildren: () => NewsModule,
    canActivate: [LoggedInRouteGuard]
  },
  {
    path: 'projects',
    loadChildren: () => ProjectsModule,
    canActivate: [LoggedInRouteGuard]
  },
  {
    path: 'marketing',
    loadChildren: () => MarketingModule,
    canActivate: [LoggedInRouteGuard]
  },
  {
    path: 'sales',
    loadChildren: () => SalesModule,
    canActivate: [LoggedInRouteGuard]
  },
  {
    path: 'login',
    // loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    loadChildren: () => AuthModule,
  },
  {
    path: 'feedback',
    loadChildren: () => FeedbackModule,
  },
  // {
  //   path: 'dpaview',
  //   loadChildren: () => import('./dpaView/dpa-view.module').then(m => m.DpaViewModule),
  //   canActivate: [LoggedInRouteGuard]
  // },
  { path: "**", redirectTo: "dashboards" }
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
