import { Component, OnInit } from '@angular/core';
import { Observable, Subject, Subscription, ReplaySubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { pluck, switchMap, map } from 'rxjs/operators';
import { PartnersBackend } from '../../../backend/partners.backend';

@Component({
  selector: 'partner-editor',
  templateUrl: './partner-editor.component.html',
  styleUrls: ['./partner-editor.component.scss'],
})
export class PartnerEditorComponent implements OnInit {

  _id: Observable<number>;
  _subscriptions: Subscription[] = [];
  protected debts: Subject<Array<any>> = new ReplaySubject(1);
  protected debtsSum: Observable<number>;
  protected lateDebtsSum: Observable<number>;

  constructor(private _route: ActivatedRoute, private partnersService: PartnersBackend,) {

  }

  ngOnInit() {
    this._id = this._route.params.pipe(pluck('id'));
    this._setupPartnerDebts();
  }

  private _setupPartnerDebts() {
    const getDebtsSum = (debts: Array<any>) => debts.reduce((acc, d) => acc + d.quantity, 0.0);
    const getLateDebtsSum = (debts: Array<any>) => debts.reduce((acc, d) => d.daysAfterTerm < 0 ? acc + d.quantity : acc, 0.0);
    this._subscriptions.push(this._id.pipe(switchMap((pc) => this.partnersService.getPartnerDebts(pc))).subscribe((d) => (<Subject<any>>this.debts).next(d)));
    this.debtsSum = this.debts.pipe(map((dl) => getDebtsSum(dl)));
    this.lateDebtsSum = this.debts.pipe(map((dl) => getLateDebtsSum(dl)));
  }

}