import { Component, Input, OnInit } from '@angular/core';
import { UserBackend } from '../../../backend/user.backend';

@Component({
  selector: 'rights-control',
  templateUrl: './rights-control.component.html',
  styleUrls: ['./rights-control.component.scss']
})
export class RightsControlComponent implements OnInit {

  @Input() rights: Array<any> = [];
  @Input() assigneeId;
  constructor(private _userBackend: UserBackend) { }

  ngOnInit() {

  }

  _permissionToggle(id, enabled) {
    this._userBackend.permissionToggle(this.assigneeId, id, enabled).subscribe((r) => console.log('Claim changed: ', r));
  }
}
