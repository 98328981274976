import { Component, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FeedbackBackend } from '../../backend/feedback.backend';
import { formPath } from '../../core/routing/helpers';
import { REQUESTS, REVIEW, TASKS } from '../../taskboard/taskboard.help';
import { DashboardService } from '../dashboard.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SHORT_DATE } from '../../core/time';

@Component({
  selector: 'bad-rated-requests',
  templateUrl: './bad-rated-requests.component.html',
  styleUrls: ['./bad-rated-requests.component.scss']
})
export class BadRatedRequestsComponent implements OnInit, OnDestroy {

  shortDate = SHORT_DATE;

  dataReady = false;
  date: Date = new Date();
  comments: any[];
  commentsFiltred: any[];

  selectRating: number;
  subs: Subscription[] = [];

  @ViewChild('defaultHeader', { read: TemplateRef, static: true }) defaultHeaderTpl: TemplateRef<any>;
  @ViewChild('default', { read: TemplateRef, static: true }) defaultTpl: TemplateRef<any>;
  @ViewChild('dateTemplate', { read: TemplateRef, static: true }) dateTemplate: TemplateRef<any>;

  columns;
  sorts = [{ prop: 'date', dir: 'desc' }];

  constructor(
    private feedbackBackend: FeedbackBackend,
    private dashboardService: DashboardService,
    private router: Router
  ) { }

  ngOnInit() {

    this.initColumns()

    this.subs.push(this.dashboardService.ratingSelect$.subscribe({
      next: (rating) => {
        this.selectRating = rating;
        if (this.comments && this.comments.length > 0 && this.selectRating) {
          this.commentsFiltred = this.comments.filter(comment => comment.rating === this.selectRating);
        } else {
          this.commentsFiltred = this.comments;
        }
        // console.log(this.commentsFiltred);
      }
    }))

    this.subs.push(this.dashboardService.monthSelect$.subscribe({
      next: (date) => {
        this.date = date;
        this.getData(this.date, this.selectRating);
      }
    }))
  }

  initColumns() {
    this.columns = [
      {
        name: 'Data',
        prop: 'date',
        width: 60,
        sortable: true,
        cellTemplate: this.dateTemplate
      }, {
        name: 'Partneris',
        prop: 'name',
        width: 100,
      }, {
        name: 'Kliento atsiliepimas',
        tooltip: 'Kliento atsiliepimas',
        prop: 'comment',
        width: 200
      }
    ];
  }

  getData(date: Date, rating) {
    const dateTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    // console.log(dateTime);
    const paramsObj = {
      dateTime
    }
    const unsub = this.feedbackBackend.getFeedback(paramsObj).subscribe(resp => {
      // console.log(resp);
      this.comments = resp.entity.badRatedRequestsViews.map(el => {
        const url = formPath('/taskboard', REQUESTS, REVIEW, el.taskId);
        return { url, ...el }
      });
      if (rating) {
        this.commentsFiltred = this.comments.filter(comment => comment.rating === rating);
      } else {
        this.commentsFiltred = this.comments
      }

      this.dataReady = true;
      unsub.unsubscribe();
    });

  }

  dateMonthChenge(ev: Date) {
    console.log(ev);
    this.getData(ev, this.selectRating);
  }

  ngOnDestroy() {
    this.subs.forEach(s => { s.unsubscribe(); s = null; });
  }

  rowClickedTasks(row) {
    this.router.navigate([row.url]);
  }
}
