import { Directive, HostListener, TemplateRef, ViewContainerRef } from '@angular/core';
import { TextBoxComponent } from "./text-box.component";
import { ContentRef } from "ngx-bootstrap/component-loader";

@Directive({
  selector: '[tbContent]'
})
export class TbContentDirective {

  constructor(public contentRef: ContentRef) {

  }

}
