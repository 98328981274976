
import { pluck } from 'rxjs/operators';
import { Subject, Observable, Subscription, ReplaySubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DATE_WITH_TIME, SHORT_DATE } from '../../core/time';
import { MarketingCampaignBackend } from '../../backend/marketing-campaign.backend';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CalendarCustomHeaderComponent } from '../../components/filters/date-interval-alt/calendarCustomHeader.component';
import { Location } from '@angular/common';
import swal from 'sweetalert2';
import { LIST } from '../../services/lists/lists.data.service';
import { toDbString } from '../../functions/utils/date/date.utils';
import { AuthService } from '../../services/auth.service';
import { NEW_MARKETING_TASK, SELECTED_MARKETING_TASKS } from '../../taskboard/taskboard.help';
import * as XLSX from 'xlsx';
import { TasksBackend } from '../../backend/tasks.backend';
import { ListsBackend } from '../../backend/lists.backend';
import { PersonsBackend } from '../../backend/persons.backend';

@Component({
  selector: 'app-edit-marketing-campaign',
  templateUrl: './edit-marketing-campaign.component.html',
  styleUrls: ['./edit-marketing-campaign.component.scss']
})
export class EditMarketingCampaignComponent implements OnInit, OnDestroy {
  _id: Observable<any>;
  _newsDetails: Subject<any> = new Subject();
  dateWithTime = DATE_WITH_TIME;
  shortDate = SHORT_DATE;
  _isEditable = false;
  public _news: any;
  isLoading = false;

  subs: Subscription[] = [];
  isNew = false;
  editMarketingCampaignForm: UntypedFormGroup;
  marketingCampaign: any;
  id: string;

  initTitle: string;
  initDate: any;
  initProductId: any;
  initUserId: any;

  initUserSelector = (val: any) => val.userId == this.initUserId;

  showForm = false;

  calendarCustomHeaderComponent: any = CalendarCustomHeaderComponent;

  _loadingUpdate = false;
  _completeUpdate = false;

  employees: LIST = LIST.EMPLOYEES;
  productsSource = () => this.productsListObs;
  productsListObs: Subject<any> = new ReplaySubject(1);
  products: any[];
  errorList: any[];
  taskTypesList: any[];
  taskPriorityList: any[];
  partnersList: any[];
  servicesList: any[];
  sourcesList: any[];
  clientTypesList: any[];
  userList: any[];

  taskTypeId: any;
  loading = false;

  loadingTotal: number;
  loadingCurrent: number;
  deleteInProgress = false;

  constructor(
    private _route: ActivatedRoute,
    private chg: ChangeDetectorRef,
    private marketingCampaignBackend: MarketingCampaignBackend,
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private authService: AuthService,
    private _router: Router,
    private tasksBackend: TasksBackend,
    private listsBackend: ListsBackend,
    private personsBackend: PersonsBackend,
  ) {
  }

  ngOnInit() {
    this._loadProducts();
    this.getLists();
    this.subs.push(this._route.params.pipe(pluck('id')).subscribe((id) => {
      if (id === 'new') {
        // no need to get current values
        this.initTitle = null;
        this.initDate = null;
        this.initProductId = null;
        this.initUserId = this.authService.user.usersId;
        this.isNew = true;
        this.buildForm();
        this.showForm = true;
      } else {
        this.isNew = false;
        this.id = id;
        this.subs.push(this.marketingCampaignBackend.getMarketingCampaign(id).subscribe((r: any) => {
          this.marketingCampaign = r.entities && r.entities[0] || {};
          this.initTitle = this.marketingCampaign?.name;
          this.initDate = this.marketingCampaign?.date && new Date(this.marketingCampaign?.date) || null;
          this.initProductId = this.marketingCampaign?.productId || null;
          this.initUserId = this.marketingCampaign?.ownerId || this.authService.user.usersId;
          this.buildForm();
          this.editMarketingCampaignForm.controls.title.setValue(this.initTitle);
          this.editMarketingCampaignForm.controls.title.updateValueAndValidity();
          this.editMarketingCampaignForm.controls.date.setValue(this.initDate);
          this.editMarketingCampaignForm.controls.date.updateValueAndValidity();
          this.editMarketingCampaignForm.controls.productId.setValue(this.initProductId);
          this.editMarketingCampaignForm.controls.productId.updateValueAndValidity();
          this.showForm = true;
          try {
            this.chg.detectChanges();
          } catch (err) { }
        }));
      }
    }));
  }

  getLists() {
    this.subs.push(this.listsBackend.getTaskPriorities().subscribe(resp => {
      this.taskPriorityList = resp;
    }));
    this.subs.push(this.listsBackend.getTaskTypes().subscribe(resp => {
      this.taskTypesList = resp;
      this.taskTypeId = this.taskTypesList.find(el => el.code == 'VT_5')?.id
      this.subs.push(this.listsBackend.getServices(this.taskTypeId).subscribe(resp => {
        this.servicesList = resp;

      }));
    }));
    this.subs.push(this.listsBackend.getAllPartners().subscribe(resp => {
      this.partnersList = resp;
    }));
    this.subs.push(this.marketingCampaignBackend.getMarketingSourcesList().subscribe(resp => {
      this.sourcesList = resp;
    }));
    this.subs.push(this.marketingCampaignBackend.getMarketingClientTypesList().subscribe(resp => {
      this.clientTypesList = resp;
    }));
    this.subs.push(this.personsBackend.getEmployees().subscribe(resp => {
      this.userList = resp;
    }));
  }

  buildForm() {
    this.editMarketingCampaignForm = this.formBuilder.group(
      {
        title: [null, Validators.required],
        date: [new Date(), Validators.required],
        productId: [null, Validators.required],
        userId: [this.initUserId, Validators.required],
      });
  }

  filesChanged(event) {
    const currentFile = event.target?.files[event.target?.files?.length - 1] || null;
    if (currentFile) {
      const splittedFileName = currentFile.name.split('.');
      const currentFileExt = splittedFileName[splittedFileName.length - 1];
      const reader: FileReader = new FileReader();
      if (currentFileExt == 'xlsx') {
        reader.readAsBinaryString(currentFile);
      }
      reader.onload = (e: any) => {
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        this.errorList = [];
        const preparedRequests = this.prepareMultipleRequestData(data, this.errorList);
        this.loadingTotal = preparedRequests.length;
        this.loadingCurrent = 0;
        this.loading = true;
        this.deleteInProgress = true;
        try {
          this.chg.detectChanges();
        } catch (e) { console.log(e) };
        this.subs.push(this.marketingCampaignBackend.deleteCampaignsTasks(this.id).subscribe(resp => {
          this.deleteInProgress = false;
          try {
            this.chg.detectChanges();
          } catch (e) { console.log(e) };
          this.startExecuting(preparedRequests);
        }, err => {
          this.loading = false;
          this.deleteInProgress = false;
          swal.fire({
            title: 'Trinimas nepavyko. Importas nutrauktas.',
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn-primary'
            },
          })
        }))
      };
    }
  }

  startExecuting(preparedRequests) {
    if (!preparedRequests || preparedRequests.length === 0 || (this.errorList && this.errorList.length > 0)) {
      this.errorList = [...this.errorList];
      this.loading = false;
      try {
        this.chg.detectChanges();
      } catch (e) { console.log(e) };
      if (this.errorList.length > 0) {
        swal.fire({
          title: 'Importas įvyko su klaidomis eilutėje ' + (this.errorList[0].row + 1),
          showConfirmButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn-primary'
          },
        })
      } else {
        swal.fire({
          title: 'Importas įvyko sėkmingai',
          showConfirmButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn-primary'
          },
        })
        this.gotoTasks();
      }
    } else {
      this.loadingCurrent++;
      try {
        this.chg.detectChanges();
      } catch (e) { console.log(e) };
      const requestData = preparedRequests.shift();
      if (!requestData.hasError) {
        this.subs.push(this.tasksBackend.insertTask(requestData.requestData).subscribe(taskId => {
          if (requestData.commentRequestData.text) {
            this.subs.push(this.tasksBackend.commentTask({ ...requestData.commentRequestData, taskId }).subscribe(resp => {
              this.startExecuting(preparedRequests);
            }, err => {
              this.addError(requestData.row, 'Error_creating_comment');
              this.startExecuting(preparedRequests);
            }
            ));
          } else {
            this.startExecuting(preparedRequests);
          }
        }, err => {
          this.addError(requestData.row, 'Error_creating_task');
          this.startExecuting(preparedRequests);
        }))
      } else {
        this.startExecuting(preparedRequests);
      }
    }
  }

  gotoTasks() {
    this._router.navigate(['/marketing', SELECTED_MARKETING_TASKS, this.id]);
  }

  addError(row, errorText) {
    const existingRow = this.errorList.find(el => el.row === row);
    if (existingRow) {
      existingRow.errors.push(errorText);
    } else {
      this.errorList.push({ row, errors: [errorText] });
    }
  }

  prepareMultipleRequestData(excelData, errorList) {
    const requestsData = [];
    const requestConstantPart = {
      taskTypeId: this.taskTypeId,
      serviceId: this.servicesList.find(el => el.code == 'KA01').serviceId,
      priorityId: this.taskPriorityList.find(el => el.code == 'Iprastas*C').id,
      mainTaskId: null,
      expenses: null,
      // executorId: this.authService.user.personId,
      delegateId: null,
      campaignsId: +this.id,
      watchers: [],
      participants: [],
    }
    excelData.forEach((el, index) => {
      const date1 = new Date(1900, 0, 1);
      date1.setDate(date1.getDate() + el['DateFrom'] - 2);
      const dateFrom = date1.toLocaleDateString('LT');
      const date2 = new Date(1900, 0, 1);
      date2.setDate(date2.getDate() + el['DateTo'] - 2);
      const dateTo = date2.toLocaleDateString('LT');
      const executorId = this.userList.find(elem => elem.userCode == el['Executor'])?.id;
      const userId = this.userList.find(elem => elem.userCode == el['Executor'])?.userId;
      requestsData.push({
        requestData: {
          ...requestConstantPart,
          productId: this.products.find(el => el['Product'])?.id ? this.products.find(el => el['Product']).id : this.marketingCampaign?.productId,
          title: `${el['Company'] || ''} ${el['PhoneNumber'] || ''} ${el['FirstName'] || ''} ${el['LastName'] || ''} ${el['Email'] || ''} Dalyvavo: ${el['Participated'] || ''} Dalyvavo %: ${el['TimePercent'] || ''}`,
          description: `${el['Company'] || ''} ${el['PhoneNumber'] || ''} ${el['FirstName'] || ''} ${el['LastName'] || ''} ${el['Email'] || ''} Dalyvavo: ${el['Participated'] || ''} Dalyvavo %: ${el['TimePercent'] || ''}
${el['Script']}`,
          companyName: el['Company'],
          name: el['FirstName'],
          lastname: el['LastName'],
          email: el['Email'],
          partnerId: this.partnersList.find(elem => elem.code == (el['Partner'] ? el['Partner'] : 'ECRM_PK'))?.id,
          clientTypeId: this.clientTypesList.find(elem => elem.code == el['ClientType'])?.typeId,
          sourceId: this.sourcesList.find(elem => elem.code == el['Source'])?.typeId,
          dateFrom: dateFrom,
          dateTo: dateTo,
          taskStateId: el['State'] == 5 ? 5 : null,
          executorId: executorId,
          userId: userId,
        },
        commentRequestData: {
          userId: userId,
          text: el['Comment'],
          textHtml: `<p>${el['Comment']}</p>`,
          changeState: false,
          isInternalComment: false,
          taskId: null,
        },
        row: index + 1,
        hasError: !!errorList.find(el => el.row === index + 1),
      })
    })
    return requestsData;
  }

  private _loadProducts() {
    this.subs.push(this.marketingCampaignBackend.getProducts()
      .subscribe((res) => { this.products = res; this.productsListObs.next(this.products); }));
  }

  submitMarketingCampaign(form: any) {
    const editObj: any = {};
    !this.isNew && (editObj.campaignsId = +this.id);
    editObj.name = form.title;
    editObj.date = toDbString(form.date);
    editObj.productId = form.productId;
    editObj.userId = form.userId;
    this._loadingUpdate = true;
    this._completeUpdate = false;
    this.subs.push(this.marketingCampaignBackend.editMarketingCampaign(editObj).subscribe(resp => {
      this._loadingUpdate = false;
      this._completeUpdate = true;
      if (this.isNew) {
        this._router.navigate(['/marketing', resp.entity]);
      } else {
        this._router.navigate(['/marketing', 'mc']);
      }
      try {
        this.chg.detectChanges();
      } catch (err) { }
    }, err => {
      this._loadingUpdate = false;
      try {
        this.chg.detectChanges();
      } catch (err) { }
    }))
  }

  cancel() {
    this.location.back();
  }

  gotoAddTask() {
    this._router.navigate(['/marketing', this.id, NEW_MARKETING_TASK]);
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

}