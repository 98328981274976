
import { map, filter } from 'rxjs/operators';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ChangeDetectorRef
} from "@angular/core"
import { BehaviorSubject, Subject, Subscription } from "rxjs"
import { FlagState, ProtoFlagDirective } from "./flag.directive";
import { replaceAt } from "../helpers";
import { ValueProvider } from "../common/ValueProvider";
import { ControlValueAccessor } from "@angular/forms";
import { VALUE_PROVIDER } from "../proto";

type Flip = (b: boolean) => boolean
type Validate = (a: string) => string

const mergeToObject: (a: Array<FlagState>) => any = (s) => {
  let o = {};
  for (let i = 0; i < s.length; i++) {
    o[s[i].id] = s[i].state;
  }
  return o;
};

const makeApplicatorV2 = (flags: Array<any>) => (newState: string) => {
  let s = ""; //Actual state
  for (let i = 0; i < flags.length; i++) {
    s += flags[i].checked ? "1" : "0";
    let c = newState[i] == "1";
    flags[i].checked = c;
  }
  return s;
};

const getState: (a: string) => Flip = (r) => {
  if (!r || r.length != 1) {
    throw "Invalid state: " + r;
  }
  switch (r[0]) {
    case "!": return (s: boolean) => !s;
    case "|": return (s: boolean) => s;
    case "0": return (s: boolean) => false;
    case "1": return (s: boolean) => true;
    default: throw "Unknown state";
  }
};

const makeValidatorsV2: (a: any, i: number) => Validate = (a, i) => {
  return function (before: string) {
    return replaceAt(before, before[i] == "0" ? "1" : "0", i)
  }
};

@Component({
  selector: "proto-flags-group",
  templateUrl: "./flagsGoup.component.html",
  styleUrls: ["./flagsGoup.component.scss"],
  providers: [
    {
      provide: VALUE_PROVIDER, useExisting: forwardRef(() => ProtoFlagsGroupComponent),
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProtoFlagsGroupComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy, OnChanges, ValueProvider<any>, ControlValueAccessor {

  private _flagsGroup: string;
  @Input() set flagsGroup(val: string) {
    this._flagsGroup = val;
  };
  get flagsGroup() {
    return this._flagsGroup;
  };
  flagsArr: any;
  @Input() rules: any;
  @Input() flagsInput: any;
  @Input() buttonTitle: any;
  @Input() displayType = 1;
  @Output() flagsStateChanged: EventEmitter<Array<FlagState>> = new EventEmitter();

  @ContentChildren(forwardRef(() => ProtoFlagDirective), { descendants: true }) flags: QueryList<ProtoFlagDirective>;

  private _subscriptions: Array<Subscription> = [];
  private _isValid: boolean = true;
  private _validators: Array<{ id: string, v: Validate }> = [];
  private _flagStates: Array<any> = [];

  private _currentState: string = this.flagsGroup;
  private _flagsStatesSubj: Subject<Array<FlagState>> = new BehaviorSubject(null);
  public set currentState(val: string) {
    throw "ProtoFlagsGroupComponent: CurrentState cannot be manipulated outside!"
  }
  public get currentState(): string {
    return this._currentState;
  }
  private _apply: (s: string) => string;
  private _chg: (v: any) => void;
  private _initialized: boolean = false;
  constructor(private chg: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.flagsInput) {
      this.flagsArr = this.flagsInput;
    }
  }

  ngAfterViewInit(): void {
    this._subscriptions.push(this.flagsStateChanged.subscribe((fs) => this._flagsStatesSubj.next(fs)));
  }

  ngAfterContentInit(): void {
    this.initV2();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initV2();
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  private initV2(): void {
    if (!(this.flagsGroup && !this._initialized)) {
      return;
    }
    this.flagsInitV2();
  }

  private flagsInitV2() {
    //fix when adding new State to system
    if (this.flagsGroup && this.flagsArr) {
      if (this.flagsGroup.length < this.flagsArr.length) {
        const smallerBy = this.flagsArr.length - this.flagsGroup.length;
        for (let i = 0; i < smallerBy; i++) {
          this.flagsGroup += '0';
        }
      }
      //when removing states from system
      if (this.flagsGroup.length > this.flagsArr.length) {
        const greaterBy = this.flagsGroup.length - this.flagsArr.length;
        this.flagsGroup = this.flagsGroup.slice(0, -1 * greaterBy);
      }
    }
    this._currentState = this.flagsGroup;
    this._apply = makeApplicatorV2(this.flagsArr);
    this.flagsArr.forEach((f, i) => {
      f.checked = this.flagsGroup[i] == "1";
      this._flagStates.push({
        id: f.flag,
        state: f.checked
      });
      this._validators.push(({ id: f.flag, v: makeValidatorsV2(f, i) }));
    });
    this.flagsStateChanged.emit(this._flagStates);
    this._initialized = true;
  }

  public getFlagsState(): Array<FlagState> {
    return this.flags.reduce((acc: Array<FlagState>, f) => { acc.push(f.getState()); return acc }, [])
  }

  writeValue(obj: any): void {

  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState(isDisabled: boolean): void {

  }

  getValueChanges() {
    return this._flagsStatesSubj.pipe(filter((f) => f != null), map((fs) => mergeToObject(this._flagStates)))
  }

  registerOnChange(fn: any): void {
    this._chg = fn;
  }

  getValue(): any {
    return this._flagStates;
  }

  stateChange(fs: any) {
    let index = -1;
    for (let i = 0; i < this._flagStates.length; i++) {
      if (this._flagStates[i].id == fs.id) {
        index = i;
        break;
      }
    }
    let validated = this._validators[index].v(this._currentState);
    if (this.rules && this.rules[validated]) {
      validated = this.rules[validated];
    }
    let fStateBefore = this._apply(validated);
    this._currentState = validated;
    if (fStateBefore != validated) {
      this._flagStates = [];
      this.flagsArr.forEach((f, i) => {
        f.checked = validated[i] == "1";
        this._flagStates.push({
          id: f.flag,
          state: f.checked
        });
        this._validators.push(({ id: f.flag, v: makeValidatorsV2(f, i) }));
      });
      this.flagsArr = this.flagsArr.map((item) => { return { ...item } });
      this.flagsStateChanged.emit(this._flagStates);
    }
  }
}
