import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Observable, of, Subject, Subscription, combineLatest } from 'rxjs';
import { UserBackend } from '../../backend/user.backend';
import { NgxPermissionsService } from 'ngx-permissions';
import { DashboardService } from '../dashboard.service';
import { takeLast } from 'rxjs/operators';

@Component({
  selector: 'work-time-by-type-chart-menu',
  templateUrl: './work-time-by-type-chart-menu.component.html',
  styleUrls: ['./work-time-by-type-chart-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkTimeByTypeChartMenuComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() userSelected = new EventEmitter();

  showItems = false;
  searchField = '';
  users: any = [];
  me = { name: 'Mano', userId: null };
  _subscriptions: Subscription[] = [];
  currentUser;

  menuUsersReady = false;
  menuUsersReady$ = new Subject();


  protected _mapType = (t) => t.name;

  constructor(private userBackend: UserBackend, private chg: ChangeDetectorRef, protected permissionsService: NgxPermissionsService, private dashboardService: DashboardService) {
  }

  ngOnInit() {
    this.currentUser = this.me;
    this.currentUser.userId = this.dashboardService.authService.user.usersId;
    if (this.permissionsService.getPermission('crm_manager') || this.permissionsService.getPermission('crm_director')) {
      this.dashboardService.getUsersByGroup((this.permissionsService.getPermission('crm_director') ? true : false));
      this._subscriptions.push(this.dashboardService.usersByGroup$.subscribe((users: any) => {
        this.users = [this.me, ...users];
        this.currentUser = this.users.find((el) => { return el.userId === this.dashboardService.workTimeByTypeUserId });
        if (!this.currentUser) {
          this.dashboardService.setWorkTimeByTypeUserLocalstorage(this.dashboardService.authService.user.usersId);
          this.currentUser = this.users.find((el) => { return el.userId === this.dashboardService.workTimeByTypeUserId });
        }
        this.menuUsersReady = true;
        this.menuUsersReady$.next(true);
        try {
          this.chg.detectChanges();
        } catch (e) { }
      }));
    } else {
      this.dashboardService.setWorkTimeByTypeUserLocalstorage(this.dashboardService.authService.user.usersId);
      this.menuUsersReady = true;
      this.menuUsersReady$.next(true);
      try {
        this.chg.detectChanges();
      } catch (e) { }
    }
  }

  userselected(user) {
    this.currentUser = user;
    this.dashboardService.setWorkTimeByTypeUserLocalstorage(user.userId);
    this.userSelected.emit(user);
  }

  showMenuItems() {
    this.showItems = true;
    try {
      this.chg.detectChanges();
    } catch (err) { }
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }


}
