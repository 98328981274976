
import { empty as observableEmpty, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { msToTimeString, Timer, TimerState } from "../../../template/shared/services/timer/timer";
import { TimerService } from "../../../template/shared/services/timer/timer-service";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { TimerDialogComponent } from "../../../template/shared/components/timer/timer-dialog/timer-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TaskboardSidebarService } from "../../services/taskboardSidebar.service";
import { Router } from "@angular/router";
import { JobFormModel } from "../../models/job-form";
import { TaskReview } from "../../models/task-review";
import { TaskboardDataService } from '../../../taskboard/services/taskboard-data.service';
import { TimersBackend } from '../../../backend/timers.backend';

@Component({
  selector: 'all-timers-list',
  templateUrl: './all-timers-list.component.html',
  styleUrls: ['./all-timers-list.component.scss']
})
export class AllTimersListComponent implements OnInit, OnDestroy {

  @Input() task: TaskReview;
  @ViewChild('datatable') datatable: DatatableComponent;
  @ViewChild('timerControlsTpl', { static: true }) timerControlsTpl: TemplateRef<any>;
  // @Output() editTimerText = new EventEmitter<Timer>();
  columns;

  // _timers$: Observable<Timer[]>;
  timers: any[];
  _refresher: any;

  _timersSub: Subscription;

  // _timerState = TimerState;
  subscriptions = new Array<Subscription>();

  constructor(private timerService: TimerService,
    private chg: ChangeDetectorRef,
    private taskboardSidebar: TaskboardSidebarService,
    private taskboardDataService: TaskboardDataService,
    private timersBackend: TimersBackend,
    private router: Router) { }

  ngOnInit() {
    if (!this.task) {
      console.error('Task not provided!');
    } else {
      this.getTimers();
      this.subscriptions.push(this.timerService.refreshTasksAllTimers.subscribe(() => {
        this.getTimers();
      }));
    }
    this.columns = [
      { name: 'Vykdytojas', minWidth: 140, maxWidth: 200, prop: 'ownerName' },
      { name: 'Pastaba', minWidth: 140, maxWidth: 500, prop: 'title' },
      { name: 'Laikas', minWidth: 100, maxWidth: 100, prop: 'visibleTime' },
      { name: '', minWidth: 100, maxWidth: 100, cellTemplate: this.timerControlsTpl }
    ];
    this.taskboardDataService.jobCreatedFromTimer.subscribe((event) => {
      this.updateTimerJobId(event.timerId, event.jobId);
    });
  }

  getTimers() {
    this.subscriptions.push(this.timersBackend.getAllTimersApi(this.task.id).subscribe(res => {
      res = res.map(t => ({ ...t, visibleTime: msToTimeString(t.allTime), title: t.title ? t.title.toString() : '' })).reverse();
      this.timers = res;
      this.refreshTable();
    }));
  }

  updateTimerJobId(timerId, jobId) {
    const timers = this.timers.find((timer) => timer.id === timerId);
    timers ? timers.jobId = jobId : null;
    this.refreshTable();
  }

  goToJob(timer: Timer) {
    this.router.navigate(['taskboard/j/v', timer.jobId])
  }

  private refreshTable() {
    this.timers = [...this.timers];
    try {
      this.chg.detectChanges();
    } catch (err) { }
  }

  generateJob(timer: Timer) {
    this.taskboardSidebar.openJobForm(JobFormModel.newTimerJob(this.taskboardDataService, timer, this.task));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
