import { Pipe, PipeTransform } from '@angular/core';
// !!!!!!import { number } from "ng2-validation/dist/number";

export type Mode = 'append' | 'prepend'

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(values: any, field: string, dir: string, skip: any, skipFieldName: string): any {
    if (!values || values && values.length <= 1) {
      return values;
    }
    const vals = values.sort((a, b) => {
      if (skip !== undefined && skipFieldName !== undefined) {
        if (a[skipFieldName] === skip || b[skipFieldName] === skip) {
          return 0;
        }
      }
      if (field === undefined) {
        return 0;
      }
      if (dir === 'asc') {
        return a[field].localeCompare(b[field]);
      } else if (dir === 'desc') {
        return b[field].localeCompare(a[field]);
      } else {
        return 0;
      }
    });
    return vals;
  }

}