import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { removeLastNewLineIfNeeded } from '../../../../taskboard/common/functions';
import anchorme from 'anchorme';

@Component({
  selector: 'app-edit-task-description',
  templateUrl: './edit-task-description.component.html',
  styleUrls: ['./edit-task-description.component.scss']
})
export class EditTaskDescriptionComponent implements OnInit {

  protected _editDescriptionForm: UntypedFormGroup;

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': ['Sans', 'monospace'] }],
        [{ 'align': [] }],
        ['clean'],                                         // remove formatting button
      ],
    },
  }

  plainTextDescription: string;
  descriptionWithLinks: string;
  descriptionText = '';

  constructor(
    public dialogRef: MatDialogRef<EditTaskDescriptionComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    private _formBuilder: UntypedFormBuilder,
  ) {
  }

  ngOnInit() {
    this._buildForm();
    this.plainTextDescription = this.data.description;
    this.descriptionWithLinks = this.data.descriptionHTML;
    this.descriptionText = this.data.descriptionHTML !== ' ' ? this.data.descriptionHTML : this.data.description;
  }

  private _buildForm() {
    // this._editDescriptionForm = this._formBuilder.group({
    //   description: [this.data.description, Validators.required],
    // });

  }

  onContentChanged(event) {
    // below commented code has problems with new lines
    this.plainTextDescription = removeLastNewLineIfNeeded(event?.text) || null;
    this.descriptionWithLinks = event && event.html ? anchorme(event.html) : null;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this.dialogRef.close({ textHtml: this.descriptionWithLinks, text: this.plainTextDescription });
  }
}
