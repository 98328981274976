import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TasksBackend } from '../../../backend/tasks.backend';
import { NearestActionFormComponent } from '../modals/nearest-action-component/nearestActionForm.component';
import { SHORT_DATE } from '../../../core/time';
import { Subscription } from 'rxjs';
import { TplService } from '../../../taskboard/services/tpl.service';


@Component({
  selector: 'action-modal',
  templateUrl: './action-modal.component.html',
  styleUrls: ['./action-modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ActionModalComponent implements OnInit, OnDestroy {

  shortDate = SHORT_DATE;

  public _taskId: any;
  public _nearestActions: any;
  public _task: any;
  private _subscriptions: Array<Subscription> = [];

  columns = [
    { name: 'Data', width: 140, prop: 'date', sortable: false, cellTemplate: this.tplService.shortDateTpl },
    { name: 'Pastaba', prop: 'text', sortable: false, },
    { name: '', prop: 'nearestActionButtons', sortable: false, },
  ];

  constructor(
    public dialogRef: MatDialogRef<any>,
    private tasksBackend: TasksBackend,
    public dialog: MatDialog,
    private tplService: TplService,
  ) { }

  ngOnInit() {
    this.getNearestActions();
  }

  getNearestActions() {
    this._subscriptions.push(this.tasksBackend.getNearestActions(this._taskId).subscribe((entities: any) => {
      this._nearestActions = entities;
    }));
  }

  cancel(): void {
    this.dialogRef.close(true);
  }

  newNearestAction(text, date) {

    const modalRef = this.dialog.open(NearestActionFormComponent, { ...{}, data: { task: this._task, date: date, text: text } });
    this._subscriptions.push(modalRef.beforeClosed().subscribe((data) => {
      if (data) {
        this.getNearestActions();
      }
    }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }
}
