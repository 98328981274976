
import { empty as observableEmpty, interval as observableInterval, Observable, ReplaySubject, Subject, BehaviorSubject } from 'rxjs';

import { tap, switchMap, withLatestFrom, filter, combineLatest } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ActivityLogBackend } from "../backend/activityLog.backend";
import { ActivityLogEntry } from "../models/ActivityLogEntry";
import { TemplateService } from "../template/shared/services/template.service";

const START = 'start';
const STOP = 'stop';
const REFRESH_EVERY = 60;
@Injectable()
export class ActivityLogService {

  private _logEntries: Subject<Array<ActivityLogEntry>> = new BehaviorSubject([]);
  public readonly logEntries: Observable<Array<ActivityLogEntry>> = this._logEntries.asObservable();
  private _unseen: Subject<number> = new ReplaySubject(0);
  public readonly unseen = this._unseen.asObservable();

  private isRunning: Subject<boolean> = new BehaviorSubject(false);
  private commands: Subject<string> = new BehaviorSubject('-');
  private isLoading: Subject<boolean> = new BehaviorSubject(false);
  private loadTime: Subject<number> = new BehaviorSubject(0);
  public constructor(private alBackend: ActivityLogBackend, private _templateService: TemplateService) {

    this._logEntries.pipe(combineLatest(this._templateService.activityLogState, (e, s) => ({ entries: e, isOpen: s })))
      .subscribe((o) => {
        let le = this._getLastEntry(o.entries);
        if (!le) {
          this._unseen.next(0);
          return;
        }
        if (o.isOpen) {
          this._storeLastEntry(le);
          this._unseen.next(0);
        } else {
          let sle = this._getLastStored();
          if (!sle) {
            this._unseen.next(o.entries.length);
            return;
          } else if (le.time.getTime() > sle.time.getTime()) {
            this._unseen.next(this._getLaterThen(sle.time.getTime(), o.entries));
          }
        }
      });
    const mc = (cmd: Observable<string>) =>
      (r: Observable<boolean>) =>
        (eq) =>
          (fltr: (a: boolean) => boolean) =>
            cmd.pipe(filter((cmd => cmd == eq)), withLatestFrom(r, (_, r) => r), filter(fltr));

    let makeCommand = mc(this.commands)(this.isRunning);
    let startCmd = makeCommand(START)((running) => running == false);
    let stopCmd = makeCommand(STOP)((running) => running == true);

    startCmd.subscribe((_) => this.isRunning.next(true));
    stopCmd.subscribe((_) => this.isRunning.next(false));

    this.isRunning.pipe(switchMap((r) => r ? observableInterval(1000) : observableEmpty()),
      withLatestFrom(this.isLoading, (_, l) => l),
      filter((l) => l == false),
      withLatestFrom(this.loadTime, (_, lt) => (new Date()).getTime() - lt),
      filter((diff) => diff > (REFRESH_EVERY * 1000)),
      tap((_) => this.isLoading.next(true)),
      // .switchMap((_) => this.alBackend.getActivityLog())                             //Kad krautu activity loga, atkomentuoti!
      switchMap(_ => observableEmpty()),
      tap((res: any) => { this._logEntries.next(res); this.loadTime.next(new Date().getTime()) }))
      .subscribe((_) => this.isLoading.next(false));
  }



  start() {
    this.commands.next(START);
  }

  stop() {
    this.commands.next(STOP);
  }

  private _countNewEntries(ae: Array<ActivityLogEntry>) {
    let le = this._getLastEntry(ae);

  }

  private _getLastEntry(ae: Array<ActivityLogEntry>) {
    let sorted = ae.sort((a1, a2) => a1.time.getTime() > a2.time.getTime() ? -1 : 1);
    return sorted.length > 0 ? sorted[0] : null;
  }

  private _storeLastEntry(e: ActivityLogEntry) {
    localStorage.setItem('LAST_ACTIVITY', JSON.stringify(e));
  }

  private _getLastStored(): ActivityLogEntry {
    const se = localStorage.getItem('LAST_ACTIVITY');
    let parsed;
    try {
      parsed = JSON.parse(se);
    } catch (e) {
    }
    if (!parsed) {
      return null;
    }
    parsed.time = new Date(parsed.time);
    return parsed;
  }

  private _getLaterThen(t: number, ae: Array<ActivityLogEntry>): number {
    return ae.map((ae) => ae.time.getTime()).reduce((acc, curr) => curr > t ? ++acc : acc, 0);
  }
}
