import { Directive, Host, Inject, Input, OnInit, Optional, Self, ViewContainerRef } from "@angular/core";
import { VALUE_PROVIDER } from "../proto";
import { ValueProvider } from "../common/ValueProvider";
import { StorageService } from "./storage.service";
import { StorageOptions } from "./StorageOptions";

export const LOCAL = 'local';
export const SESSION = 'session';
export const UNTIL_BACK = 'back';

@Directive({
  exportAs: 'storable',
  selector: '[storable]',
})
export class StorableDirective implements OnInit {
  private _storable;
  @Input() set storable(val: string) {
    this._storable = val;
  }
  get storable(): string {
    return this._storable;
  }

  @Input() storableDefault: any;

  @Input() storeOnOutput: string;
  @Input() storageOptions: StorageOptions;

  private _value: any;
  public get value(): any {
    return this._value;
  }

  //public store: (val: any, id: string) => void = this._storeLocal;

  constructor(@Inject(VALUE_PROVIDER) @Optional() @Host() @Self() private _valProvider: ValueProvider<any>,
    private _vcRef: ViewContainerRef,
    private _storageService: StorageService) {

  }

  ngOnInit(): void {
    this._value = this._storageService.get(this.storable, this.storableDefault, this.storageOptions);
  }

  public store(val: any) {
    this._storageService.store(this.storable, val, this.storageOptions);
  }

}
