
import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LogsBackend } from '../backend/logs.backend';
import swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';

@Injectable()
export class GlobalErrorHandlerAlt implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  handleError(error: any): void {
    // dont remove console.log, you wont see message in chrome console
    console.log(error);
    const authService = this.injector.get(AuthService);
    const logService = this.injector.get(LogsBackend);
    // dont send logs if error occured when trying to write log or ExpressionChangedAfterItHasBeenCheckedError
    if (error && error.url && (error.url.indexOf('/api/logs') !== -1 || error.url.indexOf('/api/users/tokenCheck') !== -1)) {
      console.log('Error on creating log. Stopped writing logs.');
      return;
    }
    if (error && error.message && error.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') !== -1) {
      console.log('ExpressionChangedAfterItHasBeenCheckedError. Stopped writing logs.');
      return;
    }
    try {
      const logObj = {
        eventId: 'globalError',
        message: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        userId: authService.user ? authService.user.usersId : null,
        token: authService.idToken
      };
      const subs = logService.saveLog(logObj).subscribe(() => {
        subs.unsubscribe();
      }, () => {
        subs.unsubscribe();
      })
    } catch (err) {
      const logObj = {
        eventId: 'globalErrorCatch',
        message: null,
        userId: authService.user ? authService.user.usersId : null,
        token: authService.idToken
      };
      const subs = logService.saveLog(logObj).subscribe(() => {
        subs.unsubscribe();
      }, () => {
        subs.unsubscribe();
      })
    }
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      localStorage.setItem('ecrm.GlobalErrorHandlerAlt', 'error occured');
      const http = this.injector.get(HttpClient);
      const sub = http.post(window.location.origin, {}).subscribe(() => { sub.unsubscribe() });
      swal.fire({
        title: 'Įvyko klaida. Puslapis bus automatiškai perkrautas.',
        showConfirmButton: false,
        showCancelButton: false,
        icon: 'error',
      })
      setTimeout(() => {
        window.location.reload();
      }, 5000)
    }
  }
}
