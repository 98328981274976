import { Component, Input } from "@angular/core";

@Component({
  selector: 'proto-editable-text',
  templateUrl: './editableText.component.html',
  styleUrls: ['./editableText.component.scss'],
})
export class EditableTextComponent<T>
{
  @Input() editable = true;
  @Input() mode: 'edit' | 'show' = 'show';
}
