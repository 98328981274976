import { Time, TimeUnit } from "./types";

export function padUnit(n: TimeUnit): string {
  const num = coalesceNumber(n);
  return "" + (num > 9 ? num : ("0" + num));
}

export function coalesceNumber(n: any): number {
  if (!n) {
    return 0;
  }
  if (typeof n === "number") {
    return n;
  }
  try {
    if (typeof n === "string") {
      return parseInt(n);
    } else if (typeof n === "object") {
      return 0;
    }
  } catch (e) {
    return 0;
  }
}

export function coalesceBoolean(val: any) {
  if (!val) {
    return false;
  }
  if (typeof val === 'string') {
    return !(val.trim() === '0')
  }
  return !!val;
}

export function minutesToTime(t: TimeUnit): Time {
  const m = Math.abs(coalesceNumber(t));
  const res = { hours: Math.floor(m / 60), minutes: m % 60 };
  return res;
}

export function timeToMinutes(t: Time): number {
  if (!t) {
    return 0;
  }
  const h = coalesceNumber(t.hours);
  const m = coalesceNumber(t.minutes);
  return h * 60 + m;
}

export function rangeMins(t1: Time, t2: Time): number {
  if (!t1 || !t2) {
    return 0;
  }
  const t1m = timeToMinutes(t1);
  const t2m = timeToMinutes(t2);
  return t2m - t1m;
}

export function rangeTime(t1: any, t2: any): Time {
  return minutesToTime(rangeMins(parseTime(t1), parseTime(t2)));
}

export function padTime(t: Time): Time {
  if (!t) {
    return { hours: "00", minutes: "00", seconds: "00" };
  }
  return { hours: padUnit(t.hours), minutes: padUnit(t.minutes), seconds: padUnit(t.seconds) };
}

export function parseMinutes(val: any): number {
  if (typeof val == 'string') {
    if (val.match(/\d{1,2}:\d{1,2}/)) {
      const [hours, minutes] = val.split(':');
      return parseInt(hours) * 60 + parseInt(minutes);
    } else {
      return coalesceNumber(val)
    }
  }
  if (typeof val == 'number') {
    return coalesceNumber(val);
  }
  if (typeof val === "object") {
    const props = Object.getOwnPropertyNames(val);
    let h = 0;
    let m = 0;
    if (props.indexOf('hh') > -1) {
      h = coalesceNumber(val['hh']);
    } else if (props.indexOf('hours') > -1) {
      h = coalesceNumber(val['hours']);
    }
    if (props.indexOf('mm') > -1) {
      m = coalesceNumber(val['mm']);
    } else if (props.indexOf('minutes') > -1) {
      m = coalesceNumber(val['minutes']);
    }
    return h * 60 + m;
  }
  return 0;
}

export function parseTime(val: any): Time {
  if (!val) {
    return { hours: 0, minutes: 0 };
  }
  const t = typeof val;
  if (t === "number") {
    return minutesToTime(val);
  } else if (t === "string") {
    if (val.indexOf(':') > -1) {
      const [hours, minutes] = val.split(':');
      return { hours: coalesceNumber(hours), minutes: coalesceNumber(minutes) };
    } else {
      return minutesToTime(val);
    }
  } else if (t === "object") {
    if (val instanceof Date) {
      return { hours: val.getHours(), minutes: val.getMinutes() };
    } else {
      const props = Object.getOwnPropertyNames(val);
      let h = 0;
      let m = 0;
      if (props.indexOf('hours') > -1) {
        h = coalesceNumber(val['hours']);
      }
      if (props.indexOf('minutes') > -1) {
        m = coalesceNumber(val['minutes']);
      }
      return { hours: h, minutes: m };
    }
  }
  return null;
}

export function parseRange(val: any) {
  if (typeof val === 'string') {
    if (val.indexOf('-') > -1) {
      const parts = val.split('-');
      if (parts.length > 1) {
        return [parseTime(parts[0].trim()), parseTime(parts[1].trim())];
      }
    }
  }
}

export function floatToTime(t: string, percentage = true): Time {
  if (!t) {
    return { hours: 0, minutes: 0 }
  }
  let symbol = ',';
  let i = t.indexOf(',');
  if (i < 0) {
    i = t.indexOf('.');
    if (i < 0) {
      symbol = null;
    } else {
      symbol = '.';
    }
  }

  let hours, minutes: number;
  if (i > -1) {
    if (i == 0) {
      hours = 0;
      minutes = coalesceNumber(t.substring(1, t.length));// * 60 / 100;
    } else {
      const [h, m] = t.split(symbol);
      hours = coalesceNumber(h);
      minutes = coalesceNumber(m);// * 60 / 100;
    }
  } else {
    hours = coalesceNumber(t);
  }
  return { hours: hours, minutes: Math.round(percentage ? minutes * 60 / 100 : minutes) };
}

export function minutesToStr(m: number): string {
  if (!m) {
    return "00:00";
  }
  const t = padTime(minutesToTime(m));
  return t.hours + ":" + t.minutes;
}

export function getPrice(time: any, price: number, decimals = 2): number | string {
  if (!time) {
    return 0;
  }
  time = parseTime(time);
  return time ? (((coalesceNumber(time.hours) * 60 + coalesceNumber(time.minutes)) * (coalesceNumber(price) / 60)) || 0).toFixed(decimals) : 0;
}

export function setTimeToDate(date: Date, time: any) {
  if (!time) {
    return new Date();
  }
  const d = new Date(date);
  d.setHours(coalesceNumber(parseTime(time).hours), coalesceNumber(parseTime(time).minutes));
  return d;
}

export function timeToFloat(time: any, decimals = 2) {
  if (!time) {
    return 0;
  }
  time = parseTime(time);
  //Jei traktuojame, kad skaiciai po kablelio yra trupmenine valandos dalis
  const percents = Math.round(coalesceNumber(time.minutes) / 60 * 100);
  return coalesceNumber(time.hours) + '.' + (percents < 10 ? '0' : '') + percents;
}

export function floatToStringTime(f) {
  const t = floatToTime(f + '');
  return padUnit(t.hours) + ':' + padUnit(t.minutes);
}

export function timeToString(t: Time): string {
  return t.hours + ":" + t.minutes;
}

export function isSmallerTime(source: Time, target: Time) {
  if (source.hours > target.hours) {
    return 1;
  }
  if (source.hours < target.hours) {
    return -1;
  }
  if (source.hours === target.hours) {
    if (source.minutes > target.minutes) {
      return 1;
    }
    if (source.minutes < target.minutes) {
      return -1;
    }
    if (source.minutes === target.minutes) {
      return 0;
    }
  }
}

export function subtractTime(sourceP: Time, targetP: Time) {
  // source time should allways be greater then target
  const source: any = { ...sourceP };
  const target: any = { ...targetP };
  const result: Time = { hours: 0, minutes: 0 };
  if (source.minutes < target.minutes) {
    source.hours--;
    source.minutes = source.minutes + 60;
  }
  result.hours = source.hours - target.hours;
  result.minutes = source.minutes - target.minutes;
  return result;
}

export function addTime(sourceP: Time, targetP: Time) {
  const source: any = { ...sourceP };
  const target: any = { ...targetP };
  const result: Time = { hours: 0, minutes: 0 };
  if (source.minutes + target.minutes > 60) {
    source.hours = source.hours++;
    source.minutes = source.minutes - 60;
  }
  result.hours = source.hours + target.hours;
  result.minutes = source.minutes + target.minutes;
  return result;
}
