import { Component, OnInit, Output, EventEmitter, Input, forwardRef } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DashboardService } from '../../../../app/dashboards/dashboard.service';
import { Subscription } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { VALUE_PROVIDER } from '../../../template/shared/proto/proto';

@Component({
  selector: 'date-month',
  templateUrl: './date-month.component.html',
  styleUrls: ['./date-month.component.scss'],
  providers: [
    {
      provide: VALUE_PROVIDER, useExisting: forwardRef(() => DateMonthComponent),
    }
  ]
})
export class DateMonthComponent implements OnInit {

  date: Date;
  maxDate = new Date();

  @Input() type: string;

  subs: Subscription[] = [];

  constructor(
    private dashboardService: DashboardService,
    // private dateAdapter: DateAdapter<Date>
  ) {
    // dateAdapter.setLocale('lt');
  }

  ngOnInit() {
    if (this.type === 'comments') {
      this.subs.push(this.dashboardService.monthSelect$.subscribe({
        next: (month) => {
          this.date = month;
        }
      }))
    }

    if (this.type === 'ratings') {
      this.subs.push(this.dashboardService.monthSelectRating$.subscribe({
        next: (month) => {
          this.date = month;
        }
      }))
    }
  }

  setMonthAndYear(normalizedMonthAndYear: Date, datepicker: MatDatepicker<Date>) {
    this.date = normalizedMonthAndYear;
    if (this.type === 'comments') {
      this.dashboardService.monthSelect$.next(this.date);
    }
    if (this.type === 'ratings') {
      this.dashboardService.monthSelectRating$.next(this.date);
    }
    datepicker.close();
  }


  ngOnDestroy() {
    this.subs.forEach(s => { s.unsubscribe(); s = null; });
  }

}
