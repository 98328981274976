import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterFiles'
})
export class FilterFilesPipe implements PipeTransform {

  transform(items: any[], onlyWithFiles?: boolean): any {
    if (onlyWithFiles) {
      return items && items.filter(item => item.files && item.files.length > 0);
    } else {
      return items;
    }
  }

}
