import { SharedModule } from './../template/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MARKETING_ROUTES, tableTypeProvider } from './marketing.routing.module';
import { TaskboardSharedModule } from '../taskboard/taskboard.shared.module';
import { MarketingComponent } from './marketing.component';
import { MarketingMenuComponent } from './marketing-menu/marketing-menu.component';
import { MarketingCampaignsComponent } from './marketing-campaigns/marketing-campaigns.component';
import { EditMarketingCampaignComponent } from './edit-marketing-campaign/edit-marketing-campaign.component';

@NgModule({
  declarations: [
    MarketingComponent,
    MarketingMenuComponent,
    MarketingCampaignsComponent,
    EditMarketingCampaignComponent,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    tableTypeProvider,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TaskboardSharedModule,
    RouterModule.forChild(MARKETING_ROUTES),
  ]
})
export class MarketingModule { }