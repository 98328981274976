
import { empty as observableEmpty, of as observableOf, timer as observableTimer, BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';

import { map, startWith, switchMap, combineLatest, filter, tap } from 'rxjs/operators';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core"
import { PageLengthDirective } from "../paging/pageLength/pageLength.directive";
import { ProtoFlagsGroupComponent } from "../../flags/flagsGoup.component";
import { ColumnModel, TableModel } from "./TableModel";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { ValueProvider } from "../../common/ValueProvider";
import { VALUE_PROVIDER } from "../../proto";
import { QueryProviderDirective } from "../../common/query/queryProvider.directive";
import { QueryPart } from "../../common/query/queryPart";
import { QueryPartDirective } from '../../common/query/queryPart.directive';

export type ColumnResized = { name: string, newWidth: number };
export type ColumnsReordered = { name: string, newValue: number, oldValue: number };

@Component({
  selector: 'server-table',
  templateUrl: "./serverTable.component.html",
  styleUrls: ['./serverTable.component.scss'],
  providers: [
    {
      provide: VALUE_PROVIDER, useExisting: forwardRef(() => ServerTableComponent),
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServerTableComponent implements OnInit, AfterViewInit, AfterContentInit, OnChanges, OnDestroy, ValueProvider<any> {
  @Input() selectedRows: Array<any> = [];
  @Input() sortingMapper;
  @Input() refreshTable: number;
  @Output() rowClicked: EventEmitter<any> = new EventEmitter();
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  @Output() columnResized: EventEmitter<ColumnResized> = new EventEmitter();
  @Output() columnsReordered: EventEmitter<ColumnsReordered> = new EventEmitter();
  @Output() onSort: EventEmitter<any> = new EventEmitter();
  @ViewChild('table', { static: true }) tableTpl: TemplateRef<any>;
  @ViewChild('header', { static: true }) headerTpl: TemplateRef<any>;
  @ViewChild('customCell', { read: ViewContainerRef, static: true }) customCell: ViewContainerRef;
  @ViewChild('dataTable', { static: false }) tableCmp: DatatableComponent;
  @ViewChild('default', { static: true }) defaultTpl: DatatableComponent;
  @ViewChild('defaultHeader', { static: true }) defaultHeaderTpl: DatatableComponent;
  @ContentChildren(forwardRef(() => PageLengthDirective), { descendants: true }) pageLengthDir: QueryList<PageLengthDirective>;
  @ContentChild(ProtoFlagsGroupComponent, { static: false }) flagsGroup: ProtoFlagsGroupComponent;
  // @ContentChild(QueryProviderDirective, { read: QueryProviderDirective, static: false }) queryProvider: QueryProviderDirective;

  protected _sorts;
  protected _columnsVisible = true;
  protected _visibleColumns: Array<ColumnModel> = [];
  protected _tm: TableModel;
  protected _isLoading: boolean = false;
  private _refreshEvents: Subject<any> = new Subject();
  private _subscriptions: Array<Subscription> = [];
  private _query: any;
  // private _currentPageObs: BehaviorSubject<number> = new BehaviorSubject(1);
  private _currentPageObs: Subject<number> = new ReplaySubject(1);
  private _pagingQp: QueryPart<any>;

  private _sortingQp: QueryPart<any>;
  private _sortingEvents: Observable<any> = new ReplaySubject(1);

  private currentSorts: any;
  private allColumnsOrder: Array<string>;

  constructor(private _chg: ChangeDetectorRef,
    private _viewCnt: ViewContainerRef,
    private queryProvider: QueryProviderDirective,
  ) { }

  private _currentPage: number = 0;

  get currentPage(): number {
    return this._currentPage;
  }

  @Input() set currentPage(val: number) {
    this._currentPage = val;
    this._currentPageObs.next(val);
  }

  get tableModel(): TableModel {
    return this._tm;
  }

  @Input() set tableModel(tm: TableModel) {
    let cols: Array<ColumnModel> = [];
    let invisibles = 0;
    const allColumns = [];
    for (let i = 0; i < tm.columns.length; i++) {
      allColumns.push(tm.columns[i].name);
    }
    let orderWithInvisibles = <Array<string>>JSON.parse(localStorage.getItem(tm.name + '_COL_ORDER') || JSON.stringify(allColumns));
    this.allColumnsOrder = [...orderWithInvisibles];
    for (let i = 0; i < tm.columns.length; i++) {
      const col = tm.columns[i];
      if (col.visible == false) {
        orderWithInvisibles = orderWithInvisibles.filter(item => item !== col.name);
      }
    }
    const order = orderWithInvisibles;
    for (let i = 0; i < tm.columns.length; i++) {
      const col = tm.columns[i];
      if (col.visible == false) {
        invisibles++;
        continue;
      }
      if (col.sorted) {
        this._sorts = [{
          prop: col.prop,
          dir: col.sorted.order
        }];
        this.currentSorts = this._sorts[0];
      }
      const oi = order.indexOf(col.name);
      if (oi > -1 && tm.columns.length > oi) {
        cols[oi] = col;
      } else {
        cols.push(col);
      }

    }
    if (!this._sorts) {
      const sortables = tm.columns.filter(c => c.sortable !== false && c.visible !== false);
      this._sorts = [{
        prop: sortables && sortables[0] ? sortables[0].prop : null,
        dir: 'desc'
      }];
      this.currentSorts = this._sorts[0];
      this.setSort(this._sorts[0].prop, this._sorts[0].dir);
      if (this._sorts && this._sorts[0] && this._sorts[0].prop) {
        this.onSort.emit({ prop: this._sorts[0].prop, order: this._sorts[0].dir });
      }
    }
    cols = cols.filter(c => c != undefined);
    this._tm = tm;
    this._visibleColumns = cols;
  }

  private _limit = 15;

  get limit(): any {
    return this._limit;
  }

  @Input() set limit(val: any) {
    this._limit = val;
  }

  private _count: number = 0;

  get count(): number {
    return this._count;
  }

  @Input() set count(val: number) {
    this._count = val;
  }

  protected _rows: Subject<Array<any>> = new BehaviorSubject([]);

  get rows(): Array<any> {
    return (<BehaviorSubject<Array<any>>>this._rows).value;
  }

  @Input() set rows(val: Array<any>) {
    this._rows.next(val);
  }

  @Input() selectRow: (row: any, column?: any, value?: any) => boolean = (row, column, val) => {
    return true;
  };

  ngOnInit(): void {
    this._sortingQp = new QueryPart<any>('sort', this._sortingEvents, true, true, this.sortingMapper);
    this._pagingQp = new QueryPart<any>('page', this._currentPageObs, true, true);
  }

  ngAfterViewInit(): void {
    if (this._sorts && this._sorts.length > 0) {
      (<Subject<any>>this._sortingEvents).next({
        prop: this._sorts[0].prop,
        order: this._sorts[0].dir
      });
    }
  }

  ngAfterContentInit(): void {
    this._init();
  }

  public refreshData() {
    this._refreshEvents.next(true);
  }

  public setColumnVisibility(col: string, isVisible: boolean) {
    let _vCols: Array<ColumnModel> = [];
    for (let i = 0; i < this._tm.columns.length; i++) {
      if (this._tm.columns[i].name === col) {
        this._tm.columns[i].visible = isVisible;
      }
      if (this._tm.columns[i].visible != false) {
        _vCols.push(this._tm.columns[i]);
      }
    }
    this._visibleColumns = _vCols;
    for (let i = 0; i < this._tm.columns.length; i++) {
      if (this._tm.columns[i].name === col) {
        if (isVisible === false && this.currentSorts.prop === this._tm.columns[i].prop || this.currentSorts.prop === null) {
          const sortables = this._tm.columns.filter(c => c.sortable !== false && c.visible !== false);
          const sort = { prop: sortables && sortables[0] ? sortables[0].prop : null, order: 'desc' };
          this.currentSorts = { prop: sort.prop, dir: sort.order };
          this.setSort(sort.prop, sort.order);
          if (sort && sort.prop) {
            this.onSort.emit(sort);
            this.refreshData();
          }
          break;
        }
      }
    }
    try {
      this._columnsVisible = false;
      this.tableModel = this.tableModel;
      this._chg.detectChanges();
      this._columnsVisible = true;
      this._chg.detectChanges();
    } catch (e) {
    }
  }

  public setSort(prop, order) {
    if (this._tm && this._tm.columns && prop) {
      for (let i = 0; i < this._tm.columns.length; i++) {
        delete this._tm.columns[i].sorted;
      }
      for (let i = 0; i < this._tm.columns.length; i++) {
        if (this._tm.columns[i].prop === prop) {
          this._tm.columns[i].sorted = { order: order };
        }
      }
    }
  }

  public getSortingQueryPart(): QueryPart<any> {
    return this._sortingQp;
  }

  public getPagingQueryPart(): QueryPart<any> {
    return this._pagingQp;
  }

  getValueChanges() {
    return this._currentPageObs.pipe(filter((pg) => pg != null));
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnDestroy() {
    this._unsubscribe();
  }

  public updateRecords(update: (r: any) => any) {
    const rows = this.rows;
    this.rows = rows.map(update);
    try {
      this._chg.detectChanges();
    } catch (err) { }
  }

  public calculateColumns() {
    const vc = this._visibleColumns;
    const table: HTMLElement = <HTMLElement>this._viewCnt.element.nativeElement;
    const rows = table.getElementsByTagName('datatable-body-row');
    const vcWidths = new Array<number>();
    for (let i = 0; i < rows.length; i++) {
      const columns = rows[i].getElementsByTagName('datatable-body-cell');
      for (let c = 0; c < columns.length; c++) {
        if (vc.length > c) {
          const gw = vc[c].getWidth;
          if (typeof gw === "function") {
            const width = gw(<HTMLElement>columns[c]);
            if (width > (vcWidths[c] || 0)) {
              vcWidths[c] = width;
            }
          } else {
            vcWidths[c] = vc[c].width;
          }
        }
      }
    }
    vc.forEach((c, index) => {
      c.width = vcWidths[index];
      this.columnResized.emit({ name: c.name, newWidth: c.width });
    });

    this._columnsVisible = false;
    try {
      this._chg.detectChanges();
    } catch (err) { }
    this._columnsVisible = true;
    try {
      this._chg.detectChanges();
    } catch (err) { }
  }

  _pageChanged($event, dTable: DatatableComponent) {
    this.rows = [];
    this.currentPage = $event.offset;
    this.pageChanged.emit($event.offset);
  }

  _columnResized($event) {
    // console.log($event);
    this.columnResized.emit({
      name: $event.column?.name,
      newWidth: $event.newValue
    });
  }

  _columnsReordered($event) {

    const oldId = $event.prevValue;
    const newId = $event.newValue;
    const vc = this._visibleColumns;
    const oldCol = vc[oldId].name;
    const newCol = vc[newId].name;
    let allColumns = [];
    for (let i = 0; i < this.allColumnsOrder.length; i++) {
      allColumns.push(this.allColumnsOrder[i]);
    }
    const oldColInd = allColumns.indexOf(oldCol);
    const newColInd = allColumns.indexOf(newCol);

    allColumns.splice(oldColInd, 1);
    allColumns = [...allColumns.slice(0, newColInd), oldCol, ...allColumns.slice(newColInd)];

    const rstr = JSON.stringify(allColumns);
    this.allColumnsOrder = [...allColumns];
    localStorage.setItem(this.tableModel.name + '_COL_ORDER', rstr);

    let temp = this._visibleColumns[oldId];
    this._visibleColumns.splice(oldId, 1);
    this._visibleColumns = [...this._visibleColumns.slice(0, newId), temp, ...this._visibleColumns.slice(newId)];
    this.columnsReordered.emit({
      name: $event.column.name,
      newValue: newId,
      oldValue: oldId
    });

  }

  private _init() {
    this._unsubscribe();
    if (this.queryProvider) {
      this._subscriptions.push(
        this.queryProvider.queryChanged.pipe(combineLatest(this._refreshEvents.pipe(startWith(true)), (q, _) => q), switchMap((q) => this.refreshTable ? observableTimer(0, this.refreshTable * 1000).pipe(map((_) => q)) : observableOf(q)),
          switchMap((q) => {
            if (this._tm) {
              this._isLoading = true;
              this._query = q;
              this._detectChanges();
              return this._tm.getData(this._query);
            }
            return observableEmpty();
          })).subscribe((res) => {
            this.rows = (<any>res).data;
            this._count = (<any>res).count;
            this._isLoading = false;
            this._detectChanges();
          }));
    }
    if (this.pageLengthDir) {
      const arr: Array<PageLengthDirective> = this.pageLengthDir.toArray();
      if (arr.length > 0) {
        this._subscriptions.push(arr[0].lengthChanger.lengthChanged.subscribe((l) => {
          this.limit = l > 0 ? l : 9999999;
        }));
      }
    }
  }

  _onSort($event) {
    let ctm: ColumnModel = this._tm.columns.find((ctm) => ctm.name === $event.column.name);
    if (!ctm) {
      return;
    }
    let e = {
      prop: ctm.prop,
      order: $event.newValue
    };
    this.onSort.emit(e);
    (<Subject<any>>this._sortingEvents).next(e);
  }

  private _unsubscribe() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  _rowClicked(row: any) {
    this.rowClicked.emit(row);
  }

  private _activate(e) {
    e.event.stopPropagation();
    e.event.preventDefault();
  }

  private _detectChanges() {
    try {
      this._chg.detectChanges();
    } catch (e) {
    }
    this.refreshHeaderWorkaround();
  }

  private refreshHeaderWorkaround() {
    const x = document.getElementsByClassName('datatable-body');
    if (x && x[0]) {
      x[0].scrollLeft += 1;
      x[0].scrollLeft -= 1;
    }
  }
}
