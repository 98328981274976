
import { take, map, scan, withLatestFrom, filter } from 'rxjs/operators';
import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs'
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Location, LocationChangeEvent } from "@angular/common";
import { CurrentRoute } from "./currentRoute";
import { getSession, storeSession } from "../storage/storage.help";

const toCurrentRoute = (router: Router) => ({ url: router.routerState.snapshot.url, queryParams: router.routerState.root.snapshot.queryParams, first: true, popState: false, afterRefresh: false });
const LAST_ROUTE = '__lastRoute';
type PopStateEvent = { timestamp: number }

export let PREVIOUS_URL = null;
export let CURRENT_URL = null;
@Injectable()
export class RoutingService {
  private readonly ROUTE_BEFORE_REFRESH = getSession(LAST_ROUTE);
  public readonly currentRoute: Observable<CurrentRoute> = new ReplaySubject(1);
  private _currentRouteSnapshot: CurrentRoute;
  public get currentRouteSnapshot(): CurrentRoute {
    return this._currentRouteSnapshot;
  }
  private _popState: boolean;
  private readonly _routeChanged: Observable<Router> = new Subject();

  public get popState() {
    return this._popState;
  }

  private _locationEvents: Observable<PopStateEvent> = new BehaviorSubject(null);
  private _popStates: Observable<boolean>;
  constructor(private _router: Router, private _location: Location) {
    this.currentRoute.subscribe((r) => storeSession(LAST_ROUTE, r.url));
    this._router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((r: NavigationEnd) => {
      PREVIOUS_URL = CURRENT_URL;
      CURRENT_URL = r.url;
    });
    this._setupRouteTracking();
    this._setupPopStates();
    this._setupCurrentRoute();
  }

  private _setupPopStates() {
    //Popstate'ai pushinami i streama
    this._location.subscribe((e: LocationChangeEvent) => {
      if ((<any>e).pop && e.type == 'popstate') {
        (<Subject<PopStateEvent>>this._locationEvents).next({ timestamp: new Date().getTime() });
      }
    })
  }

  private _setupRouteTracking() {
    this._popStates = this._router.events.pipe(filter((e) => e instanceof NavigationStart),
      withLatestFrom(this._locationEvents, (ns, le) => ({ ns: ns, le: le, popState: false })), //Lokacijos eventus kombinina su navigationStart
      scan((acc, curr) => ({ ...curr, le: curr.le, popState: acc.le != curr.le })), //Tikrina ar paskutinis lokacijos eventas sutampa su dabartiniu (NavigationStart atzvilgiu) pagal tai nustatome ar buvo paspausta back/forward
      map((e) => e.popState));
    //Uzpildo pirma routa, tik uzsikrovus puslapiui
    this._router.events.pipe(filter((e) => e instanceof NavigationStart),
      take(1))
      .subscribe((e: NavigationStart) => { let curr = toCurrentRoute(this._router); curr.afterRefresh = curr.url == this.ROUTE_BEFORE_REFRESH; this._currentRouteSnapshot = curr; });
    //Pushina NavigationEnd eventus
    this._router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => { (<Subject<any>>this._routeChanged).next(this._router); });
  }

  //Neegzistuojancio Angular funkcionalumo uzpildymas. Naujoje Angular versijoje sitas jau yra (isskyrus first ir afterRefresh)
  private _setupCurrentRoute() {
    this._routeChanged.pipe(map((_) => toCurrentRoute(this._router)), //Pagamina CurrentRoute objekta
      scan((prev, curr) => ({ ...curr, first: prev == null }), null), //Pazymi ar pirmas routeas
      withLatestFrom(this._popStates, (r, ps) => ({ ...r, popState: ps })), //Pazymi ar popstate
      map((r) => ({ ...r, afterRefresh: (<CurrentRoute>r).first ? this.ROUTE_BEFORE_REFRESH == r.url : false }))) //Pazymi ar po refresho
      .subscribe((r) => {
        this._currentRouteSnapshot = <CurrentRoute>r;
        (<Subject<CurrentRoute>>this.currentRoute).next(<CurrentRoute>r);
      });
  }
}

