
import { of as observableOf, Observable, throwError } from 'rxjs';

import { map, catchError, tap } from 'rxjs/operators';
import { Timer, TimerState } from '../template/shared/services/timer/timer';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiService } from '../core/api/api.service';

@Injectable()
export class TimersBackend {
  constructor(@Inject(HttpClient) private _http: HttpClient, private apiService: ApiService) {

  }

  public createTimer(taskId: number, description: string, userTime: number): Observable<string | number> { //TODO tested
    return this.apiService.postAuth('timers', { taskId: taskId, description: description, userTime: userTime }).pipe(catchError((err) => {
      return throwError(err);
    }),
      map((x: any) => x.body.entity));
  }

  public toggleTimer(timerId: number, userTime: number): Observable<TimerState> { //TODO tested
    return this.apiService.postAuth(`timers/${timerId}/toggle`, { userTime: userTime }).pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }),
      map((x: any) => x.body.entity)
    );
  }

  public tick(time: number): Observable<any> { //TODO tested
    return this.apiService.postAuth('timers/tick', { userTime: time }).pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }),
      map((x: any) => x.body.entity)
    );
  }

  public getTimersApi(userTime): Observable<Array<Timer>> { //TODO tested
    return this.apiService.getAuthNoUnauthRedirect('timers/getAll/' + userTime).pipe(
      catchError((err) => {
        console.log('caught error', err);
        return throwError(err);
      }),
      map((x: any) => x.body.entities)
    );
  }

  public getAllTimersApi(taskId): Observable<Array<any>> {
    return this.apiService.getAuthNoUnauthRedirect('timers/getAllTaskTimers/' + taskId).pipe(
      catchError((err) => {
        console.log('caught error', err);
        return throwError(err);
      }),
      map((x: any) => x.body.entities)
    );
  }

  public stopTimer(timerId, userTime): Observable<boolean> { //TODO tested
    return this.apiService.postAuth(`timers/${timerId}/stop`, { userTime: userTime }).pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }),
      map((x: any) => x.body.entity));
  }

  public editTimerText(timerId, newText: string): Observable<boolean> { //TODO tested
    return this.apiService.postAuth(`timers/${timerId}/editText`, { newText: newText }).pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }), map((x: any) => x.body.entity));
  }


}
