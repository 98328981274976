import { Component, ElementRef, HostBinding, HostListener, Injector, OnInit, ViewContainerRef, ChangeDetectorRef, } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { GlobalState } from "./template/app.state";
import { ConfigService } from "./template/shared/services/config/config.service";
import { SpinnerService } from "./template/shared/services/spinner/spinner.service";
import { ThemesService } from "./template/shared/services/themes/themes.service";
import { UserService } from "./services/user/user.service";
import { setInjector } from "./template/shared/proto/proto";
import { NgxPermissionsService } from "ngx-permissions";
import { User } from "./models/User";
import { AuthService } from './services/auth.service';
import { ActivatedRoute, Router } from "@angular/router";
import { ConfigurationService } from "./configuration.service";
import { ApiService } from "./core/api/api.service";
import { LogsBackend } from "./backend/logs.backend";
import { StorageService } from "./template/shared/proto/storage/storage.service";
import { RoutingService } from "./template/shared/proto/services/routing.service";
import { TokenCheckService } from "./token.check.service";
import Quill from 'quill';
import { Subscription } from "rxjs";

declare var $: any;

const Inline = Quill.import('blots/inline');

class CustomAttributes extends Inline {
  constructor(domNode, value) {
    super(domNode, value);

    const span = this.replaceWith(new Inline(Inline.create()));

    span.children.forEach(child => {
      if (child.attributes) child.attributes.copy(span);
      if (child.unwrap) child.unwrap();
    });

    // here we apply every attribute from <font> tag to span as a style
    Object.keys(domNode.attributes).forEach(function (key) {

      if (domNode.attributes[key].name != "style") {
        var value = domNode.attributes[key].value;
        var name = domNode.attributes[key].name;
        if (name == "face")
          name = "font-family";
        span.format(name, value);
      }
    });

    this.remove();

    return span;
  }
}

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  //App Left Sidebar Menu Open/Close Desktop
  @HostBinding("class.app_sidebar-menu-collapsed")
  get isApp_SidebarLeftCollapsed() {
    return this.config.appLayout.isApp_SidebarLeftCollapsed;
  }

  //Left Menu Sidebar Open/Close Tablet & Mobile
  @HostBinding("class.app_sidebar-left-open")
  get isApp_MobileSidebarLeftOpen() {
    return this.config.appLayout.isApp_MobileSidebarLeftOpen;
  }

  //App Right Sidebar Open/Close
  @HostBinding("class.sidebar-overlay-open")
  get isApp_SidebarRightOpen() {
    return this.config.appLayout.isApp_SidebarRightOpen;
  }

  url: string;
  qpSubs: Subscription;

  constructor(
    private _state: GlobalState,
    public config: ConfigService,
    private viewContainerRef: ViewContainerRef,
    private _spinner: SpinnerService,
    private titleService: Title,
    // private themesService: ThemesService,
    private root: ElementRef,
    private userService: UserService,
    private injector: Injector,
    private authService: AuthService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private configService: ConfigurationService,
    private apiService: ApiService,
    private logsBackend: LogsBackend,
    private storageService: StorageService, //needed to be initialized on app reload
    private routingService: RoutingService, //needed to be initialized on app reload
    private tokenCheckService: TokenCheckService,
    private route: ActivatedRoute,
  ) {
    setInjector(injector);
    this.authService.localstorageLogin$.subscribe(() => {
      this.url = route.snapshot.queryParamMap.get('url');
      setTimeout(() => {
        this.configService.loadPermissions(this.authService, this.permissionsService, this.apiService, this.logsBackend).then(() => {
          this.authService.loggedIn$.next(true);
          this.userService.triggerMenuCheck$.next(true);
          this.tokenCheckService.startTokenChecks();
          setTimeout(() => {
            if (this.url) {
              window.location.href = this.url;
            } else {
              this.router.navigate(['/']);
            }
          }, 0);
          // })

        });
      }, 0);
    })
    this.authService.localstorageLogout$.subscribe(() => {
      setTimeout(() => {
        this.userService.triggerMenuCheck$.next(true);
        this.tokenCheckService.stopTokenChecks();
        const url = window.location.href;
        this.router.navigate(['/login'], { queryParams: { url } });
      }, 0);
    })

  }

  public getPermissions() {
    if (this.authService.isLoggedIn()) {
      // this.permissionsService.loadPermissions(['insert_request']);
      this.userService.getUserPermissions().subscribe((resp) => {
        if (resp.status === 200) {
          const permissions = new Array();
          const respPerm = (<any>resp.body).entities;
          respPerm.forEach(element => {
            permissions.push(element.name);
          });
          this.permissionsService.loadPermissions(permissions);
          this.authService.permissionsLoaded = true;
          this.userService.permissionsLoaded.next(true);
        }
      })
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  //called after the constructor and called  after the first ngOnChanges()
  //ngOnInit() is better place to "start" - it's where/when components' bindings are resolved.
  ngOnInit() {
    $(document).on("click", '[href="#"]', e => e.preventDefault());
    if (this._shouldMenuReset()) {
      this.config.appLayout.isApp_SidebarLeftCollapsed = false;
    } else {
      this.config.appLayout.isApp_SidebarLeftCollapsed = true;
    }
    if (this.authService.isLoggedIn()) {
      this.tokenCheckService.startTokenChecks();
    }

    const FontAttributor = Quill.import('attributors/class/font');
    FontAttributor.whitelist = [
      'Sans',
      'monospace'
    ];
    Quill.register(FontAttributor, true);

    CustomAttributes.blotName = "customAttributes";
    CustomAttributes.tagName = "FONT";

    Quill.register(CustomAttributes, true);

  }

  //check if menu should reset on resize
  @HostListener("window:resize")
  public onWindowResize(): void {
    if (this._shouldMenuReset()) {
      this.config.appLayout.isApp_SidebarLeftCollapsed = false;
    } else {
      this.config.appLayout.isApp_SidebarLeftCollapsed = true;
    }
  }

  private _shouldMenuReset(): boolean {
    return window.innerWidth <= this.config.breakpoint.desktop;
  }

  public ngAfterViewInit(): void {
    this._spinner.hide();
  }

}
