import { contains, ProtoInputComponent } from '../../../shared/proto/components/input/protoInput.component';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SelectedPartnerService } from "../../../../services/partner/selectedPartner.service";
import { LIST, ListsDataService } from "../../../../services/lists/lists.data.service";
import { SuggestionsComponent } from "../../../../components/suggestions/suggestions.component";
import { Router } from '@angular/router';

@Component({
  selector: 'topbar-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class SearchComponent implements OnInit {
  searchStatus = false;
  searchValue: string = '';

  @Input() isPartner = true;
  private selectedPartner;
  protected _filterFn = contains;
  partnersSource: Function;
  suggestionMapper: Function;
  partnersSearchForm: UntypedFormGroup;
  protected _PARTNERS: any;
  // protected _PARTNERS = LIST.PARTNERS;
  // protected _PARTNERS = LIST.EMPLOYEES_ALT;
  @ViewChild(SuggestionsComponent) suggestions: SuggestionsComponent;
  get input(): ProtoInputComponent<any> {
    if (this.suggestions) {
      return this.suggestions.input;
    }
  }
  closeSearch() {

    this.searchValue = null;
    this.input.setText(null);
    setTimeout(_ => {
      this.searchStatus = false;
    });
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private selectedPartnerService: SelectedPartnerService,
    private dataService: ListsDataService,
    private router: Router
  ) {
    this.buildForm();
    this.suggestionMapper = (row) => row && row.name;
  }

  ngOnInit() {
    if (this.isPartner) {
      this._PARTNERS = LIST.PARTNERS;
    } else {
      this._PARTNERS = LIST.EMPLOYEES_ALT;
    }
  }

  ngOnChanges(changes: any) {
    if (changes.isPartner) {
      if (this.isPartner) {
        this._PARTNERS = LIST.PARTNERS;
        this.selectedPartnerService.resetUser();
        this.searchValue = '';
      } else {
        this._PARTNERS = LIST.EMPLOYEES_ALT;
        this.selectedPartnerService.resetPartner();
        this.searchValue = '';
      }
    }
  }

  partnerSelected(event) {
    if (this.isPartner) {
      if (!event) {
        this.selectedPartnerService.resetPartner();
        return;
      }
      this.selectedPartnerService.selectPartner({ id: event.id, name: event.name });
      this.router.navigate(['/taskboard/r'], { queryParams: { p: event.id } });
      setTimeout(_ => {
        this.searchStatus = false;
      })
    } else {
      if (!event) {
        this.selectedPartnerService.resetUser();
        return;
      }
      this.selectedPartnerService.selectUser({ id: event.id, name: event.name });
      this.router.navigate(['/taskboard/r/b'], { queryParams: { u: event.id } });
      setTimeout(_ => {
        this.searchStatus = false;
      })
    }

  }

  listItemChanged(value, list) {
    switch (list) {
      case "partners": this.selectedPartner = value; break;
    }
  }

  deselect() {
    if (this.isPartner) {
      this.selectedPartnerService.resetPartner();
      this.searchValue = '';
    } else {
      this.selectedPartnerService.resetUser();
      this.searchValue = '';
    }
  }

  public setSearchStatus(status: boolean) {
    this.searchStatus = status;
    setTimeout(_ => {
      if (status) {
        this.input.focus();
      }
    });
  }

  buildForm() {
    this.partnersSearchForm = this.formBuilder.group(
      {
        title: '',
        text: ''
      });
  }
}
