import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../template/shared/shared.module';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgotPassword/forgot-password.component';
import { ResetPasswordComponent } from './resetPassword/reset-password.component';
import { ChangeInitialPasswordComponent } from './changeInitialPassword/change-initial-password.component';

const LOGIN_ROUTE: Routes = [
  { path: '', pathMatch: 'full', component: LoginComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'resetPassword/:token', component: ResetPasswordComponent },
  { path: 'changeInitialPassword', component: ChangeInitialPasswordComponent },
];

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangeInitialPasswordComponent
  ],
  imports: [
    RouterModule.forChild(LOGIN_ROUTE),
    SharedModule,
  ],
  providers: [
  ],
  exports: [
  ]

})

export class AuthModule { }
