import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../core/api/api.service';
import swal from 'sweetalert2';
import { UserService } from '../../services/user/user.service';
import { TasksBackend } from '../../backend/tasks.backend';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RoutingService } from '../../template/shared/proto/services/routing.service';
import { ConfigurationService } from '../../configuration.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { LogsBackend } from '../../backend/logs.backend';
import { REQUESTS, REVIEW, TASKS } from '../../taskboard/taskboard.help';
import { SelectedPartnerService } from '../../services/partner/selectedPartner.service';
import { DashboardService } from '../../dashboards/dashboard.service';
import { TokenCheckService } from '../../token.check.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  username: string;
  password: string;
  _subscriptions: Subscription[] = [];
  isRequest = null;
  taskId = null;
  redirectUrl = '';

  hide = true;

  // RoutingService dependency is needed beacuse it gets created only if it is injected in component, and its observables are needed after redirection to /taskboard/t/v/taskId
  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private userService: UserService,
    private tasksBackend: TasksBackend,
    private router: Router,
    private _route: ActivatedRoute,
    private routingService: RoutingService,
    private configService: ConfigurationService,
    private permissionsService: NgxPermissionsService,
    private logsBackend: LogsBackend,
    private selectPartnerService: SelectedPartnerService,
    private tokenCheckService: TokenCheckService,
  ) { }

  ngOnInit() {
    this.selectPartnerService.resetPartner();
    this.selectPartnerService.resetUser();
    this._subscriptions.push(this._route.queryParams.subscribe(params => {
      if (params['taskId']) {
        this.isRequest = params['isRequest'];
        this.taskId = params['taskId'];
        this.redirectUrl = '/taskboard/' + ((this.isRequest == '1' ? REQUESTS : TASKS) + '/' + REVIEW + '/') + this.taskId;
        if (this.authService.isLoggedIn()) {
          this.router.navigateByUrl(this.redirectUrl);
        }
      } else {
        // else block not confirmed if logged in then redirect
        if (this.authService.isLoggedIn()) {
          this.router.navigateByUrl('/');
        }
      }
    }))
  }

  afterLogin(resp) {
    this.authService.setIdToken(resp.body && resp.body.token);
    this.authService.setUser(resp.body);
    this.tokenCheckService.startTokenChecks();
    this.configService.loadPermissions(this.authService, this.permissionsService, this.apiService, this.logsBackend).then(() => {
      this.authService.loggedIn$.next(true);
      this.authService.justLoggedIn = true;
      if (this.taskId) {
        setTimeout(() => {
          this.router.navigateByUrl(this.redirectUrl);
        }, 0);
      } else {
        setTimeout(() => {
          this.router.navigateByUrl(this.authService.user && this.authService.user.parameter || '/');
        }, 0);
      }
    });
  }

  login() {
    this.userService.login(this.username, this.password).subscribe((resp: any) => {
      if (resp.status === 200) {
        this.afterLogin(resp)
      }
    }, (resp) => {
      if (resp.status === 400) {
        swal.fire({
          title: 'Vartotojo vardas arba slaptažodis neteisingi',
          timer: 2000,
          showConfirmButton: false,
          icon: 'error'
        });
      } else {
        swal.fire({
          title: 'Įvyko klaida',
          timer: 2000,
          showConfirmButton: false,
          icon: 'error'
        });
      }
    }
    );

  }
  ngOnDestroy() {
    this._subscriptions.forEach((subs) => {
      subs.unsubscribe();
    });
  }

}
