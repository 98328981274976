
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ApiService } from "./../core/api/api.service";
// import { AuthService } from '../services/auth.service';
// import { HttpClient } from '@angular/common/http';


@Injectable()
export class DpaBackend {
  constructor(private _api: ApiService) {

  }

  reopenDpa(dpaId): Observable<boolean> {
    return this._api.postAuth(`dpa/${dpaId}/reopen`, {}).pipe(map((res: any) => res.body));
  }

  sendDpa(dpaObj) {
    return this._api.postDpaService('send', dpaObj).pipe(map((res: any) => res.body));
  }

  public getDpaInfoForEditing(dpaId) {
    return this._api.getAuth(`dpa/${dpaId}/getDpaForEditing`).pipe(map((res: any) => res.body.entity));
  }

  public getDpaHistory(dpaId) {
    return this._api.getAuth(`dpa/${dpaId}/history`).pipe(map((res: any) => res.body.entities));
  }

  public getDpaNotSentJobs(dpaId) {
    return this._api.getAuth(`dpa/${dpaId}/notSentWorks`).pipe(map((res: any) => res.body.entities));
  }

  public getDpaList(params) {
    const pars: any = {};
    pars.dateTo = params.to;
    pars.dateFrom = params.from;
    pars.startFrom = params.startFrom;
    pars.pageLength = params.recordsPerPage;
    pars.orderBy = params.orderBy;
    pars.orderDir = params.orderDir;
    if (params.exceededTime) {
      pars.exceededTime = 1;
    }
    if (params.dpaType) {
      pars.dpaType = params.dpaType;
    }
    if (params.groupId !== undefined && params.groupId !== null) {
      pars.groupId = params.groupId;
    }
    if (params.dpaGroupId !== undefined && params.dpaGroupId !== null && params.dpaGroupId !== '') {
      if (params.dpaGroupId == '0') {
        pars.groupId = 0;
      } else {
        pars.groupId = 1;
        pars.groupIdStr = params.dpaGroupId;
      }
    }
    params.search ? pars.filter = params.search : null;
    params.paid !== undefined ? pars.paid = params.paid : null;
    params.stateFilter !== undefined ? pars.stateFilter = params.stateFilter : null;
    params.partnerId !== undefined ? pars.partnerId = params.partnerId : null;
    params.userId !== undefined ? pars.userId = params.userId : null;
    // console.log('get dpa pars', pars, params);
    return this._api.getAuth(`dpa/list`, pars).pipe(map((res: any) => res.body));
  }

  public updateDpaInfo(dpaId, updateObj) {
    return this._api.postAuth(`dpa/${dpaId}`, updateObj).pipe(map((res: any) => res.body.entity));
  }

  public updateDpaJob(dpaId, updateObj) {
    const updObj = dpaId ? { ...updateObj, dpaId } : updateObj
    return this._api.postAuth(`dpa/addJob`, updObj).pipe(map((res: any) => res.body));
  }

  public finishDpa(dpaId) {
    return this._api.postAuth(`dpa/${dpaId}/finish`, {}).pipe(map((res: any) => res.body));
  }
}
