import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'partner-form',
  templateUrl: './partner-form.component.html',
  styleUrls: ['./partner-form.component.scss']
})
export class PartnerFormComponent implements OnInit {

  newPartnerForm: UntypedFormGroup;
  isInserting = false;

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.buildForm();
  }

  insertPartner(f) {
    this.isInserting = true;
  }

  buildForm() {
    this.newPartnerForm = this.formBuilder.group({
      title: ['', Validators.required],
      companyCode: ['', Validators.required],
      vatCode: ['', Validators.required],
      email: ['', Validators.required],
      town: ['', Validators.required],
      address: ['', Validators.required],
      fax: ['', Validators.required],
      webSite: ['', Validators.required],
      mobilePhone: ['', Validators.required],
      phone1: ['', Validators.required],
      phone2: ['', Validators.required],
      phone3: ['', Validators.required],
    });
  }
}
