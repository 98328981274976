
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ApiService } from "../core/api/api.service";

@Injectable()
export class PersonsBackend {
  constructor(private _api: ApiService) {
  }

  getEmployees() {
    return this._api.getAuth('persons/employees').pipe(map((res: any) => res.body.entities)); //TODO nr2 tested
  }

  public getPartnerProductPerson(productId, partnerId) {
    return <any>this._api.getAuth(`persons/partner/${partnerId}/product/${productId}`).pipe(map((res: any) => res.body.entity)); //TODO nr4 tested
  }
}
