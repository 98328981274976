import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  _userInfo = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) private userInfo: any,
    public dialogRef: MatDialogRef<DeleteModalComponent>
  ) { }

  ngOnInit() {
    this._userInfo = this.userInfo;
  }

  delete() {
  }

  close(): void {
    this.dialogRef.close();
  }



}
