import { Component, Input } from '@angular/core';
import { downloadFile, getFilesDropdownTitle, mapFileForView } from '../../../functions/utils/files';

@Component({
  selector: 'files-dropdown',
  templateUrl: './filesDropdown.component.html',
  styleUrls: ['./filesDropdown.component.scss'],
})
export class FilesDropdownComponent {
  @Input() files: Array<any> = [];

  _loadTitle = getFilesDropdownTitle;
  _fileMapper = mapFileForView;

  constructor() {
  }

  _fileClicked(file) {
    downloadFile(file);
  }
}
