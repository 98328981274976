import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: '.footer_wrapper',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FooterComponent implements OnInit {


  ngOnInit() {

  }

}
