import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitBy'
})
export class SplitByPipe implements PipeTransform {

  transform(value: any, delimiter?: any, index = 0, trim = true): any {
    if (value && typeof value === 'string' && delimiter && value.indexOf(delimiter) > -1) {
      const parts = value.split(delimiter);
      let res: string;
      if (parts.length - 1 >= index) {
        res = parts[index];
      } else {
        res = parts.pop();
      }
      return trim ? res.trim() : res;
    }
    return value;
  }

}
