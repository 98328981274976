
import { empty as observableEmpty, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Timer, TimerState } from "../../../template/shared/services/timer/timer";
import { TimerService } from "../../../template/shared/services/timer/timer-service";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { TimerDialogComponent } from "../../../template/shared/components/timer/timer-dialog/timer-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TaskboardSidebarService } from "../../services/taskboardSidebar.service";
import { Router } from "@angular/router";
import { JobFormModel } from "../../models/job-form";
import { TaskReview } from "../../models/task-review";
import { TaskboardDataService } from '../../../taskboard/services/taskboard-data.service';

@Component({
  selector: 'timers-list',
  templateUrl: './timers-list.component.html',
  styleUrls: ['./timers-list.component.scss']
})
export class TimersListComponent implements OnInit, OnDestroy {

  @Input() task: TaskReview;
  @ViewChild('datatable') _datatable: DatatableComponent;
  @ViewChild('timerControlsTpl', { static: true }) _timerControlsTpl: TemplateRef<any>;
  @Output() editTimerText = new EventEmitter<Timer>();
  _columns;

  _timers$: Observable<Timer[]>;
  _timers: Timer[];
  _refresher: any;

  _timersSub: Subscription;

  _timerState = TimerState;
  _subscriptions = new Array<Subscription>();

  constructor(private _timerService: TimerService,
    private _chg: ChangeDetectorRef,
    private _dialog: MatDialog,
    private _taskboardSidebar: TaskboardSidebarService,
    private _taskboardDataService: TaskboardDataService,
    private _router: Router) { }

  ngOnInit() {
    if (!this.task) {
      console.error('Task not provided!');
    } else {
      this._timersSub = this._timerService.getTaskTimers$(this.task.id).subscribe(
        timers => {
          this._timers = timers ? timers.reverse() : timers;
          const hasRunning = !!timers.find(t => t.state == TimerState.RUNNING);
          if (!hasRunning) {
            clearTimeout(this._refresher);
            this._refresher = undefined;
          } else if (!this._refresher) {
            this._refresher = setInterval(_ => {
              this._refreshTable();
            }, 100)
          }
        }
      );
    }
    this._columns = [
      { name: 'Pastaba', minWidth: 100, maxWidth: 500, prop: 'title' },
      { name: 'Laikas', minWidth: 100, maxWidth: 100, prop: 'visibleTime' },
      { name: '', minWidth: 100, maxWidth: 100, cellTemplate: this._timerControlsTpl }
    ];
    this._taskboardDataService.jobCreatedFromTimer.subscribe((event) => {
      this._updateTimerJobId(event.timerId, event.jobId);
    });
  }

  _updateTimerJobId(timerId, jobId) {
    const timer = this._timers.find((timer) => timer.id === timerId);
    timer ? timer.jobId = jobId : null;
    this._refreshTable();
  }

  _toggleTimer(timer: Timer) {
    this._timerService.toggleTimer(timer.id);
    this._refreshTable();
  }

  _goToJob(timer: Timer) {
    this._router.navigate(['taskboard/j/v', timer.jobId])
  }

  _stopTimer(timer: Timer) {
    this._timerService.stopTimer(timer.id);
  }

  private _refreshTable() {
    this._timers = [...this._timers];
    try {
      this._chg.detectChanges();
    } catch (err) { }
  }

  _editTimerText(timer: Timer) {
    this._subscriptions.push(
      this._dialog.open(TimerDialogComponent, { data: { text: timer.title }, minWidth: 600 }).afterClosed().pipe(switchMap(res => {
        if (res) {
          return this._timerService.editTimerText(timer, res);
        } else {
          return observableEmpty();
        }
      })
      ).subscribe(res => this._refreshTable())
    );
  }

  _generateJob(timer: Timer) {
    this._taskboardSidebar.openJobForm(JobFormModel.newTimerJob(this._taskboardDataService, timer, this.task));
  }

  ngOnDestroy() {
    if (this._timersSub) {
      this._timersSub.unsubscribe();
    }
    clearTimeout(this._refresher);
    this._subscriptions.forEach(s => s.unsubscribe());
  }

}
