import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export const CANCELLED = -1;

@Component({
  selector: 'app-timer-dialog',
  templateUrl: './timer-dialog.component.html',
  styleUrls: ['./timer-dialog.component.scss']
})
export class TimerDialogComponent implements OnInit {

  _text: string;

  constructor(@Inject(MAT_DIALOG_DATA) protected _data,
    protected _dialogRef: MatDialogRef<TimerDialogComponent>) { }

  ngOnInit() {
    this._text = this._data.text;
  }

  _commit() {
    this._dialogRef.close(this._text);
  }

  _cancel() {
    this._dialogRef.close(CANCELLED);
  }
}
