
import { Observable, of, throwError } from 'rxjs';

import { catchError, delay, map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { isActiveState } from "../taskboard/common/functions";
import { PartnersBackend } from "./partners.backend";
import {
  TaskComment,
  TaskReview,
  TaskStateChange,
} from "../taskboard/models/task-review";
import { ApiService } from "../core/api/api.service";
import { EntityUpdated } from "../core/api/response/entity-updated";
import { EntityCreated } from "../core/api/response/entity-created";
import { getStateBitMask } from '../core/helpers';

export const ERR_UNFINISHED_CHILDS = 'ERR_UNFINISHED_CHILDS';

export interface TransferTask {
  taskId: number;
  newExecutorId: number;
  comment: string;
}

export interface CommentTask {
  taskId: number;
  text: string;
  changeState: boolean;
  textHtml?: string;
  filesKey?: string;
  isInternalComment?: boolean;
}

export interface UpdateTaskState {
  taskId: number;
  newStateId: number;
  comment: string;
  isPublic: boolean;
  dateType: number;
}

export interface TaskStateChanged {
  change: TaskStateChange;
  stateId: number;
  state: string;
  stateColor: string;
}

export interface ChangeTaskPriority {
  taskId: number;
  priorityId: number;
}

export interface TaskPriorityChanged {
  priorityId: number;
  priority: string;
}

export interface TaskProductChanged {
  productId: number;
  product: string;
}

export interface AddNearestAction {
  taskId: number;
  date: Date;
  text: string;
}

export interface TaskCommented {
  stateId: number;
  state: string;
  comment: TaskComment;
}

export interface TaskTaken {
  executor: string;
  userTypeInTask: number;
  person: string;
  transfers: any;
}

@Injectable()
export class TasksBackend {
  constructor(
    private _api: ApiService,
    private partnersBackend: PartnersBackend) {

  }

  private paramsFromObject(params): HttpParams {
    if (params) {
      let httpParams = new HttpParams();
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.append(key, params[key]);
      });
      return httpParams;
    } else {
      return undefined
    }
  }

  public getTaskDetails(id: number, showProjectInfo: boolean): Observable<TaskReview> {
    if (!id) {
      throw new Error('Provide task id to get task details');
    }
    const queryParams: any = {};
    if (showProjectInfo) queryParams.showProjectInfo = 1;
    queryParams.showCampaignInfo = 1;
    return this._api.getAuth(`tasks/${id}`, queryParams).pipe(
      map(res => new TaskReview(res.body)),
      catchError(e => throwError(e)));
  }

  public getProjectDpa(taskId) {
    return this._api.getAuth(`projects/task/${taskId}/dpalist`).pipe(
      catchError((err) => {
        console.log('caught error', err);
        return throwError(err);
      }),
      map((r: any) => r.body.entities));
  }



  public insertTask(taskData): Observable<number> {
    return this._api.postAuth('tasks', taskData).pipe(
      map((res: any) => res.body && res.body.entity ? res.body.entity : -1));
  }

  public takeTask(taskId) {
    return <any>this._api.postAuth(`tasks/${taskId}/execute/`, null).pipe(map((x) => x.body));
  }

  public changeTaskState(params: UpdateTaskState) {
    return <any>this._api.postAuth('tasks/state', params).pipe(map((x) => x.body));
  }

  public commentTask(commentTask: CommentTask): Observable<EntityCreated<TaskCommented>> {
    return <any>this._api.postAuth('tasks/comment', commentTask).pipe(map((x: any) => x.body));
  }

  public getNearestActionsHistory(id) { // not used method nr4
    return this._api.getAuth('getActionsHistory', { id: id }).pipe(map((r: any) => r && r.result && r.result.length > 0 ? r.result[0] : []));
  }

  public getFilesList(taskId) {
    return this._api.getAuth(`tasks/files/${taskId}`).pipe(map((r: any) => r?.body?.entities));
  }

  public editOwnersText(taskId: number, text: string, textHTML: string): Observable<EntityUpdated<string>> {
    return <any>this._api.postAuth('tasks/editOwnersText', { taskId, text, textHTML }).pipe(map((x) => x.body));
  }

  public getTaskPersonsByTask(taskId: any): Observable<any> {
    return this.partnersBackend.getCompanyEmployeesByTask(taskId);
  }

  public getRequestPersons(params?: { taskId?: any, partnerId?: any }) {
    return this.partnersBackend.getPartnerDelegates(params);
  }

  public getRequestPersonsByTask(taskId: any) {
    return this.partnersBackend.getPartnerDelegatesByTask(taskId);
  }

  public getRequestPersonsByPartner(partnerId: any) {
    return this.partnersBackend.getPartnerDelegatesByPartner(partnerId);
  }

  public getRequestProductsByPartner(partnerId: any) {
    return this.partnersBackend.getRequestProductsByPartner(partnerId);
  }

  public changeTaskPerson(taskId: any, updateObj: any): Observable<any> {
    return this._api.postAuth(`tasks/${taskId}/taskPerson`, updateObj);
  }

  public markTaskAsUnseen(taskId: any): Observable<any> {
    return this._api.postAuth(`tasks/mark/${taskId}`, {});
  }

  public getTaskState(id) { //TODO not used method nr2
    return this._api.getAuth('tasks/' + id + '/state').pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }));
  }

  public updateTaskPriority(id, changeTaskPriority: any) {
    return this._api.postAuth(`tasks/${id}/updateProperty`, { changeTaskProperties: [changeTaskPriority] }).pipe(map((x) => x.body));
  }

  public updateTaskDeadline(id, deadlineChange) {
    return this._api.postAuth(`tasks/${id}/updateProperty`, { changeTaskProperties: [deadlineChange] }).pipe(map((x) => x.body));
  }

  public updateTaskService(id, serviceChange) {
    return this._api.postAuth(`tasks/${id}/updateProperty`, { changeTaskProperties: [serviceChange] }).pipe(map((x) => x.body));
  }

  // public updateTaskType(id, typeId, text, propertyName = 'typeId') {
  public updateTaskType(id, updateArr) {
    // const updObj = { text };
    // updObj[propertyName] = typeId;
    return this._api.postAuth(`tasks/${id}/updateProperty`, { changeTaskProperties: updateArr }).pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }));
  }

  public updateTaskSalesFields(id, updObj) {
    return this._api.postAuth(`tasks/${id}/updateProperty`, { changeTaskProperties: [updObj] }).pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }));
  }

  public updateStartDateType(id, dateStart) {
    return this._api.postAuth(`tasks/${id}/updateProperty`, { changeTaskProperties: [{ dateStart }] }).pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }));
  }

  public updatePartner(id, partnerId) {
    return this._api.postAuth(`tasks/${id}/updateProperty`, { changeTaskProperties: [{ partnerId }] }).pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }));
  }

  public updateTaskProduct(id, productId, RecountDeadline) {
    return this._api.postAuth(`tasks/${id}/updateProperty`, { changeTaskProperties: [{ productId, RecountDeadline }] }).pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }));
  }

  public updateTaskDelegate(id, delegateId, updateTaskDelegate) {
    return this._api.postAuth(`tasks/${id}/updateProperty`, { changeTaskProperties: [{ delegateId, updateTaskDelegate }] }).pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }));
  }

  public insertClientRequest(requestData): Observable<number> {
    return this._api.postAuth('tasks/request', requestData).pipe(
      map((res: any) => res.body && res.body.entity ? res.body.entity : -1));
  }

  public getTasksList(type: any, groupId: any, params?: any): Observable<any> {
    const pars: any = {};
    pars.type = type;
    pars.dateTo = params.to;
    pars.dateFrom = params.from;
    pars.startFrom = params.startFrom;
    pars.pageLength = params.recordsPerPage;
    pars.orderBy = params.orderBy;
    pars.orderDir = params.orderDir;
    params.search ? pars.filter = params.search : null;
    // pars.taskTypeId = params.taskTypeId;
    pars.taskTypeStr = params.taskTypeStr;
    pars.statesBitmask = getStateBitMask(params);
    if (params.streams) {
      pars.streamStr = params.streams;
    }
    if (params.onlyDelegate && params.onlyWatcher) {
      pars.delegateWatcher = 0;
    } else if (params.onlyDelegate) {
      pars.delegateWatcher = 1;
    } else if (params.onlyWatcher) {
      pars.delegateWatcher = 2;
    }
    if (type == 4 || type == 2) {
      pars.groupId = params.groupId ? params.groupId : 0;
    }
    if (groupId) {
      pars.groupId = groupId;
    }
    if (params.dpaGroupId !== undefined && params.dpaGroupId !== null && params.dpaGroupId !== '') {
      if (params.dpaGroupId == '0') {
        pars.groupId = 0;
      } else {
        pars.groupId = 1;
        pars.groupIdStr = params.dpaGroupId;
      }
    }
    params.partnerId !== undefined ? pars.partnerId = params.partnerId : null;
    params.userId !== undefined ? pars.userId = params.userId : null;
    return this._api.getAuth('tasks/list', pars).pipe(
      catchError((err) => {
        console.log('caught error', err);
        return throwError(err);
      }),
      map((x) => x.body)
    );
  }

  public getTasksListTransfers(isRequest: any, params?: any): Observable<any> {
    const pars: any = {};
    pars.dateTo = params.to;
    pars.dateFrom = params.from;
    pars.startFrom = params.startFrom;
    pars.pageLength = params.recordsPerPage;
    pars.orderBy = params.orderBy;
    pars.orderDir = params.orderDir;
    params.search ? pars.filter = params.search : null;
    // pars.taskTypeId = params.taskTypeId;
    pars.taskTypeStr = params.taskTypeStr;
    pars.statesBitmask = getStateBitMask(params);
    pars.isRequest = isRequest;
    return this._api.getAuth('tasks/list/transfers', pars).pipe(
      catchError((err) => {
        console.log('caught error', err);
        return throwError(err);
      }),
      map((tasks) => tasks.body));
  }

  // public getRelevantTasks(): Observable<any> {
  //   return this._api.getAuth('tasks/relevantTasks').pipe(
  //     catchError((err) => {
  //       console.log('caught error', err);
  //       return throwError(err);
  //     }),
  //     map((res: any) => res.body.entity));
  // }

  public getRelevantTasks(myGroup): Observable<any> {
    return this._api.getAuth('tasks/relevantTasks?group=' + (myGroup ? '1' : '0')).pipe(
      catchError((err) => {
        console.log('caught error', err);
        return throwError(err);
      }),
      map((res: any) => res.body.entities));
  }

  public getTasksTree(taksId): Observable<any> {
    return this._api.getAuth(`tasks/${taksId}/tree`).pipe(
      catchError((err) => {
        console.log('caught error', err);
        return throwError(err);
      }),
      map((x: any) => x.body.entities)
    );
  }

  public distributeTask(taskId, data): Observable<any> {
    return this._api.postAuth('tasks/distribute/' + taskId, data).pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }));
  }

  public transferTask(taskId, transferTask) {
    return <any>this._api.postAuth(`tasks/${taskId}/transfer`, transferTask).pipe(
      catchError((err) => {
        console.log('caught error', err);
        return throwError(err);
      }),
      map((res) => res.body));
  }

  public addNearestAction(taskId, addNearestAction) {
    return <any>this._api.postAuth(`tasks/${taskId}/nearestAction`, addNearestAction).pipe(
      catchError((err) => {
        console.log('caught error', err);
        return throwError(err);
      }),
      map((res) => res.body));
  }

  public getNearestActions(taskId) {
    return <any>this._api.getAuth(`tasks/${taskId}/nearestAction`).pipe(map((res: any) => res.body.entities));
  }

  public getTaskStatesForChanging(taskId) {
    return <any>this._api.getAuth(`tasks/${taskId}/taskStatesForChanging`).pipe(map((res: any) => res.body.entities));
  }

  public getPartnerProductTime(partnerId, productId) {
    return <any>this._api.getAuth(`partners/${partnerId}/products/${productId}/productTime`).pipe(map((res: any) => res.body.entity));
  }

  public setFavorite(taskId) {
    return <any>this._api.postAuth(`tasks/favorite/${taskId}`, {}).pipe(map((res: any) => res.body.entity));
  }

  public setUnFavorite(taskId) {
    return <any>this._api.postAuth(`tasks/unFavorite/${taskId}`, {}).pipe(map((res: any) => res.body.entity));
  }

  public setFavoriteCampaign(campignsId) {
    return <any>this._api.postAuth(`tasks/campaign/${campignsId}/favorite`, {}).pipe(map((res: any) => res.body.entity));
  }

  public setUnFavoriteCampaign(campignsId) {
    return <any>this._api.deleteAuth(`tasks/campaign/${campignsId}/unFavorite`).pipe(map((res: any) => res.body.entity));
  }

  public getStreamsList() {
    return <any>this._api.getAuth(`projects/types/5`).pipe(map((res: any) => res.body.entities));
  }

}
