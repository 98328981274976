
import { Subject, Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { map, tap } from 'rxjs/operators';
import { PartnersBackend } from '../../backend/partners.backend';
import swal from 'sweetalert2';

@Component({
  selector: 'partner-note',
  templateUrl: './partnerNote.component.html',
  styleUrls: ['./partnerNote.component.scss']
})
export class PartnerNoteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() partnerId;
  protected _partnerNote: Subject<any> = new Subject();
  protected partnerNote: any;
  // protected _partnerDelegates: Subject<any> = new Subject();
  private _subscriptions: Array<Subscription> = [];
  protected isLoading = true;

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': ['Sans', 'monospace'] }],
        [{ 'align': [] }],
        ['clean'],                                         // remove formatting button
      ],
    },
  }

  constructor(private partnersBackend: PartnersBackend, private chg: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.partnerId && changes.partnerId.currentValue > 0 && changes.partnerId.currentValue != changes.partnerId.previousValue) {
      this._loadData(this.partnerId)
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  private _loadData(partnerId: any) {
    this._subscriptions.push(
      this.partnersBackend.getPartnerNote(partnerId).pipe( //TODO nr3 tested
        map((res: any) => res.entity ? res.entity : null)
        , tap((res) => { this._partnerNote.next(res); this.partnerNote = res; })
        // this.partnersBackend.getAllPartnerDelegates(this.partnerId).pipe( //TODO nr3 tested
        //   map((res: any) => res ? res : [])
        //   , tap((res) => this._partnerDelegates.next(res))
        // )
      ).subscribe(() => {
        this.isLoading = false
        try {
          this.chg.detectChanges();
        } catch (e) { }
      }));
  }

  saveClick() {
    this._subscriptions.push(this.partnersBackend.updatePartnerNote({ partnerId: this.partnerId, notes: this.partnerNote }).subscribe(() => {
      this._loadData(this.partnerId);
      swal.fire({
        title: 'Pastaba išsaugota',
        allowOutsideClick: false,
        timer: 1500,
        icon: 'success',
        showConfirmButton: false
      });
    }));
  }
}
