
import { filter } from 'rxjs/operators';
import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { StateService } from "../../services/state/stateService";
import { MatSidenav } from "@angular/material/sidenav";
import { JobFormModel } from "../models/job-form";

export const JOB_PARAMS = new InjectionToken<any>('JOB_PARAMS');
export let jobParams: JobFormModel;

export const getJobParams: () => JobFormModel = () => jobParams;

@Injectable({
  providedIn: 'root'
})
export class TaskboardSidebarService {
  public value: boolean = false;
  public isOpen: Observable<boolean> = new BehaviorSubject(this.value);
  private _toggles: Observable<any> = new BehaviorSubject(null);
  private _opens: Observable<any> = new BehaviorSubject(null);
  public opens: Observable<any> = this._opens.pipe(filter((t) => t != null));
  public toggles = this._toggles.pipe(filter((t) => t != null));
  private sidenav: MatSidenav;
  private _jobFormVisible: Subject<boolean> = new BehaviorSubject(false);
  public jobFormVisible = this._jobFormVisible.asObservable();
  private onCloseSubscribtion: Subscription;
  public jobData: Subject<any> = new ReplaySubject(1);

  constructor(private state: StateService) {
  }

  public registerSideBar(sidenav: MatSidenav) {
    this.sidenav = sidenav;
    this.onCloseSubscribtion = this.sidenav.closedStart.subscribe(() => {
    });
  }

  public unregisterSideBar() {
    this.sidenav = null;
    this.onCloseSubscribtion.unsubscribe();
  }

  public open() {
    if (this.sidenav) {
      this.sidenav.open();
      this._jobFormVisible.next(true);
    }
  }

  public close() {
    if (this.sidenav) {
      this.sidenav.close();
      this._jobFormVisible.next(false);
    }
  }

  public toggle() {
    (<Subject<any>>this._toggles).next('toggle');
  }

  public openTimerJobForm() {

  }

  public openEnterJobForm(jobData) {
    (<Subject<any>>this._toggles).next('toggle');
  }

  public openJobForm(jobFormModel: JobFormModel) {
    const current: JobFormModel = getJobParams();
    if (!(current && current.refreshForm(jobFormModel))) {
      this._jobFormVisible.next(false);
    }
    this._jobFormVisible.next(true);
    jobParams = jobFormModel;
    this.sidenav.open();
  }


}
