import { AfterContentInit, AfterViewInit, Directive, OnInit, Renderer2, ViewContainerRef } from "@angular/core";
import { printObj } from "../helpers";

export const LOCAL = 'local';
export const SESSION = 'session';
export const UNTIL_BACK = 'back';

@Directive({
  exportAs: 'editable',
  selector: '[editable]',
})
export class EditableTextDirective implements OnInit, AfterViewInit, AfterContentInit {

  constructor(private _viewRef: ViewContainerRef, private _renderer: Renderer2) {
  }

  ngOnInit(): void {
    const div: HTMLElement = this._renderer.createElement('div');
    const editIcon = this._renderer.createElement('i');
    this._renderer.addClass(editIcon, 'zmdi');
    this._renderer.addClass(editIcon, 'zmdi-edit');
    this._renderer.listen(editIcon, 'click', (c) => {

    });

    const nl = this._viewRef.element.nativeElement;
    printObj(nl);
    printObj(editIcon);
  }

  ngAfterViewInit(): void {

  }

  ngAfterContentInit(): void {

  }
}
