
import { map, combineLatest, filter, switchMap } from 'rxjs/operators';
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { Subject, BehaviorSubject, Observable } from "rxjs";
import { PartnersBackend } from "../../../../backend/partners.backend";
import { SHORT_DATE } from '../../../../core/time';

@Component({
  selector: 'maintenance-list',
  templateUrl: './maintenanceList.component.html',
  styleUrls: ["./maintenanceList.component.scss"]
})
export class MaintenanceListComponent implements OnInit {
  mainTabset: any;
  @Input() partnerId;
  private INIT_ONLY_REQUESTS: boolean = false;
  private INITIAL_DATE_FROM: Date;
  private INITIAL;
  private limit = 15;
  private rows: Subject<any> = new BehaviorSubject(null);
  private onlyRequests: Subject<boolean> = new BehaviorSubject(this.INIT_ONLY_REQUESTS);
  visibleRows: Observable<Array<any>>;
  private filterFunctions: Subject<(r: any) => boolean> = new BehaviorSubject((r) => true);

  shortDate = SHORT_DATE;

  private dateTo: Date;
  private stateColors = {
    '-1': 'red',
    '0': 'black',
    '1': 'green'
  };

  private columns = [
    { name: 'Data', prop: 'date' },
    { name: 'Užduotis', prop: 'Tekstas' },
    { name: 'Vykdymas', prop: 'Vykdymas' },
    { name: 'Darbuotojas', prop: 'Darbuotojas' },
  ];

  constructor(private partnersBackend: PartnersBackend,
    private chg: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.onlyRequests.pipe(switchMap((wr) => this.partnersBackend.getPartnerMaintenance({ id: this.partnerId, onlyRequests: wr ? 1 : 0 }))).subscribe((res) => { this.rows.next(res) });
    this.visibleRows = this.rows.pipe(
      filter((r) => r != null),
      combineLatest(this.filterFunctions, (rows, filterFn) => rows.filter((r) => filterFn(r))),
      map(this.rowToViewRow)
    );
  }

  _intervalChanged(interval) {
    this.dateTo = interval.to == '' ? new Date() : new Date(interval.to);
    this.filterFunctions.next((row) => {
      let date = new Date(row.date);
      return date >= new Date(interval.from) && date <= this.dateTo
    })
  }

  _onlyRequestsChanged(withRequests) {
    this.onlyRequests.next(withRequests)
  }

  private rowToViewRow(row) {
    return row;
  }



}
