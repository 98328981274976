export const environment = {
  production: false,
  // apiUrl: 'https://apis.prototechnika.lt:2000/api/',
  apiUrl: 'http://appdev:5557/api/',
  // apiUrl: 'http://appdev:5567/api/', //no cert
  // apiUrl: 'http://dport::5557/api/'
  dpaServiceUrl: 'http://192.168.0.51:3000/'
  // dpaServiceUrl: 'http://appdev:3000/'
  // dpaServiceUrl: 'https://apis.prototechnika.lt:3000/'
};
