import { Component, OnDestroy, OnInit, HostListener, ViewChild } from '@angular/core';
import { FeedbackBackend } from '../../backend/feedback.backend';
import { round } from 'lodash';
import { DashboardService } from '../dashboard.service';
import { Subscription, Observable } from 'rxjs';
import { ChartDataset, ChartOptions } from 'chart.js';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})

export class RatingComponent implements OnInit, OnDestroy {

  @ViewChild('pie', { read: BaseChartDirective }) pieChart?: BaseChartDirective;
  @ViewChild('bar', { read: BaseChartDirective }) barChart?: BaseChartDirective;

  dataReady = false;
  date: Date = new Date();
  // @Input() set date(val: Date) {
  //   if (!val) {
  //     return;
  //   }
  //   this.getData(val);
  // }
  // maxDate = this.date;
  qualityEvaluation: any[];
  weeks: any[];

  ratingDate: Date = new Date(Date.now());

  chartData: any;
  chartPieData: any[];

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  xAxisLabel: string = 'Savaitės';

  unsub: Subscription[] = [];

  pieLabels: any[] = [];
  pieValues: any[] = [];

  pieOptions: ChartOptions<'doughnut'>;
  pieData: ChartDataset<'doughnut'>[];

  barLabels: any[] = [];
  barValueTeigiamas: any[] = [];
  barValueVidutinis: any[] = [];
  barValueNeigiamas: any[] = [];
  barColors: string[] = [];
  barOptions: ChartOptions<'bar'>;
  barData: ChartDataset<'bar'>[];

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]);
  cheight = '170';

  constructor(
    private feedbackBackend: FeedbackBackend,
    private dashboardService: DashboardService,
    private breakpointObserver: BreakpointObserver
  ) {
    // Object.assign(this.multi);
  }

  @HostListener("window:resize")
  canvasUpdate() {
    this.pieChart?.render();
    this.barChart?.render();
  }

  ngOnInit() {
    this.unsub.push(this.dashboardService.monthSelectRating$.subscribe({
      next: (date) => {
        this.getData(date);
      }
    }));

    this.unsub.push(this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        this.cheight = '280';
      } else {
        this.cheight = '170';
      }
    }));

    // PIE OPTIONS
    this.pieOptions = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 10,
          right: 10,
          top: 14,
          bottom: 10
        }
      },
      plugins: {
        legend: {
          position: 'right',
          align: 'start',
          onClick: function (e) {
            e.native.stopPropagation();
          }
        },
        colors: {
          // forceOverride: true,
        }
      },
    }

    // BAR OPTIONS
    this.barOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right',
          align: 'start',
          onClick: function (e) {
            e.native.stopPropagation();
          },
          labels: {
            padding: 7
          }
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              let label = tooltipItem.dataset.label || '';
              if (label) {
                label += ': ';
              }

              let all = 0;
              this.chart.data.datasets.forEach(dataset => {
                all = all + <number>dataset.data[tooltipItem.parsed.x]
              })
              const percents = `${tooltipItem.parsed.y} (${round((tooltipItem.parsed.y / all) * 100, 2)}%)`;

              label += percents;
              return label;
            }
          }
        }
      },
      layout: {
        padding: {
          left: 10,
          right: 10,
          top: 14,
          bottom: 10
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          stacked: true,
          min: 0,
          ticks: {
            precision: 0,
          },
          title: {
            // text: 'Valandos',
            display: true
          }
        },
        x: {
          beginAtZero: true,
          stacked: true,
          title: {
            // text: 'Savaitės',
            display: true
          }
        }
      }
    };
  }

  getData(date: Date) {

    this.barLabels = [];
    this.barValueTeigiamas = [];
    this.barValueVidutinis = [];
    this.barValueNeigiamas = [];

    this.pieValues = [];
    this.pieLabels = [];

    const dateTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    // console.log(dateTime);
    const paramsObj = {
      dateTime
    }
    const sub = this.feedbackBackend.getFeedback(paramsObj).subscribe(resp => {
      // console.log(resp);
      this.qualityEvaluation = resp.entity.qualityEvaluationViews;
      this.weeks = resp.entity.dates.map((week: string) => {
        return week.split(' - ').map(date => {
          // console.log(date);
          return date.slice(5);
        }).join(' - ');
      })

      this.chartData = this.setChartData();

      this.barData = [
        { data: this.barValueTeigiamas, label: 'Teigiamas', backgroundColor: 'rgb(90,164,84, 0.6)', hoverBackgroundColor: 'rgb(90,164,84, 0.9)' },
        { data: this.barValueVidutinis, label: 'Vidutinis', backgroundColor: 'rgb(199,180,44, 0.6)', hoverBackgroundColor: 'rgb(199,180,44, 0.9)' },
        { data: this.barValueNeigiamas, label: 'Neigiamas', backgroundColor: 'rgb(161,10,40, 0.6)', hoverBackgroundColor: 'rgb(161,10,40, 0.9)' },
      ]

      this.chartPieData = this.setChartPieData();

      this.chartPieData.forEach((el, index) => {
        this.pieLabels.push(el.name + ': ' + el.extra.percents);
        this.pieValues.push(el.value);
        // this.pieColors.push(this.colorScheme.domain[index]);
      });

      this.pieData = [{
        data: this.pieValues,
        backgroundColor: ['rgb(90,164,84, 0.6)', 'rgb(199,180,44, 0.6)', 'rgb(161,10,40, 0.6)'],
        hoverBackgroundColor: ['rgb(90,164,84, 0.9)', 'rgb(199,180,44, 0.9)', 'rgb(161,10,40, 0.9)']
      }]

      this.dataReady = true;
      sub.unsubscribe();
    })
  }

  public getRatingValues(week: number, ratingLevel: number) {
    if (this.qualityEvaluation) {
      const weekRating = this.qualityEvaluation.filter(el => el.weekNr === week);

      if (weekRating) {

        let all = 0;
        weekRating.forEach(el => {
          all = all + el.count;
        })

        switch (ratingLevel) {
          case 1:
            return { all, ...weekRating.find(el => el.rating === ratingLevel) } || null
          case 2:
            return { all, ...weekRating.find(el => el.rating === ratingLevel) } || null
          case 3:
            return { all, ...weekRating.find(el => el.rating === ratingLevel) } || null
        }
      } else {
        return null;
      }

    }
  }

  getRatingValuesPie(ratingLevel: number) {
    if (this.qualityEvaluation) {
      const ratingLevelAllWeeks = this.qualityEvaluation.filter(el => el.rating === ratingLevel);
      if (ratingLevelAllWeeks) {
        let all = 0;
        ratingLevelAllWeeks.forEach(el => {
          all = all + el.count;
        });
        return all;
      } else {
        return 0;
      }
    }
  }

  setChartData() {
    return this.weeks.map((value, index) => {
      this.barLabels.push(value);

      this.barValueTeigiamas.push(this.getRatingValues(index + 1, 1).count || 0);
      this.barValueVidutinis.push(this.getRatingValues(index + 1, 2).count || 0);
      this.barValueNeigiamas.push(this.getRatingValues(index + 1, 3).count || 0);
    })
  }

  setChartPieData() {
    return [
      {
        "name": "Teigiamas",
        "value": this.getRatingValuesPie(1) || 0,
        'extra': {
          'percents': `${(round((this.getRatingValuesPie(1) / (this.getRatingValuesPie(1) + this.getRatingValuesPie(2) + this.getRatingValuesPie(3))) * 100, 2)) || 0}%`
        }
      },
      {
        "name": "Vidutinis",
        "value": this.getRatingValuesPie(2) || 0,
        'extra': {
          'percents': `${(round((this.getRatingValuesPie(2) / (this.getRatingValuesPie(1) + this.getRatingValuesPie(2) + this.getRatingValuesPie(3))) * 100, 2)) || 0}%`
        }
      },
      {
        "name": "Neigiamas",
        "value": this.getRatingValuesPie(3) || 0,
        'extra': {
          'percents': `${(round((this.getRatingValuesPie(3) / (this.getRatingValuesPie(1) + this.getRatingValuesPie(2) + this.getRatingValuesPie(3))) * 100, 2)) || 0}%`
        }
      }
    ];
  }

  dateMonthChenge(ev: Date) {
    this.getData(ev);
  }

  ngOnDestroy() {
    this.unsub.forEach(sub => sub.unsubscribe());
  }

}
