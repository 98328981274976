
import { empty as observableEmpty, interval as observableInterval, Observable } from 'rxjs';

import { switchMap } from 'rxjs/operators';
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import {
  ActivityLogEntry,
  COMMENTED,
  DISQUALIFIED,
  EXECUTOR,
  FINISHED,
  JOB_ENTERED,
  PARTICIPANT,
  STATE_CHANGED,
  TRANSFERRED,
  WATCHER
} from "../../../../models/ActivityLogEntry";
import { TemplateService } from "../../../shared/services/template.service";
import { A_TASK_COMMENTED, A_TASK_PERSON, A_TASK_STATE_CHANGED } from "../../../../app.constants";

@Component({
  selector: "activity-log-entry",
  templateUrl: "./activity-log-entry.component.html",
  styleUrls: ["./activity-log-entry.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ActivityLogEntryComponent implements OnInit {
  @Input() entry: ActivityLogEntry;

  protected readonly TASK_PERSON = A_TASK_PERSON;
  protected readonly TASK_STATE = A_TASK_STATE_CHANGED;
  protected readonly TASK_COMMENTED = A_TASK_COMMENTED;

  private readonly goToTask: '/taskboard/t/v/';
  private readonly goToRequest: '/taskboard/r/v/';

  private isVisible: boolean;
  protected elapsed: string = "00s";
  private visibleEvents: Array<number> = [FINISHED, COMMENTED, WATCHER, STATE_CHANGED, DISQUALIFIED, TRANSFERRED, EXECUTOR, JOB_ENTERED, PARTICIPANT];

  public constructor(private templateService: TemplateService) {

  }

  ngOnInit(): void {
    this.elapsed = this.getElapsedTime(this.entry.time);
    this.templateService.activityLogState.pipe(switchMap((s) => s ? observableInterval(1000) : observableEmpty())).subscribe((t) => this.elapsed = this.getElapsedTime(this.entry.time));
    if (this.entry && this.visibleEvents.indexOf(this.entry.type) > -1) {
      this.isVisible = true;
    }
  }

  getElapsedTime(log: Date) {
    let now = new Date();
    let diff = (<any>now - <any>log) / 1000.0;
    let arr = [Math.floor(diff / 31536000), Math.floor((diff % 31536000) / 2628000), Math.floor(((diff % 31536000) % 2628000) / 86400),
    Math.floor((((diff % 31536000) % 2628000) % 86400) / 3600), Math.floor(((((diff % 31536000) % 2628000) % 86400) % 3600) / 60),
    Math.floor((((diff % 31536000) % 2628000) % 86400) % 3600) % 60],
      trimmed = this.trimArray(arr),
      offset = 6 - trimmed.length,
      divs = [1, 1, 31, 24, 60, 60],
      shrt = ["m.", "mėn.", "d.", "val.", "min.", "sek."],
      v = divs.slice(offset),
      e = shrt.slice(offset);
    if (trimmed.length > 1) {
      trimmed[1] = trimmed[1] + this.round(trimmed.slice(offset, 2), v);
      if (trimmed[1] == v[1]) {
        trimmed[0] = trimmed[0] + 1;
        if (trimmed[0] == v[0] && offset > 0) {
          return 1 + "" + shrt[offset - 1];
        } else if (trimmed[0] + "" + e[0]) {
          return
        } else {
          var str = "" + trimmed[0] + e[0];
          var str = trimmed[1] > 0 ? str + "" + trimmed[1] + e[1] : str;
          return str;
        }
      } else {
        str = trimmed[0] + '' + e[0];
        str = trimmed[0] > 0 ? str + ' ' + trimmed[1] + '' + e[1] : str + '';
        return str;
      }
    } else {
      return trimmed[0] + "s";
    }
  }

  closeActivityLog() {
    this.templateService.toggleActivityLog();
  }

  round(a, d, i = 1) {
    if (a.length <= i) {
      return 0;
    }
    a[i] = a[i] + this.round(a, d, i = 1);
    return (a[i] / d[i] > 0.5) ? 1 : 0;
  }

  trimArray(arr, trim = 0) {
    if (!arr) {
      return [];
    }
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] == trim) {
        continue;
      }
      return arr.splice(i, arr.length);
    }
    return [];
  }

}
