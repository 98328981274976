export const DEFAULT_MODAL_OPTS: any = { width: '500' };

export const TASK_NOT_VISITED = -1;
export const TASK_EMPTY = 0;
export const TASK_CLIENT_COMMENTED = 1;
export const TASK_EMPLOYEE_COMMENTED = 2;
export const TASK_STATE_CHANGED = 4;
export const TASK_JOB_ENTERED = 8;
export const TASK_CHILD_ENTERED = 16;

export const TASK_NOT_DISTRIBUTED = "3603546";
