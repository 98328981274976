export class User {
  public readonly name: string;
  public readonly lastName: string;
  public readonly pictureUrl: string;
  public readonly email: string;
  public readonly userName: string;
  public readonly rights: Array<string>;
  public readonly parameters: string;


  constructor(user: any) {
    this.name = user.Name;
    this.rights = user.rights;
    this.lastName = user.LastName;
    this.email = user.Email;
    this.userName = user.UserName;
    this.pictureUrl = 'getUserPicture';
    this.parameters = user.parameters;
  }

  getName() {
    return this.name;
  }

  getLastName() {
    return this.lastName;
  }

  getPictureUrl() {
    return this.pictureUrl;
  }

  getEmail() {
    return this.email;
  }

  getUserName() {
    return this.userName;
  }

  getRights() {
    return this.rights;
  }

  hasRight(right: string) {
    return this.rights && this.rights.indexOf(right) > 0;
  }
}
