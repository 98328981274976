import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { downloadFile, isImage } from '../../../functions/utils/files';
import { TasksBackend } from "../../../backend/tasks.backend";
import { DATE_WITH_TIME } from "../../../core/time";
import { ImageModalComponent } from "../../../template/shared/components/modals/image-modal/image-modal.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss']
})

export class FileListComponent implements OnInit, OnDestroy {

  @Input() taskId: number;
  filesList: any;

  columns: any[];

  @ViewChild('viewFileTpl', { static: true }) viewFileTpl: TemplateRef<any>;
  @ViewChild('dateTpl', { static: true }) dateTpl: TemplateRef<any>;
  @ViewChild('isEmployeeTpl', { static: true }) isEmployeeTpl: TemplateRef<any>;

  subs: Subscription[] = [];

  isImage = isImage;
  dateWithTime = DATE_WITH_TIME;

  constructor(
    private tasksBackend: TasksBackend,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getFileList(this.taskId);
    this.columns = [
      { name: '', minWidth: 50, maxWidth: 50, prop: 'id', cellTemplate: this.viewFileTpl },
      { name: 'Pavadinimas', minWidth: 400, maxWidth: 500, prop: 'fileName' },
      { name: 'Data', minWidth: 160, maxWidth: 200, prop: 'date', cellTemplate: this.dateTpl },
      { name: 'Įkėlė', minWidth: 100, maxWidth: 100, prop: 'isEmployee', cellTemplate: this.isEmployeeTpl },
      { name: 'Aprašymas', prop: 'description' }
    ];
  }

  getFileList(taskId) {
    this.subs.push(this.tasksBackend.getFilesList(taskId).subscribe((filesList) => {
      this.filesList = filesList;
    }))
  }



  downloadFileClick(file) {
    downloadFile(file);
  }

  viewFileClick(file) {
    this.dialog.open(ImageModalComponent, { data: file });
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}