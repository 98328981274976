
import { empty as observableEmpty, BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs';

import { take, catchError, switchMap, tap, pluck, map, filter, startWith } from 'rxjs/operators';
import { ProtoInputComponent } from './../../../../template/shared/proto/components/input/protoInput.component';
import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatInput } from "@angular/material/input";
import { MatDialog } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { LIST, ListsDataService } from '../../../../services/lists/lists.data.service';
import { getDpaEmailText, getDpaEmailTextV2, printDpa, DPA_NAME } from '../../../common/dpa';
import { FilesBackend } from "../../../../backend/files.backend";
import { PREVIOUS_URL } from "../../../../template/shared/proto/services/routing.service";
import { DPA } from '../../../taskboard.help';
import { getEmail } from "../../../../functions/utils/helpers";
import { DpaBackend } from "../../../../backend/dpa.backend";
import { SendDpaModalComponent } from './../../../../template/shared/components/modals/send-dpa-modal/send-dpa-modal.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { DATE_WITH_TIME } from '../../../../core/time';
import { Location } from '@angular/common';
import { CalendarCustomHeaderComponent } from '../../../../components/filters/date-interval-alt/calendarCustomHeader.component';

const dpaEvents = {
  CREATED: 'CREATED',
  COMPLETED: 'COMPLETED',
  SEND: 'SEND',
  REOPEN: 'REOPEN',
}

@Component({
  selector: 'dpa-editor',
  templateUrl: './dpaEditor.component.html',
  styleUrls: ['./dpaEditor.component.scss'],
})
export class DpaEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('executorInput', { read: ProtoInputComponent }) executorInput: ProtoInputComponent<any>;
  @ViewChild('dpaPartnerInput', { read: ProtoInputComponent }) dpaPartnerInput: ProtoInputComponent<any>;
  @ViewChild('sfNrCtrl', { read: MatInput }) _sfNrCtrl: MatInput;
  private _persons = LIST.CRM_PERSONS;
  protected _dpa: Subject<any> = new BehaviorSubject(null);
  dpa: any;
  protected _dpaId: any;
  protected _id: Observable<number>;
  protected _dpaInfoForm: UntypedFormGroup;
  private _subscriptions: Array<Subscription> = [];
  private _dpaCode: any;
  currentDpa: any;
  protected _record: any;
  protected _dpaDate: any;
  protected _isUpdating = false;
  public _isPrinting = false;
  public _isOpening = false;
  private _executorId: any;
  private _dpaPartnerId: any;
  private _initialExecutorId: any;
  protected _executorsSource: Function;
  protected _dpaPartnerSource: Function;
  protected _executorPlaceholder = 'Pasirinkite vykdytoją';
  protected _dpaPartnerPlaceholder = 'Priskirkite papildomą DPA partnerį';
  private _executorSelectedFlag = false;
  private _dpaPartnerSelectedFlag = false;
  private _companyAttrSelectedFlag = false;
  private _initialCompanyAttrCode: any;
  private _companyAttrCode: string;
  protected _companyAttrSource: Function;
  protected _initialCompanyAttrSelector: Function;
  protected _companyAttrPlaceholder = 'Įmonė';
  protected _companyAttrValueMapper: Function;
  protected _isFinishing: boolean;
  private __finished = false;
  private _delegateEmail;
  private initialDate;
  public dpaSysName;
  public projectId;
  public notSentDpaList: any[] = [];
  protected set _finished(val) {
    if (val != this.__finished) {
      setTimeout(_ => {
        const cmd = val ? 'disable' : 'enable';
        const sfNrCtrl = this._dpaInfoForm.controls['record'];
        if (sfNrCtrl) {
          sfNrCtrl[cmd]();
        }
        const dateCtrl = this._dpaInfoForm.controls['date'];
        if (dateCtrl) {
          dateCtrl[cmd]();
        }
      })
    }
    this.__finished = !!val;
  }
  protected get _finished(): boolean {
    return this.__finished;
  }
  protected _dpaUpdated: boolean;
  private _dpaDownloading = false;
  private _isSending = false;
  private _companyAttrInput: ProtoInputComponent<any>;
  @ViewChild('companyAttrInput', { read: ProtoInputComponent }) set companyAttrInput(val: ProtoInputComponent<any>) {
    this._companyAttrInput = val;
  }

  protected get isFinished(): boolean {
    return false;
  }

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]);

  DATE_WITH_TIME = DATE_WITH_TIME;

  calendarCustomHeaderComponent: any = CalendarCustomHeaderComponent;
  refreshDpaTrigger$: Subject<any> = new Subject();
  refreshDpaHistoryTrigger$: Subject<any> = new Subject();
  dpaHistory: any;
  @ViewChild('eventTypeTpl', { static: true }) eventTypeTpl;
  @ViewChild('dateTpl', { static: true }) dateTpl;

  historyColumns;
  dpaEvents = dpaEvents;

  constructor(
    private _route: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private _chg: ChangeDetectorRef,
    private _listsService: ListsDataService,
    private _router: Router,
    private _filesBackend: FilesBackend,
    private _dpaBackend: DpaBackend,
    public dialog: MatDialog,
    private location: Location,
    private breakpointObserver: BreakpointObserver) {
    this._executorsSource = () => _listsService.loadList(LIST.CRM_PERSONS);
    this._dpaPartnerSource = () => _listsService.loadList(LIST.PARTNERS);
    this._companyAttrSource = () => this._dpa.pipe(filter((dpa) => dpa != null), map((dpa) => dpa.companyInfo || []));
    this._initialCompanyAttrSelector = (companyAttr) => companyAttr.companyAttrCode == this._initialCompanyAttrCode;
    this._companyAttrValueMapper = (companyAttr) => companyAttr.companyAttrCode;
  }

  ngOnInit(): void {
    this.historyColumns = [
      { name: 'Kas atliko', prop: 'name', width: 220, sortable: false },
      { name: 'Kada atliko', prop: 'date', width: 220, sortable: false, cellTemplate: this.dateTpl },
      { name: 'Veiksmas', prop: 'eventType', width: 280, sortable: false, cellTemplate: this.eventTypeTpl },
    ]
  }

  ngAfterViewInit() {
    this._id = <Observable<number>>this._route.params.pipe(pluck('id'), tap((id) => { this._dpaId = id; }));
    this._subscriptions.push(
      combineLatest(this.refreshDpaTrigger$.pipe(startWith(true)), this._id).pipe(switchMap(([t, id]) => this._dpaBackend.getDpaInfoForEditing(id)), map(this._makeDpaModel))
        .subscribe((res) => {
          if (!(<any>res).dpaInfo || Object.keys((<any>res).dpaInfo).length === 0) {
            this._router.navigate(PREVIOUS_URL ? [PREVIOUS_URL] : ['taskboard', DPA]);
          }
          this._finished = (<any>res) && (<any>res).dpaInfo && (<any>res).dpaInfo.isFinished;
          this._dpa.next(res);
          this.currentDpa = res;
          this.getDpaNotSentJobs();
          if ((<any>res).dpaInfo) {
            let r = (<any>res).dpaInfo;
            this.dpaSysName = r.dpaSysName;
            this._delegateEmail = r.delegateEmail;
            this._dpaCode = r.dpaCode;
            this._dpaInfoForm.controls.record.setValue(r.record == 'NULL' ? null : r.record);
            this._executorId = r.executorId;
            this._dpaPartnerId = r.dpaLaPartnerId;
            this._companyAttrCode = r.companyAttrCode;
            this._initialCompanyAttrCode = r.companyAttrCode;
            this.projectId = r.projectId;
            setTimeout(_ => this._companyAttrInput?.select((c: any) => c && c.companyAttrCode == r.companyAttrCode));
            setTimeout(_ => this.executorInput?.select((executor) => (
              <any>executor).personId == this._executorId), 0);
            setTimeout(_ => this.dpaPartnerInput?.select((dpaPartner) => (
              <any>dpaPartner).id == this._dpaPartnerId), 0);
            this._dpaInfoForm.controls.date.setValue(r.date ? new Date(r.date) : null);
            this.initialDate = r.date ? new Date(r.date) : null;
          }
        }));

    this._subscriptions.push(
      combineLatest(this.refreshDpaHistoryTrigger$.pipe(startWith(true)), this._id).pipe(switchMap(([t, id]) => this._dpaBackend.getDpaHistory(id)))
        .subscribe((res) => {
          this.dpaHistory = res?.sort((x, y) => { return x.date < y.date ? -1 : x.date > y.date ? 1 : 0; });
          try {
            this._chg.detectChanges();
          } catch (e) {
          }
        }));
    this._buildForm();
  }

  dateChanged(el) {
    el.close && el.close();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  refreshDPA(jobsCount, goBackConditionCheck) {
    if (jobsCount == 1 && goBackConditionCheck) {
      this.location.back();
    } else {
      this.refreshDpaTrigger$.next(true);
    }
  }

  private _executorSelected(executor) {
    this._executorSelectedFlag = true;
    if (!executor) {
      this._executorId = null;
      return;
    }
    this._executorId = executor.personId ? executor.personId : null;
  }

  private _dpaPartnerSelected(dpaPartner) {
    this._dpaPartnerSelectedFlag = true;
    if (!dpaPartner) {
      this._dpaPartnerId = null;
      return;
    }
    this._dpaPartnerId = dpaPartner.id ? dpaPartner.id : null;
  }

  private _companyAttrSelected(companyAttr) {
    this._companyAttrSelectedFlag = true;
    if (!companyAttr) {
      this._companyAttrCode = null;
      return;
    }
    this._companyAttrCode = companyAttr;
  }

  protected _executorMapper(executor) {
    return executor.name + ' ' + executor.lastName;
  }

  protected _companyAttrMapper(companyAttr) {
    return companyAttr.name;
  }

  protected _dpaPartnerMapper(dpaPartner) {
    return dpaPartner.name;
  }

  private _openDpa(dpaId) {
    // window.open(window.location.origin + '/dpa/' + dpaId);
    if (!this._isOpening) {
      this._isOpening = true;
      let newWindow = window.open('/assets/loading.html');
      this._filesBackend.getDpaForPrint(dpaId).subscribe((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        // const fileLink = document.createElement('a');
        // fileLink.href = blobUrl;
        // fileLink.target = '_blank';
        // fileLink.click();
        newWindow && newWindow.location && newWindow.location.assign && newWindow.location.assign(encodeURI(blobUrl));
        this._isOpening = false;
        try {
          this._chg.detectChanges();
        } catch (e) {
        }
      },
        (err) => {
          this._isOpening = false;
          try {
            this._chg.detectChanges();
          } catch (e) {
          }
        }
      );
    }
  }

  _printDpa(id) {
    // printDpa(id);
    if (!this._isPrinting) {
      this._isPrinting = true;
      this._filesBackend.getDpaForPrint(id).subscribe((response) => {
        var blob = new Blob([response], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
        this._isPrinting = false;
        try {
          this._chg.detectChanges();
        } catch (e) {
        }
      },
        (err) => {
          this._isPrinting = false;
          try {
            this._chg.detectChanges();
          } catch (e) {
          }
        }
      );
    }
  }

  isAptDPA() {
    return !!(this.projectId === 1 && this.dpaSysName);
  }

  _sendDpaModal(id) {

    const modalRef = this.dialog.open(SendDpaModalComponent);
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        modalRef.updateSize('100%', '100%');
      } else {
        modalRef.updateSize('50%');
      }
    });
    modalRef.componentInstance._dpaNr = DPA_NAME + id;
    modalRef.componentInstance._subject = DPA_NAME + id;
    modalRef.componentInstance._emailBody = getDpaEmailTextV2(id, this.isAptDPA());
    modalRef.componentInstance._email = this._delegateEmail;
    modalRef.beforeClosed().subscribe((data) => {
      if (data) {
        const dpaEmailObj = {
          email: data.email,
          subject: data.subject,
          emailBody: data.emailBody,
          dpaId: id
        }
        this._sendDpa(dpaEmailObj);
      }
    });
  }

  getDpaNotSentJobs() {
    this._subscriptions.push(this._dpaBackend.getDpaNotSentJobs(this._dpaId).subscribe(resp => {
      this.notSentDpaList = resp;
      try {
        this._chg.detectChanges();
      } catch (e) { }
    }))
  }

  _sendDpa(dpaEmailObj) {
    if (this._isSending) {
      return;
    }
    this._isSending = true;
    try {
      this._chg.detectChanges();
    } catch (err) { }
    this._dpaBackend.sendDpa(dpaEmailObj).pipe(catchError(err => {
      const s: SweetAlertOptions = {
        text: err.error,
        // icon: 'error',
        showConfirmButton: false,
        timer: 5000
      };
      swal.fire(s);
      this._isSending = false;
      try {
        this._chg.detectChanges();
      } catch (err) { }
      return observableEmpty();
    })).subscribe((res: any) => {
      if (res.success) {
        this.currentDpa.dpaInfo.lastSendDate = new Date().toLocaleString('LT');
        this._dpa.next(this.currentDpa);
        this.getDpaNotSentJobs();
        this.refreshDpaHistoryTrigger$.next(true);
        const s: SweetAlertOptions = {
          text: 'DPA' + dpaEmailObj.dpaId + ' išsiųstas į ' + dpaEmailObj.email,
          // icon: 'success'
        };
        this._isSending = false;
        try {
          this._chg.detectChanges();
        } catch (err) { }
        swal.fire(s);
      } else {
        const s: SweetAlertOptions = {
          text: res.error,
          // icon: 'error',
          showConfirmButton: false,
          timer: 5000
        };
        swal.fire(s);
        this._isSending = false;
        try {
          this._chg.detectChanges();
        } catch (err) { }
      }
    });
  }

  _downloadDpa(id) {
    if (!this._dpaDownloading) {
      this._dpaDownloading = true;
      this._subscriptions.push(this._filesBackend.downloadDpa(id).subscribe((_) => {
        this._dpaDownloading = false;
        try {
          this._chg.detectChanges();
        } catch (e) {
        }
      },
        (err) => {
          this._dpaDownloading = false;
          try {
            this._chg.detectChanges();
          } catch (e) {
          }
        }
      ));
    }
  }

  _getDpaToEmailHref(row) {
    var recepient = getEmail(row.delegateEmail);
    if (!recepient) {
      return;
    }
    var options = {
      subject: "DPA" + this._dpaId,
      body: getDpaEmailText(this._dpaId)
    };
    var link = "mailto:";
    link += encodeURIComponent(recepient);
    let params = [];
    for (let o in options) {
      params.push(o.toLowerCase() + "=" + options[o]);
    }
    if (params.length > 0) {
      link += "?" + params.join("&");
    }
    return link;
  }


  private _buildForm() {
    this._dpaInfoForm = this._formBuilder.group({
      //dpaCode: [],
      record: [],
      date: [],
      executorId: [null, [Validators.required]],
      companyAttrCode: [null, [Validators.required]]
    });
  }

  _reopenDpa(dpaId) {
    this._dpaBackend.reopenDpa(dpaId).subscribe((res: any) => {
      if (res.success) {
        this._finished = false;
        this.refreshDpaHistoryTrigger$.next(true);
      }
    });
  }

  private _updateDpaInfo(form: UntypedFormGroup, callback) {
    let tmpDate;
    if (!this._isUpdating && form.valid) {
      this._isUpdating = true;
      let params = { ...form.value };
      if (!form.value.date || !(params.date instanceof Date)) {
        delete params['date'];
      } else {
        tmpDate = params.date;
        params.date = params.date.toLocaleString('LT')
      }
      params['docNr'] = form.value.record;
      params['dpaId'] = this._dpaId;
      params['executorId'] = this._executorId;
      params['dpaPartnerId'] = this._dpaPartnerId;
      params['companyAttributeCode'] = this._companyAttrCode;
      this._id.pipe(take(1), switchMap((id) => this._dpaBackend.updateDpaInfo(this._dpaId, params)))
        .subscribe((res: any) => {
          this.initialDate = tmpDate;
          const success = res > 0; //TODO now
          const msg = success ? 'DPA atnaujintas sėkmingai' : 'DPA atnaujinti nepavyko';
          this._isUpdating = false;
          if (!callback) {
            swal.fire({ title: msg, icon: success ? 'success' : 'error', timer: 2000 });
            this._dpaUpdated = true;
          }
          try {
            this._chg.detectChanges();
          } catch (e) {
          }
          if (callback) {
            callback();
          }
        });
    }
  }

  private _makeDpaModel(res: any) {
    if (!(res)) {
      return {};
    }
    let dpaInfo = res.dpaInfo ? res.dpaInfo : {};
    let companyInfo = res.companyInfo ? res.companyInfo : [];
    let dpaJobs = res.dpaJobs ? res.dpaJobs : [];
    return { dpaInfo: dpaInfo, companyInfo: companyInfo, dpaJobs: dpaJobs };
  }

  noJobs(dpa) {
    if (!dpa) { return true }
    if (dpa.dpaJobs && dpa.dpaJobs.length === 0) { return true; }
    return false;
  }

  private _finishDpa(form) {
    if (this._isFinishing) {
      return;
    }
    this._isFinishing = true;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let askIfChangeDate = false;
    let formValue = { ...form.value };
    if (!formValue.date || !(formValue.date instanceof Date)) {
      // delete params['date'];
      askIfChangeDate = true;
    } else {

      if (formValue.date.getTime() < today.getTime()) {
        askIfChangeDate = true;
      }
      // params.date = params.date.toLocaleString('LT')
    }

    const dateChanged = !formValue.date || !(formValue.date instanceof Date) || formValue.date.getTime() !== this.initialDate.getTime();
    if (askIfChangeDate && !dateChanged) {
      swal.fire({
        title: 'Ar pakeisti DPA datą į šiandienos?',
        showCancelButton: true,
        confirmButtonText: 'Taip',
        cancelButtonText: 'Ne'
      })
        .then((result) => {
          if (result.value) {
            form.controls['date'].setValue(today);
            this._updateDpaInfo(form, () => {
              this.actualFinishDpa();
            });
          } else {
            this._updateDpaInfo(form, () => {
              this.actualFinishDpa();
            });
          }
        });
    } else if (dateChanged) {
      this._updateDpaInfo(form, () => {
        this.actualFinishDpa();
      });
    } else {
      this._updateDpaInfo(form, () => {
        this.actualFinishDpa();
      });
    }

  }

  actualFinishDpa() {
    this._dpaBackend.finishDpa(this._dpaId)
      .subscribe((res: any) => {
        this._isFinishing = false;
        this.refreshDpaHistoryTrigger$.next(true);
        const success = res && res.success;
        const msg = success ? 'DPA užbaigtas sėkmingai' : 'DPA užbaigti nepavyko';
        if (success) {
          swal.fire({
            title: msg,
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'Išsiųsti el.paštu',
            cancelButtonText: 'Eiti į sąrašą'
          })
            .then((result) => {
              if (result.value) {
                setTimeout(() => this._sendDpaModal(this._dpaId), 500);
              } else if (result.dismiss === swal.DismissReason.cancel) {
                if (this.dpaSysName) {
                  this._router.navigate(['/taskboard/k'], { queryParamsHandling: 'preserve' });
                } else {
                  this._router.navigate(['/taskboard/d'], { queryParamsHandling: 'preserve' });
                }
              }
            });
          this._finished = true;
          try {
            this._chg.detectChanges();
          } catch (e) {
          }
        }

      });
  }
}
