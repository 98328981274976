import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, AfterViewInit, Input, ChangeDetectorRef, ViewChild, TemplateRef, HostListener, ElementRef, AfterViewChecked } from '@angular/core';
import { TasksBackend } from './../../backend/tasks.backend';
import { DashboardService } from '../dashboard.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SHORT_DATE } from '../../core/time';
import { MatDialog } from '@angular/material/dialog';
import { ActionModalComponent } from '../../taskboard/components/actions-modal/action-modal.component';
import { NearestActionFormComponent } from './../../taskboard/components/modals/nearest-action-component/nearestActionForm.component';
import { TaskboardDataService } from '../../taskboard/services/taskboard-data.service';

const today = (today, d) => d.getFullYear() == today.getFullYear() && d.getMonth() == today.getMonth() && d.getDate() == today.getDate();


@Component({
  selector: 'tasks-view',
  templateUrl: './tasks-view.component.html',
  styleUrls: ['./tasks-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TasksViewComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  @ViewChild('default', { read: TemplateRef, static: true }) defaultTpl: TemplateRef<any>;
  @ViewChild('defaultHeader', { read: TemplateRef, static: true }) defaultHeaderTpl: TemplateRef<any>;
  @ViewChild('deadlineTpl', { read: TemplateRef, static: true }) deadlineTpl: TemplateRef<any>;
  @ViewChild('stateTpl', { read: TemplateRef, static: true }) stateTpl: TemplateRef<any>;
  @ViewChild('nearestActionTpl', { read: TemplateRef, static: true }) nearestActionTpl: TemplateRef<any>;
  @ViewChild('isRequestTpl', { read: TemplateRef, static: true }) isRequestTpl: TemplateRef<any>;
  @ViewChild('containerDiv') containerDiv: ElementRef;
  currentTab: number;
  currentDay: number;
  tasksListToday;
  tasksListYesterday;
  requestsListToday;
  requestsListYesterday;
  tasksColumns = [];
  requestsColumns = [];
  currentList: any[];
  currentColumns: any[];
  private _now = new Date();
  private _subscriptions: Array<Subscription> = [];
  shortDate = SHORT_DATE;

  myTasksColumns;
  myGroupTasksColumns;

  dataReady = false;
  gotCountainerWidth = false;
  containerWidth: number;

  sorts = [{ prop: 'deadLine', dir: 'desc' }];

  constructor(
    public chg: ChangeDetectorRef,
    private _tasksBackend: TasksBackend,
    private dashboardService: DashboardService,
    private router: Router,
    public dialog: MatDialog,
    private taskboardDataService: TaskboardDataService,
  ) {
    this.currentTab = this.dashboardService.currentTaskTypesTab;
    this.currentDay = this.dashboardService.currentTaskDay;
  }

  ngOnInit() {
    this.initMyTasksColumns();
    this.initMyGroupTasksColumns();
    this.setCurrentTable();
    this._subscriptions.push(this.taskboardDataService.refreshDatatable.subscribe(event => {
      //refreshes tasks
      this.setCurrentTable();
    }))
    // this.initTasksColumns();
    // this.initRequestsColumns();
    // this._subscriptions.push(this._tasksBackend.getRelevantTasks().subscribe((result) => {

    //   const tasks = result.endsTodayTasks ? [...result.endsTodayTasks] : [];
    //   result.todayNewTasks && result.todayNewTasks.forEach(element => {
    //     if (!tasks.find((el) => element.taskId === el.taskId)) {
    //       tasks.push(element);
    //     }
    //   });
    //   result.nearestActionTasks && result.nearestActionTasks.forEach(element => {
    //     if (!tasks.find((el) => element.taskId === el.taskId)) {
    //       tasks.push(element);
    //     }
    //   });
    //   this.tasksListToday = tasks;

    //   const requests = result.endsTodayRequests ? [...result.endsTodayRequests] : [];

    //   result.todayNewRequests && result.todayNewRequests.forEach(element => {
    //     if (!requests.find((el) => element.taskId === el.taskId)) {
    //       requests.push(element);
    //     }
    //   });
    //   result.nearestActionRequests && result.nearestActionRequests.forEach(element => {
    //     if (!requests.find((el) => element.taskId === el.taskId)) {
    //       requests.push(element);
    //     }
    //   });
    //   this.requestsListToday = requests;
    //   this.setCurrentTable();
    // })
    // );

  }

  // setCurrentTable() {
  //   if (this.currentTab === 2) {
  //     switch (this.currentDay) {
  //       case 1:
  //         this.currentList = this.tasksListToday;
  //         this.currentColumns = this.tasksColumns;
  //         break;
  //       case 2:
  //         this.currentList = this.tasksListYesterday;
  //         this.currentColumns = this.tasksColumns;
  //         break;
  //     }
  //   } else {
  //     switch (this.currentDay) {
  //       case 1:
  //         this.currentList = this.requestsListToday;
  //         this.currentColumns = this.requestsColumns;
  //         break;
  //       case 2:
  //         this.currentList = this.requestsListYesterday;
  //         this.currentColumns = this.requestsColumns;
  //         break;
  //     }
  //   }
  //   this.dataReady = true;
  //   try {
  //     this.chg.detectChanges();
  //   } catch (err) { }
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.containerDiv && this.containerDiv.nativeElement && this.containerDiv.nativeElement.offsetWidth) {
      this.containerWidth = this.containerDiv.nativeElement.offsetWidth;
      this.adjustColumnWidths();
    }
  }



  // (this.containerDiv.nativeElement as HTMLElement).offsetWidth

  getNearestActions(task) {
    if (task.nearestAction) {
      const modalRef = this.dialog.open(ActionModalComponent, { panelClass: 'custom-modal' });
      modalRef.componentInstance._task = { ...task, id: task.taskId };
      modalRef.componentInstance._taskId = task.taskId;
      this._subscriptions.push(modalRef.afterClosed().subscribe((data) => {
        if (data) {
          this.taskboardDataService.refreshDatatable.emit(true);
        }
      }));
    } else {
      const modalRef = this.dialog.open(NearestActionFormComponent, { ...{}, data: { task: { ...task, id: task.taskId } } });
      this._subscriptions.push(modalRef.afterClosed().subscribe((data) => {
        this.taskboardDataService.refreshDatatable.emit(true);
      }));
    }
  }

  getRelevantTasks(myGroup) {
    Promise.resolve(null).then(() => { this.dashboardService.disableTasksTabs = true; })
    this._subscriptions.push(this._tasksBackend.getRelevantTasks(myGroup).subscribe((resp) => {
      this.dashboardService.disableTasksTabs = false;
      this.currentList = resp;
      this.dataReady = true;
      try {
        this.chg.detectChanges();
      } catch (err) { }
    }, (err) => {
      this.dashboardService.disableTasksTabs = false;
    }));
  }

  setCurrentTable() {
    this.dataReady = false;
    if (this.currentTab === 1) {
      this.currentColumns = this.myTasksColumns;
      this.getRelevantTasks(false);
    } else {
      this.currentColumns = this.myGroupTasksColumns;
      this.getRelevantTasks(true);
    }
    this.adjustColumnWidths();
    try {
      this.chg.detectChanges();
    } catch (err) { }
  }

  // initTasksColumns() {
  //   this.tasksColumns = [
  //     {
  //       name: 'Klientas',
  //       prop: 'clientName',
  //       width: 200
  //     }, {
  //       name: 'Aprašymas',
  //       prop: 'description'
  //     }, {
  //       name: 'Terminas',
  //       prop: 'deadLine',
  //       width: 100,
  //       cellTemplate: this.deadlineTpl
  //     }, {
  //       name: 'Būsena',
  //       prop: 'state',
  //       width: 85 + 34,
  //       cellTemplate: this.stateTpl
  //     }
  //   ];
  // }

  // initRequestsColumns() {
  //   this.requestsColumns = [
  //     {
  //       name: 'Klientas',
  //       prop: 'clientName',
  //       width: 200
  //     }, {
  //       name: 'Tipas',
  //       prop: 'taskType',
  //       // width: 100,
  //       width: 110,
  //     }, {
  //       name: 'Kliento atstovas',
  //       prop: 'delegate',
  //       width: 140,
  //     }, {
  //       name: 'Aprašymas',
  //       prop: 'description'
  //     }, {
  //       name: 'Būsena',
  //       prop: 'state',
  //       width: 85 + 34,
  //       cellTemplate: this.stateTpl
  //     }
  //   ];
  // }

  initMyTasksColumns() {
    this.myTasksColumns = [
      {
        name: '',
        prop: 'type',
        width: 30,
        initWidth: 30,
        cellTemplate: this.isRequestTpl
      }, {
        name: 'Klientas',
        prop: 'clientName',
        width: 260,
        initWidth: 260,
      }, {
        name: 'Užsakovas',
        prop: 'owner',
        width: 180,
        initWidth: 180,
      }, {
        name: 'Tipas',
        prop: 'taskType',
        width: 120,
        initWidth: 120,
      }, {
        name: 'Būsena',
        prop: 'state',
        width: 85,
        initWidth: 85,
        cellTemplate: this.stateTpl
      }, {
        name: 'Terminas',
        prop: 'deadLine',
        width: 100,
        initWidth: 100,
        sortable: true,
        cellTemplate: this.deadlineTpl
      }, {
        name: 'Veiksmas',
        prop: 'nearestAction',
        width: 100,
        initWidth: 100,
        sortable: true,
        cellTemplate: this.nearestActionTpl
      }, {
        name: 'Aprašymas',
        prop: 'description',
        width: 460,
        initWidth: 460,
      }
    ];
  }

  initMyGroupTasksColumns() {
    this.myGroupTasksColumns = [
      {
        name: '',
        prop: 'type',
        width: 30,
        initWidth: 30,
        cellTemplate: this.isRequestTpl
      }, {
        name: 'Klientas',
        prop: 'clientName',
        width: 260,
        initWidth: 260,
      }, {
        name: 'Užsakovas',
        prop: 'owner',
        width: 180,
        initWidth: 180,
      }, {
        name: 'Tipas',
        prop: 'taskType',
        width: 120,
        initWidth: 120,
      }, {
        name: 'Būsena',
        prop: 'state',
        width: 85,
        initWidth: 85,
        cellTemplate: this.stateTpl
      }, {
        name: 'Terminas',
        prop: 'deadLine',
        width: 100,
        initWidth: 100,
        sortable: true,
        cellTemplate: this.deadlineTpl
      }, {
        name: 'Veiksmas',
        prop: 'nearestAction',
        width: 100,
        initWidth: 100,
        sortable: true,
        cellTemplate: this.nearestActionTpl,
      }, {
        name: 'Vykdytojas',
        prop: 'executor',
        width: 180,
        initWidth: 180,
      }, {
        name: 'Aprašymas',
        prop: 'description',
        width: 460,
        initWidth: 460,
      }
    ];
  }

  _actionText(row: any) {
    return row && row.nearestActionText && row.nearestActionText.trim().length > 0 ? row.nearestActionText : 'Artimiausių veiksmų nėra';
  }

  _getNearestActionClass(row) {
    if (!row.nearestAction) {
      return '';
    }
    const d = new Date(row.nearestAction);
    if (today(this._now, d)) {
      return 'today';
    } else if (d.getTime() < this._now.getTime()) {
      return 'late';
    }
    return '';
  }

  _getDeadlineClass(date) {
    const d = new Date(date);
    if (today(this._now, d)) {
      return 'today';
    } else if (d.getTime() < this._now.getTime()) {
      return 'late';
    }
    return '';
  }

  ngAfterViewInit() {

  }

  ngAfterViewChecked() {
    if (!this.gotCountainerWidth && this.containerDiv && this.containerDiv.nativeElement && this.containerDiv.nativeElement.offsetWidth) {
      this.gotCountainerWidth = true;
      this.containerWidth = this.containerDiv.nativeElement.offsetWidth;
      this.adjustColumnWidths();
    }
  }

  adjustColumnWidths() {
    if (!this.currentColumns || !this.containerWidth) return;
    let minWidthSum = 0;
    let addPart = 0;
    let remainderPart = 0;
    this.currentColumns.forEach(el => {
      minWidthSum += el.initWidth;
    });
    if (this.containerWidth > minWidthSum && this.currentColumns && this.currentColumns.length > 0) {
      addPart = Math.floor((this.containerWidth - minWidthSum) / this.currentColumns.length);
      remainderPart = Math.floor((this.containerWidth - minWidthSum) % this.currentColumns.length);
    }
    let counter = 0;
    this.currentColumns.forEach((el) => {
      if (counter < remainderPart) {
        el.width = el.initWidth + addPart + 1;
      } else {
        el.width = el.initWidth + addPart;
      }
      counter++;
    });
    this.currentColumns = [...this.currentColumns];
  }

  _rowClickedTasks(row) {
    if (row.isRequest !== 1) {
      this.router.navigate(['/taskboard/t/v/' + row.taskId]);
    } else {
      this.router.navigate(['/taskboard/r/v/' + row.taskId]);
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }


}
