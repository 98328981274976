
import { combineLatest, startWith, scan, withLatestFrom, switchMap, toArray, find, mergeMap, skip, take, filter, map, distinctUntilChanged, tap } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  UntypedFormControl,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgForm
} from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

const ASCIIFolder = require('./../../../../../../../node_modules/fold-to-ascii/lib/ascii-folder');

type Predicate = <T>(s: T) => boolean;
export type Filter = (s: string, c: string) => boolean;
export type Selector = Predicate | string;

export const startsWith: Filter = (s, c) => s && s.toLowerCase().startsWith(c);
export const contains: Filter = (s, c) => s && s.toLowerCase().indexOf(c) > -1;
export const containsAsciiFold: Filter = (s, c) => s && ASCIIFolder.foldReplacing(s).toLowerCase().indexOf(ASCIIFolder.foldReplacing(c).toLowerCase()) > -1;

@Component({
  selector: 'proto-input',
  templateUrl: './protoInput.component.html',
  styleUrls: ['./protoInput.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ProtoInputComponent), multi: true },
  ]
})
export class ProtoInputComponent<T> implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {
  private _disabled = false;
  @Input() set disabled(val: boolean) {
    if (this._disabled != val) {
      setTimeout(_ => {
        const ctrl = this._ctrl;
        const outCtrl = this._outerCtrl;
        const cmd = val ? 'disable' : 'enable';
        if (ctrl) {
          ctrl[cmd]();
        }
        if (outCtrl) {
          outCtrl[cmd]();
        }
      })
    }
    this._disabled = val;
  }
  get disabled(): boolean {
    return this._disabled;
  }
  @Input() suggestionsSource: () => Observable<Array<T>>;  //Funkcija, grazinanti observable su visais suggestions
  @Input() formControlName: string;
  @Input() maxVisible = 500;
  private _mapView: (T) => string;
  @Input() set mapView(val: (T) => string | string) //Mapperis suggestiono atvaizdavimui. Jei nera, rodys pati suggestiona. TODO: padaryti input suggestionProp, kad butu galima tiesiog atvaizduoti property is suggestiono.
  {
    this._mapView = typeof val == 'string' ? ((v) => v && v[<string>val] || v) : ((v) => v && val(v) || "");
  }
  get mapView(): (T) => string | string {
    return this._mapView;
  }
  private _mapVal: any;//(T) => any | string;
  @Input() set mapVal(val: (T) => any | string)//Mapperis suggestiono reiksmes emitinimui. Jei inputas naudojamas FormGroup, tai jo reiksme bus gauta per sia funkcija. Jei nera, reiksme bus pats objektas.
  {
    this._mapVal = typeof val == 'string' ? ((v) => v && v[<string>val] || v) : ((v) => v && val(v) || v);
  }
  get mapVal(): (T) => any | string {
    return this._mapVal;
  }

  @Input() clearVo$;

  private _filterFn: Filter = startsWith;
  @Input() set filterFn(val: Filter | string) //Funkcija, naudojama suggestions filtravimui, kai rasomas tekstas. Jei nera, naudojama 'startsWith'
  {
    if (typeof val === "string") {
      switch (val) {
        case "startsWith": this._filterFn = startsWith; break;
        case "contains": this._filterFn = contains; break;
        default:
          this._filterFn = contains;
      }
    } else if (typeof val === "function") {
      this._filterFn = val;
    }
  }

  private _minChars = 0;
  @Input() set minChars(val: number) //Reiksme, nurodanti nuo kokio teksto ilgio rodyti suggestions. Jei ilgas sarasas (pvz 50 000), geriau nurodyti 2 - 3.
  {
    this._minChars = val || 0;
  }
  @Input() set controlName(val: string) {
    this.formControlName = val;
  }
  get controlName(): string {
    return this.formControlName;
  }
  get minChars(): number {
    return this._minChars;
  }
  @Input() initialSelector: Selector; //Selektorius, suveikiantis tik uzkrovus suggestions sarasa ir parenkantis pradine lauko reiksme (radus reiksme, emitinamas eventas 'suggestionSelected')
  @Input() placeholder: string;
  @Input() isTouched: boolean;
  @Input() initialVal;
  @Input() topBarSearch;
  @Input() groupedProduct = false;
  @Input() initialProductId;
  //Outputs
  @Output() suggestionSelected: EventEmitter<T> = new EventEmitter();
  @Output() suggestionObjSelected: EventEmitter<T> = new EventEmitter();

  //Private
  @ViewChild(MatAutocomplete) private _autoComplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) private _trigger: MatAutocompleteTrigger;
  @ViewChild(MatInput, { read: MatInput }) private _input: MatInput;
  @ViewChild('inputField') private _inputEl: ElementRef;
  @ViewChild(MatFormField, { read: MatFormField }) private _formField: MatFormField;
  @ViewChild(FormControlName) _formControlN: FormControlName;
  protected _ctrl: AbstractControl;
  private _outerCtrl: AbstractControl;
  private _valueChanges: Subject<string> = new BehaviorSubject('');
  private __hasFocus: Subject<boolean> = new BehaviorSubject(false);
  protected _hasFocus = this.__hasFocus.pipe(distinctUntilChanged());
  private _cache: Map<string, Array<T>> = new Map();
  private _options: Subject<Array<T>> = new ReplaySubject(1);
  private _visibleOptions: Subject<Array<T>> = new BehaviorSubject([]);
  protected _vo: Observable<Array<T>>;
  protected _isLoading: boolean = false;
  protected _selectedSuggestion: T;
  private _changeFn: Function;
  private _touchFn: Function;
  public _isRequired = false;
  private _lastVal = '';
  private _firstVal = true;

  private _subscriptions: Array<Subscription> = [];

  public textStream: Observable<string> = this._valueChanges.asObservable();

  constructor(
    @Optional() private _controlContainer: ControlContainer,
    @Optional() private _formGroup: FormGroupDirective,
    @Optional() private _ngForm: NgForm,
    private _chg: ChangeDetectorRef,) {
  }

  ngOnInit(): void {
    if (this.clearVo$) {
      this._subscriptions.push(this.clearVo$.subscribe((s) => { this._visibleOptions.next([]); this._cache.clear(); }));
    }
    this._vo = this._visibleOptions.pipe(map((o) => o.length > this.maxVisible ? o.slice(0, this.maxVisible) : o));
    this._ctrl = new UntypedFormControl();
    this._subscriptions.push(this._ctrl.valueChanges.subscribe((v) => {
      if (!v || typeof v === 'string') {
        this._nextVal(v);
      }
    }));
    this._subscriptions.push(this.suggestionObjSelected.subscribe((s) => {
      setTimeout(() => {
        this._nextVal('');
        try {
          this._detectChanges();
        } catch (err) { }
      }, 0);
    }));
    this._subscriptions.push(this.suggestionSelected.subscribe((s) => {
      if (this._changeFn) {
        this._changeFn(s);
      }
      if (this._outerCtrl) {
        this._outerCtrl.setValue(s);
      }
    }));
    if (this._controlContainer && (this.formControlName || this.controlName)) {
      this._outerCtrl = this._controlContainer.control.get(this.formControlName || this.controlName);
      if (this._outerCtrl && this._outerCtrl.validator) {
        //this._ctrl.setValidators(this._outerCtrl.validator);
        const validator = this._outerCtrl.validator({} as AbstractControl);
        this._isRequired = validator && validator.required;
        if (this._isRequired) {
          this._subscriptions.push(this._valueChanges.subscribe((v) => {
            this._ctrl.setErrors(this._selectedSuggestion ? null : { required: true });
          }));
        }
        this._subscriptions.push(this._outerCtrl.statusChanges.subscribe((v) => {
          if (v === 'INVALID') {
            setTimeout(() => { this._ctrl.setErrors(this._outerCtrl.errors); });
          } else if (v === 'VALID') {
            this._ctrl.setErrors(null);
          }
        }));
      }
      this._hasFocus.pipe(filter((f) => f), take(1))
        .subscribe((f) => {
          if (this._outerCtrl) {
            this._outerCtrl.markAsTouched();
          }
          this._ctrl.markAsTouched();
        });
      this.__hasFocus.pipe(filter((f) => !f), skip(1), take(1))
        .subscribe((f) => {
          if (this._isRequired && !this._selectedSuggestion) {
            if (this._outerCtrl) {
              this._outerCtrl.setErrors({ required: true });
            }
            this._ctrl.setErrors({ required: true });
          }
        });
      this._valueChanges.pipe(take(1)).subscribe((v) => {
        if (this._outerCtrl) {
          setTimeout(() => {
            this._outerCtrl.markAsDirty();
            try {
              this._chg.detectChanges();
            } catch (err) { }
          }, 0);
        }
        this._ctrl.markAsDirty();
      });
    }

    //Needed to mark invalid, when trying to submit form
    if (this._controlContainer instanceof FormGroupDirective) {
      (<FormGroupDirective>this._controlContainer).ngSubmit.subscribe((f) => {
        if (this._outerCtrl) {
          if (this._outerCtrl.validator) {
            this._outerCtrl.updateValueAndValidity();
            //const err = this._outerCtrl.validator(this._ctrl);
            //TODO: refactor to function and get rid of accessing 'required' validator
            this._ctrl.setErrors(this._outerCtrl.errors);
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialProductId) {
      setTimeout(() => {
        this.select((pr: any) => pr.id == this.initialProductId);
        this._detectChanges();
      });
    }
  }

  ngAfterViewInit() {
    this._subscriptions.push(this._hasFocus.pipe(filter((f) => f)).subscribe((f) => this._touchFn && this._touchFn(this._ctrl)));
    if (this.initialSelector) {
      //this._options.take(1).subscribe((o) => this.select(this.initialSelector));
      this.select(this.initialSelector);
    }
    if (this.suggestionsSource) {
      this._setupFilter();
      this._loadFromSource();
    }
    setTimeout(() => {
      this._detectChanges()
    }, 0);
  }

  trackByIndex = (index: number): number => {
    return index;
  };

  writeValue(obj: any): void {
    if (this._firstVal) {
      this._firstVal = false;
      this.select((v) => this.mapVal(v) == obj)
    }
  }

  registerOnChange(fn: any): void {
    if (fn instanceof Function) {
      //this._ctrl.registerOnChange(fn);
      this._changeFn = fn;
    }
  }

  registerOnTouched(fn: any): void {
    if (fn instanceof Function) {
      this._touchFn = fn;
    }
    //throw new Error("Method not implemented.");
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    // console.log('SET DISABLED STATE');
    // const ctrl = this._ctrl || this._outerCtrl;
    // console.log(this._ctrl, this._outerCtrl);
    // if (ctrl)
    // {
    //   if (isDisabled)
    //   {
    //     ctrl.disable();
    //   }else
    //   {
    //     ctrl.enable();
    //   }
    // }
    // isDisabled ? this._ctrl.disable() : this._ctrl.enable();
  }

  _autocompleteFocus() {
    setTimeout(_ => {
      try {
        this._chg.detectChanges();
      } catch (err) { }
    });
  }

  public select(selector: Selector) {
    // this._autoComplete.options.changes.take(1)
    //     .subscribe((o) =>
    //                 {
    //                     this._selectFirst();
    //                     /* TODO:
    //                      * Jei sarasas yra PVZ [Skaita, Skaita ECRM], o initialSelector (o) => o.name == 'Skaita',
    //                      * tai paspaudus pirma karta ant paieskos lauko rodys tik 'Skaita'.
    //                      * Atkomentavus sia eilute rodys abu variantus.
    //                      * Reiketu tokia f-ja padaryti konfiguruojama.
    //                      */
    //                     //this._valueChanges.next(active.viewValue);
    //                 });

    this._options.pipe(take(1),
      mergeMap((o) => o), find(<Predicate>selector), filter((r) => r != undefined), toArray())
      .subscribe((o) => {
        if (o.length > 0) {
          this._visibleOptions.next(o);
          if (o && o.length > 0) {
            this._setText(this.mapView(o[0]));
            this._detectChanges();
            this._selectFirst()
          }
        }
      });
  }

  _onEnter(val: string) {
    //this._selectFirst();
  }

  private _selectFirst() {
    const active = this._autoComplete.options.first;
    if (!active) {
      return;
    }
    active.select();
    active.setActiveStyles();
    this._input.value = active.viewValue;
    //this._suggestionSelected(active.value);
    //this._valueChanges.next(active.viewValue);
    (<HTMLInputElement>this._inputEl.nativeElement).setSelectionRange(active.viewValue.length, active.viewValue.length);
  }

  private _setActiveFirst(val: string) {
    setTimeout((t) => {
      const active = this._autoComplete.options.first;
      //const curr = (val && val.toLowerCase()) || "";
      if (!active || !active.viewValue.toLowerCase().startsWith(val.toLowerCase())) {
        return;
      }
      active.setActiveStyles();
      this._input.value = active.viewValue;
      (<HTMLInputElement>this._inputEl.nativeElement).setSelectionRange(val.length, active.viewValue.length);
    });
  }

  protected _onSuggestionSelected(e: MatAutocompleteSelectedEvent) {
    if (e) {
      this._inputEl.nativeElement.value = this.mapView(e.option.value);
      this._suggestionSelected(e.option.value);
    }
  }

  private _suggestionSelected(e: T) {
    if (this._selectedSuggestion !== e) {
      const val = this.mapVal ? this.mapVal(e) : e;
      this._selectedSuggestion = e;
      this.suggestionObjSelected.emit(e);
      this.suggestionSelected.emit(val);
    }
    this._inputEl.nativeElement.blur();
  }

  private _selectionChanged(e: MatOptionSelectionChange) {
    if (e.source.selected) {
      this._suggestionSelected(e.source.value);
    }
  }

  private _loadFromSource() {
    this._isLoading = true;
    this._subscriptions.push(this.suggestionsSource().pipe(filter((s) => s && (s.length > 0 || this.groupedProduct)), tap(() => {
      if (this.topBarSearch) {
        this._ctrl && this._ctrl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        setTimeout(() => {
          this._inputEl.nativeElement.blur();
          try {
            this._chg.detectChanges();
          } catch (e) { }
          this._inputEl.nativeElement.focus();
          try {
            this._chg.detectChanges();
          } catch (e) { }
        }, 0)
      }
    }))
      .subscribe((s) => {
        if (s.length > 1000 && !this.minChars) {
          this.minChars = 3;
        }
        this._options.next(<Array<T>>s);
        if (this.initialProductId) {
          setTimeout(() => {
            this.select((pr: any) => pr.id == this.initialProductId);
            this._detectChanges();
          });
        }
        this._isLoading = false;
        this._detectChanges();
      }));
  }

  private _setupFilter() {
    const visibleChanges = new Subject<string>();
    this._subscriptions.push(
      this._valueChanges.pipe(filter((c) => {
        return c && c.length < this.minChars
      }))
        .subscribe((f) => { this._visibleOptions.next([]); this._cache.clear(); })
    );
    this._subscriptions.push(
      this._options.pipe(switchMap((o) => visibleChanges.pipe(map((val) => ({ val: val, all: o })))), withLatestFrom(this._visibleOptions.pipe(startWith([])), (o, v) => ({ ...o, visible: v })),
        scan((prev, curr) => {
          const val = (curr.val || '').toLowerCase();
          const fltr = (e: T) => {
            const v = this.mapView(e);
            if (!v) {
              return false;
            }
            return this._filterFn((this.mapView(e) || '').toLowerCase(), val)
          };
          if (curr.val === '') {
            this._cache.clear();
            return { val: curr.val, all: curr.all, visible: curr.all };
          }
          if ((this._filterFn.toString() === startsWith.toString() || this._filterFn.toString() === contains.toString()) && curr.visible.length > 0) {
            if ((prev.val.length > 0 && curr.val.startsWith(prev.val))) {
              const v = curr.visible.filter(fltr);
              this._cache.set(curr.val, v);
              return { val: curr.val, all: curr.all, visible: v };
            } else if (prev.val.startsWith(curr.val)) {
              const v = this._cache.get(curr.val) || curr.all.filter(fltr);
              this._cache.delete(prev.val);
              return { val: curr.val, all: curr.all, visible: v };
            }
          }
          this._cache.clear();
          return { val: curr.val, all: curr.all, visible: curr.all.filter(fltr) };
        }
          , { val: '', all: [], visible: [] }))
        .subscribe((f) => {
          this._visibleOptions.next(f.visible);
          this._detectChanges();
        })
    );

    this._subscriptions.push(
      this._valueChanges.pipe(map((vc) => ({ curr: vc, prev: '.' })), scan((prev, curr) => {
        return ({ curr: curr.curr, prev: prev && prev.curr });
      })).subscribe((a) => {
        if (!(a && a.curr && a.prev && a.curr.length >= this.minChars && (a.curr.length > a.prev.length))) //Jei trynimas
        {
          return;
        }
        this._setActiveFirst(a.curr);
      })
    );
    this._subscriptions.push(
      this._hasFocus.pipe(filter((f) => !f),
        withLatestFrom(this._valueChanges, (_, v) => v))
        .subscribe((v) => {
          !this._selectedSuggestion && this._setText(v)
        })
    );
    this._subscriptions.push(this._options.pipe(switchMap((o) => this._hasFocus.pipe(filter((f) => f), combineLatest(this._valueChanges.pipe(filter((v) => v != null), startWith(''), filter((c) => c.length >= this.minChars)), (f, v) => v)))).subscribe((v) => visibleChanges.next(v)));
  }

  public focus() {
    this._inputEl.nativeElement.focus();
    this.__hasFocus.next(true);
  }

  public deselect() {
    this._deselect();
    this._setText(null);
  }

  _deselect() {
    this._ctrl.setValue(null);
    if (this._outerCtrl) {
      this._outerCtrl.setValue(null);
    }
    this._selectedSuggestion = null;
    this.suggestionObjSelected.emit(null);
    this.suggestionSelected.emit(null);
    const a = this._autoComplete.options.find((o) => o.active);
    if (a) {
      a.setInactiveStyles();
    }
  }

  public setText(txt: string) {
    this._setText(txt);
    this._nextVal(txt);
  }

  private _setText(txt: string) {
    this._input.value = txt;
  }

  private _nextVal(txt: string) {
    this._valueChanges.next(txt || '');
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  _focus(f: boolean) {
    this.__hasFocus.next(f);
  }

  private _detectChanges() {
    try {
      this._chg.detectChanges();
    } catch (e) { }
  }
}
