import { AfterContentChecked, Directive, ElementRef, OnChanges, OnInit } from '@angular/core';
import anchorme from "anchorme";

@Directive({
  selector: '[linkify]'
})
export class LinkifyDirective implements OnInit, OnChanges, AfterContentChecked {

  constructor(private _elementRef: ElementRef) { }

  ngOnInit(): void {
  }
  ngOnChanges() {
  }

  ngAfterContentChecked(): void {
    const html: HTMLElement = this._elementRef.nativeElement;
    const content = html.innerHTML;
    if (!content || content.length < 4) {
      return;
    }
    const linkified = anchorme(content);
    html.innerHTML = linkified;
  }
}
