import { EditableTextDirective } from './proto/directives/editableText.directive';
import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule, Location } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TabsModule } from "ngx-bootstrap/tabs";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PopoverModule } from "ngx-bootstrap/popover";
import { NgChartsConfiguration, NgChartsModule } from "ng2-charts";

//Wizard
//import {FormWizardModule} from "angular2-wizard";
//Form Validation
// https://github.com/yuyang041060120/ng2-validation

//// !!!!!! import { CustomFormsModule } from "ng2-validation";

import { FooterComponent } from "../layout/footer/footer.component";
import { AppBackdropComponent } from "./components/app_backdrop/app_backdrop.component";
import { SelectOptionsComponent } from "./components/select-options/select-options.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { UploadFileComponent } from "./components/upload-file/upload-file.component";
import { FileUploadModule } from 'ng2-file-upload';
import { ButtonDropdownComponent } from "./components/dropdowns/button-dropdown/button-dropdown.component";
import { DateIntervalComponent } from "../../components/filters/date-interval/dateInterval.component";
import { SuggestionsComponent } from "../../components/suggestions/suggestions.component";
import { ProtoFlagDirective } from "./proto/flags/flag.directive";
import { ProtoFlagsGroupComponent } from "./proto/flags/flagsGoup.component";
import { PageLengthDirective } from "./proto/dataTable/paging/pageLength/pageLength.directive";
import { ServerTableComponent } from "./proto/dataTable/serverTable/serverTable.component";
import { DateBtnIntervalComponent } from '../../components/filters/date-btn-interval/dateBtnInterval.component';
import { StoreDirective } from "./proto/storage/store.directive";
import { StorableDirective } from "./proto/storage/storable.directive";
import { RoutingService } from "./proto/services/routing.service";

import { ListModalComponent } from "./components/modals/listModal/listModal.component";
import { LoadingDirective } from "./proto/loading/loading.directive";
import { LoaderButtonComponent } from "./components/buttons/loaderButton/loaderButton.component";
import { QueryPartDirective } from "./proto/common/query/queryPart.directive";
import { QueryProviderDirective } from "./proto/common/query/queryProvider.directive";
import { RowsCountComponent } from '../../components/dropLists/rows-count/rows-count.component';
import { ProtoInputComponent } from "./proto/components/input/protoInput.component";
import { ImageModalComponent } from './components/modals/image-modal/image-modal.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  LoadableDataDirective,
  StateEmptyDirective,
  StateErrorDirective,
  StateLoadedDirective,
  StateLoadingDirective
} from './proto/common/loadable/loadable.directives';
import { TimeInputDirective } from "./directives/time-input/time-input.directive";
import { PreventFormSubmitDirective } from './directives/prevent-form-submit.directive';
import { ListsDataService } from "../../services/lists/lists.data.service";
import { LinkifyDirective } from './directives/linkify.directive';
import { TextBoxComponent } from './components/text-box/text-box.component';
import { TbEditDirective } from './components/text-box/tb-edit.directive';
import { TbSubmitDirective } from './components/text-box/tb-submit.directive';
import { TbCancelDirective } from "./components/text-box/tb-cancel.directive";
import { LengthListenerDirective } from './directives/input/length-listener.directive';
import { SplitByPipe } from './pipes/split-by.pipe';
import { StrEditPipe } from './pipes/str-edit.pipe';
import { SmileComponent } from './components/smile/smile.component';
import { TimerComponent } from './components/timer/timer.component';
import { TimersSummaryComponent } from './components/timer/timers-summary/timers-summary.component';
import { TimerService } from "./services/timer/timer-service";
import { TimersBackend } from "../../backend/timers.backend";
import { TimerDialogComponent } from './components/timer/timer-dialog/timer-dialog.component';
import { TbContentDirective } from "./components/text-box/tb-content.directive";
import { MoneyPipe } from "./pipes/money.pipe";
import { SendDpaModalComponent } from './components/modals/send-dpa-modal/send-dpa-modal.component';
import { SortPipe } from "./pipes/sort.pipe";
import { DateIntervalAltComponent } from '../../components/filters/date-interval-alt/dateIntervalAlt.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { APP_VERSION } from './shared.help';
import { PagerComponent } from './components/pager/pager.component';
import { FilterPipe } from './pipes/filter.pipe';
import { EditableTextComponent } from './proto/components/editableText/editableText.component';
import { ListComponent } from './proto/components/list/list.component';
import { PageLengthComponent } from './proto/dataTable/paging/pageLength/pageLength.component';
import { InfoLoaderComponent } from './components/info-loader/info-loader.component';
import { ProjectEditModalComponent } from '../../components/project-edit-modal/project-edit-modal.component';
import { DynamicProjectServiceFieldComponent } from '../../components/dynamic-project-service-field/dynamic-project-service-field.component';
import { FilterFilesPipe } from './pipes/filter-files.pipe';
import { SelectChildTaskModalComponent } from '../../taskboard/components/select-child-task-modal/select-child-task-modal.component';
import { CalendarCustomHeaderComponent } from '../../components/filters/date-interval-alt/calendarCustomHeader.component';
import { FilterByPropertyPipe } from './pipes/filter-by-property.pipe';
import { MatTabsModule } from '@angular/material/tabs';

import { CustomDateAdapter } from "../../utils/dateAdapter";
import {
  DateAdapter,
  // MAT_DATE_FORMATS,
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_LOCALE
  // MAT_HAMMER_OPTIONS 
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { Platform } from '@angular/cdk/platform';
import { CommentsModalComponent } from './components/modals/comments-modal/comments-modal.component';
import { CommentsListComponent } from '../../taskboard/components/comments/comments-list/comments-list.component';
import { QuillModule } from 'ngx-quill';
import { FilesDropdownComponent } from '../../components/files/files-dropdown/filesDropdown.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatInputModule,

    MatDatepickerModule,
    MatNativeDateModule,

    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatMenuModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgChartsModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    NgxDatatableModule,
    FileUploadModule,
    MatAutocompleteModule,
    NgxPermissionsModule.forChild(),
    FontAwesomeModule,
    MatTabsModule,
    QuillModule,
  ],
  declarations: [
    RowsCountComponent,
    ProtoInputComponent,
    AppBackdropComponent,
    FooterComponent,
    EditableTextComponent,
    SelectOptionsComponent,
    UploadFileComponent,
    ButtonDropdownComponent,
    DateIntervalComponent,
    DateIntervalAltComponent,
    CalendarCustomHeaderComponent,
    SuggestionsComponent,
    QueryPartDirective,
    ProtoFlagDirective,
    ProtoFlagsGroupComponent,
    PageLengthDirective,
    ServerTableComponent,
    QueryProviderDirective,
    StoreDirective,
    StorableDirective,
    DateBtnIntervalComponent,
    ListModalComponent,
    LoadingDirective,
    LoaderButtonComponent,
    ImageModalComponent,
    LoadableDataDirective,
    StateLoadedDirective,
    StateEmptyDirective,
    StateErrorDirective,
    StateLoadingDirective,
    EditableTextDirective,
    TimeInputDirective,
    PreventFormSubmitDirective,
    LinkifyDirective,
    TextBoxComponent,
    TbEditDirective,
    TbSubmitDirective,
    TbCancelDirective,
    TbContentDirective,
    LengthListenerDirective,
    SplitByPipe,
    FilterByPropertyPipe,
    StrEditPipe,
    SmileComponent,
    TimerComponent,
    TimersSummaryComponent,
    TimerDialogComponent,
    MoneyPipe,
    SendDpaModalComponent,
    SortPipe,
    PagerComponent,
    FilterPipe,
    ListComponent,
    PageLengthComponent,
    InfoLoaderComponent,
    ProjectEditModalComponent,
    DynamicProjectServiceFieldComponent,
    FilterFilesPipe,
    CommentsModalComponent,
    CommentsListComponent,
    FilesDropdownComponent,
  ],
  exports: [
    InfoLoaderComponent,
    RowsCountComponent,
    CommonModule,
    MoneyPipe,
    FilterPipe,
    SortPipe,
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatInputModule,

    MatDatepickerModule,
    MatNativeDateModule,

    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatMenuModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTooltipModule,
    AppBackdropComponent,
    ReactiveFormsModule,
    TabsModule,
    BsDropdownModule,
    PopoverModule,
    NgChartsModule,
    SelectOptionsComponent,
    NgxDatatableModule,
    ProtoInputComponent,
    UploadFileComponent,
    ButtonDropdownComponent,
    DateIntervalComponent,
    DateIntervalAltComponent,
    CalendarCustomHeaderComponent,
    SuggestionsComponent,
    ProtoFlagsGroupComponent,
    ProtoFlagDirective,
    PageLengthDirective,
    ServerTableComponent,
    QueryPartDirective,
    QueryProviderDirective,
    StoreDirective,
    StorableDirective,
    DateBtnIntervalComponent,
    RouterModule,
    ListModalComponent,
    LoadingDirective,
    LoaderButtonComponent,
    MatAutocompleteModule,
    ImageModalComponent,
    NgxPermissionsModule,
    LoadableDataDirective,
    StateLoadedDirective,
    StateEmptyDirective,
    StateErrorDirective,
    StateLoadingDirective,
    EditableTextDirective,
    TimeInputDirective,
    PreventFormSubmitDirective,
    LinkifyDirective,
    TextBoxComponent,
    TbEditDirective,
    TbSubmitDirective,
    TbCancelDirective,
    LengthListenerDirective,
    SplitByPipe,
    FilterByPropertyPipe,
    StrEditPipe,
    SmileComponent,
    TimerComponent,
    SendDpaModalComponent,
    FontAwesomeModule,
    PagerComponent,
    ListComponent,
    PageLengthComponent,
    ProjectEditModalComponent,
    DynamicProjectServiceFieldComponent,
    FilterFilesPipe,
    MatTabsModule,
    CommentsModalComponent,
    CommentsListComponent,
    QuillModule,
    FilesDropdownComponent,
  ],
  providers: [
    TimersBackend,

    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform] },
    // { provide: MAT_DATE_FORMATS, useValue: AppDateFormat },
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ListsDataService,

        { provide: TimersBackend, useClass: TimersBackend },
        { provide: TimerService, useClass: TimerService },

        { provide: RoutingService, useClass: RoutingService, deps: [Router, Location] },
        // { provide: StorageService, useClass: StorageService, deps: [RoutingService] },
      ],
    };
  }
}
