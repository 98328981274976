
import { toArray, filter, mergeMap, catchError } from 'rxjs/operators';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TasksBackend } from "../../../../backend/tasks.backend";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { TaskReview } from "../../../models/task-review";
import { of as observableOf, Subscription, throwError } from 'rxjs';
import swal from 'sweetalert2';
import { CalendarCustomHeaderComponent } from '../../../../components/filters/date-interval-alt/calendarCustomHeader.component';

@Component({
  selector: 'update-task-deadline',
  templateUrl: './update-task-deadline.component.html',
  styleUrls: ['./update-task-deadline.component.scss']
})
export class UpdateTaskDeadlineComponent implements OnInit, OnDestroy {

  protected _submitting = false;
  protected _submitted = false;
  componentType: 'deadLine' | 'dateStart';
  ingoingFieldName: 'dateDeadline' | 'dateStart';
  outgoingFieldName: 'deadline' | 'dateStart';
  task: TaskReview;
  _deadlineForm: UntypedFormGroup;
  subs: Subscription[] = [];
  calendarCustomHeaderComponent: any = CalendarCustomHeaderComponent;
  placeholder: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private tasksBackend: TasksBackend,
    public dialogRef: MatDialogRef<UpdateTaskDeadlineComponent>,
    private _formBuilder: UntypedFormBuilder) {
    this.task = data.task;
    this.componentType = data.componentType;
    if (this.componentType == 'deadLine') {
      this.ingoingFieldName = 'dateDeadline';
      this.outgoingFieldName = 'deadline';
      this.placeholder = 'Termino data';
    }
    if (this.componentType == 'dateStart') {
      this.ingoingFieldName = 'dateStart';
      this.outgoingFieldName = 'dateStart';
      this.placeholder = 'Vykdymo data';
    }
  }

  ngOnInit() {
    this._buildForm();
  }

  private _buildForm() {
    this._deadlineForm = this._formBuilder.group({
      date: [this.task[this.ingoingFieldName] ? new Date(this.task[this.ingoingFieldName]) : null, Validators.required],
      text: [null, this.componentType == 'deadLine' ? Validators.required : null],
    });
  }

  dateChanged(el) {
    el.close && el.close();
  }

  protected _submit() {
    this._submitting = true;
    const date = new Date(this._deadlineForm.controls.date.value);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    if (this.componentType == 'deadLine') {
      this.subs.push(this.tasksBackend.updateTaskDeadline(this.task.id, { deadline: date, deadlineComment: this._deadlineForm.controls.text.value, recountDeadline: 0 })
        .pipe(catchError((err) => {
          console.log('caught error', err);
          return throwError(err);
        }))
        .subscribe((res: any) => {
          this._submitting = false;
          this._submitted = true;
          if (res && res.success) {
            this.dialogRef.close({ success: true });
          } else {
            this.dialogRef.close({ success: false, errorMessage: res && res.error });
          }
        }, (err) => {
          this._submitting = false;
          swal.fire({
            title: 'Įvyko klaida',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          });
        }));
    }
    if (this.componentType == 'dateStart') {
      this.subs.push(this.tasksBackend.updateStartDateType(this.task.id, date)
        .pipe(catchError((err) => {
          console.log('caught error', err);
          return throwError(err);
        }))
        .subscribe((resR: any) => {
          const res = resR.body;
          this._submitting = false;
          this._submitted = true;
          if (res && res.success) {
            this.dialogRef.close({ success: true });
          } else {
            this.dialogRef.close({ success: false, errorMessage: res && res.error });
          }
        }, (err) => {
          this._submitting = false;
          swal.fire({
            title: 'Įvyko klaida',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          });
        }));
    }
  }

  protected _cancel() {
    this.dialogRef.close({ success: null });
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
