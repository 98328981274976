
import { map } from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LIST } from "../../../../services/lists/lists.data.service";
import { TasksBackend } from "../../../../backend/tasks.backend";

@Component({
  selector: 'distribute-task',
  templateUrl: './distribute-task.component.html',
  styleUrls: ['./distribute-task.component.scss']
})
export class DistributeTaskComponent implements OnInit {

  EMPLOYEES = LIST.EMPLOYEES;
  TASK_TYPES = LIST.TASK_TYPES_FILTER;

  _selectedEmployee;
  _selectedType;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private _dialogRef: MatDialogRef<DistributeTaskComponent>,
    private _tasksBackend: TasksBackend) { }

  ngOnInit() {
  }

  _commit() {
    const employee = this._selectedEmployee;
    const type = this._selectedType;
    if (employee && type) {
      this._dialogRef.close(this._tasksBackend.distributeTask(this.data.id, { executorId: employee.MusuAsmenysId, typeId: type.id }).pipe(map(_ => ({ executor: employee.Vardas + ' ' + employee.Pavarde, type: type.name }))));
    }
  }
}
