
import { map, pluck, withLatestFrom, filter, tap, delay } from 'rxjs/operators';
import { UPDATE_TASKBOARD_LIST } from '../../state/state';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject } from "rxjs";
import { PARTNER, SELECTED_MARKETING_TASKS, TYPE } from "../../taskboard/taskboard.help";
import { TableModel } from "../../template/shared/proto/dataTable/serverTable/TableModel";
import { PRIORITY_ABBR, PRIORITY_COLORS, PRIORITY_TOOLTIPS } from "../../taskboard/taskboard";
import { StateService } from "../../services/state/stateService";
import { TOGGLE_LEFT_MENU } from "../../state/state";
import { StorableDirective } from "../../template/shared/proto/storage/storable.directive";
import { StorageOptions } from "../../template/shared/proto/storage/StorageOptions";
import { StorageService } from "../../template/shared/proto/storage/storage.service";
import {
  ColumnResized,
  ServerTableComponent
} from "../../template/shared/proto/dataTable/serverTable/serverTable.component";
import { QueryProviderDirective } from "../../template/shared/proto/common/query/queryProvider.directive";
import { TplService } from "../../taskboard/services/tpl.service";
import { TaskboardDataService } from "../../taskboard/services/taskboard-data.service";
import { TASK_CHILD_ENTERED, TASK_CLIENT_COMMENTED, TASK_JOB_ENTERED, TASK_STATE_CHANGED } from "../../taskboard/common/constants";
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { faBars, faDownload, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { isActiveState } from '../../taskboard/common/functions';

import { MarketingCampaignBackend } from '../../backend/marketing-campaign.backend';
import * as XLSX from 'xlsx';

const cloneArr = (arr: object[]) => arr.map(o => ({ ...o }));

const applyCurrentPage = (q: any) => {
  if (q.pageLength > 0) {
    return {
      ...q,
      startFrom: (q.page) * q.pageLength
    };
  } else {
    q.pageLength = 99999999;
    return {
      ...q,
      startFrom: 0
    };
  }
};
const defaultParams = (query: any, p: any = {}) => applyCurrentPage(({ ...query, ...p }));
const noAddition = (query: any) => defaultParams(query);
const withAddition = (paramsToAdd: any) => (query: any) => defaultParams(query, paramsToAdd);
const withCondition = (condition: (params: any) => any) => (query: any) => defaultParams(query, condition(query));

// const projectLink = (r: any) => r && r.taskId ? '#/projects/v/' + r.taskId : 'javascript:void(0)';

const MAX_COLUMN_WIDTH = 600;
const MIN_COLUMN_WIDTH = 75;

const firstElem: (nl: NodeList) => HTMLElement = (nl: NodeList) => {
  if (!nl) {
    return;
  }
  for (let i = 0; i < nl.length; i++) {
    const n = nl[i];
    if (n.nodeType === Node.ELEMENT_NODE) {
      return <HTMLElement>n;
    }
  }
};

const calcTextCellWidth = (el: HTMLElement) => {
  const f = firstElem(el.childNodes);
  const ff = firstElem(f.childNodes);
  if (!f) {
    return 0;
  }
  return Math.max(Math.min(ff.offsetWidth + 10 || MAX_COLUMN_WIDTH), MIN_COLUMN_WIDTH);
};

const markIfNotSeen = (row: any) => {
  const state = parseInt(row.stateForUser);
  if (state == 0) {
    return '';
  }
  if (state < 0) {
    return 'not-seen';
  }
  let classList = '';
  if (state & TASK_CLIENT_COMMENTED) {
    classList += 'not-seen task-client-commented';
  }
  if (state & TASK_CHILD_ENTERED) {
    classList += ' task-child-entered';
  }
  if (state & TASK_JOB_ENTERED) {
    classList += ' task-job-entered';
  }
  if (state & TASK_STATE_CHANGED) {
    classList += ' task-state-changed';
  }
  return classList;
};

@Component({
  selector: "marketing-campaigns",
  templateUrl: "./marketing-campaigns.component.html",
  styleUrls: ["./marketing-campaigns.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketingCampaignsComponent implements OnInit, AfterViewInit, OnDestroy {

  loading: any;

  @ViewChildren('filterStrbl') _storable: QueryList<StorableDirective>;
  @ViewChild('searchField') _searchField: ElementRef;
  @ViewChild(QueryProviderDirective) _queryProvider: QueryProviderDirective;
  @ViewChild(ServerTableComponent) _serverTable: ServerTableComponent;
  @ViewChild('headerTmp', { read: TemplateRef, static: true }) headerTmp: TemplateRef<any>;
  @ViewChild('headerCnt', { read: ViewContainerRef }) headerCnt: ViewContainerRef;
  protected _priorityColors = PRIORITY_COLORS;
  protected _priorityAbbr = PRIORITY_ABBR;
  protected _priorityTooltips = PRIORITY_TOOLTIPS;
  protected _selectedRows: Array<any> = [];
  protected _showSearchClear = false;
  protected ctm;  //current table model
  protected _isVisible: boolean = false;
  protected _tm: TableModel;
  protected _currentPage: number = 3;
  private _pageSavingOptions: StorageOptions = {
    popState: true,
    refresh: true
  };
  private isFocused: boolean;
  private _lastId: any;
  private _colSettings: Array<any> = [];
  private _now = new Date();

  campaignsId: any;

  //Nustatymai skirti lenteles filtravimui, paieskai, cia nurodomi nustatymai ProtoFlagsGroup komponentui
  private marketingCampaignsCtm = {
    hasButton: true,
    showTaskTypeFilter: false,
    hasDateInterval: true,
    hasDownloadExcelButton: true,
    products: true,
  };

  private marketingTasksCtm = {
    hasButton: true,
    showTaskTypeFilter: true,
    hasDateInterval: true,
    clientTypes: true,
    products: true,
    sources: true,
    isTasks: true,
    filtering: {
      flagsGroup: '1100000000',
      rules: {
        '0000000000': '1100000000',
      },
      flags: [
        {
          flag: 'new',
          label: 'Naujos'
        },
        {
          flag: 'ownerCommented',
          label: 'U-Patikslintos'
        },
        {
          flag: 'seen',
          label: 'Peržiūrėtos'
        },
        // {
        //   flag: 'distributed',
        //   label: 'Paskirstytos'
        // },
        {
          flag: 'completed',
          label: 'Atliktos'
        },
        {
          flag: 'finished',
          label: 'Baigtos'
        },
        {
          flag: 'nonCompleted',
          label: 'Neatliktos'
        },
        {
          flag: 'rejected',
          label: 'Atmestos'
        },
        {
          flag: 'returnToAnalytic',
          label: 'Grąžinta analitikui'
        },
        {
          flag: 'postponed',
          label: 'Atidėta'
        },
        {
          flag: 'won',
          label: 'Laimėta'
        },
      ]
    }
  };
  private campaignMarketingTasksCtm = {
    hasButton: true,
    showTaskTypeFilter: true,
    hasDateInterval: true,
    clientTypes: true,
    sources: true,
    products: true,
    isTasks: true,
    filtering: {
      flagsGroup: '1100000000',
      rules: {
        '0000000000': '1100000000',
      },
      flags: [
        {
          flag: 'new',
          label: 'Naujos'
        },
        {
          flag: 'ownerCommented',
          label: 'U-Patikslintos'
        },
        {
          flag: 'seen',
          label: 'Peržiūrėtos'
        },
        // {
        //   flag: 'distributed',
        //   label: 'Paskirstytos'
        // },
        {
          flag: 'completed',
          label: 'Atliktos'
        },
        {
          flag: 'finished',
          label: 'Baigtos'
        },
        {
          flag: 'nonCompleted',
          label: 'Neatliktos'
        },
        {
          flag: 'rejected',
          label: 'Atmestos'
        },
        {
          flag: 'returnToAnalytic',
          label: 'Grąžinta analitikui'
        },
        {
          flag: 'postponed',
          label: 'Atidėta'
        },
        {
          flag: 'won',
          label: 'Laimėta'
        },
      ]
    }
  };

  private _tableConstructors = {
    marketingCampaigns: ({
      ...this.marketingCampaignsCtm,
      toYear: false,
      new: ['/marketing/new'],
      id: 'marketingCampaigns',
      title: 'Marketingo kampanijos',
      buttonTooltip: 'Sukurti naują kampaniją'
    }),
    marketingTasks: ({
      ...this.marketingTasksCtm,
      // new: ['/marketing/new'],
      toYear: true,
      hasButton: false,
      id: 'marketingTasks',
      salesExecutors: true,
      title: 'Visos užduotys'
    }),
    campaignMarketingTasks: ({
      ...this.campaignMarketingTasksCtm,
      toYear: true,
      new: ['/marketing/new'],
      id: 'campaignMarketingTasks',
      title: 'Kampanijos užduotys',
      buttonTooltip: 'Sukurti naują užduotį',
      salesExecutors: true,
    }),
  };
  private _subscriptions: Array<Subscription> = [];
  private _rowClicks: Subject<any> = new Subject();
  triggerInit: Subject<any> = new Subject();

  faBars = faBars;
  faPlusSquare = faPlusSquare;
  faDownload = faDownload;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _chg: ChangeDetectorRef,
    private _stateService: StateService,
    private _storageService: StorageService,
    private _taskboardDataService: TaskboardDataService,
    private _tplService: TplService,
    private marketingCampaignBackend: MarketingCampaignBackend,
  ) { }

  @ViewChildren('dropdown') dropdown: QueryList<BsDropdownDirective>;

  ngOnInit(): void {
    this._subscriptions.push(this._route.params.subscribe(p => {
      this.campaignsId = p['id'];
    }))
  }

  ngAfterViewInit(): void {
    this._subscriptions.push(this.dropdown.changes.subscribe((comps: QueryList<BsDropdownDirective>) => {
      if (comps.first) {
        comps.first.show();
        comps.first.hide();
      }
    }));
    this._initTableModel();
    // this._subscriptions.push(this._stateService.getStream(JOB_ENTERED).subscribe((j) => {
    //   this._serverTable.refreshData();
    // }));
    this._subscriptions.push(this._stateService.getStream(UPDATE_TASKBOARD_LIST).subscribe((fn) => {
      this._serverTable.updateRecords(fn);
    }));
    this._subscriptions.push(
      this._rowClicks.pipe(
        withLatestFrom(
          this._route.params.pipe(pluck(TYPE)).pipe(

            withLatestFrom(
              this._route.queryParams.pipe(pluck(PARTNER)),
              (p, q) => ({ params: p, partner: q })
            )), (row, t) => ({ row: row, t: t })
        ), filter((rt) => rt.t != null && rt.row && rt.row.campaignsId)).subscribe((rt) => {
          console.log('navigate');
          // this._router.navigate(['/', MARKETING, rt.row.campaignsId]);
        }));
    this.triggerInit.subscribe(() => {
      this._subscriptions.push(this._route.data.pipe(delay(0)).subscribe((d) => {
        this._isVisible = false;
        this._selectedRows = [];
        //Parenkamas atitinkamas konstruktorius
        this.ctm = this._tableConstructors[d.tableType];
        if (!this.ctm) {
          return;
        }
        //Atstatomas pasirinktas eilutes ID, tam, kad matytusi ant kurios eilutes buvo paspausta
        this._lastId = this._storageService.get(this.ctm.id, null, {
          popState: true,
          static: true
        });
        //Pakraunami pasirinkti stulpeliai ir ju plociai
        let colSettings = this._storageService.get(this.ctm.id + '.colSettings') || [];
        let sortSettings = this._storageService.get(this.ctm.id + '.sort');
        //Gaunamas reikiamas TableModel
        let __tm = this._tModels.find((tm) => tm.name == d.tableType);

        //Aplungiami TableModel nustatymai, su issaugotais atmintyje
        for (let i = 0; i < __tm.columns.length; i++) {
          let cs = colSettings.find((s) => s.name == __tm.columns[i].name);
          if (cs) {
            __tm.columns[i].visible = cs.visible != undefined ? cs.visible : true;
            __tm.columns[i].width = cs.width || __tm.columns[i].width || 200;
            __tm.columns[i].index = cs.index || i;
          } else {
            __tm.columns[i].width = __tm.columns[i].width || 200;
            __tm.columns[i].index = i;
          }
        }
        if (sortSettings && sortSettings.prop) {
          let _c = __tm.columns.find((c) => c.prop == sortSettings.prop);
          if (_c) {
            let _dc = __tm.columns.find((c) => !!c.sorted);
            if (_dc) {
              delete _dc['sorted'];
            }
            _c.sorted = { order: sortSettings.order };
          }
        } else {
        }
        this._colSettings = colSettings;
        this._tm = __tm;
        //Perkraunama lentele
        try {
          this._chg.detectChanges();
        } catch (err) { }
        this._isVisible = true;
        this._currentPage = this._storageService.get('currentPage', 0, this._pageSavingOptions);
        try {
          this._chg.detectChanges();
        } catch (err) { }
        //Atstamoma paieskos lauko reiksme
        this._searchField ? this._searchField.nativeElement.value = this._storageService.get(this.ctm.id + '.search', undefined, {
          popState: true,
          refresh: true,
          sameRoute: true,
        }) || '' : null;
        this._searchField && this._searchField.nativeElement.value != '' ? this._searchField.nativeElement.dispatchEvent(new Event('keyup')) : {};

        const sortingQp = this._serverTable && this._serverTable.getSortingQueryPart();
        this._queryProvider && this._queryProvider.registerQueryPart(sortingQp);

        const pagingQp = this._serverTable && this._serverTable.getPagingQueryPart();
        this._queryProvider && this._queryProvider.registerQueryPart(pagingQp);

        if (this._queryProvider) this._queryProvider.active = true;
        //Trigerinama paieska
        this._queryProvider && this._queryProvider.emit('sort');
        this._queryProvider && this._queryProvider.emit('page');
        this._queryProvider && this._queryProvider.emit('search');
      }));
    });
    this._subscriptions.push(this._taskboardDataService.refreshDatatable.subscribe((e) => {
      this._queryProvider.emit('search');
    }));
    this.triggerInit.next(true);
  }

  downloadExcelExampleClick() {
    const docTypeFields = {};
    const wscols = [];
    docTypeFields['Product'] = '';
    wscols.push({ width: 10 });
    docTypeFields['Source'] = '';
    wscols.push({ width: 10 });
    docTypeFields['ClientType'] = '';
    wscols.push({ width: 10 });
    docTypeFields['Email'] = '';
    wscols.push({ width: 30 });
    docTypeFields['FirstName'] = '';
    wscols.push({ width: 15 });
    docTypeFields['LastName'] = '';
    wscols.push({ width: 20 });
    docTypeFields['Company'] = '';
    wscols.push({ width: 25 });
    docTypeFields['Partner'] = '';
    wscols.push({ width: 25 });
    docTypeFields['PhoneNumber'] = '';
    wscols.push({ width: 20 });
    docTypeFields['DateFrom'] = '';
    wscols.push({ width: 15 });
    docTypeFields['DateTo'] = '';
    wscols.push({ width: 15 });
    docTypeFields['State'] = '';
    wscols.push({ width: 8 });
    docTypeFields['Participated'] = '';
    wscols.push({ width: 20 });
    docTypeFields['TimePercent'] = '';
    wscols.push({ width: 10 });
    docTypeFields['Executor'] = '';
    wscols.push({ width: 20 });
    docTypeFields['Script'] = '';
    wscols.push({ width: 20 });
    docTypeFields['Comment'] = '';
    wscols.push({ width: 20 });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([docTypeFields]);
    ws['!cols'] = wscols;
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    XLSX.writeFile(workbook, `${'Kampaniju importo pavyzdys.xlsx'}`);
  }

  _onSort(e) {
    this._storageService.store(this.ctm.id + '.sort', {
      prop: e.prop,
      order: e.order
    });
  }

  _addNewClicked(ctmId, campaignsId) {
    this._isVisible = false;
    if (campaignsId) {
      this._router.navigate(['/marketing', campaignsId, 'nt']);
    } else {
      this._router.navigate(this.ctm.new);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  protected _sortingMapper = (v) => {
    let col = v.prop ? this._tm.columns.find((c) => c.prop == v.prop) : this._tm.columns.find((c) => c.sortable !== false);
    return col ? ({
      orderBy: col.prop,
      orderDir: v.order
    }) : v;
  };

  protected _recalcColumns() {
    const table = this._serverTable;
    if (table) {
      table.calculateColumns();
    }
    try {
      this._chg.detectChanges();
    } catch (err) { }
  }

  protected _pageChanged(page) {
    this._storageService.store('currentPage', page, this._pageSavingOptions);
  }

  protected _untilToday(event) {
    return {
      ...event,
      to: 'today'
    };
  }

  private makeQuery = (query: string) => (additionalParams: (q: any) => any) => (params: any) => {
    const p = additionalParams(params);
    switch (query) {
      case 'getMarketingCampaigns':
        return this.marketingCampaignBackend.getMarketingCampaignsList(p || params).pipe(map((entities: any) => {
          this.reloadPageOnCondition(p || params, entities.entities);
          return {
            data: entities.entities.map(el => ({ ...el, id: el.taskId, isActive: isActiveState(el.stateId) })),
            count: entities.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getMarketingTasks':
        return this.marketingCampaignBackend.getAllMarketingTasks(p || params).pipe(map((entities: any) => {
          this.reloadPageOnCondition(p || params, entities);
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId), partner: t.partnerName, id: t.taskId, code: t.taskCode, type: t.taskType })),
            count: entities.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getCampaignsMarketingTasks':
        return this.marketingCampaignBackend.getCampaignsMarketingTasks(this.campaignsId, p || params).pipe(map((entities: any) => {
          this.reloadPageOnCondition(p || params, entities);
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId), partner: t.partnerName, id: t.taskId, code: t.taskCode, type: t.taskType })),
            count: entities.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
    }

  };

  private _applySelected = (res) => {
    if (!res || !res.data || res.data.length < 1) {
      return;
    }
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].taskId == this._lastId) {
        this._selectedRows.push(res.data[i]);
        return;
      }
    }
  };

  private _setActiveList = (list: string, params: any, tasks: any) => {
    this._taskboardDataService.setActiveList(list, params, tasks);
  };

  private _tModels: Array<TableModel>;

  private _initTableModel() {
    const tpls = this._tplService;

    const COL_TITLE = {
      name: 'Pavadinimas',
      prop: 'name',
      width: 190,
      getWidth: calcTextCellWidth
    };

    const COL_CAMPAIGNS_BUTTONS = {
      name: '      ',
      prop: 'id6',
      width: 40,
      optional: false,
      sortable: false,
      getWidth: (_) => 40,
      cellTemplate: tpls.gotoCampaignsEditTpl,

    };
    const COL_CLIENT_TYPE = {
      name: 'Kliento tipas',
      prop: 'clientType',
      width: 190,
      getWidth: calcTextCellWidth,
    };
    const COL_PARTICIPANTS_COUNT = {
      name: 'Dalyvių skaičius',
      prop: 'participantsCount',
      sortable: false,
      width: 190,
      getWidth: calcTextCellWidth
    };
    const COL_PARTICIPANS_BUTTONS = {
      name: ' ',
      prop: 'participantsButtons',
      sortable: false,
      width: 190,
      getWidth: (_) => 190,
      cellTemplate: tpls.participansButtonsTpl
    };
    const COL_DATE = {
      name: 'Data',
      prop: 'date',
      width: 190,
      sorted: { order: 'desc' },
      getWidth: calcTextCellWidth,
      cellTemplate: tpls.shortDateTpl
    };
    const COL_PRODUCT_CAMPAIGNS = {
      name: 'Produktas',
      prop: 'productName',
      width: 190,
      getWidth: calcTextCellWidth,
    };
    const COL_PRODUCT_ALT = {
      name: 'Produktas',
      prop: 'name',
      width: 190,
      getWidth: calcTextCellWidth,
    };
    const COL_OWNER_CAMPAIGNS = {
      name: 'Užsakovas',
      prop: 'ownerName',
      width: 190,
      getWidth: calcTextCellWidth,
    };
    const COL_CAMPAIGN_NAME = {
      name: 'Kampanijos pavadinimas',
      prop: 'campaignName',
      width: 190,
      getWidth: calcTextCellWidth,
    };
    const COL_PARTNER_NAME = {
      name: 'Partneris',
      prop: 'partnerName',
      width: 190,
      getWidth: calcTextCellWidth,
      cellTemplate: tpls.partnerTpl,
    };

    const COL_OWNER = {
      name: 'Užsakovas',
      prop: 'owner',
      width: 190,
      getWidth: calcTextCellWidth
    };

    const COL_EXECUTOR = {
      name: 'Vykdytojas',
      prop: 'executor',
      width: 190,
      cellTemplate: tpls.executorEditableTpl,
      getWidth: calcTextCellWidth
    };

    const COL_NEAREST_ACTION = {
      name: 'Veiksmai',
      prop: 'nearestAction',
      class: 'r-7-col-wdth',
      headerClass: 'r-7-header-wdth',
      width: 90,
      getWidth: (_) => 90,
      cellTemplate: tpls.nearestActionTpl
    };

    const COL_SERVICE_NAME = {
      name: 'Paslauga',
      prop: 'serviceName',
      width: 230,
      getWidth: calcTextCellWidth
    };

    const COL_SOURCE = {
      name: 'Šaltinis',
      prop: 'source',
      width: 190,
      getWidth: calcTextCellWidth
    };

    const COL_DATE_ENTERED = {
      name: 'Data',
      prop: 'dateEntered',
      width: 110,
      sorted: { order: 'desc' },
      getWidth: (_) => 110,
      cellTemplate: tpls.dateEnteredTpl
    };

    const COL_DATE_ENTERED_SHORT = {
      name: 'Data',
      prop: 'dateEntered',
      width: 110,
      sorted: { order: 'desc' },
      getWidth: (_) => 110,
      cellTemplate: tpls.dateEnteredShortTpl
    };

    const COL_TEXT = {
      name: 'Aprašymas',
      prop: 'text',
      width: 200,
      getWidth: calcTextCellWidth,
      cellTemplate: tpls.textTpl,
    };

    const COL_TYPE_TASKS = {
      name: 'Tipas',
      prop: 'taskType',
      width: 105,
      getWidth: calcTextCellWidth,
      cellTemplate: tpls.taskTypeTpl,
    };

    const COL_STATE = {
      name: 'Būsena',
      prop: 'state',
      headerClass: 'center',
      width: 75,
      getWidth: (_) => 75,
      cellTemplate: tpls.stateTpl
    };

    const COL_IS_FAVORITE_CAMPAIGNS = {
      name: '  ',
      prop: 'isFavorite',
      width: 50,
      optional: false,
      sortable: false,
      cellTemplate: tpls.isFavoriteCampaignsTpl,
      headerTemplate: tpls.isFavoriteHeaderTpl,
      getWidth: (_) => 50,
    };

    const _MC_COLUMNS = [COL_IS_FAVORITE_CAMPAIGNS, COL_CAMPAIGNS_BUTTONS, COL_TITLE, COL_DATE, COL_OWNER_CAMPAIGNS, COL_PRODUCT_CAMPAIGNS, COL_PARTICIPANTS_COUNT, COL_PARTICIPANS_BUTTONS];

    const _MC_TASKS_COLUMNS = [COL_CAMPAIGN_NAME, COL_DATE_ENTERED_SHORT, COL_PARTNER_NAME, COL_SOURCE, COL_PRODUCT_ALT, COL_TYPE_TASKS, COL_STATE, COL_SERVICE_NAME, COL_NEAREST_ACTION, COL_CLIENT_TYPE, COL_TEXT, COL_EXECUTOR, COL_OWNER];

    this._tModels = [
      {
        name: 'marketingCampaigns',
        // linkProvider: projectLink,
        columns: _MC_COLUMNS,
        getRowClass: markIfNotSeen,
        getData: (q) => (this.makeQuery('getMarketingCampaigns')(noAddition)(q))
      },
      {
        name: 'marketingTasks',
        // linkProvider: projectLink,
        columns: _MC_TASKS_COLUMNS,
        getData: (q) => this.makeQuery('getMarketingTasks')(noAddition)(q)
      },
      {
        name: 'campaignMarketingTasks',
        // linkProvider: projectLink,
        columns: _MC_TASKS_COLUMNS,
        getData: (q) => this.makeQuery('getCampaignsMarketingTasks')(noAddition)(q)
      },
    ].map(m => { m.columns = <any>cloneArr(m.columns); return m });
  }

  reloadPageOnCondition(params, entities) {
    if (entities.length === 0 && params.page !== 0 && params.page !== undefined && params.page !== null) {
      this._pageChanged(0);
      location.reload();
    }
  }

  private _colVisibilityChanged(col: string, visible: boolean) {
    this._serverTable.setColumnVisibility(col, visible);
    let cs = this._colSettings.find((cs) => cs.name == col);
    if (!cs) {
      this._colSettings.push({
        name: col,
        visible: visible
      });
    } else {
      cs.visible = visible;
    }
    this._storageService.store(this.ctm.id + '.colSettings', this._colSettings);
  }

  private _columnResized(e: ColumnResized) {
    let cs = this._colSettings.find((cs) => cs.name == e.name);
    if (!cs) {
      this._colSettings.push({
        name: e.name,
        width: e.newWidth
      });
    } else {
      cs.width = e.newWidth;
    }
    this._storageService.store(this.ctm.id + '.colSettings', this._colSettings);
  }

  private _rowClicked(row: any) {
    if (this.ctm.id == 'marketingCampaigns') {
      this._router.navigate(['/marketing', SELECTED_MARKETING_TASKS, row.campaignsId]);
    } else {
      this._router.navigate(['/marketing/t/v', row.id]);
    }
  }

  private _toggleLeftMenu() {
    this._stateService.notify(TOGGLE_LEFT_MENU);
  }

  private _search(val) {
    this._storageService.store(this.ctm.id + '.search', val, {
      popState: true,
      refresh: true
    });
    document.getElementById('content_header_search').blur();
    this._queryProvider.emit('search');
  }

  private _clearSearch() {
    this._searchField ? this._searchField.nativeElement.value = '' : null;
    this._searchField && this._searchField.nativeElement.dispatchEvent(new Event('keyup'));
    this._queryProvider.emit('search');
    this._storageService.store(this.ctm.id + '.search', undefined, {
      popState: true,
      refresh: true
    });
  }

  private _detectSearchVal(val) {
    this._showSearchClear = val.length > 0;
  }
}
