
import { Subject, ReplaySubject, Subscription } from 'rxjs';

import { map, switchMap, filter, delay, tap } from 'rxjs/operators';
import { DELEGATE, WATCHER } from '../../participants-table/participants-table.component';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren, } from '@angular/core';
import { LIST, ListsDataService } from '../../../../services/lists/lists.data.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UploadFileComponent } from '../../../../template/shared/components/upload-file/upload-file.component';
import { EMPTY_PARTNER } from '../../../../app.constants';
import { SelectedPartnerService } from '../../../../services/partner/selectedPartner.service';
import { PartnersBackend } from '../../../../backend/partners.backend';
import { ProtoInputComponent } from '../../../../template/shared/proto/components/input/protoInput.component';
import { SuggestionsComponent } from '../../../../components/suggestions/suggestions.component';
import { TasksBackend } from '../../../../backend/tasks.backend';
import { mainPerson, removeLastNewLineIfNeeded } from '../../../common/functions';
import { MatDialog } from '@angular/material/dialog';
import { UserBackend } from '../../../../backend/user.backend';
import { NgxPermissionsService } from 'ngx-permissions';
import { PersonsBackend } from '../../../../backend/persons.backend';
import { generatePassword } from '../../../../template/shared/proto/helpers';
import { TaskboardDataService } from '../../../../taskboard/services/taskboard-data.service';
import { TaskReview } from '../../../../taskboard/models/task-review';
import anchorme from 'anchorme';


const extractExecutor = (res) => {
  try {
    const r = res;
    let id = '0';
    for (let i of [r['maId1'], r['maId2'], r['maId3']]) {
      if (parseInt(i) > 1) {
        id = i;
        break;
      }
    }
    return id != '0' && id != '1' ? id : null;
  } catch (e) {
    return null;
  }
};

const UNKNOWN_ERROR = 'unknownError';
const UNKNOWN_ID = -9999;
const mapDel = (e) => e.Atst_Vardas ? e.Atst_Vardas + ' ' + e.Atst_Pavarde : e.Vardas + ' ' + e.Pavarde;

@Component({
  selector: '.content_inner_wrapper',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss'],
})
export class NewRequestComponent implements OnInit, AfterViewInit {
  // protected products: LIST = LIST.PRODUCTS;
  protected services: LIST = LIST.SERVICES;
  protected priorities: LIST = LIST.PRIORITIES;
  // protected projects: LIST = LIST.PROJECTS;
  protected partners: LIST = LIST.PARTNERS;
  protected employees: LIST = LIST.EMPLOYEES;
  protected taskTypes: LIST = LIST.TASK_TYPES_FILTER;

  protected saleClientTypes: LIST = LIST.MARKETING_CLIENT_TYPES_LIST;
  protected selesTypes: LIST = LIST.SALES_TYPES_LIST;
  protected selesClientNeeds: LIST = LIST.SALES_CLIENTS_NEEDS_LIST;

  protected submitted = false;
  protected _showForm = true;
  isChecked = true;
  showClient = true;
  protected isInserting = false;
  protected insertingError = false;
  @ViewChild('partnerInput', { read: SuggestionsComponent }) partnerInput: SuggestionsComponent;
  @ViewChild('productInput', { read: ProtoInputComponent }) productInput: ProtoInputComponent<any>;
  // @ViewChild(ProtoInputComponent, { static: true }) input: ProtoInputComponent<any>;
  @ViewChild('executorSelector', { read: SuggestionsComponent }) executorInput: SuggestionsComponent;
  @ViewChild('delegatesInput', { read: ProtoInputComponent }) delegatesInput: ProtoInputComponent<any>;
  @ViewChild(UploadFileComponent) uploadFile: UploadFileComponent;

  @ViewChildren(SuggestionsComponent, { read: SuggestionsComponent }) suggestions: QueryList<SuggestionsComponent>;
  @ViewChildren(ProtoInputComponent, { read: ProtoInputComponent }) inputs: QueryList<ProtoInputComponent<any>>;

  protected _requestInserted = false;
  private delegatesList: Subject<any> = new ReplaySubject(1);
  private productsList: Subject<any> = new ReplaySubject(1);
  protected _initialPriority = (p) => p.id == '1854027'; //Standartinis reagavimas

  newRequestForm: UntypedFormGroup;
  delegatesSource: Function;
  productsSource: Function;
  persons: Map<number, Array<any>> = new Map();
  personsProt: Map<number, Array<any>> = new Map();
  private _subscriptions: Array<Subscription> = [];
  protected getPersons: Function;
  protected getPersonsProt: Function;
  private _delegateUserId;
  protected selectPartner: Function;

  private _selectedPartner: any;
  protected set selectedPartner(sp: any) {
    if (this._selectedPartner != sp) {
      this._selectedPartner = sp;
      this._loadDelegates(sp);
      this._loadProducts(sp);
    }
  }

  protected get selectedPartner(): any {
    return this._selectedPartner;
  }

  private _selectedDelegate: any;
  protected set selectedDelegate(val: any) {
    this._selectedDelegate = val;
  }

  protected get selectedDelegate(): any {
    return this._selectedDelegate;
  }

  private _selectedProductId: any;
  protected set selectedProductId(val: any) {
    if (this._selectedProductId != val) {
      this._selectedProductId = val;
      this._loadPartnerProductPerson(this.selectedPartner, val);
    }
  }

  protected get selectedProductId(): any {
    return this._selectedProductId;
  }
  parentTask: TaskReview;
  private _partnerProduct = new ReplaySubject<{ partnerId: string, productId: string }>(1);

  inheritedFromRequestId: number;
  commentId: number;

  initialProductId;
  taskTypesList: SuggestionsComponent;

  @ViewChild('taskTypesList') set content(taskTypesList: SuggestionsComponent) {
    this.taskTypesList = taskTypesList;
    if (taskTypesList) {
      this._subscriptions.push(taskTypesList.suggestionObjSelected.subscribe((s) => {
        this._taskTypeSelected = s;
        if (this.isSale) {
          this.changeFormStateSales(true);
        } else if (!this.isSale) {
          this.changeFormStateSales(false);
        }
      }))
    }
  }

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': ['Sans', 'monospace'] }],
        [{ 'align': [] }],
        ['clean'],                                         // remove formatting button
      ],
    },
  }

  get isSale(): boolean {
    return this._taskTypeSelected?.code === 'VT_3';
  }
  private _taskTypeSelected;
  plainTextDescription: string;
  descriptionWithLinks: string;
  descriptionText = '';

  constructor(
    private listsService: ListsDataService,
    private formBuilder: UntypedFormBuilder,
    private partnersBackend: PartnersBackend,
    private router: Router,
    private location: Location,
    private selectedPartnerService: SelectedPartnerService,
    private chg: ChangeDetectorRef,
    private taskBackend: TasksBackend,
    protected dialog: MatDialog,
    protected usersBackend: UserBackend,
    private personsBackend: PersonsBackend,
    protected permissionsService: NgxPermissionsService,
    private route: ActivatedRoute,
    private _taskboardDataService: TaskboardDataService,
  ) {
    this.delegatesSource = () => this.delegatesList;
    this.productsSource = () => this.productsList;
    this.getPersons = () => this.delegatesList;
    this.getPersonsProt = () => personsBackend.getEmployees();
  }

  ngOnInit(): void {
    this.parentTask = this._taskboardDataService.parentTask;
    this.parentTask ? this.initialProductId = this.parentTask.productId : null;
    this._buildForm();
    if (this.parentTask && this.parentTask.taskTypeCode === 'VT_3') {
      // this.newRequestForm.controls.description.setValue(this.parentTask.ownerNote);
      this.descriptionText = this.parentTask.ownerNote;
      this.plainTextDescription = this.parentTask.ownerNote;
      this.descriptionWithLinks = this.parentTask.ownerNoteHtml !== ' ' && this.parentTask.ownerNoteHtml ? this.parentTask.ownerNoteHtml : this.parentTask.ownerNote;
      // this.newRequestForm.controls.description.updateValueAndValidity();
    }
    this._subscriptions.push(this.route.params.subscribe((params) => {
      this.inheritedFromRequestId = params['id'];
      this.commentId = params['cid'];
      if (this.inheritedFromRequestId) {
        this._subscriptions.push(this.taskBackend.getTaskDetails(this.inheritedFromRequestId, false).subscribe((task) => {
          this.partnerInput.input.select((pr) => task.partnerId == (<any>pr).id);
          this.initialProductId = this.parentTask ? this.parentTask.productId : task.productId;
          const description = task.comments?.find(comment => comment.id == this.commentId)?.text;
          this.descriptionText = description;
          this.plainTextDescription = description;
          this.descriptionWithLinks = description;
          // this.newRequestForm.controls.description.setValue(description);
          // this.newRequestForm.controls.description.updateValueAndValidity();
          this.chg.detectChanges();
        }, (err) => {
        }
        ))
      }
    }));
  }

  ngAfterViewInit(): void {
    this._subscriptions.push(
      this.selectedPartnerService.selectedPartner.subscribe(
        (sp) => {
          if (sp.isEmpty) {
            this.selectedPartner = sp.partner;
          } else {
            if (!sp.isUser) {
              this.partnerInput.input.select((pr: any) => { return sp.partner.id == (<any>pr).id });
            }
          }
        }
      )
    );
    this._subscriptions.push(
      this.delegatesList.pipe(delay(1)).subscribe((dl) => {
        this.delegatesInput.select(this.parentTask ? ((d: any) => d.id == this.parentTask.delegateId) : mainPerson);
      })
    );
    // if (this.parentTask) {
    //   this.productInput.select((pr: any) => { return this.parentTask.productId == (<any>pr).id });
    // }
    this._subscriptions.push(
      this._partnerProduct.pipe(tap((e) => {
      }),
        tap(el => {
          // TODO needs deselecting
          // !el.partnerId || !el.productId ? this.executorInput.input.deselect() : null;
        }),
        filter((r: any) => { return r.partnerId != null && r.productId != null }),
        switchMap(pp => this.personsBackend.getPartnerProductPerson(pp.productId, pp.partnerId)), //TODO nr4 tested
        map(extractExecutor))
        .subscribe((executor) => {
          if (executor) {
            this.executorInput.input.select(this.parentTask ? ((e: any) => e.id == this.parentTask.executorId) : (e: any) => e.id == executor);
          }
        }));
  }

  private _clear() {
    this.submitted = false;
    this.inputs.forEach((l) => l.deselect());
    this.suggestions.forEach((l) => l.deselect());
    this.descriptionText = null;
    this.plainTextDescription = null;
    this.descriptionWithLinks = null;
    try {
      this._showForm = false;
      this.chg.detectChanges();
      setTimeout(() => {
        this._showForm = true;
        this._buildForm();
        try {
          this.chg.detectChanges();
        } catch (err) { }
      });
    } catch (e) { }
  }

  changeFormStateSales(required: boolean) {
    if (!this.newRequestForm) return;
    if (required) {
      this.newRequestForm.controls.saleClientTypeId.setValidators([Validators.required]);
    } else {
      this.newRequestForm.controls.saleClientTypeId.setValidators(null);
    }
    this.newRequestForm.controls.saleClientTypeId.updateValueAndValidity();
  }

  productSelected(event) {
    this._partnerProduct.next({ partnerId: this._selectedPartner && this._selectedPartner.id, productId: event && event.id });
  }

  private _loadDelegates(sp) {

    if (!sp || sp.id == EMPTY_PARTNER) {
      this.delegatesList.next([]);
    } else {
      this.taskBackend.getRequestPersonsByPartner(sp.id)
        .subscribe((res) => this.delegatesList.next(res));
    }
  }

  private _loadProducts(sp) {
    if (!sp || sp.id == EMPTY_PARTNER) {
      this.productsList.next([]);
    } else {
      //TODO 1
      this.taskBackend.getRequestProductsByPartner(sp.id)
        // .subscribe((res) => this.productsList.next(res.map(el => { return { ...el, isClientProduct: el.id % 2 == 0 ? true : false } })));
        .subscribe((res) => this.productsList.next(res));
    }
  }

  private _buildForm() {
    const controls = {
      startDate: [new Date(), Validators.required],
      // description: ['', Validators.required],
      // this.parentTask.delegateId can be = 0 from parent task
      delegateId: [this.parentTask && this.parentTask.delegateId ? this.parentTask.delegateId : null, Validators.required],
      priorityId: [null, Validators.required],
      productId: [this.parentTask ? this.parentTask.productId : null, Validators.required],
      executorId: [this.parentTask ? (<any>this.parentTask).executorId : null, Validators.required],
      partnerId: [this.parentTask ? this.parentTask.partnerId : null, Validators.required],
      saleClientTypeId: [null, this.isSale ? Validators.required : null],
      saleClientNeedsId: [null],
      saleTypeId: [null],
      saleValue: [null],
    };
    if (this.permissionsService.hasPermission('manage_task_type')) {
      controls['taskTypeId'] = [null];
    }
    this.newRequestForm = this.formBuilder.group(controls);
  }

  private _submitForm(fg: UntypedFormGroup) {
    if (!fg.valid) {
      return;
    }
    this.isInserting = true;
    if (this.selectedDelegate.isCrmUser == false) {
      swal.fire({
        title: 'Sukurti vartotoją atstovui?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Taip',
        cancelButtonText: 'Ne',
        icon: 'question',
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
      }).then((res) => {
        if (res && res.value === true) {
          this.usersBackend.createUserForDelegate({
            password: generatePassword(8),
            delegateId: this.selectedDelegate.id,
            name: this.selectedDelegate.name
          }).subscribe((res) => {
            this._insertRequest(fg);
          }, (err) => {
            swal.fire({
              title: 'Vartotojo sukurti nepavyko, užklausa nesukurta',
              allowOutsideClick: false,
              timer: 1500,
              icon: 'error'
            }).then(() => {
              this.isInserting = false;
              try {
                this.chg.detectChanges();
              } catch (err) { }
            });
          });
        } else {
          this._insertRequest(fg);
        }
      })
    } else {
      this._insertRequest(fg);
    }
  }

  private _insertRequest(fg: UntypedFormGroup) {
    this.isInserting = true;
    const form = fg.value;
    form.filesKey = this.uploadFile.key;
    const watchers = this.persons.has(WATCHER) ? this.persons.get(WATCHER).map((w) => ({ personsId: w.id })) : [];
    const watchersProto = this.personsProt.has(WATCHER) ? this.personsProt.get(WATCHER).map((w) => ({ personsId: w.id })) : []
    form.watchers = [...watchers.map(el => { return { ...el, personsSubj: 'atstovas' } }), ...watchersProto.map(el => { return { ...el, personsSubj: 'darbuotojas' } })];
    const participants = this.persons.has(DELEGATE) ? this.persons.get(DELEGATE).map((w) => ({ personsId: w.id })) : [];
    const participantsProt = this.personsProt.has(DELEGATE) ? this.personsProt.get(DELEGATE).map((w) => ({ personsId: w.id })) : [];
    form.participants = [...participants.map(el => { return { ...el, personsSubj: 'atstovas' } }), ...participantsProt.map(el => { return { ...el, personsSubj: 'darbuotojas' } })];
    this.parentTask ? form.masterTaskId = this.parentTask.id : null;
    this.isSale ? form.klNeed = form.saleClientNeedsId : null;
    delete form.saleClientNeedsId;
    this.isSale ? form.klType = form.saleClientTypeId : null;
    delete form.saleClientTypeId;
    this.isSale ? form.saleType = form.saleTypeId : null;
    delete form.saleTypeId;
    this.isSale ? form.pCost = form.saleValue : null;
    delete form.saleValue;
    form.userId = this._delegateUserId;
    form.description = this.plainTextDescription;
    form.descriptionHTML = this.descriptionWithLinks;
    form.startDate = new Date(form.startDate).toLocaleDateString('LT');
    this.taskBackend.insertClientRequest(form).subscribe((res) => {
      this.isInserting = false;
      this._requestInserted = true;
      this.parentTask = null;
      this.initialProductId = null;

      this._clear();
      // this.newRequestForm.reset();

      // this.newRequestForm.controls.description.setValue(null);
      // this.newRequestForm.controls.delegateId.setValue(null);
      // this.newRequestForm.controls.productId.setValue(null);
      // this.newRequestForm.controls.executorId.setValue(null);
      // this.newRequestForm.controls.partnerId.setValue(null);
      // this.newRequestForm.controls.saleClientTypeId.setValue(null);
      // this.newRequestForm.controls.saleClientNeedsId.setValue(null);
      // this.newRequestForm.controls.saleTypeId.setValue(null);
      // this.newRequestForm.controls.saleValue.setValue(null);
      // this.newRequestForm.controls.startDate.setValue(new Date());

      // this.partnerInput && this.partnerInput.deselect();
      // this.productInput && this.productInput.deselect();
      // this.executorInput && this.executorInput.deselect();
      // this.taskTypesList && this.taskTypesList.deselect();
      // this.delegatesInput.deselect();

      try {
        this.chg.detectChanges();
      } catch (err) { }
      this._onRequestInserted(res);
    }, (err) => {
      this.isInserting = false;
      try {
        this.chg.detectChanges();
      } catch (err) { }
    });
  }

  // private _insertRequest(fg: FormGroup): Observable<any> {
  //   swal.fire({
  //     title: 'Kuriama užklausa',
  //     allowOutsideClick: false,
  //     imageUrl: 'assets/img/m_loader.gif',
  //     showConfirmButton: false
  //   });
  //   this.submitted = true;
  //   this.isInserting = false;
  //   this._requestInserted = false;
  //   const form = fg.value;
  //   form.filesKey = this.uploadFile.key;
  //   form.watchers = this.persons.has(WATCHER) ? this.persons.get(WATCHER).map((w) => ({ personsId: w.id })) : [];
  //   form.participants = this.persons.has(DELEGATE) ? this.persons.get(DELEGATE).map((w) => ({ personsId: w.id })) : [];
  //   form.userId = this._delegateUserId;
  //   return this.taskBackend.insertClientRequest(form);
  // }

  private _onRequestInserted(taskId) {
    if (taskId < 2) {
      this._showError();
      return;
    }
    const router = this.router;
    //this._clear();
    swal.fire({
      title: 'Nauja užklausa pridėta',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Eiti į užklausą',
      cancelButtonText: 'Eiti į sąrašą'
    }).then((result) => {
      if (result.value) {
        this._redirectToTask(taskId, this.router);
      } else if (result.dismiss === swal.DismissReason.cancel) {
        router.navigate(['/taskboard/r/b']);
      }
    });
  }

  private _loadPartnerProductPerson(partner, product) {
    if (!partner || partner.id == EMPTY_PARTNER) {
      return;
    }
    this._partnerProduct.next({ partnerId: partner ? partner.id : null, productId: product });
  }

  private _redirectToTask(taskId, router: Router) {
    router.navigate(['/taskboard/r/v/', taskId]);
  }

  private _showError() {
    swal.fire({ title: 'Užklausos pridėti nepavyko', timer: 2000, showConfirmButton: false, icon: 'error' })
    // .catch(swal.noop);
  }

  onContentChanged(event) {
    // below commented code has problems with new lines
    this.plainTextDescription = removeLastNewLineIfNeeded(event?.text) || null;
    this.descriptionWithLinks = event && event.html ? anchorme(event.html) : null;
  }

  protected cancel() {
    this.location.back();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}
