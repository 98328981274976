import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { SelectedPartnerService } from "../../services/partner/selectedPartner.service";
import { ActivatedRoute, Router } from "@angular/router";
import { StorageOptions } from '../../template/shared/proto/storage/StorageOptions';
import { StorageService } from '../../template/shared/proto/storage/storage.service';

@Component({
  selector: "admin-menu",
  templateUrl: "./adminMenu.component.html",
  styleUrls: ["./adminMenu.component.scss"]
})
export class AdminMenuComponent implements OnInit, OnDestroy {
  protected _menu = [
    {
      title: 'Sąrašai',
      icon: 'zmdi zmdi-settings',
      entries: [
        {
          title: 'Vartotojai',
          routerLink: 'users'
        },
        {
          title: 'Partnerių atstovai',
          routerLink: 'delegates'
        },
        {
          title: 'Partneriai',
          routerLink: 'partners'
        },
        {
          title: 'Tvirtinimas',
          routerLink: 'confirmation'
        },
      ]
    }
  ];
  private _subscriptions: Array<Subscription> = [];
  private _pageSavingOptions: StorageOptions = {
    popState: true,
    refresh: true
  };

  constructor(private selectedPartner: SelectedPartnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private selectPartnerService: SelectedPartnerService,
    private _storageService: StorageService,
  ) {

  }

  ngOnInit() {
    this.selectPartnerService.resetPartner();
    this.selectPartnerService.resetUser();
  }

  resetPageSessionStorage() {
    this._storageService.store('currentPage', 0, this._pageSavingOptions);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sp) => sp.unsubscribe());
  }
}
