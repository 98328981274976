import { StorageOptions } from "./StorageOptions";
import { Inject, Injectable, Injector } from "@angular/core";
import { CurrentRoute } from "../services/currentRoute";
import { RoutingService } from "../services/routing.service";
import { APP_VERSION } from "../../shared.help";
import { storeSession, getSession } from "./storage.help";
import { LogsBackend } from "../../../../backend/logs.backend";
import { AuthService } from "../../../../services/auth.service";

const UNTIL_ROUTE_CHANGE = 'UNTIL_ROUTE_CHANGE';
const staticData = {};
let dataToClean = [];
const dataForSameRoute = [];
export const storeLocal: (id: string, val: any) => void = (id: string, val: string) => localStorage.setItem(id, val);
export const getLocal: (id: string, def?: string) => string = (id: string, def?: string) => localStorage.getItem(id) || def;
export const storeStatic: (id: string, val: string) => void = (id: string, val: string) => staticData[id] = val;
export const getStatic: (id: string, def?: any) => string = (id: string, def?: any) => staticData[id] || def;
export const removeLocal: (id: string) => void = (id: string) => localStorage.removeItem(id);
export const removeSession: (id: string) => void = (id: string) => sessionStorage.removeItem(id);
export const removeStatic: (id: string) => void = (id: string) => delete staticData[id];

const encodeData = (d) => {
  if (d instanceof String) {
    return 'str__' + d;
  }
  if (d instanceof Object) {
    return 'obj__' + JSON.stringify(d);
  }
  return 'str__' + d;
};

const decodeData = (d) => {
  if (!d) {
    return d;
  }
  if (!(typeof d == 'string' && d.length >= 5)) {
    throw new Error("Unknown value type tried to decode: " + d);
  }
  const t = d.substring(0, 5);
  switch (t) {
    case 'str__': return d.substring(5);
    case 'obj__': return JSON.parse(d.substring(5));
    default: throw new Error("Malformed storable format! " + d + " --- " + t);
  }
};

const applyOptions = (o: StorageOptions) => (d: string) => {
  if (!o || o.static) {
    return d;
  }
  return ('_OPT_' + JSON.stringify(o) + '//') + d;
};

const getDataWithOptions: (dec: (data: any) => any) => (d: string) => { options: StorageOptions, data: any } =
  (dec: (data: any) => any) =>
    (d: string) => {
      if (!(typeof d == 'string')) {
        return d;
      }
      const optEnd = d.indexOf('//');
      const options = d.startsWith('_OPT_') && optEnd > -1 ? JSON.parse(d.substring(5, optEnd)) : null;
      if (d.length < optEnd + 2) {
        return {
          options: options,
          data: null
        };
      }
      const data = dec(optEnd > -1 ? d.substring(optEnd + 2) : d);
      return {
        options: options,
        data: data
      };
    };


const store = (o: StorageOptions) => o ? (o.refresh || o.sameRoute ? storeSession : o.static || o.popState ? storeStatic : storeLocal) : storeLocal;
const get = (o: StorageOptions) => o ? (o.refresh || o.sameRoute ? getSession : o.static || o.popState ? getStatic : getLocal) : getLocal;
const remove = (o: StorageOptions) => o ? (o.refresh || o.sameRoute ? removeSession : o.static || o.popState ? removeStatic : removeLocal) : removeLocal;
const applyRouteToKey = (k: string, r: CurrentRoute) => k + '_' + r.url;

const getStoredDataUntilRouteChange: () => Array<{ key: string, route: string }> = () => {
  const s = sessionStorage.getItem('UNTIL_ROUTE_CHANGE');
  return s ? JSON.parse(s) : [];
};

let untilRouteChanged = getStoredDataUntilRouteChange();

const registerDataUntilRouteChange = (key: string, val: any, o: StorageOptions, r: CurrentRoute, sd: (key, val) => any) => {
  if (untilRouteChanged.find((kd) => kd.key == key)) {
    return;
  } else {
    untilRouteChanged.push({ key: key, route: r.url });
    sessionStorage.setItem(UNTIL_ROUTE_CHANGE, JSON.stringify(untilRouteChanged));
  }
};



const clearDataUntilRouteChange = (r: string) => {
  const d = getStoredDataUntilRouteChange();
  const c = [];
  for (let i = 0; i < d.length; i++) {
    if (d[i].route != r) {
      sessionStorage.removeItem(d[i].key);
    } else {
      c.push(d[i]);
    }
  }
  untilRouteChanged = c;
  sessionStorage.setItem(UNTIL_ROUTE_CHANGE, JSON.stringify(c));
};

const storeData = (o: StorageOptions) => (r: CurrentRoute) => (k: string, v: any) => {
  if (!v) {
    const removeFn = remove(o);
    if (!o || !(o.popState || o.refresh || o.sameRoute)) {
      removeFn(k);
    } else {
      removeFn(applyRouteToKey(k, r));
    }
    return;
  }
  const sd = store(o);
  const _encode = sd != storeStatic ? encodeData : (d) => d;

  if (!o || !(o.popState || o.refresh || o.sameRoute)) {
    return sd(k, _encode(v));
  }
  const _key = applyRouteToKey(k, r);
  if (o.sameRoute) {
    registerDataUntilRouteChange(_key, v, o, r, sd);
  } else {
    dataToClean.push({ url: r.url, key: _key });
  }
  return sd(_key, _encode(v));
};

const getData = (o: StorageOptions) => (r: CurrentRoute) => (k: string) => {
  const gd = get(o);
  const _decode = gd != getStatic ? decodeData : (d) => d;
  if (!o || !(o.popState || o.refresh || o.sameRoute)) {
    return _decode(gd(k));
  }
  if ((o.popState && r.popState) || (o.refresh && r.afterRefresh) || o.sameRoute) {
    const _key = applyRouteToKey(k, r);
    const _data = _decode(gd(_key));
    if (_data) {
      dataToClean.push({ url: r.url, key: _key });
    }
    return _data;
  }
};

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private _routingService: RoutingService, private logsBackend: LogsBackend, private authService: AuthService) {
    this._validateVersion();
    this._setupRouteWatch();
  }

  //Jei nepaduota routo optionu, storinama i localStorage
  //Jei val - undefined, istrinama esanti reiksme
  public store(key: string, val: any, options?: StorageOptions) {
    storeData(options)(this._routingService.currentRouteSnapshot)(key, val);
  }

  public get(key: string, def?: any, options?: StorageOptions) {
    return getData(options)(this._routingService.currentRouteSnapshot)(key) || def;
  }

  //Reaguojama i routo pasikeitimus ir trinami nereikalingi duomenys
  private _setupRouteWatch() {
    this._routingService.currentRoute.subscribe((r) => {
      clearDataUntilRouteChange(r.url);
      if (!(r.popState || r.afterRefresh)) {
        const arr = [];
        for (let i = 0; i < dataToClean.length; i++) {
          if (dataToClean[i].url == r.url) {
          } else {
            arr.push(dataToClean[i]);
          }
        }
        dataToClean = arr;
      }
    });
  }

  //Jei pasikeite appso versija, isvalomi visi duomenys
  private _validateVersion() {
    let cleared = false;
    if (localStorage.getItem('APP_VERSION') != APP_VERSION) {
      // setTimeout(() => {
      //   const logObj = {
      //     eventId: 'clearTokenAPP_Version',
      //     message: 'clear localstorage APP_VERSION change ' + 'old=' + localStorage.getItem('APP_VERSION') + ' new=' + APP_VERSION,
      //     userId: this.authService && this.authService.user ? this.authService.user.usersId : null,
      //     token: this.authService && this.authService.idToken
      //   };
      //   const subs = this.logsBackend.saveLog(logObj).subscribe(() => {
      //     subs.unsubscribe();
      //   }, () => {
      //     subs.unsubscribe();
      //   });
      // }, 0);
      localStorage.clear();
      sessionStorage.clear();
      cleared = true;
    }
    localStorage.setItem('APP_VERSION', APP_VERSION);
    if (cleared) {
      location.reload();
    }
  }
}
