import { Pipe, PipeTransform } from '@angular/core';

export type Mode = 'append' | 'prepend'

@Pipe({
  name: 'strEdit'
})
export class StrEditPipe implements PipeTransform {

  transform(value: any, val: string, mode: Mode = 'prepend'): any {
    const v = value || '';
    const p = val || '';
    if (mode === 'append') {
      return v + p;
    } else if (mode === 'prepend') {
      return p + v;
    }
    return value;
  }

}
