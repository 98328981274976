
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../core/api/api.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class FeedbackBackend {
  constructor(private _api: ApiService) {
  }

  sendFeedback(feedback): Observable<any> {
    return <any>this._api.postNoAuth('tasks/feedback', feedback).pipe(map((res: any) => res.body));
  }

  getFeedback(dateTime): Observable<any> {
    return <any>this._api.getAuth('tasks/feedback/chart', dateTime).pipe(map((res: any) => res.body));
  }

}