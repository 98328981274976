import { AdminListComponent } from './admin-list/admin-list.component';
import { AdminMenuComponent } from './adminMenu/adminMenu.component';
import { SharedModule } from './../template/shared/shared.module';
import { AdminComponent } from './admin.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UsersListComponent } from './users-list/users-list.component';
import { EmployeesListComponent } from './employees-list/employees-list.component';
import { PartnersListComponent } from './partners-list/partners-list.component';
import { NewUserComponent } from './users-list/new-user/new-user.component';
import { EditUserComponent } from './users-list/edit-user/edit-user.component';
import { NewEmployeesComponent } from './employees-list/new-employees/new-employees.component';
import { NewPartnerComponent } from './partners-list/new-partner/new-partner.component';
import { RightsControlComponent } from './components/rights-control/rights-control.component';
import { PermissionReviewComponent } from './components/permission-review/permission-review.component';
import { PartnerFormComponent } from './partners-list/partner-form/partner-form.component';
import { EditPartnerComponent } from './partners-list/edit-partner/edit-partner.component';
import { DelegatesComponent } from './delegates/delegates.component';
import { DelegateFormComponent } from './delegates/delegate-form/delegate-form.component';
import { NewDelegateComponent } from './delegates/new-delegate/new-delegate.component';
import { EditDelegateComponent } from './delegates/edit-delegate/edit-delegate.component';
import { DelegateTableComponent } from './components/delegate-table/delegate-table.component';
import { UserFormComponent } from './users-list/user-form/user-form.component';
import { EmployeesFormComponent } from './employees-list/employees-form/employees-form.component';
import { DeleteModalComponent } from '../template/shared/components/modals/delete-modal/delete-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationListComponent } from './components/confirmation-list/confirmation-list.component';
import { EditUserModalComponent } from './users-list/edit-user-modal/edit-user-modal.component';
import { PartnerDetailsComponent } from './partners-list/partner-info/partner-details.component';


const ADMIN_ROUTE = [
  {
    path: '', component: AdminComponent,
    children: [
      {
        path: ':type',
        component: AdminListComponent,
      },
      {
        path: 'users/new',
        component: NewUserComponent
      },

      {
        path: 'users/:id',
        component: EditUserComponent
      },
      {
        path: 'employees',
        component: EmployeesListComponent,
      },
      {
        path: 'employees/new',
        component: NewEmployeesComponent
      },
      {
        path: 'delegates/new',
        component: NewDelegateComponent
      },
      {
        path: 'delegates/:id',
        component: EditDelegateComponent
      },
      {
        path: 'partners/:id',
        component: EditPartnerComponent
      }
    ]
  },
];

@NgModule({
    declarations: [
        AdminComponent,
        AdminMenuComponent,
        UsersListComponent,
        EmployeesListComponent,
        PartnersListComponent,
        NewUserComponent,
        EditUserComponent,
        NewEmployeesComponent,
        NewPartnerComponent,
        RightsControlComponent,
        PermissionReviewComponent,
        PartnerFormComponent,
        EditPartnerComponent,
        DelegatesComponent,
        DelegateFormComponent,
        NewDelegateComponent,
        EditDelegateComponent,
        DelegateTableComponent,
        UserFormComponent,
        EmployeesFormComponent,
        AdminListComponent,
        DeleteModalComponent,
        ConfirmationListComponent,
        EditUserModalComponent,
        PartnerDetailsComponent
    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} }
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(ADMIN_ROUTE),
    ]
})
export class AdminModule { }
