import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, HostListener } from '@angular/core';
// import { Color } from 'ng2-charts';
import { KpisBackend } from '../../backend/kpis.backend';
import { Subscription, Subject, Observable } from 'rxjs';
import { DashboardService } from '../dashboard.service';
import { Breakpoints, BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'work-time-by-type-chart',
  styleUrls: ['work-time-by-type-chart.component.scss'],
  templateUrl: 'work-time-by-type-chart.component.html'
})

export class WorkTimeByTypeChartComponent implements OnInit, OnDestroy {

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  // data: Chartist.IChartistData;
  // data: any;
  options: any;
  dataReady = false;
  dataReady$ = new Subject();

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]);

  daysOfWeekNames = ['Pirmadienis', 'Antradienis', 'Trečiadienis', 'Ketvirtadienis', 'Penktadienis', 'Šeštadienis', 'Sekmadienis'];

  avarageData$ = new Subject();
  userData$ = new Subject();

  cheight = '180';

  public lineChartData: any[];
  public lineChartLabels;
  public lineChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        align: 'start',
        onClick: function (e) {
          e.native.stopPropagation();
        },
        labels: {
          padding: 7
        }
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            // let label = data.datasets[tooltipItem.datasetIndex].label || '';
            let label = tooltipItem.dataset.label || '';
            if (label) {
              label += ': ';
            }
            //console.log(tooltipItem);
            // const minutes = Math.round(parseFloat(tooltipItem.yLabel.toString()) * 60 * 100000) / 100000;
            const minutes = Math.round(parseFloat(tooltipItem.parsed.y.toString()) * 60 * 100000) / 100000;

            const m = minutes % 60;
            const h = (minutes - m) / 60;
            const ms = m < 10 ? '0' + m : '' + m;
            const hs = h < 10 ? '0' + h : '' + h;
            const time = hs + ':' + ms;
            label += time;
            return label;
          }
        }
      }
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 14,
        bottom: 10
      }
    },

    scales: {
      y:
      {
        beginAtZero: true,
        stacked: true,
        min: 0,
        ticks: {
          precision: 0,
        },
        title: {
          text: 'Valandos',
          display: true
        }
      }
      ,
      x:
      {
        beginAtZero: true,
        stacked: true,
        title: {
          text: 'Dienos',
          display: true
        }
      }
    }
  };
  // public lineChartColors: Color[]
  // public lineChartColors: any[]
  //   = [
  //     {
  //       borderColor: 'rgb(60, 179, 113, 0.5)',
  //       backgroundColor: 'rgb(60, 179, 113, 0.5)',
  //     },
  //     {
  //       borderColor: 'rgb(30, 144, 255, 0.5)',
  //       backgroundColor: 'rgb(30, 144, 255, 0.5)',
  //     },
  //     {
  //       borderColor: 'rgb(178, 34, 34, 0.5)',
  //       backgroundColor: 'rgb(178, 34, 34, 0.5)',
  //     },
  //     {
  //       borderColor: 'rgb(147, 112, 219, 0.5)',
  //       backgroundColor: 'rgb(147, 112, 219, 0.5)',
  //     },
  //     {
  //       borderColor: 'rgb(128, 128, 128, 0.5)',
  //       backgroundColor: 'rgb(128, 128, 128, 0.5)',
  //     },
  //     {
  //       borderColor: 'rgb(207, 181, 59, 0.5)',
  //       backgroundColor: 'rgb(207, 181, 59, 0.5)',
  //     },
  //     {
  //       borderColor: 'rgb(255, 218, 185, 0.5)',
  //       backgroundColor: 'rgb(255, 218, 185, 0.5)',
  //     },
  //   ];
  public lineChartLegend = true;
  public lineChartType = 'bar';
  public lineChartPlugins = [];

  private _subscriptions: Array<Subscription> = [];


  constructor(private kpisBackend: KpisBackend, private dashboardService: DashboardService, private breakpointObserver: BreakpointObserver, private chg: ChangeDetectorRef) {
  }

  ngOnInit() {

    this._subscriptions.push(this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        this.cheight = '280';
        this.lineChartOptions.plugins.legend.position = 'bottom';
      } else {
        this.cheight = '180';
        this.lineChartOptions.plugins.legend.position = 'right';
      }
      try {
        this.chg.detectChanges();
      } catch (err) { }
    }));
    // this.getCurrentUserData();
    this._subscriptions.push(this.userData$.subscribe((userData: any) => {
      this.lineChartLabels = userData && userData.data && userData.data.dayOfWeek && userData.data.dayOfWeek.map((day) => this.daysOfWeekNames[day - 1]);
      this.lineChartData = this.prepareChartData(userData && userData.data);
      this.dataReady = true;
      this.dataReady$.next(true);
      try {
        this.chg.detectChanges();
      } catch (e) { }
    }));
  }

  @HostListener("window:resize", ["$event"])
  canvasUpdate() {
    this.chart.render();
  }

  getCurrentUserData() {
    this._subscriptions.push(this.kpisBackend.getWorkTimeByTaskTypeKpis(this.dashboardService.workTimeByTypeUserId).subscribe((res) => {
      this.userData$.next({ user: { userId: this.dashboardService.workTimeByTypeUserId }, data: res });
    }));
  }

  changeUserChart(user) {
    this._subscriptions.push(this.kpisBackend.getWorkTimeByTaskTypeKpis(user.userId).subscribe((res) => {
      this.userData$.next({ user, data: res })
    }));
  }

  prepareChartData(responseData) {
    if (!responseData.labels || !responseData.minutes) {
      return;
    }
    const dataArr = [];
    let hoursData = responseData.minutes['code:Not_assigned'] && responseData.minutes['code:Not_assigned'].map((item) => item / 60);
    dataArr.push({ data: hoursData, label: 'Tipas nenurodytas' });
    for (let i = 0; i < responseData.labels.length; i++) {
      hoursData = responseData.minutes['code:' + responseData.labels[i].code] && responseData.minutes['code:' + responseData.labels[i].code].map((item) => item / 60);
      if (hoursData) {
        dataArr.push({ data: hoursData, label: responseData.labels[i].name });
      }
    }

    return dataArr;
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

}
