import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TASKBOARD_ROUTES } from './taskboard.routing.module';
import { TaskboardSharedModule } from "./taskboard.shared.module";

@NgModule({
    declarations: [],
    imports: [
        TaskboardSharedModule,
        RouterModule.forChild(TASKBOARD_ROUTES),
    ],
    providers: []
})
export class TaskboardModule {
}
