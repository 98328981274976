import { ChangeDetectionStrategy, Component, OnInit, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'tasks-tabs',
  templateUrl: './tasks-tabs.component.html',
  styleUrls: ['./tasks-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TasksTabsComponent implements OnInit, AfterViewInit {

  @Output() tabSelected = new EventEmitter();
  @Input() disabledSwitch;
  initiallySelectedTab;

  constructor(public dashboardService: DashboardService) { }

  ngOnInit() {
    this.initiallySelectedTab = this.dashboardService.currentTaskTypesTab;
  }

  ngAfterViewInit() { }

  tabselected(i) {
    this.tabSelected.emit(i);
  }


}

