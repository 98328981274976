
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../template/shared/proto/services/routing.service';
import { Subscription } from 'rxjs';
import { MYPROJECTS, ALLPROJECTS } from '../../taskboard/taskboard.help';
import { flatMap } from '../../core/helpers';
import { SelectedPartnerService } from '../../services/partner/selectedPartner.service';
import { StorageOptions } from '../../template/shared/proto/storage/StorageOptions';
import { StorageService } from '../../template/shared/proto/storage/storage.service';

const PROJECTS_MENU: any = [
  {
    title: 'PROJEKTAI',
    icon: 'zmdi zmdi-case',
    entries: [
      {
        title: 'Mano įvesti',
        isActive: false,
        routerLink: MYPROJECTS,
      },
      {
        title: 'Visi projektai',
        isActive: false,
        routerLink: ALLPROJECTS,
      },
    ]
  },
];

@Component({
  selector: 'projects-menu',
  templateUrl: './projects-menu.component.html',
  styleUrls: ['./projects-menu.component.scss']
})
export class ProjectsMenuComponent implements OnInit, OnDestroy {

  showMenu: boolean = false;
  prevMenu: any;
  currentMenu: any;
  private _subscriptions: Array<Subscription> = [];
  private _pageSavingOptions: StorageOptions = {
    popState: true,
    refresh: true
  };

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private selectedPartnerService: SelectedPartnerService,
    private _storageService: StorageService,
  ) {
  }

  ngOnInit() {
    this.selectedPartnerService.resetPartner();
    this.selectedPartnerService.resetUser();
    this.currentMenu = PROJECTS_MENU.map((el) => { return { ...el, isActive: false } });
    this.showMenu = true;
    this._subscriptions.push(this.routingService.currentRoute.subscribe((cr) => {
      try {
        let currentUrl = cr.url.split('/projects/')[1];
        if (currentUrl.indexOf('/v/') < 0) {
          const parts = currentUrl.split('?');
          if (parts.length > 1) {
            currentUrl = parts[0];
          }
          let current;
          current = flatMap(PROJECTS_MENU, m => m.entries).find(e => e.routerLink == currentUrl);
          if (current) {
            const menuEntries = flatMap(PROJECTS_MENU, m => m.entries);
            menuEntries.forEach(item => item.isActive = false);
            current.isActive = true;
          }
        }
      } catch (e) {
        console.log('try catch error');
      }
    }));
  }

  resetPageSessionStorage() {
    this._storageService.store('currentPage', 0, this._pageSavingOptions);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sp) => sp.unsubscribe());
  }
}
