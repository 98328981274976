
import { switchMap, pluck } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NewsBackend } from '../../../backend/news.backend';
import { DATE_WITH_TIME, SHORT_DATE } from '../../../core/time';
import { NEWS_STATUS_PUBLISHED, NEWS_STATUS_NOT_PUBLISHED } from '../../../content/constants';
import swal from 'sweetalert2';

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.scss']
})
export class EditNewsComponent implements OnInit {
  _id: Observable<number>;
  _newsDetails: Subject<any> = new Subject();
  dateWithTime = DATE_WITH_TIME;
  shortDate = SHORT_DATE;
  _isEditable = false;
  public _news: any;
  isLoading = false;


  constructor(private _route: ActivatedRoute, private chg: ChangeDetectorRef,
    private _newsBackend: NewsBackend) {
  }

  ngOnInit() {
    this._id = this._route.params.pipe(pluck('id'));
    this._id.pipe(switchMap((id) => this._newsBackend.getNews(id)))
      .subscribe((r: any) => {
        this._news = r.entity;
        try {
          this.chg.detectChanges();
        } catch (err) { }
      });
  }

  _getStatus(status) {
    switch (status) {
      case NEWS_STATUS_PUBLISHED.status.toString(): return 'Taip'
      case NEWS_STATUS_NOT_PUBLISHED.status.toString(): return 'Ne';
      default: return null;
    }
  }

  _getPrivate(isPrivate: any) {
    return isPrivate ? 'Taip' : 'Ne';
  }

  _setIsEditable() {
    this._isEditable = true;
  }

  cancel() {
    this._isEditable = false;
    try {
      this.chg.detectChanges();
    } catch (err) { }
  }

  save(updateObj) {
    this.isLoading = true;
    this._newsBackend.editNews(updateObj).subscribe((r: any) => {
      this.isLoading = false;
      try {
        this.chg.detectChanges();
      } catch (err) { }
      if (r.success) {
        this._isEditable = false
        this._news = r.entity;
        swal.fire({
          title: 'Išsaugota sėkmingai',
          timer: 2000,
          showConfirmButton: false,
          icon: 'success'
        })
      } else {
        if (r.errorCode === 417) {
          swal.fire({
            title: 'Nėra užpildyti Santrumpa arba Tekstas laukai',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          })
        } else {
          swal.fire({
            title: 'Išsaugoti nepavyko',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          });
        }
      }
      try {
        this.chg.detectChanges();
      } catch (err) { }
    });
  }

}