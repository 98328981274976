
import { switchMap, pluck, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PartnersBackend } from '../../../backend/partners.backend';


@Component({
  selector: 'app-edit-delegate',
  templateUrl: './edit-delegate.component.html',
  styleUrls: ['./edit-delegate.component.scss']
})
export class EditDelegateComponent implements OnInit {
  protected _showEdit = false;
  id: any;
  _id: Observable<number>;
  _delegateInfo: Subject<any> = new Subject();
  _rights: Subject<any> = new Subject();

  constructor(private _route: ActivatedRoute,
    private _partnersBackend: PartnersBackend) { }

  ngOnInit() {
    this._id = this._route.params.pipe(tap((pars) => { this.id = pars.id }), pluck('id'));
    this._id.pipe(switchMap((id) => this._partnersBackend.getDelegateInfo(id)))
      .subscribe((r: any) => {
        if (r.entity) {
          this._delegateInfo.next(r.entity);
        }
      });
  }

  openEdit(value) {
    this._showEdit = value;
    if (!value) {
      this._partnersBackend.getDelegateInfo(this.id)
        .subscribe((r: any) => {
          if (r.entity) {
            this._delegateInfo.next(r.entity);
          }
        });
    }
  }

}
