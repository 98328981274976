import { AfterContentInit, Directive, Inject, OnDestroy, OnInit } from '@angular/core';
import { PageLengthChanger } from './PageLengthChanger';
import { PAGE_LENGTH_CHANGER } from '../../dataTable';
import { Subscription } from 'rxjs';
import { ValueProvider } from '../../../common/ValueProvider';

@Directive({
  selector: '[pageLength]',
})
export class PageLengthDirective implements OnInit, AfterContentInit, OnDestroy, ValueProvider<any> {

  private _subscriptions: Array<Subscription> = [];
  private _chg: (v: any) => any;
  private _currentVal: any;
  constructor(@Inject(PAGE_LENGTH_CHANGER) public lengthChanger: PageLengthChanger) {
  }

  ngOnInit() {

  }

  ngAfterContentInit() {

  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe())
  }

  getValueChanges() {
    return this.lengthChanger.lengthChanged;
  }
}
