import 'reflect-metadata';
import { StorageOptions } from "./StorageOptions";

export const STORABLE_KEY = Symbol('storable');

export function Store(p?: StorageOptions, def?: any): PropertyDecorator {
  return function (target: Object, propertyKey: string) {
    let properties: any[] = Reflect.getMetadata(STORABLE_KEY, target);
    if (properties) {
      properties.push({ prop: propertyKey, options: p, default: def });
    } else {
      properties = [{ prop: propertyKey, options: p, default: def }];
      Reflect.defineMetadata(STORABLE_KEY, properties, target.constructor);
    }
  }
}
