import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardsComponent } from './dashboards.component';
import { SharedModule } from '../template/shared/shared.module';
// Demos of ng-chartist
// import { ChartistBarsComponent } from "../template/shared/components/charts/chartist/chartist-bars/chartist-bars.component";
// import { ChartistLineComponent } from "../template/shared/components/charts/chartist/chartist-line/chartist-line.component";
// import { ChartistLinesComponent } from "../template/shared/components/charts/chartist/chartist-lines/chartist-lines.component";

//cards
import { StackedCardsComponent } from "./stacked-cards/stacked-cards.component";
import { TasksViewComponent } from './tasks-view/tasks-view.component';
import { TasksTabsComponent } from './tasks-tabs/tasks-tabs.component';
import { DashboardService } from './dashboard.service';
import { NewsViewComponent } from './news-view/news-view.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ViolationChartComponent } from './violation-chart/violation-chart.component';
import { ViolationChartMenuComponent } from './violation-chart-menu/violation-chart-menu.component';
import { WorkTimeByTypeChartComponent } from './work-time-by-type-chart/work-time-by-type-chart.component';
import { WorkTimeByTypeChartMenuComponent } from './work-time-by-type-chart-menu/work-time-by-type-chart-menu.component';
import { EmployeeWorkSummaryViewComponent } from './employee-work-summary-view/employee-work-summary-view.component';
import { TaskboardSharedModule } from './../taskboard/taskboard.shared.module';
import { RatingComponent } from './rating/rating.component';
import { DateMonthComponent } from '../components/filters/date-month/date-month.component';
import { BadRatedRequestsComponent } from './bad-rated-requests/bad-rated-requests.component';
import { BadRatedRequestsSelectComponent } from '../components/filters/bad-rated-requests-select/bad-rated-requests-select.component';
import {
  DateAdapter,
  // MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import {
  CustomMonthAdapter,
  // AppMonthFormat
} from '../utils/monthAdapter';
import { Platform } from '@angular/cdk/platform';

const DASHBOARDS_ROUTE: Routes = [
  { path: '', pathMatch: 'full', component: DashboardsComponent },
];

@NgModule({
  declarations: [
    DashboardsComponent,
    // ChartistBarsComponent,
    // ChartistLineComponent,
    // ChartistLinesComponent,
    StackedCardsComponent,
    TasksViewComponent,
    TasksTabsComponent,
    NewsViewComponent,
    ViolationChartComponent,
    ViolationChartMenuComponent,
    WorkTimeByTypeChartComponent,
    WorkTimeByTypeChartMenuComponent,
    EmployeeWorkSummaryViewComponent,
    DateMonthComponent,
    RatingComponent,
    BadRatedRequestsComponent,
    BadRatedRequestsSelectComponent
  ],
  imports: [
    RouterModule.forChild(DASHBOARDS_ROUTE),
    SharedModule,
    TaskboardSharedModule,
    MatExpansionModule
  ],
  providers: [
    DashboardService,

    { provide: DateAdapter, useClass: CustomMonthAdapter, deps: [MAT_DATE_LOCALE, Platform] },
    // { provide: MAT_DATE_FORMATS, useValue: AppMonthFormat },
    // { provide: MAT_DATE_LOCALE, useValue: 'lt-LT' },
  ]

})

export class DashboardsModule { }
