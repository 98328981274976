
import { take, tap, map, switchMap, startWith } from 'rxjs/operators';
import { Component, Inject, Input, OnInit, Optional, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs'
import swal from 'sweetalert2';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { StateService } from "../../../../services/state/stateService";
import { JOB_UPDATED, UPDATE_TASKBOARD_LIST } from "../../../../state/state";
import { JobDpaEntry } from "../../../models/job-dpa-entry";
import { JobsBackend } from "../../../../backend/jobs/jobs.backend";
import { JobsListEntry } from "../../../models/jobs-list-entry";
import { DpaBackend } from '../../../../backend/dpa.backend';
import { FilesBackend } from '../../../../backend/files.backend';
import { remove } from "../../../../template/shared/proto/helpers";

@Component({
  selector: 'dpa-controls',
  templateUrl: './dpaControls.component.html',
  styleUrls: ['./dpaControls.component.scss']
})
export class DpaControlsComponent implements OnInit, OnDestroy {
  private _jobId;
  @Input() set jobId(val) {
    this._jobId = val;
  }
  get jobId() {
    if (this._data) {
      return this._data.jobId;
    } else {
      return this._jobId;
    }
  }

  private _currentDpaId;
  @Input() set currentDpaId(val) {
    this._currentDpaId = val;
  }
  get currentDpaId() {
    if (this._currentDpaId) {
      return this._currentDpaId;
    } else if (this._data) {
      return this._data.dpaId;
    }
  }

  private _isUpdating = false;
  jobDpa: Subject<JobDpaEntry[]> = new BehaviorSubject([]);
  private _openingDpas = [];

  @Input() possibleDpa: JobDpaEntry[];
  @Output() dpaChanged = new EventEmitter<any>();

  trigger$: Subject<any> = new Subject();

  subs: Subscription[] = [];

  constructor(
    private _dpaBackend: DpaBackend,
    @Optional() @Inject(MAT_DIALOG_DATA) private _data: any,
    @Optional() private _dialogRef: MatDialogRef<DpaControlsComponent>,
    private _jobsBackend: JobsBackend,
    private _filesBackend: FilesBackend,
    private _chg: ChangeDetectorRef,
    private _stateService: StateService) {
    if (_data) {
      this._currentDpaId = _data.dpaId;
    }
  }

  ngOnInit(): void {
    if (!this.jobId) {
      throw new Error("DpaControlsComponent: job ID must be provided!");
    }
    this.subs.push(this._stateService.subscribe(JOB_UPDATED, (job: any) => {
      this.trigger$.next(true);
    }));
    this.subs.push(this._loadList().subscribe((res) => {
      this.jobDpa.next(res);
    }));
  }

  _attachToNewDpa() {
    if (!this._isUpdating) {
      const prevList = (<BehaviorSubject<Array<any>>>this.jobDpa).value;
      swal.fire({ title: 'Pradėti naują DPA?', showConfirmButton: true, showCancelButton: true })
        .then((r) => {
          if (r.value) {
            this._isUpdating = true;
            this.subs.push(this._updateDpa({ jobId: this.jobId, createDpa: 1, deleteFromDpa: 1 }).pipe(
              switchMap((res) => {
                this._isUpdating = true;
                return this._loadList();
              }))
              .subscribe((res) => {
                if (res) {
                  for (const r of res) {
                    if (prevList.find((l) => l.id == (<any>r).id)) {

                    } else {
                      this.currentDpaId = (<any>r).id;
                    }
                  }
                }
                this.dpaChanged.emit(this.currentDpaId ? res.find(el => el.id === this.currentDpaId) : null);
                this.jobDpa.next(res);
                this._isUpdating = false;
                this._close();
              }));
          }
        });
    }

  }

  private _checked(checked, id) {
    if (!this._isUpdating) {
      let msg = checked ? 'Perkelti darbą į DPA?' : 'Atkabinti nuo esamo DPA?';
      let params = checked ? { jobId: this.jobId, dpaId: id } : { jobId: this.jobId, dpaId: id, deleteFromDpa: 1 };
      swal.fire({ title: msg, showConfirmButton: true, showCancelButton: true })
        .then((r) => {
          if (r.value) {
            this._isUpdating = true;
            this.subs.push(this._updateDpa(params).pipe(//.do((res) => this.currentDpaId = checked ? id : null)  //TODO: prideti tikrinima ar operacija pavyko bazeje
              switchMap((res) => {
                if (!checked) {
                  this.currentDpaId = null;
                }
                return this._loadList();
              }))
              .subscribe((res) => {
                this.dpaChanged.emit(this.currentDpaId ? res.find(el => el.id === this.currentDpaId) : null);
                this.jobDpa.next(res);
                this._isUpdating = false;
                this._close();
              }));
          }
        });
    }
  }

  private _loadList(): Observable<JobDpaEntry[]> {
    {
      return this.trigger$.asObservable().pipe(
        startWith(0),
        switchMap(() => {
          return <Observable<JobDpaEntry[]>>this._jobsBackend.getPossibleDpa(this.jobId).pipe(map((e: any) => e.entities))
        }
        ));
    }
  }

  //make refresh data without backend call
  // private _loadList(): Observable<JobDpaEntry[]> {
  //   {
  //     return combineLatest([this._jobsBackend.getPossibleDpa(this.jobId), this.trigger$.pipe(startWith(null)),]).pipe(tap(e => console.log('eeee', e)), map(([e]) => (<any>e).entities));
  //   }
  // }

  test1() {
    this.trigger$.next(true);
  }

  private _updateDpa(params): Observable<any> {
    return this._dpaBackend.updateDpaJob(params.dpaId, params).pipe(map((res: any) => res && res.entity > 0 ? res.entity : -1), tap((r) => { //TODO nr4
      const jobId = this.jobId;
      this._stateService.notify(UPDATE_TASKBOARD_LIST, (row: JobsListEntry) => {
        if (row.id == jobId) {
          if (typeof row.setDpa == 'function') {
            return row.setDpa(r, 'DPA' + r);
          }
        }
        return row;
      });
      this.currentDpaId = params.deleteFromDpa ? null : params.dpaId;
    }));
  }

  private _openDpa(dpa) {
    const id = dpa.id;
    if (this._isDpaOpening(id)) {
      return;
    }
    if (dpa.allowedToFinish) {
      if (this._openingDpas.indexOf(id) < 0) {
        this._openingDpas.push(id);
        let newWindow = window.open('/assets/loading.html');
        this.subs.push(this._filesBackend.getDpaForPrint(id).subscribe((response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
          // const fileLink = document.createElement('a');
          // fileLink.href = blobUrl;
          // fileLink.target = '_blank';
          // fileLink.click();
          newWindow && newWindow.location && newWindow.location.assign && newWindow.location.assign(encodeURI(blobUrl));
          this._openingDpas = remove(this._openingDpas, id);
          try {
            this._chg.detectChanges();
          } catch (e) {
          }
        },
          (err) => {
            this._openingDpas = remove(this._openingDpas, id);
            try {
              this._chg.detectChanges();
            } catch (e) {
            }
          }
        ));
      }
    }
  }

  _isDpaOpening(id: any) {
    return this._openingDpas.indexOf(id) > -1;
  }

  private _close() {
    if (!this._dialogRef) {
      return;
    }
    const id = this._currentDpaId;
    this.subs.push(this.jobDpa.pipe(take(1)).subscribe(dpas => {
      for (const d of dpas) {
        if (d.id == id) {
          this._dialogRef.close({ dpaId: d.id, dpaCode: d.code });
          return;
        }
      }
      this._dialogRef.close({ dpaId: null, dpaCode: null });
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
