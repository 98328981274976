import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { FileItem, FileLikeObject, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { downloadFile, isImage } from '../../../../functions/utils/files';
import { ImageModalComponent } from '../modals/image-modal/image-modal.component';
import { MatDialog } from '@angular/material/dialog';
import swal from 'sweetalert2';
import { AuthService } from '../../../../services/auth.service';
import { environment } from '../../../../../environments/environment';
import { FilesBackend } from '../../../../backend/files.backend';

const URL_UPLOAD = environment.apiUrl + 'files/upload/';

@Component({
  selector: 'upload-file',
  templateUrl: 'upload-file.component.html',
  styleUrls: ['upload-file.component.scss']
})
export class UploadFileComponent implements AfterViewInit, OnChanges, OnInit {
  @Input() keyPrefix: string;
  private _key;
  public get key(): string {
    return (this.keyPrefix ? this.keyPrefix + '_' : '') + this._key;
  }

  @Input() initFiles: any;
  @Input() disallowFilesEdit;

  public get uploadUrl(): string {
    return URL_UPLOAD + this.key;// + '/';
  }

  protected _uploader: FileUploader;

  protected MAX_FILE_SIZE = 20 * 1024 * 1024;

  public constructor(private _chg: ChangeDetectorRef,
    private _dialog: MatDialog,
    private authService: AuthService,
    private filesBackend: FilesBackend) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (!this._uploader) {
      this.refresh();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['keyPrefix']) {
      this.refresh();
    }
  }

  public refresh() {
    this._key = new Date().getTime();
    this._makeUploader();
  }

  remove(item) {
    item.deleting = true;
    this.key;
    this.filesBackend.removeFile(item.itemId, this.key).subscribe((res) => {
      if (res.success == true) {
        item.deleting = false;
        item.deleted = true;
        this._uploader.removeFromQueue(item);
        try {
          this._chg.detectChanges();
        } catch (e) { }
      }
    });
  }

  download(item) {
    downloadFile(item);
  }

  downloadInit(item) {
    downloadFile(item);
  }

  downloadByDstring(item) {
    this.filesBackend.downloadFileByDstring(item);
  }

  private _makeUploader() {
    const chg = this._chg;
    if (this._uploader) {
      // this._uploader.destroy();
      delete this._uploader;
    }
    const auth = 'Bearer ' + this.authService.idToken;
    this._uploader = new FileUploader({
      url: this.uploadUrl, autoUpload: true, maxFileSize: this.MAX_FILE_SIZE, authTokenHeader: 'Authorization',
      authToken: auth
    });
    this._uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this._uploader.onWhenAddingFileFailed = (f: FileLikeObject, filter, o) => {
      if (filter.name === 'fileSize') {
        swal.fire({ text: 'Failas negali būti didesnis nei 20MB', icon: 'warning', timer: 2000, showConfirmButton: false });
      }
    };
    this._uploader.onBeforeUploadItem = (fileItem: FileItem) => {
      if (!this._key) {
        throw new Error('Can\'t upload files without key');
      }
    };
    this._uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      if (status == 200) {
        response = response ? JSON.parse(response) : {};
        item['name'] = item.file.name;
        (<any>item).itemId = (<any>response).entity.id;
        (<any>item).dString = (<any>response).entity.dString;
        try {
          chg.detectChanges();
        } catch (e) {
          console.error(e);
        }
      } else {
        item.remove();
      }
    };
  }

  _isImage(opt) {
    return opt && opt.name ? isImage(opt) : false;
  }

  _isImageInit(opt) {
    const file = { name: opt.fileName };
    return file && file.name ? isImage(opt) : false;
  }

  _showImage(opt) {
    this._dialog.open(ImageModalComponent, { data: opt });
  }

  _showImageInit(opt) {
    this._dialog.open(ImageModalComponent, { data: opt });
  }
}
