import { Component } from '@angular/core'
import { MatCalendarHeader, MatCalendar, MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'date-icalendar-custom-header',
  templateUrl: './calendarCustomHeader.component.html',
  styleUrls: ['./calendarCustomHeader.component.scss'],
  providers: [
    MatCalendarHeader<any>
  ]
})

export class CalendarCustomHeaderComponent {

  constructor(
    public calendar: MatCalendar<any>,
    public datePicker: MatDatepicker<any>,
    public calendarHeader: MatCalendarHeader<any>,
  ) { }


  todayClick() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let fire = false;
    if (this.calendar.minDate && this.calendar.maxDate) {
      if (today.getTime() >= new Date(this.calendar.minDate).getTime() && today.getTime() <= new Date(this.calendar.maxDate).getTime()) {
        fire = true;
      }
    } else if (this.calendar.minDate) {
      if (today.getTime() >= new Date(this.calendar.minDate).getTime()) {
        fire = true;
      }
    } else if (this.calendar.maxDate) {
      if (today.getTime() <= new Date(this.calendar.maxDate).getTime()) {
        fire = true;
      }
    } else {
      fire = true;
    }
    if (fire) {
      this.calendar.selectedChange.emit(today);
      this.datePicker.select(today);
    }
  }
}





