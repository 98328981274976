import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LogsBackend {

  baseUrl = environment.apiUrl;

  constructor(
    private _http: HttpClient
  ) {
  }

  saveLog(createObj) {
    return this._http.post(this.baseUrl + 'logs', createObj, { observe: 'response' });
  }

}
