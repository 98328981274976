import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByProperty'
})
export class FilterByPropertyPipe implements PipeTransform {

  transform(items: any[] = [], property: string, value: any, trigger: any = 0): any {
    return items.filter(item => item[property] !== value);
  }

}