
import { catchError } from 'rxjs/operators';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TasksBackend } from "../../../../backend/tasks.backend";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { TaskReview } from "../../../models/task-review";
import { Subscription, throwError } from 'rxjs';
import { LIST } from '../../../../services/lists/lists.data.service';
import swal from 'sweetalert2';

@Component({
  selector: 'update-task-service',
  templateUrl: './update-task-service.component.html',
  styleUrls: ['./update-task-service.component.scss']
})
export class UpdateTaskServiceComponent implements OnInit, OnDestroy {

  protected _submitting = false;
  protected _submitted = false;
  task: any;
  _serviceForm: UntypedFormGroup;
  subs: Subscription[] = [];

  protected services: LIST = LIST.SERVICES;

  constructor(@Inject(MAT_DIALOG_DATA) private data: TaskReview,
    private tasksBackend: TasksBackend,
    public dialogRef: MatDialogRef<UpdateTaskServiceComponent>,
    private _formBuilder: UntypedFormBuilder) {

    this.task = data;
  }

  ngOnInit() {
    this._buildForm();
  }

  private _buildForm() {
    this._serviceForm = this._formBuilder.group({
      serviceId: [this.task.serviceId ? this.task.serviceId : null, Validators.required],
      text: [null],
    });
  }

  protected _submit() {
    this._submitting = true;
    let updateObj;
    if (this._serviceForm.controls.text.value) {
      updateObj = { serviceId: parseInt(this._serviceForm.controls.serviceId.value), serviceComment: this._serviceForm.controls.text.value };
    } else {
      updateObj = { serviceId: parseInt(this._serviceForm.controls.serviceId.value) };
    }
    this.subs.push(this.tasksBackend.updateTaskService(this.task.id, updateObj)
      .pipe(catchError((err) => {
        console.log('caught error', err);
        return throwError(err);
      }))
      .subscribe((res: any) => {
        this._submitting = false;
        this._submitted = true;
        if (res && res.success) {
          this.dialogRef.close({ success: true });
        } else {
          this.dialogRef.close({ success: false, errorMessage: res && res.error });
        }
      }, (err) => {
        this._submitting = false;
        swal.fire({
          title: 'Įvyko klaida',
          timer: 2000,
          showConfirmButton: false,
          icon: 'error'
        });
      }));
  }

  noChanges(): boolean {
    return (this._serviceForm.controls.serviceId.value && parseInt(this._serviceForm.controls.serviceId.value)) === this.task.serviceId;
  }

  protected _cancel() {
    this.dialogRef.close({ success: null });
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
