import { Pipe, PipeTransform } from '@angular/core';
//// !!!!! import { number } from "ng2-validation/dist/number";

export type Mode = 'append' | 'prepend'

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {

  transform(value: any): any {
    let val: number;
    if (typeof value == 'string') {
      val = parseInt(value);
      if (!val) {
        return '0,00';
      }
    } else if (typeof value == 'number') {
      val = value;
    } else if (val == null) {
      val = 0;
    } else {
      throw new Error('Wrong value passed to money pipe! ' + value);
    }
    return val.toFixed(2).replace('.', ',');
  }

}
