
import { switchMap, pluck } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditUserModalComponent } from '../edit-user-modal/edit-user-modal.component';
import { UserBackend } from '../../../backend/user.backend';
import swal from 'sweetalert2';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  _id: Observable<number>;
  _userDetails: Subject<any> = new Subject();
  _rights: Subject<any> = new Subject();
  _currentUserDetailes;

  constructor(private _route: ActivatedRoute,
    public dialog: MatDialog,
    private userBackend: UserBackend) { }

  ngOnInit() {
    this._id = this._route.params.pipe(pluck('id'));
    this._id.pipe(switchMap((id) => this.userBackend.getUserDetails(id)))
      .subscribe((r: any) => {
        if (r.entity && r.entity.userDetails) {
          this._currentUserDetailes = r.entity.userDetails;
          this._userDetails.next(r.entity.userDetails);
        }
        if (r.entity && r.entity.claimsForAssignment) {
          this._rights.next(r.entity.claimsForAssignment);
        }
      });
  }

  _editUser() {
    const modalRef = this.dialog.open(EditUserModalComponent, <MatDialogConfig>{ width: '30%' });
    modalRef.componentInstance._email = this._currentUserDetailes.email;
    modalRef.componentInstance._name = this._currentUserDetailes.name;
    modalRef.componentInstance._userName = this._currentUserDetailes.userName;
    modalRef.componentInstance._lastName = this._currentUserDetailes.lastName;
    modalRef.componentInstance._partnerId = this._currentUserDetailes.companyId;
    modalRef.beforeClosed().subscribe((data) => {
      if (data) {
        this.userBackend.editUser({ ...data, userId: this._currentUserDetailes.userId }).subscribe(res => {
          if (res && res.success) {
            swal.fire({
              title: 'Vartotojo duomenys pakeisti',
              allowOutsideClick: false,
              timer: 1500,
              icon: 'success',
              showConfirmButton: false
            });
            this._userDetails.next(res.entity);
            this._currentUserDetailes = res.entity;
          } else {
            swal.fire({
              title: 'Vartotojo duomenų pakeisti nepavyko',
              allowOutsideClick: false,
              timer: 1500,
              icon: 'error'
            });
          }
        });
      }
    });
  }

}
