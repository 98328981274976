
import { tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../core/api/api.service';

@Injectable()
export class FilesBackend {
  constructor(private http: HttpClient,
    private apiService: ApiService) {

  }

  // public downloadFile(file) { //TODO nr7 not used method
  //   if (!file || !(file.dString || file.fileId)) {
  //     throw new Error('Cannot download file without either of dString or fileId');
  //   }
  //   const url = file.dString ? ('service/f/download/' + file.dString) : ('files/' + file.fileId);
  //   return this.apiService.downloadFileAuth(url, { responseType: 'blob' }).subscribe((d: any) => {
  //     this._download(d, file.name || file.fileName);
  //   });
  // }

  getImage(fileId) {
    return this.apiService.getImage(`files/download/${fileId}`); //TODO tested
  }

  getImageByDstring(fileId) {
    return this.apiService.getImage(`files/downloadByDstring/${fileId}`); //TODO tested
  }

  public downloadFile(file) { //TODO tested
    if (!file || !file.fileId) {
      throw new Error('Cannot download file without either of dString or fileId');
    }
    return this.apiService.downloadFileAuth(`files/download/${file.fileId}`).subscribe((d: any) => {
      this._download(d, file.name || file.fileName);
    });
  }

  public downloadFileByDstring(file) { //TODO tested
    if (!file || !file.dString) {
      throw new Error('Cannot download file without either of dString');
    }
    const url = 'files/downloadByDstring/' + file.dString;
    return this.apiService.downloadFileAuth(url).subscribe((d: any) => {
      this._download(d, file.name || file.fileName);
    });
  }

  public downloadDpa(id) {
    return this.apiService.getDpaServiceBlob(`download/${id}`).pipe(
      tap((d: any) => {
        this._download(d, 'DPA' + id + '.pdf');
      }));
  }

  public getDpaForPrint(dpaId) {
    return this.apiService.getDpaServiceBlob(`download/${dpaId}`);
  }

  private _download(blob: Blob, name) {
    var a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public removeFile(itemId, key) { //TODO tested
    return this.apiService.deleteAuth('files/delete/' + itemId + (key ? '/' + key : '')).pipe(map((res: any) => res.body));
  }
}
