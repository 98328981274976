
import { map, filter } from 'rxjs/operators';
import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonDropdownComponent } from './../../../template/shared/components/dropdowns/button-dropdown/button-dropdown.component';
import { StorageService } from './../../../template/shared/proto/storage/storage.service';
import { LIST, ListsDataService } from "../../../services/lists/lists.data.service";
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { VALUE_PROVIDER } from '../../../template/shared/proto/proto';
import { ValueProvider } from '../../../template/shared/proto/common/ValueProvider';

@Component({
  selector: 'dpa-types',
  template: `
        <button-dropdown
            [options]="types | async"
            [map]="suggestionMapper"
            [itemClass]="'btn-default btn-sm'"
            (itemClick) ="_typeSelected($event)">
        </button-dropdown>
    `,
  styleUrls: ['./dpa-types.component.scss'],
  providers: [
    { provide: VALUE_PROVIDER, useExisting: forwardRef(() => DpaTypesComponent) }
  ]
})
export class DpaTypesComponent implements OnInit, ValueProvider<String>, OnDestroy {
  @Input() placeholder = 'DPA tipas';
  @Output() typeSelected: Subject<any> = new Subject();
  suggestionMapper = (p) => p.name;
  protected types: Subject<Array<any>> = new BehaviorSubject([]);
  private _chgListener: (v: any) => void;
  private _currentType;
  private _subscriptions = new Array<Subscription>();
  @ViewChild(ButtonDropdownComponent) button: ButtonDropdownComponent<any>;

  typesList = [
    {
      typeId: 1,
      name: 'Aptarnavimo DPA'
    },
    {
      typeId: 2,
      name: 'Projektų DPA'
    }
  ]


  constructor(private dataService: ListsDataService,
    private _storageService: StorageService,
    private _chg: ChangeDetectorRef) {

  }


  ngOnInit() {
    const stored = this._storageService.get('selectedDpaType', null);
    this.types.next(this.typesList);
    if (stored != null) {
      const s = (<Array<any>>this.typesList).find((a) => a.typeId == stored.typeId);
      if (s) {
        setTimeout(() => {
          this.button.title = s.name;
          this._typeSelected(s);
          try {
            this._chg.detectChanges();
          } catch (e) { }
        }, 0);

      }

    } else {
      if (this.typesList.length > 0) {
        setTimeout(() => {
          this._typeSelected(this.typesList[0]);
        }, 0);
      }
    }
  }

  _typeSelected(type) {
    this._storageService.store('selectedDpaType', type);
    this._currentType = type.typeId;
    if (this._chgListener) {
      this._chgListener(this._currentType);
    }
    this.typeSelected.next(type);
  }

  getValueChanges() {
    return this.typeSelected.pipe(filter((t) => t != null), map((t) => t.typeId));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}
