
import { delay, map, startWith, switchMap, tap } from 'rxjs/operators';
import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Host, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges, SkipSelf, ViewChild } from '@angular/core';
import { LIST, List, ListsDataService } from '../../services/lists/lists.data.service';
import { Observable, Subject, Subscription, BehaviorSubject, combineLatest, of } from 'rxjs';
import { ControlContainer, ControlValueAccessor, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProtoInputComponent, startsWith } from '../../template/shared/proto/components/input/protoInput.component';

@Component({
  selector: 'suggestions',
  templateUrl: './suggestions.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SuggestionsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SuggestionsComponent),
      multi: true
    }
  ]
})
export class SuggestionsComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  text: Observable<string>;
  @Input() placeholder = '';
  @Input() list: LIST;
  @Input() formControlName: string;
  @Input() searchFn: Function;
  @Input() submitted = false;
  @Input() initialValueSelector;
  @Input() filterFn;
  @Input() deselect$;
  @Input() removeList;
  @Input() removeListId;
  @Input() topBarSearch;
  @Input() deselectOnParmasChange: boolean = false;
  @Output() suggestionSelected: EventEmitter<any> = new EventEmitter();
  @Output() suggestionObjSelected: EventEmitter<any> = new EventEmitter();
  @ViewChild(ProtoInputComponent, { static: true }) input: ProtoInputComponent<any>;
  private data: Observable<Array<any>> = new BehaviorSubject([]);
  @Input() protected mapView: Function;
  @Input() protected mapVal: Function;
  private _params: Subject<any> = new BehaviorSubject(null);
  private lst: List<any>;
  private loader: Function;
  private _minChars: number;
  private _form: UntypedFormGroup;
  protected source = () => this.data;
  private _subscriptions: Array<Subscription> = [];
  private removeListChanges$ = new Subject();
  clearVo$ = new Subject();

  @Input() set params(val: any) {
    this._params.next(val);
  }

  constructor(private dataService: ListsDataService,
    private chg: ChangeDetectorRef,
    @Optional() @Host() @SkipSelf()
    private ctrlContainer: ControlContainer,
    @Optional() @SkipSelf() private _formGroup: FormGroupDirective) {

  }

  filterOutList(mainList, filterOutList) {
    if (!filterOutList || filterOutList.length < 1 || !this.removeListId) {
      return mainList;
    }
    const result = [];
    mainList.forEach((el) => {
      if (!filterOutList.find((x) => el[this.removeListId] == x[this.removeListId])) {
        result.push(el);
      }
    });
    return result;
  }

  ngOnInit() {
    if (this.deselect$) {
      this._subscriptions.push(this.deselect$.subscribe(() => {
        this.deselect()
      }));
    }
    this.lst = this.dataService.getList(this.list);
    // setTimeout(() => {
    //   console.log('removeList', this.removeList, this.dataService.getList(this.list));
    // }, 2000);
    if (!this.placeholder) {
      this.placeholder = this.lst.placeholder;
    }
    !this.mapView && (this.mapView = this.lst.mapView);
    !this.mapVal && (this.mapVal = this.lst.mapVal);
    this.filterFn = this.lst.filterFn || startsWith;
    if (!this.initialValueSelector) {
      this.initialValueSelector = this.lst.initialValueSelector;

    }
    this._subscriptions.push(
      combineLatest(this._params, this.removeListChanges$.pipe(startWith(null))).pipe(switchMap((p, _) => this.lst.searchable ? this.lst.load(p, this.input.textStream) : this.lst.load(p)),
        // combineLatest(this._params).pipe(switchMap((p) => this.lst.searchable ? this.lst.load(p, this.input.textStream) : this.lst.load(p)),
        delay(0),
        map(arr => this.filterOutList(arr, this.removeList)),
      )
        .subscribe((r) => {
          (<Subject<any>>this.data).next(r);
          this.clearVo$.next(true);
          try {
            this.chg.detectChanges();
          } catch (e) { }
        })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.removeList) {
      this.removeListChanges$.next(null);
    }
    if (changes.params && !changes.params.firstChange && this.deselectOnParmasChange) {
      this.deselect();
    }
  }

  _suggestionSelected(s) {
    this.suggestionSelected.emit(s);
  }

  writeValue(obj: any): void {
    this.input.writeValue(obj);
  }

  registerOnChange(fn: any): void {
    this.input.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.input.registerOnTouched(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.input.setDisabledState(isDisabled);
  }

  validate(c: UntypedFormControl) {
  }

  _suggestionObjSelected(obj) {
    this.suggestionObjSelected.emit(obj);
  }

  public deselect() {
    this.input.deselect();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

}
