
import { of as observableOf, EMPTY, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FinishJob } from './models';
import { ApiService } from '../../core/api/api.service';
import { EntityUpdated } from "../../core/api/response/entity-updated";
import { JobModel } from "../../taskboard/models/job-model";
import { EntityList } from "../../core/api/response/entity-list";
import { JobsListEntry } from "../../taskboard/models/jobs-list-entry";
import { TaskCommented } from "../tasks.backend";
import { EntityCreated } from "../../core/api/response/entity-created";

export interface JobEntered {
  job: JobsListEntry;
  commented?: TaskCommented;
}

@Injectable()
export class JobsBackend {
  constructor(
    private _http: HttpClient,
    private _api: ApiService) {

  }

  private paramsFromObject(params): HttpParams {
    if (params) {
      let httpParams = new HttpParams();
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.append(key, params[key]);
      });
      return httpParams;
    } else {
      return undefined
    }
  }

  public getJobsList(params: number | Object): Observable<EntityList<JobsListEntry>> {
    if (typeof params == 'number')// tasko id
    {
      return EMPTY;
    } else {
      const pars: any = { ...params };
      const sentDpa = pars.sentDpa;
      const notSentDpa = pars.notSentDpa;
      delete pars.sentDpa;
      delete pars.notSentDpa;
      if (sentDpa && notSentDpa) {
        //if all jobs needed - then sentDpa parameter should be null
      } else if (sentDpa) {
        pars.sentDpa = 1;
      } else if (notSentDpa) {
        pars.sentDpa = 0;
      }
      return <any>this._api.getAuth('jobs/list', pars).pipe(map(x => x.body)); //TODO nr6 tested
    }
  }

  public getPossibleDpa(jobId: number) {
    return <any>this._api.getAuth(`jobs/possibleDpa/${jobId}`).pipe(map(x => x.body)); //TODO nr6 tested
  }

  public finishJob(finishJob: FinishJob): Observable<EntityCreated<JobEntered>> {
    return <any>this._api.postAuth('jobs/add', finishJob).pipe(map(x => x.body)); //TODO nr6 tested
  }

  public getJobData(jobId) {
    return this._api.getAuth(`jobs/${jobId}`).pipe(map((x: any) => x.body)); //TODO nr6 tested
  }

  public editJob(jobData, id): Observable<EntityUpdated<JobModel>> { //TODO nr6 tested
    return <any>this._api.postAuth(`jobs/${id}`, jobData).pipe(map((x: any) => x.body));
  }

  private wasJobDeleted(res) {
    return res.extras && res.extras.length > 0 && res.extras[0].poValue && parseInt(res.extras[0].poValue) > 0;
  }

  public setjobsFormDurationCopy(durationCopy: boolean) {
    const strObj = { jobsFormDurationCopy: durationCopy };
    const updateObj = {
      parameter: JSON.stringify(strObj)
    };
    return <any>this._api.postAuth(`users/parameter`, updateObj).pipe(map((x: any) => x.body));
  }
}
