import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AppComponent } from './app.component';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy, Location, DecimalPipe } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { enableProdMode, ErrorHandler, NgModule, APP_INITIALIZER } from "@angular/core";
import { AppState } from "./app.service";
import { GlobalState } from "./template/app.state";
import { ServicesModule } from "./template/shared/services/services.module";
import { SharedModule } from "./template/shared/shared.module";
import { AppRoutingModule } from "./app.routing";
// import {LocalStorageTodoService} from "./template/shared/services/localstorage/localstorage-todo.service";
import { SelectedPartnerService } from "./services/partner/selectedPartner.service";
import { UserService } from "./services/user/user.service";
import { LayoutComponent } from "./template/layout/layout.component";
import { LeftSidebarComponent } from "./template/layout/left-sidebar/left-sidebar.component";
import { TopNavbarComponent } from "./template/layout/top-navbar/top-navbar.component";
import { SearchComponent } from "./template/layout/top-navbar/search/search.component";
import { ScrollbarDirective } from "./template/shared/directives/scrollbar.directive";
import { NavDropDownDirectives } from "./template/shared/directives/nav-dropdown.directive";
import { TasksBackend } from "./backend/tasks.backend";
import { JobsBackend } from "./backend/jobs/jobs.backend";
import { UserBackend } from "./backend/user.backend";
import { GlobalErrorHandler } from "./core/errorHandling/globalErrorHandler";
import { StateService } from "./services/state/stateService";
import { PartnersBackend } from "./backend/partners.backend";
// import {ActivityLogComponent} from "./template/layout/activity-log/activity-log.component";
// import {ActivityLogService} from "./services/activityLog.service";
// import {ActivityLogBackend} from "./backend/activityLog.backend";
import { ActivityLogEntryComponent } from "./template/layout/activity-log/activity-log-entry/activity-log-entry.component";
import { FilesBackend } from "./backend/files.backend";
import { TaskboardSidebarService } from './taskboard/services/taskboardSidebar.service';

import { environment } from '../environments/environment';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { DpaBackend } from "./backend/dpa.backend";
import { ApiService } from "./core/api/api.service";
import { PersonsBackend } from "./backend/persons.backend";
import { ListsBackend } from "./backend/lists.backend";
import { NewsBackend } from './backend/news.backend';
import { MarketingCampaignBackend } from './backend/marketing-campaign.backend';
import { ScriptHackComponent } from './template/layout/script.hack.component';
import { KpisBackend } from './backend/kpis.backend';
import { ActivityLogComponent } from './template/layout/activity-log/activity-log.component';
import { ConfigurationService } from './configuration.service';
import { AuthService } from './services/auth.service';
import { TokenInterceptor } from './core/token.interceptor';
import { GlobalErrorHandlerAlt } from './core/global.error.handler';
import { LogsBackend } from './backend/logs.backend';
import { ProjectsBackend } from './backend/projects.backend';
import { RoutingService } from './template/shared/proto/services/routing.service';
import { Router } from '@angular/router';
import { FeedbackBackend } from './backend/feedback.backend';
import { QuillConfig, QuillModule } from "ngx-quill";
import { TokenCheckService } from "./token.check.service";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { SalesBackend } from "./backend/sales.backend";

if (environment.production) {
  enableProdMode();
}

const APP_PROVIDERS = [
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandlerAlt
  },
  AppState,
  GlobalState,
  Title,
  // LocalStorageTodoService,
  //JobTimerService,
  //UtilsService,
  UserService,
  { provide: LocationStrategy, useClass: HashLocationStrategy },
  // SelectedPartnerService,
  //{provide: SelectedPartnerService, useExisting: forwardRef(() => SelectedPartnerService), deps: [ListsDataService]},
  // TaskboardSidebarService,
  //TaskboardDataService,
  TasksBackend,
  PersonsBackend,
  ListsBackend,
  DpaBackend,
  JobsBackend,
  UserBackend,
  NewsBackend,
  DatePipe,
  DecimalPipe,
  // StateService,
  //JobControlService,
  PartnersBackend,
  // ActivityLogService,
  // ActivityLogBackend,
  FilesBackend,
  // ApiService,
  NgxPermissionsService,
  //Constants
  //{provide: EMPTY_PARTNER, useValue: Const.EMPTY_PARTNER}
  KpisBackend,
  ProjectsBackend,
  FeedbackBackend,
  TokenCheckService,
  MarketingCampaignBackend,
  SalesBackend,
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    // HttpModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ServicesModule,
    AppRoutingModule,
    //CoreModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    SharedModule.forRoot(),
    //DashboardsModule,
    //TaskboardModule,
    //ListsModule
    // EditorModule,
    QuillModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    LayoutComponent,
    LeftSidebarComponent,
    TopNavbarComponent,
    SearchComponent,
    ScriptHackComponent,
    ActivityLogComponent,
    ActivityLogEntryComponent,
    ScrollbarDirective,
    NavDropDownDirectives,
    //        UtilsComponent,
    //        ParamComponent,
    //        ToggleJobFormBtnComponent,

  ],

  exports: [
    // TaskboardModule,
  ],
  providers: [
    APP_PROVIDERS,
    NgxPermissionsService,
    // AuthService,
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigurations,
      deps: [ConfigurationService, AuthService, NgxPermissionsService, ApiService, LogsBackend],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: RoutingService, useClass: RoutingService, deps: [Router, Location] },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_DATE_LOCALE, useValue: 'lt-LT' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public appState: AppState) { }
}

export function loadConfigurations(configService: ConfigurationService, authService: AuthService, ngxPermissionsService: NgxPermissionsService, apiService: ApiService, logsBackend: LogsBackend) {
  return () => configService.loadPermissions(authService, ngxPermissionsService, apiService, logsBackend);
}
