
import { toArray, filter, mergeMap } from 'rxjs/operators';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TasksBackend } from "../../../../backend/tasks.backend";
import { UntypedFormBuilder } from "@angular/forms";
import { TaskReview } from "../../../models/task-review";
import { ListsBackend } from '../../../../backend/lists.backend';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';

@Component({
  selector: 'update-task-priority',
  templateUrl: './update-task-priority.component.html',
  styleUrls: ['./update-task-priority.component.scss']
})
export class UpdateTaskPriorityComponent implements OnDestroy {

  protected _taskPriorities: any;
  protected _isDisabled: boolean = true;
  protected _initialPriority: number;
  protected _initialPrioritySelector;
  protected _selectedPriority;
  protected _submitting = false;
  protected _submitted = false;
  subs: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TaskReview,
    private tasksBackend: TasksBackend,
    public dialogRef: MatDialogRef<UpdateTaskPriorityComponent>,
    private listsBackend: ListsBackend,
    private _formBuilder: UntypedFormBuilder) {
    this._taskPriorities = () => listsBackend.getTaskPriorities().pipe(mergeMap((p: any) => p), filter((p: any) => p.id != data.priorityId), toArray());
    this._initialPriority = data.priorityId;
    this._initialPrioritySelector = (p) => p.id == data.priorityId;
  }

  protected _prioritySelected(priority) {
    this._isDisabled = !priority;
    this._selectedPriority = priority;
  }

  protected _submit() {
    this._submitting = true;
    this.subs.push(this.tasksBackend.updateTaskPriority(this.data.id, { priorityId: this._selectedPriority }).subscribe(res => {
      this._submitted = true;
      this._submitting = false;
      this.dialogRef.close(res);
    }, (err) => {
      this._submitting = false;
      swal.fire({
        title: 'Įvyko klaida',
        timer: 2000,
        showConfirmButton: false,
        icon: 'error'
      });
    }));
  }

  protected _cancel() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
