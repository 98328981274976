import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { SharedModule } from '../template/shared/shared.module';
import { ImageCropperModule } from "ngx-image-cropper";
import { PhotoChangerComponent } from './photo-changer/photo-changer.component';


const PROFILE_ROUTE = [
  { path: '', component: ProfileComponent },
];

@NgModule({
    declarations: [ProfileComponent, PhotoChangerComponent],
    imports: [
        ImageCropperModule,
        CommonModule,
        SharedModule,
        RouterModule.forChild(PROFILE_ROUTE),
    ]
})
export class ProfileModule { }
