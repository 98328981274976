
import { map } from 'rxjs/operators';
import { Component, ElementRef, HostBinding, NgZone, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TimerService } from "../../services/timer/timer-service";
import { Observable, Subscription } from "rxjs";
import { Timer, TimerState } from "../../services/timer/timer";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { TimerDialogComponent } from "./timer-dialog/timer-dialog.component";

@Component({
  selector: 'timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {

  @ViewChild('timersContainer') _timersContainer: ElementRef;
  get containerEl(): HTMLElement {
    const tc = this._timersContainer;
    if (tc) {
      return tc.nativeElement;
    }
  }

  @ViewChildren('timerCont') _timers: QueryList<ElementRef>;
  get timers(): HTMLElement[] {
    if (!this._timers) {
      return [];
    }
    return this._timers.toArray().map(e => e.nativeElement);
  }

  @HostBinding('class.collapsed') collapsed = true;

  get requiredHeight(): number {
    const el = this.containerEl;
    if (el) {
      return el.offsetHeight;
    }
  }

  get availableWidth(): number {
    const el = this.containerEl;
    if (el) {
      return el.offsetWidth;
    }
  }

  @HostBinding('style.height.px') get height() {
    return this.collapsed ? 55 : this.requiredHeight;
  }

  get expanderVisible(): boolean {
    let timers = this.timers;
    if (!timers || timers.length < 2) {
      return false;
    }
    let lastTop = timers[0].offsetTop;
    timers = timers.slice(1);
    for (const t of timers) {
      if (t.offsetTop != lastTop) {
        return true;
      }
      lastTop = t.offsetTop;
    }
    return false;
  }

  _editTimerVisible = false;

  _timers$: Observable<Timer[]>;
  _timerState = TimerState;
  private _subscriptions = new Array<Subscription>();

  constructor(private _timerService: TimerService, private _router: Router, private _activatedRoute: ActivatedRoute, private _ngZone: NgZone, private _dialog: MatDialog) { }



  ngOnInit() {
    this._timers$ = this._timerService.timers$.pipe(map(timers => timers.filter(t => t.state != TimerState.FINISHED)));
  }

  getIconClass(timer: Timer) {
    if (timer.state == TimerState.RUNNING) {
      return 'zmdi-pause';
    } else if (timer.state == TimerState.PAUSED) {
      return 'zmdi-play'
    }
  }

  getIconTitle(timer: Timer) {
    if (timer.state == TimerState.RUNNING) {
      return 'Stabdyti';
    } else if (timer.state == TimerState.PAUSED) {
      return 'Paleisti'
    }
  }

  _toggleTimer(timer: Timer) {
    this._timerService.toggleTimer(timer.id);
  }

  _stopTimer(timer: Timer) {
    this._timerService.stopTimer(timer.id);
  }

  _isActive(taskId): boolean {
    return this._timerService.activeTask == taskId;
  }

  _goToTask(taskId) {
    this._router.navigate(['taskboard', 't', 'v', taskId])
  }

  _openClose() {
    this.collapsed = !this.collapsed;
  }

  _editTimerText(timer: Timer) {
    this._subscriptions.push(
      this._dialog.open(TimerDialogComponent, { data: { text: timer.title }, minWidth: 600 }).afterClosed().subscribe(res => {
        if (res) {
          this._timerService.editTimerText(timer, res);
        }
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  private _showExpander(): boolean {
    const aw = this.availableWidth;
    let requiredWidth = 0;
    for (const t of this.timers) {

    }
    return false;
  }

  private _setupResizeListener() {
    this._ngZone.runOutsideAngular(() => {
    });
  }
}
