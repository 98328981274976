
import { filter } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatSelect } from "@angular/material/select";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { PAGE_LENGTH_CHANGER } from '../../../template/shared/proto/dataTable/dataTable';
import { VALUE_PROVIDER } from '../../../template/shared/proto/proto';
import { PageLengthChanger } from '../../../template/shared/proto/dataTable/paging/pageLength/PageLengthChanger';
import { ValueProvider } from '../../../template/shared/proto/common/ValueProvider';
import { StorageService } from "../../../template/shared/proto/storage/storage.service";

@Component({
  selector: 'rows-count',
  template: `<mat-select #select class="block row-selector" [value]="rowsCount" (selectionChange)="selected($event)" [hideSingleSelectionIndicator]="true" [disableOptionCentering]="true">
               <mat-option [value]="15">15</mat-option>
               <mat-option [value]="30">30</mat-option>
               <mat-option [value]="50">50</mat-option>
               <mat-option [value]="100">100</mat-option>
               <mat-option [value]="-1">*</mat-option>
              </mat-select>`,
  styleUrls: ["./rows-count.component.scss"],
  providers: [
    {
      provide: PAGE_LENGTH_CHANGER, useExisting: forwardRef(() => RowsCountComponent),
    },
    {
      provide: VALUE_PROVIDER, useExisting: forwardRef(() => RowsCountComponent),
    }
  ]
})
export class RowsCountComponent implements OnInit, OnChanges, AfterViewInit, PageLengthChanger, ValueProvider<number>, OnDestroy {
  private _rowsCountWasSet = false;
  private _rowsCount = 15;
  @Input() set rowsCount(val) {

    this._rowsCountWasSet = true;
    this._rowsCount = val;
    this.lengthChanged.next(val)
  }
  get rowsCount() {
    return this._rowsCount;
  }
  @Input() id: any;
  @Output()
  public readonly lengthChanged: Subject<number> = new BehaviorSubject(null);
  @ViewChild(MatSelect) selector: MatSelect;

  private _subscriptions: Array<Subscription> = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private storage: StorageService) {

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngAfterViewInit() {
    if (!this._rowsCountWasSet) {
      this.rowsCount = parseInt(this.storage.get(this.id + '_length', '15'));
      this.lengthChanged.next(this.rowsCount);
    }
  }

  selected($event) {
    this.storage.store(this.id + '_length', $event.value);
    (<Subject<any>>this.lengthChanged).next($event.value);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  getValueChanges() {
    return this.lengthChanged.pipe(filter((l) => l != null));
  }
}
