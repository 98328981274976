
import { Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { TaskReview } from '../../../../taskboard/models/task-review';
import { TplService } from '../../../../taskboard/services/tpl.service';
import { Router } from '@angular/router';

@Component({
  selector: 'dpa-list',
  templateUrl: './dpa-list.component.html',
  styleUrls: ['./dpa-list.component.scss']
})
export class DpaListComponent implements OnInit, OnDestroy {

  @Input() task: TaskReview;
  @Input() dpaList: any;
  @ViewChild('datatable') datatable: DatatableComponent;
  columns;
  subscriptions = new Array<Subscription>();
  chg: ChangeDetectorRef;

  constructor(
    private _chg: ChangeDetectorRef,
    private tplService: TplService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.chg = this._chg;
    if (!this.task) {
      console.error('Task not provided!');
    } else {
    }
    this.columns = [
      { name: 'Numeris', width: 120, prop: 'dpaCode', sortable: false },
      { name: 'Sukūrimo Data', width: 120, prop: 'date', sortable: true, cellTemplate: this.tplService.shortDateTpl },
      { name: 'Užbaigimo Data', width: 130, prop: 'endDate', sortable: true, cellTemplate: this.tplService.endDateTpl },
      { name: 'Partneris', width: 190, prop: 'partner', sortable: true },
      { name: 'Atstovo el. paštas', width: 260, prop: 'delegateEmail', sortable: false, headerClass: 'd-header-wdth', cellTemplate: this.tplService.delegateEmailTpl },
      { name: 'DPA suma', width: 100, prop: 'sumWithoutVAT', sortable: false, cellTemplate: this.tplService.sumWithoutVATDpaListTpl },
      { name: 'S.F. Numeris', width: 93, prop: 'sfNr', sortable: false },
      { name: 'Apmokamas', width: 100, prop: 'isPaid', sortable: false, cellTemplate: this.tplService.isPaidTpl },
      { name: 'Būsena', width: 75, prop: 'isFinished', sortable: false, headerClass: 'center', cellTemplate: this.tplService.isDpaFinishedTpl },
      { name: 'Išsiuntimo būsena', width: 160, prop: 'lastSendDate', sortable: false, cellTemplate: this.tplService.DPAMailStateTpl },
      { name: ' ', width: 200, prop: 'id', headerClass: 'center', sortable: false, cellTemplate: this.tplService.dpaOptionsTpl },
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refreshTable();
  }

  public refreshTable() {
    if (this.dpaList) {
      this.dpaList = [...this.dpaList];
      try {
        this._chg.detectChanges();
      } catch (err) { }
    }
  }

  rowClicked(event) {
    this.router.navigate(['/taskboard/d/v/', event.id]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
