import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EMAIL_PATTERN_REGEXP } from '../../../app.constants';
import { MatDialogRef } from '@angular/material/dialog';
import { LIST } from '../../../services/lists/lists.data.service';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss']
})
export class EditUserModalComponent implements OnInit {

  public _editUserForm: UntypedFormGroup;
  public _email: string;
  public _name: string;
  public _lastName: string;
  public _userName: string;
  public _partnerId: number;
  // protected partners: LIST = LIST.PARTNERS;

  constructor(private formBuilder: UntypedFormBuilder, public dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
    this._buildForm();
  }

  private _buildForm() {
    this._editUserForm = this.formBuilder.group({
      userName: [this._userName],
      email: [this._email, [Validators.required, Validators.pattern(EMAIL_PATTERN_REGEXP)]],
      // partnerId: [this._partnerId, Validators.required],
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  clientSelected(client) {
  }

  send() {
    this.dialogRef.close({
      userName: this._editUserForm.controls.userName.value,
      email: this._editUserForm.controls.email.value,
      // partnerId: this._editUserForm.controls.partnerId.value,
    });
  }
}
