import { Component, Input, Output } from "@angular/core";

@Component({
  selector: 'proto-list-component',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent<T>
{
  @Input() items: Array<T> = [];
  @Input() mapToView: ((T) => any);

  @Output() itemSelected
}
