import { ID, MARKETING_CAMPAIGNS, ALL_MARKETING_TASKS, SELECTED_MARKETING_TASKS, REVIEW, TASKS, NEW_MARKETING_TASK, REQUESTS } from '../taskboard/taskboard.help';
import { formPath, param } from '../core/routing/helpers';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { TaskReviewComponent } from '../taskboard/components/tasks/task-review/task-review.component';
import { MarketingComponent } from './marketing.component';
import { MarketingCampaignsComponent } from './marketing-campaigns/marketing-campaigns.component';
import { EditMarketingCampaignComponent } from './edit-marketing-campaign/edit-marketing-campaign.component';
import { NewTaskComponent } from '../taskboard/components/tasks/new-task/new-task.component';
import { RequestReviewComponent } from '../taskboard/components/requests/request-review/request-review.component';

export const redirectToPath = () => formPath(MARKETING_CAMPAIGNS);

export const MARKETING_ROUTES: Routes = [
  {
    path: '',
    component: MarketingComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: redirectToPath(),
      },
      {
        path: formPath(MARKETING_CAMPAIGNS),
        component: MarketingCampaignsComponent,
        data: { tableType: 'marketingCampaigns' }
      },
      {
        path: formPath(ALL_MARKETING_TASKS),
        component: MarketingCampaignsComponent,
        data: { tableType: 'marketingTasks' }
      },
      {
        path: formPath(SELECTED_MARKETING_TASKS, param(ID)),
        component: MarketingCampaignsComponent,
        data: { tableType: 'campaignMarketingTasks' }
      },
      {
        path: formPath(TASKS, REVIEW, param(ID)),
        component: TaskReviewComponent,
      },
      {
        path: formPath(REQUESTS, REVIEW, param(ID)),
        component: RequestReviewComponent,
      },
      {
        path: formPath(param(ID)),
        component: EditMarketingCampaignComponent,
      },
      {
        path: formPath(param(ID), NEW_MARKETING_TASK),
        component: NewTaskComponent,
        data: { sourceRoute: 'marketing' }
      },
    ]
  }
];

export const tableTypeProvider = {
  provide: 'tableTypeProvider',
  useValue: (route: ActivatedRouteSnapshot) => {

    const m = { ...route.params, ...route.queryParams };
    if (!m || !m.type) {
      throw new Error('Empty model options not allowed');
    }
    switch (m.type) {
      case ALL_MARKETING_TASKS: return 'allmarketingtasks';
        break;
      case SELECTED_MARKETING_TASKS: return 'selectedmarketingtasks';
        break;
    }
    return '';
  }
};