import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ReplaySubject, Subject } from 'rxjs'
import { DateAdapter } from '@angular/material/core';
import { ValueProvider } from '../../../template/shared/proto/common/ValueProvider';
import { VALUE_PROVIDER } from '../../../template/shared/proto/proto';
import { TaskboardDataService } from './../../../taskboard/services/taskboard-data.service';
import { CalendarCustomHeaderComponent } from './calendarCustomHeader.component';

export type DateInterval = { from: string, to: string, toChanged?: boolean }
@Component({
  selector: 'date-interval-alt',
  templateUrl: './dateIntervalAlt.component.html',
  styleUrls: ['./dateIntervalAlt.component.scss'],
  providers: [
    {
      provide: VALUE_PROVIDER, useExisting: forwardRef(() => DateIntervalAltComponent),
    }
  ]
})
export class DateIntervalAltComponent implements OnInit, AfterViewInit, ValueProvider<any> {

  private _interval: DateInterval;
  protected btnFilterState: number = 0;
  private todayDate: Date = new Date();
  private time: Date;
  private date: string;
  protected showDateFrom: boolean = true;
  @Input() showButtons: boolean = false;
  @Input() toYear: boolean = false;


  @Input() set interval(val) {
    if (!val) {
      this._interval = val;
      this._dateFrom = val;
      this._dateTo = val;
      return;
    }

    let dateTo;
    if (val.to == 'today') {
      if (this.toYear) {
        const aYearFromNow = new Date();
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
        dateTo = aYearFromNow.toLocaleDateString('LT')
      } else {
        dateTo = new Date().toLocaleDateString('LT');
      }
    } else {
      dateTo = val.to;
    }

    this._interval = ({ ...val, to: dateTo });
    this._dateFrom = new Date(this._interval.from);
    this._dateTo = new Date(this._interval.to);
    setTimeout(() => {
      this.dateChanged();
    }, 0)
  }
  protected _dateFrom: Date;
  protected _dateTo: Date;
  @Input() set dateFrom(val: any) {
    if (!val) {
      return;
    }
    this._dateFrom = new Date(val);
    this._interval.from = val;
  }
  @Input() set dateTo(val: any) {
    this._dateTo = new Date(val);
    this._interval.to = val;
    // this._interval.toChanged = true;
  }
  private _intervalChanges: Subject<DateInterval> = new ReplaySubject(1);
  @Output() intervalChanged: EventEmitter<DateInterval> = new EventEmitter();

  calendarCustomHeaderComponent: any = CalendarCustomHeaderComponent;

  protected set _hasFocus(val) {
  }

  @ViewChild('pickerFrom') pickerFrom: any;

  constructor(
    // private dateAdapter: DateAdapter<Date>,
    private _taskboardDataService: TaskboardDataService,
    private chg: ChangeDetectorRef) {
    // dateAdapter.setLocale('lt-LT');
  }

  ngOnInit(): void {
    if (this.showButtons) {
      this.toggle(1);
    }
    this._taskboardDataService.setDateToToday.subscribe(() => {
      this.dateTo = new Date().toLocaleDateString('LT');;
      this._interval.toChanged = false;
      this.dateChanged();
    })
  }

  getValueChanges(): any {
    return this.intervalChanged;
  }

  dateChanged() {
    this.intervalChanged.emit(this._interval);
  }

  _textSelected($event) {
    $event.stopPropagation()
  }

  ngAfterViewInit(): void {
    try {
      this.chg.detectChanges();
    } catch (err) { }
  }

  _dateChanged(event, el, what, who = null) {
    if (who != 'btn') {
      this.btnFilterState = null;
    }


    let date = new Date(event.value);
    if (!this._interval) {
      this._interval = { from: '', to: '' };
    }
    if (what == 'from') {
      this._interval.from = date.toLocaleDateString('LT');
      this._interval.toChanged = false;
    } else {
      this._interval.to = date.toLocaleDateString('LT');
      this._interval.toChanged = true;
    }
    // console.log(el);
    el.close && el.close();
    this.dateChanged();
  }

  _keyUp(event: KeyboardEvent, what: string, date: Date) {
    if (event.key === 'Enter') {
      this._dateChanged({ value: date }, {}, what);
    }
  }

  toggle(v) {
    this.btnFilterState = v;
    this.todayDate = new Date();

    if (v == 0) {
      this.date = null;
      this.showDateFrom = false;
    } else {
      this.time = new Date(this.todayDate.setMonth(this.todayDate.getMonth() - this.btnFilterState));
      this.date = this.time.toString();
      this.showDateFrom = true;
    }
    this._dateChanged(this.date, {}, 'from', 'btn');
    this._dateFrom = new Date(this.date);
    this._dateTo = new Date();
  }
}



