
import { tap, map } from 'rxjs/operators';
import { Component, forwardRef, OnInit, ViewChild, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LIST, ListsDataService } from "../../../services/lists/lists.data.service";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { VALUE_PROVIDER } from "../../../template/shared/proto/proto";
import { ValueProvider } from "../../../template/shared/proto/common/ValueProvider";
import { StorageService } from "../../../template/shared/proto/storage/storage.service";
import { ButtonDropdownComponent } from "../../../template/shared/components/dropdowns/button-dropdown/button-dropdown.component";

@Component({
  selector: 'task-types',
  template: `
                <!-- <button-dropdown
                    [options]="_types | async | sort: 'name': 'asc': 0: 'id'"
                    [map]="_mapType"
                    [itemClass]="'btn-default btn-sm'"
                    [limitDropdownHeight]="'500px'"
                    (itemClick) ="_typeSelected($event)">
                </button-dropdown> -->
                <div class="btn-group" dropdown>
            <button dropdownToggle type="button" class="btn cursor btn-sm btn-default dropdown-toggle" mat-ripple>
            Tipai <span class="caret"></span>
            </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu" [ngStyle]="getDropdownStyles()" [ngClass]="menuClass">
            <li role="menuitem" *ngFor="let type of _types | sort: 'name': 'asc': 0: 'id'" (click)="$event.stopPropagation()">
              <div class="flex">
              <mat-checkbox class="column-btn-check cursor" [(ngModel)]="type.checked"
                            (change)="typesChange(type, $event.checked)"><span
                              class="column-btn-text">{{type.name}}</span></mat-checkbox>
              </div>
            </li>
          </ul>
    `,
  styleUrls: ['./task-types.component.scss'],
  providers: [
    { provide: VALUE_PROVIDER, useExisting: forwardRef(() => TaskTypesComponent) }
  ]
})
export class TaskTypesComponent implements OnInit, ValueProvider<string>, OnDestroy {
  @Input() isTasks;
  @Input() isMarketing = false;
  @ViewChild(ButtonDropdownComponent) typesList: ButtonDropdownComponent<any>;
  protected _currentType = new ReplaySubject<string>();
  protected _types: any;
  protected _mapType = (t) => t.name;

  private _listToLoad;

  limitDropdownHeight = '500px';
  subs: Subscription[] = [];
  _currentTypesIds;
  private _chgListener: (v: any) => void;
  @Input() storageKey;

  constructor(
    private _listsDataService: ListsDataService,
    private _storageService: StorageService,
    private _chg: ChangeDetectorRef,
  ) {

  }

  ngOnInit() {
    if (this.isTasks) {
      this._listToLoad = LIST.TASK_TYPES_FILTER;
    } else {
      this._listToLoad = LIST.REQUEST_TYPES_FILTER;
    }
    const stored = this._storageService.get(this.storageKey);

    this.subs.push(this._listsDataService.loadList(this._listToLoad).subscribe((g) => {
      let types;
      if (stored != null) {
        types = g.map(el => { return { ...el, checked: stored.indexOf(el.id) !== -1 } });
      } else {
        if (g.length > 0) {
          types = g.map((el, index) => { return { ...el, checked: true } });
        }
      }
      if (this.isMarketing) {
        this._types = types.filter(list => list.code === 'VT_3' || list.code === 'VT_5')
      } else {
        this._types = types;
      }
      this._currentTypesIds = this._types.filter(el => el.checked).map(el => { return el.id });
      this._currentType.next(this._currentTypesIds?.join(','));
    }));
  }

  typesChange(type, checked) {
    setTimeout(() => {
      if (type.id === 0 && checked) {
        this._types.forEach(el => {
          if (el.id !== 0) el.checked = false;
        })
      } else if (type.id !== 0 && checked) {
        this._types.forEach(el => {
          if (el.id === 0) el.checked = false;
        })
      }
      if (!this._types.find(el => el.checked)) {
        this._types.find(el => el.id === 0).checked = true;
      }
      if (this._chg) {
        this._chg.detectChanges();
      }
      this._currentTypesIds = this._types.filter(el => el.checked).map(el => { return el.id });
      this._storageService.store(this.storageKey, this._currentTypesIds);
      if (this._chgListener) {
        this._chgListener(this._currentTypesIds);
      }
      this._currentType.next(this._currentTypesIds?.join(','));
    }, 0);
  }

  getDropdownStyles() {
    if (this.limitDropdownHeight) {
      return { 'overflow': 'auto', 'max-height': this.limitDropdownHeight };
    } else {
      return null;
    }

  }

  protected _typeSelected($event) {
    this._storageService.store(this.storageKey, $event);
    this._currentType.next($event.id);
  }

  getValueChanges(): Observable<string> {
    return this._currentType;
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
