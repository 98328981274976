import { Injectable } from '@angular/core';
import { LogsBackend } from '../backend/logs.backend';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  justLoggedIn = false;
  noMenus = false;
  idToken: string;
  user: any;
  loggedIn$: Subject<boolean> = new Subject();
  localstorageLogin$: Subject<boolean> = new Subject();
  localstorageLogout$: Subject<boolean> = new Subject();
  permissionsLoaded = false;
  jobsFormDurationCopy = false;

  constructor(private logsBackend: LogsBackend) {
    // console.log('authservice constructor', new Date().getTime());
    this.getUserFromLocalstorage();
    this.getIdTokenFromLocalstorage(logsBackend);
    window.addEventListener('storage', (e: any) => {
      if (e.key === 'idToken') {
        if (this.idToken !== e.newValue) {
          this.idToken = e.newValue;
          const logObj = {
            eventId: 'setIdTokenEvent',
            message: 'setIdTokenEvent',
            userId: this.user ? this.user.usersId : null,
            token: this.idToken,
          };
          const subs = logsBackend.saveLog(logObj).subscribe(() => {
            subs.unsubscribe();
          }, () => {
            subs.unsubscribe();
          })
          // using window.location because cannot use router because this service is used in APP_INITIALIZER dependency
          if (this.idToken) {
            this.localstorageLogin$.next(true);
          } else {
            this.localstorageLogout$.next(true);
          }
        }
      }
      if (e.key === 'user') {
        if (e.newValue) {
          this.user = JSON.parse(e.newValue);
          const user = this.user;
          delete this.user.token;
          this.jobsFormDurationCopy = user.parameter && JSON.parse(user.parameter) && JSON.parse(user.parameter).jobsFormDurationCopy !== undefined && JSON.parse(user.parameter).jobsFormDurationCopy;

        } else {
          this.user = null;
        }
      }
    });
  }

  getIdTokenFromLocalstorage(logsBackend) {
    this.idToken = localStorage.getItem('idToken');
    setTimeout(() => {
      try {

        const logObj = {
          eventId: 'getIdToeknFromLocalstorage',
          message: 'getIdToeknFromLocalstorage',
          userId: this.user ? this.user.usersId : null,
          token: this.idToken,
        };
        const subs = logsBackend.saveLog(logObj).subscribe(() => {
          subs.unsubscribe();
        }, () => {
          subs.unsubscribe();
        })
      } catch (err) {
        console.log(err);
      }
    }, 0);

  }

  getUserFromLocalstorage() {
    this.user = JSON.parse(localStorage.getItem('user'));
    const user = this.user;
    if (user) {
      this.jobsFormDurationCopy = user.parameter && JSON.parse(user.parameter) && JSON.parse(user.parameter).jobsFormDurationCopy !== undefined && JSON.parse(user.parameter).jobsFormDurationCopy;
    }
  }

  setIdToken(token) {
    this.idToken = token;
    localStorage.setItem('idToken', token);
    const logObj = {
      eventId: 'setIdToken',
      message: 'setIdToken',
      userId: this.user ? this.user.usersId : null,
      token: this.idToken
    };
    const subs = this.logsBackend.saveLog(logObj).subscribe(() => {
      subs.unsubscribe();
    }, () => {
      subs.unsubscribe();
    })
  }

  clearIdToken() {
    this.idToken = null;
    localStorage.removeItem('idToken');
    const logObj = {
      eventId: 'clearToken',
      message: 'clearToken',
      userId: this.user ? this.user.usersId : null,
      token: this.idToken,
    };
    const subs = this.logsBackend.saveLog(logObj).subscribe(() => {
      subs.unsubscribe();
    }, () => {
      subs.unsubscribe();
    })
  }

  setUser(user) {
    this.user = user;
    try {
      if (user) {
        this.jobsFormDurationCopy = user.parameter && JSON.parse(user.parameter) && JSON.parse(user.parameter).jobsFormDurationCopy !== undefined && JSON.parse(user.parameter).jobsFormDurationCopy;
      }
    } catch (err) {
      console.log('parameter error', err);
    }
    delete this.user.token;
    localStorage.setItem('user', user && JSON.stringify(user));
  }

  setjobsFormDurationCopy(value) {
    const parameter = JSON.stringify({ jobsFormDurationCopy: value });
    this.user.parameter = parameter;
    this.setUser(this.user);
  }

  clearUser() {
    this.user = null;
    localStorage.removeItem('user');
  }

  isLoggedIn() {
    return !!this.idToken;
  }

  isLoggedInLayout() {
    return !!this.idToken && this.permissionsLoaded;
  }

  hasMenus() {
    return !this.noMenus;
  }

  updateUserEmail(email) {
    this.setUser({ ...this.user, email });
  }
}
