
import { of as observableOf, Observable, Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DATE_WITH_TIME, SHORT_DATE } from "../../../../../core/time";

export interface ListModalConstructor {
  columns: Array<ListModalColumn>;
  data: Observable<Array<any>>;
  isLoading: Observable<boolean>;
  rowClicked?: (row: any) => any;
}
export interface ListModalColumn {
  column: string;
  prop: string;
}

@Component({
  selector: 'list-modal',
  templateUrl: 'listModal.component.html',
  styleUrls: ['./listModal.component.scss'],
})
export class ListModalComponent implements OnInit {
  private _constructor: ListModalConstructor;
  _data: Observable<Array<any>> | Array<any>;
  private _isLoading: Observable<boolean>;
  private shortDate = SHORT_DATE;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ListModalComponent>,
    private _chg: ChangeDetectorRef) {
    if (!data.modalConstructor) {
      throw new Error("ListModalComponent: modalConstructor must be provided!");
    }
    this._constructor = data.modalConstructor;
  }

  ngOnInit(): void {
    const d = this._constructor.data;
    if (!(d instanceof Observable)) {
      this._data = observableOf(d);
    } else {
      this._data = d;
    }
  }

  _createNew(row = null) {
    this.dialogRef.close(row ? row.text : true);
  }

  close(): void {
    this.dialogRef.close();
  }
}
