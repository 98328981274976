import { Injectable } from '@angular/core';
import { publishLast, tap, map } from 'rxjs/operators';
import { msToTimeString, Timer, TimerState } from "./timer";
import { BehaviorSubject, Observable, Subject, ConnectableObservable } from "rxjs";
import { TimersBackend } from "../../../../backend/timers.backend";
import { EventEmitter, Inject } from "@angular/core";
import { STOP, TimeBarier, WARNINGS } from "../../../../taskboard/common/timer-settings";
import swal from "sweetalert2";
import { AuthService } from '../../../../services/auth.service';

const SYM_TOGGLING = Symbol("toggling");
const TICK_INTERVAL = 50;
const UPDATE_EVERY = 5 * 1000;
const isToday = (dayName: string, date: Date) => {
  const day = date.getDay();
  switch (dayName) {
    case 'monday': return day == 1;
    case 'tuesday': return day == 2;
    case 'wednesday': return day == 3;
    case 'thursday': return day == 4;
    case 'friday': return day == 5;
    case 'saturday': return day == 6;
    case 'sunday': return day == 7;
  }
};

@Injectable()
export class TimerService {

  public refreshTasksAllTimers: EventEmitter<boolean> = new EventEmitter();
  private _timersLoaded = false;
  private _timers: Timer[];
  private _timers$: Subject<Timer[]>;
  public get timers$(): Observable<Timer[]> {
    if (!this._timers$) {
      this.loadTimers();
    }
    return this._timers$.asObservable();
  }

  public get hasActiveTimers$(): Observable<boolean> {
    return this.timers$.pipe(map(timers => !!timers.find(t => t.state != TimerState.FINISHED)))
  }

  public activeTask: number;

  private get _milliseconds(): number {
    return (new Date()).getTime();
  }

  private get _hasRunningTimers(): boolean {
    return !this._timersLoaded || !!this._timers.find(t => t.state == TimerState.RUNNING);
  }

  private _clock: any;
  private _lastTick: number;
  private _lastUpdate: number;
  private _isUpdating: boolean;
  private _warnings = new Array<number>();
  private _stops = new Array<number>();

  constructor(@Inject(TimersBackend) private _timersBackend: TimersBackend, private authService: AuthService) {
    this.authService.localstorageLogin$.subscribe(() => {
      this.loadTimers();
    });
    this.authService.loggedIn$.subscribe(() => {
      this.loadTimers();
    });
  }

  public loadTimers() {
    this._timers$ = new BehaviorSubject([]);
    this._timersBackend.getTimersApi(this._milliseconds).subscribe(res => {
      const ms = this._milliseconds;
      res = res.map(t => ({ ...t, time: ms, visibleTime: msToTimeString(t.allTime), title: t.title ? t.title.toString() : '' }));
      this._timers = res;
      this._timers$.next(res);
      this._timersLoaded = true;
      this._startOrStopTimer();
    });
  }

  public toggleTimer(timerId: number) {
    if (!this._timersLoaded) {
      return;
    }
    const timer = this._timers.find(t => t.id == timerId);
    if (!timer) {
      throw new Error(`Cannot find timer with id ${timerId}`);
    }
    if (timer.state == TimerState.FINISHED) {
      throw new Error(`Cannot start finished timer ${timerId}`);
    }
    if (!timer[SYM_TOGGLING]) {
      timer[SYM_TOGGLING] = true;
      this._timersBackend.toggleTimer(timerId, this._milliseconds).subscribe(
        res => {
          timer[SYM_TOGGLING] = false;
          //Jei yra veikiantis taimeris, ir buvo paleistas naujas, ji sustabdome.
          if (res == TimerState.RUNNING) {
            this._stopIfRunningTimer();
          }
          timer.state = res;
          this._timers$.next(this._timers);
          this._startOrStopTimer();
        }
      );
    }
  }

  public createTimer(taskId: number, text: string) {
    const ms = this._milliseconds;
    this._timersBackend.createTimer(taskId, text, ms).subscribe((res: any) => {
      if (res) {
        this._stopIfRunningTimer();
        this._timers.push({ id: res, taskId: taskId, time: ms, allTime: 0, title: text, state: TimerState.RUNNING });
        this._timers$.next(this._timers);
        this._startOrStopTimer();
      } else {
        // console.log("ERROR");
      }
    })
  }

  public stopTimer(timerId: number) {
    const ms = this._milliseconds;
    this._timersBackend.stopTimer(timerId, ms)
      .subscribe((res: any) => {
        if (res === 'Success') {
          const timer = this._timers.find(t => t.id == timerId);
          if (!timer) {
            return;
          }
          timer.state = TimerState.FINISHED;
          this.refreshTasksAllTimers.next(true);
          this._timers$.next(this._timers);
          this._startOrStopTimer();
        }
      });
  }

  public activeTimerForTask$(taskId): Observable<Timer> {
    if (this.timers$) {
      return this.timers$.pipe(map((timers) => {
        return timers.find(t => t.taskId == taskId && t.state != TimerState.FINISHED)
      }));
    }
  }

  public getTaskTimers$(taskId): Observable<Timer[]> {
    return this._timers$.pipe(map(timers => timers.filter(t => t.taskId == taskId)));
  }

  public editTimerText(timer: Timer, newText: string): Observable<boolean> {
    const obs = this._timersBackend.editTimerText(timer.id, newText).pipe(tap((res: any) => {
      if (res === 'Success') {
        timer.title = newText;
      }
    }), map(res => !!res), publishLast()) as ConnectableObservable<any>;
    obs.connect();
    return obs;
  }

  private _startOrStopTimer() {
    if (this._hasRunningTimers) {
      this._startTicking();
    } else {
      this._stopTicking();
    }
  }

  private _startTicking() {
    if (this._clock) {
      return;
    }
    const ms = this._milliseconds;
    this._lastTick = ms;
    this._lastUpdate = ms;
    this._setupNotifications();
    this._clock = setInterval(_ => {
      const ms = this._milliseconds;
      for (const t of this._timers) {
        if (t.state == TimerState.RUNNING) {
          t.allTime += ms - this._lastTick;
          t.visibleTime = msToTimeString(t.allTime)
        }
      }
      if (!this._isUpdating && ms - this._lastUpdate > UPDATE_EVERY) {
        this._checkNotifications(ms);
        this._isUpdating = true;
        this._timersBackend.tick(ms).subscribe(_ => { this._isUpdating = false; this._lastUpdate = this._milliseconds });
      }
      this._lastTick = ms;
    }, TICK_INTERVAL);
  }

  private _setupNotifications() {
    this._warnings = new Array();
    this._stops = new Array();
    const stop = STOP;
    const date = new Date();
    WARNINGS.forEach(w => this._setupWarning(w, date, "warn"));
    STOP.forEach(s => this._setupWarning(s, date, "stop"));
  }

  private _setupWarning(tb: TimeBarier, date: Date, type: "warn" | "stop") {
    const hours = tb.hours;
    const minutes = tb.minutes || 0;
    const currentHours = date.getHours();
    const currentMinutes = date.getMinutes();
    const bd = new Date();
    if (tb.except && isToday(tb.except, bd)) {
      return;
    }
    if (tb.only && !isToday(tb.only, bd)) {
      return;
    }
    bd.setHours(hours, minutes);
    bd.setSeconds(0);
    if ((currentHours * 60 + currentMinutes) >= (hours * 60 + minutes)) {
      bd.setDate(date.getDate() + 1);
    }
    if (type == "warn") {
      this._warnings.push(bd.getTime());
    } else {
      this._stops.push(bd.getTime());
    }
  }

  private _checkNotifications(current: number) {
    for (const s of this._stops) {
      if (current >= s) {
        this._showStop();
        return;
      }
    }
    for (let i = 0; i < this._warnings.length; i++) {
      const w = this._warnings[i];
      if (current >= w) {
        this._showWarning();
        this._warnings = [...this._warnings.slice(0, i), ...this._warnings.slice(i + 1)];
        return;
      }
    }
  }

  private _showStop() {
    this._stopPauseTimer();
    swal.fire({ title: "Darbas sustabdytas automatiškai", icon: "warning" });
  }

  private _showWarning() {
    const running = this._getRunningTimer();
    if (!running) {
      return;
    }
    swal.fire({ titleText: "Ar norite tęsti pradėtus darbus?", showCancelButton: true, confirmButtonText: "Tęsti", cancelButtonText: "Stabdyti", confirmButtonColor: "green", cancelButtonColor: "blue", icon: "question" }).then(r => {
      if (r.value) {
        return;
      } else if (r.dismiss == swal.DismissReason.cancel) {
        this.toggleTimer(running.id);
        swal.fire({ titleText: "Darbas sustabdytas. Gero poilsio!", showConfirmButton: false, icon: "info", timer: 2000 })
      }
    }).catch(e => {
    });
  }

  private _stopTicking() {
    clearInterval(this._clock);
    this._clock = undefined;
  }

  /**
   * Jei yra veikiantis taimeris, ji sustabdome. Metodas naudojamas, kai paleidziant taimeri ar sukuriant nauja, bazeje buvo sustabdytas pries tai veikiantis.
   * @private
   */
  private _stopIfRunningTimer() {
    if (!this._timers) {
      return;
    }
    const toStop = this._getRunningTimer();
    if (toStop) {
      toStop.state = TimerState.PAUSED;
    }
  }

  private _stopPauseTimer() {
    const timer = this._getRunningTimer();
    if (timer) {
      this.toggleTimer(timer.id);
    }
  }

  private _getRunningTimer() {
    return this._timers.find(t => t.state == TimerState.RUNNING);
  }
}
