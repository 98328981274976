
import { map, pluck, withLatestFrom, filter, tap, delay } from 'rxjs/operators';
import { JOB_ENTERED, UPDATE_TASKBOARD_LIST } from './../../state/state';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject } from "rxjs";
import { PARTNER, REQUESTS, REVIEW, TYPE } from "../../taskboard/taskboard.help";
import { TableModel } from "../../template/shared/proto/dataTable/serverTable/TableModel";
import { PRIORITY_ABBR, PRIORITY_COLORS, PRIORITY_TOOLTIPS } from "../../taskboard/taskboard";
import { StateService } from "../../services/state/stateService";
import { TOGGLE_LEFT_MENU } from "../../state/state";
import { TaskboardSidebarService } from "../../taskboard/services/taskboardSidebar.service";
import { StorableDirective } from "../../template/shared/proto/storage/storable.directive";
import { StorageOptions } from "../../template/shared/proto/storage/StorageOptions";
import { StorageService } from "../../template/shared/proto/storage/storage.service";
import {
  ColumnResized,
  ServerTableComponent
} from "../../template/shared/proto/dataTable/serverTable/serverTable.component";
import { QueryProviderDirective } from "../../template/shared/proto/common/query/queryProvider.directive";
import { NgxPermissionsService } from "ngx-permissions";
import { TplService } from "../../taskboard/services/tpl.service";
import { TaskboardDataService } from "../../taskboard/services/taskboard-data.service";
import { TASK_CHILD_ENTERED, TASK_CLIENT_COMMENTED, TASK_JOB_ENTERED, TASK_STATE_CHANGED } from "../../taskboard/common/constants";
import { EntityList } from "../../core/api/response/entity-list";
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { faBars, faDownload, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { isActiveState } from '../../taskboard/common/functions';
import { ProjectsBackend } from '../../backend/projects.backend';
import { TASKTYPE } from '../../projects/projects.component';
import { DATE_WITH_TIME, SHORT_DATE } from '../../core/time';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

const cloneArr = (arr: object[]) => arr.map(o => ({ ...o }));

const mapToRes = (res: Array<any>) => ({
  count: res.length > 0 && res[0].recordsCount ? res[0].recordsCount : res.length,
  data: res
});
const applyCurrentPage = (q: any) => {
  if (q.recordsPerPage > 0) {
    return {
      ...q,
      startFrom: (q.page) * q.recordsPerPage
    };
  } else {
    q.recordsPerPage = 99999999;
    return {
      ...q,
      startFrom: 0
    };
  }
};
const defaultParams = (query: any, p: any = {}) => applyCurrentPage(({ ...query, ...p }));
const noAddition = (query: any) => defaultParams(query);
const withAddition = (paramsToAdd: any) => (query: any) => defaultParams(query, paramsToAdd);
const withCondition = (condition: (params: any) => any) => (query: any) => defaultParams(query, condition(query));

const projectLink = (r: any) => r && r.taskId ? '#/projects/v/' + r.taskId : 'javascript:void(0)';

const MAX_COLUMN_WIDTH = 600;
const MIN_COLUMN_WIDTH = 75;

const firstElem: (nl: NodeList) => HTMLElement = (nl: NodeList) => {
  if (!nl) {
    return;
  }
  for (let i = 0; i < nl.length; i++) {
    const n = nl[i];
    if (n.nodeType === Node.ELEMENT_NODE) {
      return <HTMLElement>n;
    }
  }
};

const calcTextCellWidth = (el: HTMLElement) => {
  const f = firstElem(el.childNodes);
  const ff = firstElem(f.childNodes);
  if (!f) {
    return 0;
  }
  return Math.max(Math.min(ff.offsetWidth + 10 || MAX_COLUMN_WIDTH), MIN_COLUMN_WIDTH);
};

const TERMINAS_WIDTH = 80;

const markIfNotSeen = (row: any) => {
  const state = parseInt(row.stateForUser);
  if (state == 0) {
    return '';
  }
  if (state < 0) {
    return 'not-seen';
  }
  let classList = '';
  if (state & TASK_CLIENT_COMMENTED) {
    classList += 'not-seen task-client-commented';
  }
  if (state & TASK_CHILD_ENTERED) {
    classList += ' task-child-entered';
  }
  if (state & TASK_JOB_ENTERED) {
    classList += ' task-job-entered';
  }
  if (state & TASK_STATE_CHANGED) {
    classList += ' task-state-changed';
  }
  return classList;
};

const datePipe = new DatePipe('en_US');

@Component({
  selector: "project-list",
  templateUrl: "./projects-list.component.html",
  styleUrls: ["./projects-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectsListComponent implements AfterViewInit, OnDestroy {

  loading: any;
  isLoadingExcel = false;

  @ViewChildren('filterStrbl') _storable: QueryList<StorableDirective>;
  @ViewChild('searchField') _searchField: ElementRef;
  @ViewChild(QueryProviderDirective) _queryProvider: QueryProviderDirective;
  @ViewChild(ServerTableComponent) _serverTable: ServerTableComponent;
  @ViewChild('headerTmp', { read: TemplateRef, static: true }) headerTmp: TemplateRef<any>;
  @ViewChild('headerCnt', { read: ViewContainerRef }) headerCnt: ViewContainerRef;
  protected _priorityColors = PRIORITY_COLORS;
  protected _priorityAbbr = PRIORITY_ABBR;
  protected _priorityTooltips = PRIORITY_TOOLTIPS;
  protected _selectedRows: Array<any> = [];
  protected _showSearchClear = false;
  protected ctm;  //current table model
  protected _isVisible: boolean = false;
  protected _tm: TableModel;
  protected _currentPage: number = 3;
  private _pageSavingOptions: StorageOptions = {
    popState: true,
    refresh: true
  };
  stages: any[];
  private isFocused: boolean;
  private _lastId: any;
  private _colSettings: Array<any> = [];
  private _now = new Date();
  //Nustatymai skirti lenteles filtravimui, paieskai, cia nurodomi nustatymai ProtoFlagsGroup komponentui
  private projectsCtm = {
    isProject: true,
    hasButton: false,
    showTaskTypeFilter: false,
    hasDateInterval: true,
    filtering: {
      flagsGroup: "1111111",
      rules: {
        "0000000": "1000000",
      },
      flags: [
      ]
    }
  };

  private _tableConstructors = {
    myProjects: ({
      ...this.projectsCtm,
      id: 'myProjects',
      title: 'Mano projektai'
    }),
    allProjects: ({
      ...this.projectsCtm,
      hasDownloadExcelButton: true,
      showGroups: true,
      id: 'allProjects',
      title: 'Visi projektai'
    }),
  };
  private _subscriptions: Array<Subscription> = [];
  private _rowClicks: Subject<any> = new Subject();
  triggerInit: Subject<any> = new Subject();

  faBars = faBars;
  faPlusSquare = faPlusSquare;
  faDownload = faDownload;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _chg: ChangeDetectorRef,
    private _stateService: StateService,
    private _storageService: StorageService,
    private _permissionsService: NgxPermissionsService,
    private _taskboardSidebar: TaskboardSidebarService,
    private _taskboardDataService: TaskboardDataService,
    private _tplService: TplService,
    private projectsBackend: ProjectsBackend,
  ) { }

  @ViewChildren('dropdown') dropdown: QueryList<BsDropdownDirective>;

  ngAfterViewInit(): void {
    this._subscriptions.push(this.dropdown.changes.subscribe((comps: QueryList<BsDropdownDirective>) => {
      if (comps.first) {
        comps.first.show();
        comps.first.hide();
      }
    }));
    this._initTableModel();
    this.getStages();
    this._subscriptions.push(this._stateService.getStream(JOB_ENTERED).subscribe((j) => {
      this._serverTable.refreshData();
    }));
    this._subscriptions.push(this._stateService.getStream(UPDATE_TASKBOARD_LIST).subscribe((fn) => {
      this._serverTable.updateRecords(fn);
    }));
    this._subscriptions.push(
      this._rowClicks.pipe(
        withLatestFrom(
          this._route.params.pipe(pluck(TYPE)).pipe(

            withLatestFrom(
              this._route.queryParams.pipe(pluck(PARTNER)),
              (p, q) => ({ params: p, partner: q })
            )), (row, t) => ({ row: row, t: t })
        ), filter((rt) => rt.t != null && rt.row && rt.row.taskId)).subscribe((rt) => {
          // only request can be root element in projects child tree
          this._router.navigate(['../', REQUESTS, REVIEW, rt.row.taskId], { relativeTo: this._route });
        }));
    this.triggerInit.subscribe(() => {


      this._subscriptions.push(this._route.data.pipe(delay(0)).subscribe((d) => {
        this._isVisible = false;
        this._selectedRows = [];
        //Parenkamas atitinkamas konstruktorius
        this.ctm = this._tableConstructors[d.tableType];
        if (!this.ctm) {
          return;
        }
        //Atstatomas pasirinktas eilutes ID, tam, kad matytusi ant kurios eilutes buvo paspausta
        this._lastId = this._storageService.get(this.ctm.id, null, {
          popState: true,
          static: true
        });
        //Pakraunami pasirinkti stulpeliai ir ju plociai
        let colSettings = this._storageService.get(this.ctm.id + '.colSettings') || [];
        let sortSettings = this._storageService.get(this.ctm.id + '.sort');
        //Gaunamas reikiamas TableModel
        let __tm = this._tModels.find((tm) => tm.name == d.tableType);

        //Aplungiami TableModel nustatymai, su issaugotais atmintyje
        for (let i = 0; i < __tm.columns.length; i++) {
          let cs = colSettings.find((s) => s.name == __tm.columns[i].name);
          if (cs) {
            __tm.columns[i].visible = cs.visible != undefined ? cs.visible : true;
            __tm.columns[i].width = cs.width || __tm.columns[i].width || 200;
            __tm.columns[i].index = cs.index || i;
          } else {
            __tm.columns[i].width = __tm.columns[i].width || 200;
            __tm.columns[i].index = i;
          }
        }
        if (sortSettings && sortSettings.prop) {
          let _c = __tm.columns.find((c) => c.prop == sortSettings.prop);
          if (_c) {
            let _dc = __tm.columns.find((c) => !!c.sorted);
            if (_dc) {
              delete _dc['sorted'];
            }
            _c.sorted = { order: sortSettings.order };
          }
        } else {
        }
        this._colSettings = colSettings;
        this._tm = __tm;
        //Perkraunama lentele
        try {
          this._chg.detectChanges();
        } catch (err) { }
        this._isVisible = true;
        this._currentPage = this._storageService.get('currentPage', 0, this._pageSavingOptions);
        try {
          this._chg.detectChanges();
        } catch (err) { }
        //Atstamoma paieskos lauko reiksme
        this._searchField ? this._searchField.nativeElement.value = this._storageService.get(this.ctm.id + '.search', undefined, {
          popState: true,
          refresh: true,
          sameRoute: true,
        }) || '' : null;
        this._searchField && this._searchField.nativeElement.value != '' ? this._searchField.nativeElement.dispatchEvent(new Event('keyup')) : {};

        const sortingQp = this._serverTable && this._serverTable.getSortingQueryPart();
        this._queryProvider && this._queryProvider.registerQueryPart(sortingQp);

        const pagingQp = this._serverTable && this._serverTable.getPagingQueryPart();
        this._queryProvider && this._queryProvider.registerQueryPart(pagingQp);

        if (this._queryProvider) this._queryProvider.active = true;
        //Trigerinama paieska
        this._queryProvider && this._queryProvider.emit('sort');
        this._queryProvider && this._queryProvider.emit('page');
        this._queryProvider && this._queryProvider.emit('search');
      }));
    });
    this._subscriptions.push(this._taskboardDataService.refreshDatatable.subscribe((e) => {
      this._queryProvider.emit('search');
    }));
  }

  _onSort(e) {
    this._storageService.store(this.ctm.id + '.sort', {
      prop: e.prop,
      order: e.order
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  getStages() {
    this._subscriptions.push(this.projectsBackend.getProjectStages(TASKTYPE).subscribe((resp) => {
      this.stages = resp;
      this.projectsCtm.filtering.flags = [];
      this.stages.forEach((el) => {
        this.projectsCtm.filtering.flags.push({ flag: el.orderId, label: el.stageName });
      });
      try {
        this._chg.detectChanges();
      } catch (e) { }
      this.triggerInit.next(true);
    }));
  }

  protected _sortingMapper = (v) => {
    let col = v.prop ? this._tm.columns.find((c) => c.prop == v.prop) : this._tm.columns.find((c) => c.sortable !== false);
    return col ? ({
      orderBy: col.prop,
      orderDir: v.order
    }) : v;
  };

  protected _recalcColumns() {
    const table = this._serverTable;
    if (table) {
      table.calculateColumns();
    }
    try {
      this._chg.detectChanges();
    } catch (err) { }
  }

  protected _pageChanged(page) {
    this._storageService.store('currentPage', page, this._pageSavingOptions);
  }

  protected _untilToday(event) {
    return {
      ...event,
      to: 'today'
    };
  }

  private makeQuery = (query: string) => (additionalParams: (q: any) => any) => (params: any) => {
    const p = additionalParams(params);
    switch (query) {
      case 'getMyProjects':
        p.type = 1;
        return this.projectsBackend.getProjectsList(p || params).pipe(map((entities: EntityList<any>) => {
          this.reloadPageOnCondition(p || params, entities.entities);
          return {
            data: entities.entities.map(el => ({ ...el, id: el.taskId, isActive: isActiveState(el.stateId) })),
            count: entities.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getAllProjects':
        return this.projectsBackend.getProjectsList(p || params).pipe(map((entities: EntityList<any>) => {
          this.reloadPageOnCondition(p || params, entities.entities);
          return {
            data: entities.entities.map(el => ({ ...el, id: el.taskId, isActive: isActiveState(el.stateId) })),
            count: entities.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
    }

  };

  private _applySelected = (res) => {
    if (!res || !res.data || res.data.length < 1) {
      return;
    }
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].taskId == this._lastId) {
        this._selectedRows.push(res.data[i]);
        return;
      }
    }
  };

  private _setActiveList = (list: string, params: any, tasks: any) => {
    this._taskboardDataService.setActiveList(list, params, tasks);
  };

  //Nustatymai, skirti ServerTable funkcionalumui (isskyrus viska, kas yra uz lenteles ribu: - filtrai, paieska ir tt..)
  private _tModels: Array<TableModel>;

  private _initTableModel() {
    const tpls = this._tplService;

    const COL_CODE = {
      name: 'Kodas',
      prop: 'code',
      width: 190,
      sorted: { order: 'desc' },
      getWidth: calcTextCellWidth
    };
    const COL_ENDDATE = {
      name: 'Pabaiga',
      prop: 'endDate',
      width: 90,
      getWidth: calcTextCellWidth,
      cellTemplate: tpls.projectEndDateTpl
    };
    const COL_STARTDATE = {
      name: 'Pradžia',
      prop: 'startDate',
      width: 85,
      getWidth: calcTextCellWidth,
      cellTemplate: tpls.startDateTpl
    };
    const COL_NAME = {
      name: 'Pavadinimas',
      prop: 'name',
      width: 190,
      getWidth: calcTextCellWidth,
    };
    const COL_OWNER = {
      name: 'Užsakovas',
      prop: 'owner',
      width: 190,
      getWidth: calcTextCellWidth
    };
    const COL_PARTNER = {
      name: 'Klientas',
      prop: 'partner',
      width: 190,
      getWidth: calcTextCellWidth
    };
    const COL_VALUE = {
      name: 'Vertė',
      prop: 'projectValue',
      width: 80,
      getWidth: calcTextCellWidth
    };
    const COL_STAGE = {
      name: 'Etapas',
      prop: 'stageName',
      width: 100,
      getWidth: calcTextCellWidth
    };
    const COL_PRIORITY = {
      name: 'Prioritetas',
      prop: 'priority',
      headerClass: 'center',
      class: 'center',
      width: 100,
      getWidth: calcTextCellWidth,
      cellTemplate: tpls.priorityTpl
    };

    const COL_EXECUTOR = {
      name: 'Vykdytojas',
      prop: 'executor',
      width: 190,
      cellTemplate: tpls.executorTpl,
      getWidth: calcTextCellWidth
    };

    const COL_EXECUTOR_EDITABLE = {
      name: 'Vykdytojas',
      prop: 'executor',
      width: 190,
      cellTemplate: tpls.executorEditableTpl,
      getWidth: calcTextCellWidth
    };

    const COL_NEAREST_ACTION = {
      name: 'Veiksmai',
      prop: 'nearestAction',
      class: 'r-7-col-wdth',
      headerClass: 'r-7-header-wdth',
      width: 90,
      getWidth: (_) => 90,
      cellTemplate: tpls.nearestActionTpl
    };

    const COL_SUM_WITHOUT_VAT = {
      name: 'DPA Suma',
      prop: 'sumWithoutVAT',
      // class: 'r-7-col-wdth',
      // headerClass: 'r-7-header-wdth',
      sortable: false,
      width: 100,
      getWidth: (_) => 100,
      cellTemplate: tpls.sumWithoutVATProjectsListTpl
    };

    const COL_CONSUMPTION_MAX = {
      name: 'Sąnaudos iki (h)',
      prop: 'consumptionMax',
      sortable: false,
      width: 100,
      getWidth: (_) => 100,
    };

    const COL_CONSUMPTION_DURATION = {
      name: 'Fakt. trukmė (h)',
      prop: 'consumptionDuration',
      sortable: false,
      width: 100,
      getWidth: (_) => 100,
    };

    const COL_ADVANCE_DURATION = {
      name: 'Avansas',
      prop: 'advance',
      sortable: true,
      width: 100,
      getWidth: (_) => 100,
    };

    const _MY_PROJECTS_COLUMNS = [COL_CODE, COL_PARTNER, COL_NAME, COL_STAGE, COL_ENDDATE, COL_STARTDATE, COL_EXECUTOR_EDITABLE, COL_OWNER, COL_NEAREST_ACTION, COL_PRIORITY, COL_VALUE, COL_SUM_WITHOUT_VAT, COL_CONSUMPTION_MAX, COL_CONSUMPTION_DURATION, COL_ADVANCE_DURATION];

    const _ALL_PROJECTS_COLUMNS = [COL_CODE, COL_PARTNER, COL_NAME, COL_STAGE, COL_ENDDATE, COL_STARTDATE, COL_EXECUTOR_EDITABLE, COL_OWNER, COL_NEAREST_ACTION, COL_PRIORITY, COL_VALUE, COL_SUM_WITHOUT_VAT, COL_CONSUMPTION_MAX, COL_CONSUMPTION_DURATION, COL_ADVANCE_DURATION];

    this._tModels = [
      {
        name: 'myProjects',
        linkProvider: projectLink,
        columns: _MY_PROJECTS_COLUMNS,
        getRowClass: markIfNotSeen,
        getData: (q) => (this.makeQuery('getMyProjects')(noAddition)(q))
      },
      {
        name: 'allProjects',
        linkProvider: projectLink,
        columns: _ALL_PROJECTS_COLUMNS,
        getData: (q) => this.makeQuery('getAllProjects')(noAddition)(q)
      },
    ].map(m => { m.columns = <any>cloneArr(m.columns); return m });
  }

  downloadExcelClick() {
    if (this.isLoadingExcel) return;
    this.isLoadingExcel = true;
    this._subscriptions.push(this.projectsBackend.getProjectsForExport().subscribe((resp: any) => {
      this.isLoadingExcel = false;
      const excelRows = [];
      resp.entities?.forEach(row => {
        const projectFields = {};
        const wscols = [];
        projectFields['Klientas'] = row.partner;
        projectFields['Pavadinimas'] = row.name;
        projectFields['Etapas'] = row.stageName;
        projectFields['Pabaiga'] = datePipe.transform(row.endDate, SHORT_DATE);
        projectFields['Pradžia'] = datePipe.transform(row.startDate, SHORT_DATE);
        projectFields['Vykdytojas'] = row.executor;
        projectFields['Užsakovas'] = row.owner;
        projectFields['Prioritetas'] = row.priority;
        projectFields['Vertė'] = row.projectValue;
        projectFields['DPA Suma'] = row.sumWithoutVAT;
        projectFields['Sąnaudos iki (h)'] = row.consumptionMax;
        projectFields['Fakt. trukmė (h)'] = row.consumptionDuration;
        projectFields['Avansas'] = row.advance;
        excelRows.push(projectFields);
      });
      const wscols = [];
      wscols.push({ width: 40 });
      wscols.push({ width: 50 });
      wscols.push({ width: 15 });
      wscols.push({ width: 15 });
      wscols.push({ width: 15 });
      wscols.push({ width: 30 });
      wscols.push({ width: 30 });
      wscols.push({ width: 25 });
      wscols.push({ width: 15 });
      wscols.push({ width: 15 });
      wscols.push({ width: 15 });
      wscols.push({ width: 15 });
      wscols.push({ width: 15 });
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelRows);
      ws['!cols'] = wscols;
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
      XLSX.writeFile(workbook, `${'Projektai.xlsx'}`);
    }, (err) => {
      this.isLoadingExcel = false;
    }));
  }

  reloadPageOnCondition(params, entities) {
    if (entities.length === 0 && params.page !== 0 && params.page !== undefined && params.page !== null) {
      this._pageChanged(0);
      location.reload();
    }
  }

  private _canEnterRequest() {
    return this._permissionsService.getPermission('insert_request');
  }

  private _colVisibilityChanged(col: string, visible: boolean) {
    this._serverTable.setColumnVisibility(col, visible);
    let cs = this._colSettings.find((cs) => cs.name == col);
    if (!cs) {
      this._colSettings.push({
        name: col,
        visible: visible
      });
    } else {
      cs.visible = visible;
    }
    this._storageService.store(this.ctm.id + '.colSettings', this._colSettings);
  }

  private _columnResized(e: ColumnResized) {
    let cs = this._colSettings.find((cs) => cs.name == e.name);
    if (!cs) {
      this._colSettings.push({
        name: e.name,
        width: e.newWidth
      });
    } else {
      cs.width = e.newWidth;
    }
    this._storageService.store(this.ctm.id + '.colSettings', this._colSettings);
  }

  private _rowClicked(row: any) {
    this._storageService.store(this.ctm.id, row.taskId, {
      popState: true,
      static: true
    });
    this._rowClicks.next(row);
  }

  private _toggleLeftMenu() {
    this._stateService.notify(TOGGLE_LEFT_MENU);
  }

  private _search(val) {
    this._storageService.store(this.ctm.id + '.search', val, {
      popState: true,
      refresh: true
    });
    document.getElementById('content_header_search').blur();
    this._queryProvider.emit('search');
  }

  private _clearSearch() {
    this._searchField ? this._searchField.nativeElement.value = '' : null;
    this._searchField && this._searchField.nativeElement.dispatchEvent(new Event('keyup'));
    this._queryProvider.emit('search');
    this._storageService.store(this.ctm.id + '.search', undefined, {
      popState: true,
      refresh: true
    });
  }

  private _detectSearchVal(val) {
    this._showSearchClear = val.length > 0;
  }
}
