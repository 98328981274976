
import { map, tap, mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpParamsOptions } from '@angular/common/http';
import { Observable, iif, empty, of, throwError, Subject } from 'rxjs';
import { ErrorResponse } from './response/error-response';
import { EntityConstructor, ResponseBase } from './response/response-base';
import { ApiResponseType } from './response';
import { EntityView } from './response/entity-view';
import { EntityList } from './response/entity-list';
import { EntityUpdated } from './response/entity-updated';
import { EntityCreated } from './response/entity-created';
import { EntityDeleted } from './response/entity-deleted';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl = environment.apiUrl;
  logout$ = new Subject();
  dpaServiceUrl = environment.dpaServiceUrl;

  constructor(private _http: HttpClient, private authService: AuthService) {
  }

  private paramsFromObject(params): HttpParams {
    if (params) {
      let httpParams = new HttpParams();
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.append(key, params[key]);
      });
      return httpParams;
    } else {
      return undefined
    }
  }

  private dpaNotRespondingMessage() {
    swal.fire({
      title: 'Šiuo metu DPA yra laikinai nepasiekiamas. Pabandykite vėliau',
      timer: 3000,
      showConfirmButton: false,
      icon: 'error'
    });
  }

  public getDpaServiceBlob(url: string, queryParams?: Object) { //TODO mark4 now
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.idToken);
    return this._http.get(this.dpaServiceUrl + url, { headers, responseType: 'blob', observe: 'response', params: this.paramsFromObject(queryParams) }).pipe(
      catchError((err) => {
        console.log('Handling get error locally and rethrowing it...', err);
        if (err.status === 401) {
          this.logout$.next(false);
        }
        if (err.status === 0) {
          this.dpaNotRespondingMessage();
        }
        return throwError(err);
      }),
      map(x => x.body)
    );
  }

  public postDpaService(url: string, body: Object) { //TODO mark4 now
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.idToken);
    return this._http.post(this.dpaServiceUrl + url, body, { observe: 'response', headers }).pipe(
      catchError((err) => {
        console.log('Handling post error locally and rethrowing it...', err);
        if (err.status === 401) {
          this.logout$.next(false);
        }
        if (err.status === 0) {
          this.dpaNotRespondingMessage();
        }
        return throwError(err);
      })
    );
  }

  public getAuth(url: string, queryParams?: Object) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.idToken);
    return this._http.get(this.baseUrl + url, { headers, observe: 'response', params: this.paramsFromObject(queryParams) }).pipe(
      catchError((err) => {
        console.log('Handling get error locally and rethrowing it...', err);
        if (err.status === 401) {
          this.logout$.next(false);
        }
        return throwError(err);
      })
    );
  }

  public getAuthNoUnauthRedirect(url: string, queryParams?: Object) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.idToken);
    headers = headers.set('noriderect', 'true');
    return this._http.get(this.baseUrl + url, { headers, observe: 'response', params: this.paramsFromObject(queryParams) }).pipe(
      catchError((err) => {
        console.log('Handling get error locally and rethrowing it...', err);
        return throwError(err);
      })
    );
  }

  public getImage(url: string) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.idToken);
    return this._http.get(this.baseUrl + url, { observe: 'response', headers, responseType: 'blob' });
  }

  public deleteImage(url: string) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.idToken);
    return this._http.delete(this.baseUrl + url, { observe: 'response', headers });
  }

  public refreshImage(url: string) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.idToken).set('Cache-Control', 'no-cache');
    return this._http.get(this.baseUrl + url, { observe: 'response', headers, responseType: 'blob' });
  }

  public getNoAuth(url: string, queryParams?: Object) {
    return this._http.get(this.baseUrl + url, { observe: 'response', params: this.paramsFromObject(queryParams) }).pipe(
      catchError((err) => {
        console.log('Handling get error locally and rethrowing it...', err);
        return throwError(err);
      })
    )
  }

  public postAuth(url: string, body: Object) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.idToken);
    return this._http.post(this.baseUrl + url, body, { observe: 'response', headers }).pipe(
      catchError((err) => {
        console.log('Handling post error locally and rethrowing it...', err);
        if (err.status === 401) {
          this.logout$.next(false);
        }
        return throwError(err);
      })
    );
  }

  public postNoAuth(url: string, body: Object) {
    return this._http.post(this.baseUrl + url, body, { observe: 'response' });
  }

  public postNoAuthNoRedirect(url: string, body: Object, options?) {
    let headers = new HttpHeaders().set('noriderect', 'true');
    return this._http.post(this.baseUrl + url, body, { observe: 'response', headers, ...options });
  }

  public deleteAuth(url: string) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.idToken);
    return this._http.delete(this.baseUrl + url, { observe: 'response', headers }).pipe(
      catchError((err) => {
        console.log('Handling delete error locally and rethrowing it...', err);
        if (err.status === 401) {
          this.logout$.next(false);
        }
        return throwError(err);
      })
    );
  }

  public downloadFileAuth(url, queryParams?: Object) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.idToken);
    return this._http.get(this.baseUrl + url, { headers, observe: 'response', params: this.paramsFromObject(queryParams), responseType: 'blob' }).pipe(
      catchError((err) => {
        console.log('Handling file download error locally and rethrowing it...', err);
        if (err.status === 401) {
          this.logout$.next(false);
        }
        return throwError(err);
      }),
      map(x => x.body)
    );
  }

}
