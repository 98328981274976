import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { getFilesDropdownTitle, mapFileForView } from '../../../../functions/utils/files';
import { TaskEvent, TaskReview } from "../../../models/task-review";
import { DATE_WITH_TIME, SHORT_DATE } from "../../../../core/time";
import { UserBackend } from '../../../../backend/user.backend';
import { DEFAULT_CLIENT_PHOTO_URL, DEFAULT_CONSULTANT_PHOTO_URL } from '../../../../core/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss'],
})
export class CommentsListComponent implements OnInit, OnChanges {
  @Input() comments: TaskEvent[];
  @Input() noButtons = false;
  @Input() task: TaskReview;
  @Input() fileMapper = mapFileForView;
  @Input() projectComments: boolean;
  @Output() addCommentJob: EventEmitter<any> = new EventEmitter();
  @Output() fileClicked: EventEmitter<any> = new EventEmitter();
  dateWithTime = DATE_WITH_TIME;
  shortDate = SHORT_DATE;
  private loadTitle = getFilesDropdownTitle;

  onlyWithFiles;

  defaultStateChangeColor = '#a1b1c4';

  constructor(private _userBackend: UserBackend, private chg: ChangeDetectorRef, private router: Router) { }

  ngOnInit() {
    if (this.comments) {
      this.comments.forEach(comment => {
        this.getUserPicture(comment);
      }
      )
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.comments) {
      this.comments.forEach(comment => {
        this.getUserPicture(comment);
      }
      )
    }
  }

  _fileClicked(file) {
    this.fileClicked.next(file);
  }

  _addJob(comment) {
    this.addCommentJob.next(comment);
  }

  getUserPicture(comment) {
    this._userBackend.getUserPicture(comment.userId).subscribe((res) => {
      if (res.body.type === 'text/plain' && res.body.size === 14) {
        comment.userPhoto = comment.isClient ? DEFAULT_CLIENT_PHOTO_URL : DEFAULT_CONSULTANT_PHOTO_URL;
      } else {
        const blob = res.body;
        this.createImageFromBlob(blob, comment);
      }
      this.chg.markForCheck();
    },
      (err) => {
        if (err.status === 404) {
          comment.userPhoto = comment.isClient ? DEFAULT_CLIENT_PHOTO_URL : DEFAULT_CONSULTANT_PHOTO_URL;
          try {
            this.chg.detectChanges();
          } catch (err) { }
        } else {
          console.log('get photo error', err);
        }
      }
    )
  }

  gotoNewRequest(comment) {
    this.router.navigate(['/taskboard', 'r', 'n', 'i', this.task.id, comment.id]);
  }

  createImageFromBlob(image: Blob, comment) {
    let reader = new FileReader();
    reader.addEventListener('load', () => {
      comment.userPhoto = reader.result;
      try {
        this.chg.detectChanges();
      } catch (err) { }
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
