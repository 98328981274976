import {
  AfterContentChecked,
  AfterContentInit,
  ContentChildren,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  AfterViewInit,
  TemplateRef,
  ElementRef,
  ViewChild,
  forwardRef,
  Self,
  Optional,
  Inject,
  Host
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { QueryPartDirective } from './queryPart.directive';
import { flatMap as fm, remove } from '../../helpers';
import { QUERY_PART, QueryPart, QueryProvider } from './Query';
import { VALUE_PROVIDER } from '../../proto';

@Directive({
  selector: '[queryProvider]',
  exportAs: 'queryProvider'
})
export class QueryProviderDirective implements OnInit, AfterContentInit, AfterViewInit, OnDestroy, AfterContentChecked, QueryProvider {
  @Input() flatMap: boolean = false;
  private _isActive: boolean;
  @Input() set active(a: boolean) {
    this._isActive = a;
    if (a) {
      this._init((<Array<QueryPart<any>>>this.queryPartDirectives.toArray()).concat(this._registeredQueryParts));
    }
  }
  get active(): boolean {
    return this._isActive;
  }
  @Output() queryChanged: Subject<any> = new Subject();
  @ContentChildren(QueryPartDirective, { descendants: true }) queryPartDirectives = new QueryList<QueryPartDirective>;
  // @ViewChildren(QueryPartDirective) queryPartDirectives: QueryList<QueryPartDirective>;

  // @ViewChildren(forwardRef(() => QueryPartDirective), { read: QueryPartDirective }) queryPartDirectives1: QueryList<QueryPartDirective>;
  // @ContentChildren(forwardRef(() => QueryPartDirective), { read: QueryPartDirective, descendants: true }) queryPartDirectives1: QueryList<QueryPartDirective>;
  // @ViewChild('table') queryPartDirectives1: QueryList<QueryPartDirective>;

  private _required: Array<string>;
  private _manualEmission: Array<string> = [];
  private _values: any = {};
  private _mValues: any = {};
  private _valSubscriptions: Array<Subscription> = [];
  private _first: boolean = true;
  private _registeredQueryParts: Array<QueryPart<any>> = [];

  constructor(
    // private queryPartDirectivesHost: QueryPartDirective
  ) { }

  private _init(qps: Array<QueryPart<any>>) {
    this._unsubscribeVals();
    this._first = true;
    this._required = [];
    qps.forEach((qp) => { qp.waitFirst ? this._required.push(qp.queryPart) : null; !qp.autoEmit ? this._manualEmission.push(qp.queryPart) : null });
    if (this._isActive) {
      qps.forEach((qp) => { this._valSubscriptions.push(qp.value.subscribe((val) => this._pushVal(qp.queryPart, val))) })
    }
  }

  registerQueryPart<QT>(qp: QueryPart<QT>) {
    this._registeredQueryParts.push(qp);
    if (this.active) {
      this._init((<Array<QueryPart<any>>>this.queryPartDirectives.toArray()).concat(this._registeredQueryParts))
    }
  }

  public reset() {
    if (this.queryPartDirectives && this._isActive) {
      this._init((<Array<QueryPart<any>>>this.queryPartDirectives.toArray()).concat(this._registeredQueryParts));
    }
  }

  getValue(): any {

  }

  ngOnInit() {
    // console.log(this.queryPartDirectives);
  }

  ngAfterContentInit() {
    // console.log(this.queryPartDirectives1);
    // console.log(this.queryPartDirectives?.toArray());
    const qps = this.queryPartDirectives.toArray();
    if (qps.length > 0 && this._isActive) {
      this._init(qps);
    }
  }

  ngAfterViewInit() {
    // console.log(this.queryPartDirectives1);
    // console.log(this.queryPartDirectives);
  }

  ngAfterContentChecked(): void {
    // console.log(this.queryPartDirectives.toArray());
  }

  ngOnDestroy() {
    this._unsubscribeVals();
  }

  private _pushVal(qp: string, val: any) {
    if (this._manualEmission.indexOf(qp) > -1) {
      this._mValues[qp] = val;
      return;
    }
    if (this._first) {
      this._values[qp] = val;
      this._required = remove(this._required, qp);
      if (this._required.length == 0) {
        this._first = false;
        setTimeout(() => {
          this.queryChanged.next(this.flatMap ? fm(this._values) : this._values);
        }, 0);
      }
    } else {
      this._values[qp] = val;
      setTimeout(() => {
        this.queryChanged.next(this.flatMap ? fm(this._values) : this._values);
      }, 0);
    }
  }

  private _unsubscribeVals() {
    this._valSubscriptions.forEach((s) => s.unsubscribe())
  }

  public emit(...q: Array<string>) {
    for (let i of q) {
      if (i in this._mValues) {
        this._values[i] = this._mValues[i];
      }
    }
    if (this._required.length > 0) {
      return;
    }
    setTimeout(() => {
      this.queryChanged.next(this.flatMap ? fm(this._values) : this._values);
    }, 0);
  }
}
