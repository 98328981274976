import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DATE_WITH_TIME, SHORT_DATE } from '../../../core/time';
import { AuthService } from './../../../services/auth.service'

@Component({
  selector: 'project-fields',
  templateUrl: './project-fields.component.html',
  styleUrls: ['./project-fields.component.scss']
})
export class ProjectFieldsComponent implements OnInit {
  @Input() task;
  @Output() updateProjectClicked = new EventEmitter();
  @Output() updateProjectStageClicked = new EventEmitter();

  dateWithTime = DATE_WITH_TIME;
  shortDate = SHORT_DATE;

  constructor(private authService: AuthService) {
  }

  updateProject(event) {
    this.updateProjectClicked.next(event);
  }

  updateProjectStage(event) {
    this.updateProjectStageClicked.next(event);
  }

  canEdit(task) {
    if (task && task.projectDetails && this.authService.user && (task.projectDetails.ownerId == this.authService.user.usersId || task.crmUserIsDelegate)) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
  }

}
