
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, Subscription } from "rxjs"
import { Partner } from "../../models/Partner";
import { EMPTY_PARTNER } from "../../app.constants";
import { LIST, ListsDataService } from '../lists/lists.data.service';
import { RoutingService } from '../../template/shared/proto/services/routing.service';
import { StorageService } from '../../template/shared/proto/storage/storage.service';
import { StorageOptions } from '../../template/shared/proto/storage/StorageOptions';


const EMPTY: Partner = { id: EMPTY_PARTNER, name: '' };
const STORAGE_OPTIONS: StorageOptions = { refresh: true };

@Injectable({
  providedIn: 'root'
})
export class SelectedPartnerService {
  public readonly selectedPartner: Observable<any> = new ReplaySubject(1);
  // public readonly selectedUser: Observable<Partner> = new ReplaySubject(1);
  public isPartner = true;
  cPartner: any;
  cUser: any;
  curerentSubs: Subscription;
  s1: Subscription;
  s2: Subscription;
  public constructor(private _routingService: RoutingService,
    private _dataService: ListsDataService,
    private _storageService: StorageService
  ) {
    this.setupRouteChange();
    this._routingService.currentRoute.subscribe((r) => {
      if (r.popState) {
        this.curerentSubs && this.curerentSubs.unsubscribe();
        this.s1 && this.s1.unsubscribe();
        this.s2 && this.s2.unsubscribe();
        this.setupRouteChange();
      }
    });
  }

  setupRouteChange() {
    this.curerentSubs = this._routingService.currentRoute.pipe(take(1)).subscribe((r) => {
      const pId = r.queryParams['p'];
      const userId = r.queryParams['u'];
      if (pId) {
        this.isPartner = true;
        // (<Subject<Partner>>this.selectedUser).next(EMPTY);
        this.selectPartner({ id: pId, name: '' });
        this.s1 = this._dataService.loadList(LIST.PARTNERS).pipe(map((prtnrs) => prtnrs.find((prtnr) => prtnr.id == pId))).subscribe((sp) => this.selectPartner(sp && sp.id ? ({ id: sp.id, name: sp.name }) : EMPTY));
      } else if (userId) {
        this.isPartner = false;
        // (<Subject<Partner>>this.selectedPartner).next(EMPTY);
        this.selectUser({ id: userId, name: '' });
        this.s2 = this._dataService.loadList(LIST.EMPLOYEES_ALT).pipe(map((prtnrs) => prtnrs.find((prtnr) => prtnr.id == userId))).subscribe((sp) => this.selectUser(sp && sp.id ? ({ id: sp.id, name: sp.name }) : EMPTY));
      } else {
        (<Subject<any>>this.selectedPartner).next({ partner: EMPTY, isEmpty: true });
        // (<Subject<Partner>>this.selectedUser).next(EMPTY);
      }
    });
  }

  public selectPartner(partner: Partner) {
    this.cPartner = partner;
    (<Subject<any>>this.selectedPartner).next({ isUser: false, partner });
  }

  public reemitSelectedPartner() {
    (<ReplaySubject<any>>this.selectedPartner).next({ isUser: false, partner: this.cPartner });
  }

  public selectUser(partner: Partner) {
    this.cUser = partner;
    (<Subject<any>>this.selectedPartner).next({ isUser: true, partner });
  }
  public reemitSelectedUser() {
    (<ReplaySubject<any>>this.selectedPartner).next({ isUser: true, partner: this.cUser });
  }

  public resetPartner() {
    this.selectedPartner.pipe(take(1)).subscribe((sp) => sp == EMPTY ? '' : this.selectPartner(EMPTY));
  }

  public resetUser() {
    this.selectedPartner.pipe(take(1)).subscribe((sp) => sp == EMPTY ? '' : this.selectUser(EMPTY));
  }
}
