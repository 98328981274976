import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LIST } from '../../../services/lists/lists.data.service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PartnersBackend } from '../../../backend/partners.backend';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import swal from 'sweetalert2';

@Component({
  selector: 'partner-details',
  templateUrl: './partner-details.component.html',
  styleUrls: ['./partner-details.component.scss']
})
export class PartnerDetailsComponent implements OnInit, OnChanges {

  constructor(private partnersBackend: PartnersBackend, private chg: ChangeDetectorRef, private formBuilder: UntypedFormBuilder,) { }

  @Input() partnerId: number;
  partner: any;
  partnerDelegates: any[];
  partnerDelegates$: Subject<any> = new Subject();
  protected allDelegates: LIST = LIST.ALL_DELEGATES;
  delegateForm: any;
  dirty = false;
  isCompleted = false;
  isInserting = false;

  deselect$: Subject<any> = new Subject();

  partnerx: any;

  submitted: any

  ngOnInit() {
    this.delegateForm = this.formBuilder.group(
      {
        delegate: [null],
      }
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.partnerId) {
      this.getData();
    }
  }

  addDelegate() {
    // const delegate = { ...this.delegateForm.controls.delegate.value, added: true, deleted: false };
    // if (this.partnerDelegates.find((x) => { return delegate.delegateId === x.delegateId })) {
    //   return;
    // }
    // this.partnerDelegates = [delegate, ...this.partnerDelegates];
    // this.partnerDelegates$.next(this.partnerDelegates);
    // this.deselect$.next(true);
    // this.dirty = true;

    swal.fire({
      title: 'Ar tikrai norite pridėti atstovą į sąrašą?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Ne',
      confirmButtonText: 'Taip',
      // confirmButtonClass: 'btn-primary',
      customClass: {
        confirmButton: 'btn-primary'
      },
      // buttonsStyling: false
    })
      .then((r) => {
        if (r.value) {
          const delegate = { ...this.delegateForm.controls.delegate.value };
          const updObj = { removeDelegate: [], addDelegate: [] };
          updObj.addDelegate.push(delegate.delegateId);
          this.isInserting = true;
          // try {
          //   this.chg.detectChanges();
          // } catch (e) { }
          this.partnersBackend.updatePartnersDelegateList(this.partnerId, updObj).subscribe((res) => {
            this.isInserting = false;
            this.isCompleted = true;
            // try {
            //   this.chg.detectChanges();
            // } catch (e) { }
            this.getPartnerDelegates();
            this.deselect$.next(true);
          }, (err) => {
            this.isInserting = false;
          });
        }
      });

  }

  disabledAddButton() {
    return this.delegateForm.controls.delegate.value == null;
  }

  getData() {
    if (this.partnerId) {
      this.partnersBackend.getPartnerInfo(this.partnerId).pipe(
        map((res: any) => res.entity ? res.entity : null)
      ).subscribe((partner) => {
        this.partner = partner;
        try {
          this.chg.detectChanges();
        } catch (e) { }
      });
      this.getPartnerDelegates();
    }
  }

  getPartnerDelegates() {
    this.partnersBackend.getAllPartnerDelegates(this.partnerId).pipe(
      map((res: any) => res ? res : [])
    ).subscribe((partnerDelegates) => {
      // this.partnerDelegates = partnerDelegates.map((x) => { return { ...x, deleted: false, added: false } });
      this.partnerDelegates = partnerDelegates.map((x) => { return { ...x, defaultUser: x.main === 11 || x.main === 10 } });
      this.partnerDelegates$.next(this.partnerDelegates);
      try {
        this.chg.detectChanges();
      } catch (e) { }
    })
  }

  makeDefaultUser(item) {
    swal.fire({
      title: 'Ar tikrai norite padaryti atstovu pagal nutylėjimą?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Ne',
      confirmButtonText: 'Taip',
      // confirmButtonClass: 'btn-primary',
      customClass: {
        confirmButton: 'btn-primary'
      },
      // buttonsStyling: false
    })
      .then((r) => {
        if (r.value) {
          item.processing = true
          this.partnersBackend.updatePartnersDefaultDelegate(this.partnerId, { delegateId: item.delegateId, main: 10 }).subscribe((res) => {
            item.processing = false;
            // item.defaultUser = true;
            this.getPartnerDelegates();
          }, (err) => {
            item.processing = false;
          });
        }
      });
  }

  removeDefaultUser(item) {
    swal.fire({
      title: 'Ar tikrai norite nuimti atstovą pagal nutylėjimą?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Ne',
      confirmButtonText: 'Taip',
      // confirmButtonClass: 'btn-primary',
      customClass: {
        confirmButton: 'btn-primary'
      },
      // buttonsStyling: false
    })
      .then((r) => {
        if (r.value) {
          item.processing = true
          this.partnersBackend.updatePartnersDefaultDelegate(this.partnerId, { delegateId: item.delegateId, main: 0 }).subscribe((res) => {
            item.processing = false;
            // item.defaultUser = true;
            this.getPartnerDelegates();
          }, (err) => {
            item.processing = false;
          });
        }
      });
  }

  removeFromList(item) {
    // if (item.added) {
    //   this.partnerDelegates = this.partnerDelegates.filter(el => el.delegateId !== item.delegateId);
    //   this.partnerDelegates$.next(this.partnerDelegates);
    // } else {
    //   item.deleted = !item.deleted
    // }
    // this.dirty = true;
    swal.fire({
      title: 'Ar tikrai norite įštrinti atstovą iš sąrašo?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Ne',
      confirmButtonText: 'Taip',
      // confirmButtonClass: 'btn-primary',
      customClass: {
        confirmButton: 'btn-primary'
      },
      // buttonsStyling: false
    })
      .then((r) => {
        if (r.value) {
          const updObj = { removeDelegate: [], addDelegate: [] };
          updObj.removeDelegate.push(item.delegateId);
          item.deleting = true
          this.partnersBackend.updatePartnersDelegateList(this.partnerId, updObj).subscribe((res) => {
            item.deleting = false;
            this.getPartnerDelegates();
          }, (err) => {
            item.deleting = false;
          });
        }
      });

  }

  getRowClass = (row: any) => {
    if (row.added) {
      return 'added';
    }
    if (row.deleted) {
      return 'deleted';
    }
  }

  // cancel() {
  //   this.partnerDelegates = this.partnerDelegates.filter((el) => { return el.added === false });
  //   this.partnerDelegates.forEach((el) => el.deleted = false);
  //   this.partnerDelegates$.next(this.partnerDelegates);
  //   this.dirty = false;
  //   this.chg.detectChanges();
  // }

  // updateDelegatesList() {
  //   const updObj = { removeDelegate: [], addDelegate: [] };
  //   this.partnerDelegates.forEach((el) => {
  //     if (el.deleted) {
  //       updObj.removeDelegate.push(el.delegateId);
  //     }
  //     if (el.added) {
  //       updObj.addDelegate.push(el.delegateId);
  //     }
  //   });
  //   this.isInserting = true;
  //   this.partnersBackend.updatePartnersDelegateList(this.partnerId, updObj).subscribe((res) => {
  //     this.isInserting = false;
  //     this.isCompleted = true;
  //     this.dirty = false;
  //     this.getPartnerDelegates();
  //   });
  // }

}
