import { ApplicationRef, Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Task } from "../../models/Task";
import { TasksBackend } from "../../backend/tasks.backend";
import { TaskReview } from "../models/task-review";
import { map } from 'rxjs/operators';
import { JobsBackend } from '../../backend/jobs/jobs.backend';
import { DpaBackend } from '../../backend/dpa.backend';
import { isActiveState } from '../../taskboard/common/functions';
import { MY_TASKS_TYPE, REQUESTS_FOR_ME_TYPE, REQUESTS_FOR_MY_GROUP_TYPE, REQUESTS_TRANSFERS_TYPE, TASKS_FOR_ME_TYPE, TASKS_FOR_MY_GROUP_TYPE, TASKS_TRANSFERS_TYPE } from '../../core/constants';
import { ProjectsBackend } from '../../backend/projects.backend';


@Injectable({
  providedIn: 'root'
})
export class TaskboardDataService {

  public refreshDatatable: EventEmitter<any> = new EventEmitter();
  public setDateToToday: EventEmitter<any> = new EventEmitter();
  public taskStateChangedEmitter: EventEmitter<any> = new EventEmitter();
  private _list: string;
  private _params: any;
  private _tasks: any;

  private _currentTask: TaskReview
  public get currentTask(): TaskReview {
    return this._currentTask;
  }

  public set currentTask(t: TaskReview) {
    this._currentTask = t;
  }

  public get currentId(): number {
    if (this.currentTask) {
      return this.currentTask.id;
    }
  }

  private _parentTask: TaskReview;
  public set parentTask(val: TaskReview) {
    this._parentTask = val;
  }

  public get parentTask(): TaskReview {
    return this._parentTask;
  }

  public jobCreatedFromTimer: EventEmitter<any> = new EventEmitter();
  public jobDpaChange: EventEmitter<any> = new EventEmitter();

  private _prevLoading: Subscription;
  private _nextLoading: Subscription;
  private _count;
  private _previous$ = new BehaviorSubject<Task>(null);
  public get previous(): Observable<Task> {
    return this._previous$;
  }
  private _next$ = new BehaviorSubject(null);
  public get next(): Observable<Task> {
    return this._next$;
  }

  constructor(
    private _tasksBackend: TasksBackend,
    private _appRef: ApplicationRef,
    private _jobsBackend: JobsBackend,
    private _dpaBackend: DpaBackend,
    private projectsBackend: ProjectsBackend,
  ) {

  }

  public setActiveList(list: string, params: any, tasks: any) {
    this._list = list;
    this._params = params;
    this._tasks = tasks.data;
    this._count = tasks.count;
  }

  public setCurrentTask(task: TaskReview) {
    this.currentTask = task;
    this._previous$.next(null);
    this._next$.next(null);
    this._load();
  }

  private _load() {
    if (!this.currentId || !this._tasks) {
      return;
    }
    const curr = this.currentId;
    const tasks: Array<any> = this._tasks;
    let currIndex = -1;
    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].id == curr) {
        currIndex = i;
        break;
      }
    }
    if (currIndex > -1) {
      this._setPrev(currIndex, tasks);
      this._setNext(currIndex, tasks);
    }
  }

  private _setPrev(currIndex, tasks) {
    if (currIndex > 0) {
      this._previous$.next(tasks[currIndex - 1]);
    } else if (currIndex == 0) {
      const firstRid = tasks.length > 0 ? tasks[0].rowId : undefined;
      const p = this._params;
      if (!firstRid || p.startFrom == 0) {
        this._previous$.next(null);
      } else {
        this._loadPrev();
      }
    }
  }

  private _setNext(currIndex, tasks) {
    if (currIndex < tasks.length - 1) {
      this._next$.next(tasks[currIndex + 1]);
    } else if (currIndex == tasks.length - 1) {
      const count = this._count;
      if (tasks[currIndex].rowId == count - 1) {
        this._next$.next(null);
      } else {
        this._loadNext();
      }
    }
  }

  private _loadPrev() {
    const p = this._params;
    const l = this._list;
    p.startFrom -= p.recordsPerPage;
    // p.startFrom = p.startFrom - p.recordsPerPage;
    if (this._prevLoading) {
      this._prevLoading.unsubscribe();
      this._prevLoading = null;
    }
    this._prevLoading = this.mapToBakcendCall(l, p)?.subscribe((res) => {
      if (!res) {
        return;
      }
      this._tasks = res.data.concat(this._tasks);
      this._previous$.next(res.data[res.data.length - 1]);
      this._prevLoading = null;
    });
  }

  private _loadNext() {
    const p = this._params;
    const l = this._list;
    p.startFrom += p.recordsPerPage;
    // p.startFrom = p.startFrom + p.recordsPerPage;
    if (this._nextLoading) {
      this._nextLoading.unsubscribe();
      this._nextLoading = null;
    }
    this._nextLoading = this.mapToBakcendCall(l, p)?.subscribe((res) => {
      if (!res) {
        return;
      }
      this._tasks = (this._tasks as Array<any>).concat(res.data);
      this._next$.next(res.data[0]);
      this._nextLoading = null;
      this._appRef.tick();
    });
  }

  mapToBakcendCall(query, params) {
    switch (query) {
      case 'getPartnerJobs':
        params['type'] = 1;
        return this._jobsBackend.getJobsList(params).pipe(map((entities: any) => {
          return {
            data: entities.entities,
            count: entities.recordsCount
          }
        }));
      case 'getUserJobs':
        return this._jobsBackend.getJobsList(params).pipe(map((entities: any) => {
          return {
            data: entities.entities,
            count: entities.recordsCount
          }
        }));
      case 'getMyJobs':
        return this._jobsBackend.getJobsList(params).pipe(map((entities: any) => {
          return {
            data: entities.entities,
            count: entities.recordsCount
          }
        }));
      case 'getDpa':
        return this._dpaBackend.getDpaList({ dpaType: 3, ...params }).pipe(map((entities: any) => {
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getGroupDpa':
        return this._dpaBackend.getDpaList(params).pipe(map((entities: any) => {
          return {
            data: entities.entities,
            count: entities.recordsCount
          }
        }));
      case 'getDpaApt':
        return this._dpaBackend.getDpaList({ dpaType: 1, ...params }).pipe(map((entities: any) => {
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getMyTasks':
        return this._tasksBackend.getTasksList(MY_TASKS_TYPE, null, params).pipe(map((entities) => {
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getTasksForMe':
        return this._tasksBackend.getTasksList(TASKS_FOR_ME_TYPE, null, params).pipe(map((entities) => {
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getTasksForMyGroup':
        return this._tasksBackend.getTasksList(TASKS_FOR_MY_GROUP_TYPE, null, params).pipe(map((entities) => {
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getPartnerTasks':
        return this._tasksBackend.getTasksList(TASKS_FOR_MY_GROUP_TYPE, -1, params).pipe(map((entities) => {
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getUserTasks':
        return this._tasksBackend.getTasksList(TASKS_FOR_ME_TYPE, -1, params).pipe(map((entities) => {
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getRequestsForMe':
        return this._tasksBackend.getTasksList(REQUESTS_FOR_ME_TYPE, null, params).pipe(map((entities) => {
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getPartnerRequests':
        return this._tasksBackend.getTasksList(REQUESTS_FOR_MY_GROUP_TYPE, -1, params).pipe(map((entities) => {
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getUserRequests':
        return this._tasksBackend.getTasksList(REQUESTS_FOR_ME_TYPE, -1, params).pipe(map((entities) => {
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getRequestsForMyGroup':
        return this._tasksBackend.getTasksList(REQUESTS_FOR_MY_GROUP_TYPE, null, params).pipe(map((entities) => {
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getTransferedTasks':
        return this._tasksBackend.getTasksListTransfers(TASKS_TRANSFERS_TYPE, params).pipe(map((entities) => {
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getTransferedRequests':
        return this._tasksBackend.getTasksListTransfers(REQUESTS_TRANSFERS_TYPE, params).pipe(map((entities) => {
          return {
            data: entities.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getMyProjects':
        return this.projectsBackend.getProjectsList(params).pipe(map((entities: any) => {
          return {
            data: entities.entities.map(el => ({ ...el, id: el.taskId, isActive: isActiveState(el.stateId) })),
            count: entities.recordsCount
          }
        }));
      case 'getAllProjects':
        return this.projectsBackend.getProjectsList(params).pipe(map((entities: any) => {
          return {
            data: entities.entities.map(el => ({ ...el, id: el.taskId, isActive: isActiveState(el.stateId) })),
            count: entities.recordsCount
          }
        }));
    }
  }
}
