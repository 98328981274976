import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FeedbackBackend } from '../backend/feedback.backend';
import { SelectedPartnerService } from '../services/partner/selectedPartner.service';
import { AuthService } from '../services/auth.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];
  id: string;
  currentFeedbackSelection = null;
  comment: string;
  isLoading = false;
  showSuccessMessage = false;

  constructor(
    private route: ActivatedRoute,
    private feedbackBackend: FeedbackBackend,
    private selectPartnerService: SelectedPartnerService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.authService.noMenus = true;
    }, 0);
    this.selectPartnerService.resetPartner();
    this.selectPartnerService.resetUser();
    this.subs.push(this.route.params.subscribe((params) => {
      this.id = params['id'];
    }));
  }

  smileClick(smile) {
    this.currentFeedbackSelection = smile;
  }

  isSmileSelected(smile) {
    return this.currentFeedbackSelection === smile;
  }

  sendFeedbackClick() {
    const feedbackObj = {
      taskId: +this.id,
      feedback: +this.currentFeedbackSelection,
      comment: this.comment,
    }
    this.isLoading = true;
    this.subs.push(this.feedbackBackend.sendFeedback(feedbackObj).subscribe(resp => {
      this.isLoading = false;
      this.showSuccessMessage = true;
    }, err => {
      this.isLoading = false;
    }))
  }

  ngOnDestroy() {
    this.authService.noMenus = false;
    this.subs.forEach(s => s.unsubscribe());
  }

}