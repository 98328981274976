import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ProjectsBackend } from '../../backend/projects.backend';
import { TASKTYPE } from '../../projects/projects.component';
import { CalendarCustomHeaderComponent } from '../filters/date-interval-alt/calendarCustomHeader.component';


@Component({
  selector: 'project-edit-modal',
  templateUrl: './project-edit-modal.component.html',
  styleUrls: ['./project-edit-modal.component.scss']
})
export class ProjectEditModalComponent implements OnInit, OnDestroy {

  editProjectForm: UntypedFormGroup;
  subs: Subscription[] = [];
  projectTypes: any = [];
  services: any = [];
  projectStages: any = [];
  servicesForm: UntypedFormGroup;
  servicesToAdd: any = [];
  servicesToAddView: any = [];
  selectedServiceToAdd: any = null;
  faPlus = faPlus;
  calendarCustomHeaderComponent: any = CalendarCustomHeaderComponent;

  constructor(
    public dialogRef: MatDialogRef<ProjectEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    private formBuilder: UntypedFormBuilder,
    private projectsBackend: ProjectsBackend,
  ) { }

  ngOnInit() {
    this.buildForm();
    this.subs.push(this.projectsBackend.getProjectStages(TASKTYPE).subscribe((resp) => {
      this.projectStages = resp;
      if (this.data.isNew) {
        const newStage = this.projectStages.find((el) => { return el.code == 'N' });
        this.editProjectForm.controls.projectStage.setValue(newStage.stageId);
      }
    }));
    if (this.data.isNew) {
      this.subs.push(this.projectsBackend.getProjectServices(TASKTYPE).subscribe((resp) => {
        this.services = resp;
        const servicesFieldsForm = {};
        this.services.forEach((el) => {
          // servicesFieldsForm[el.serviceId] = [{ value: 0, disabled: this.editProjectForm.controls.enterCostProperty.value }, Validators.required];
          servicesFieldsForm[el.serviceId] = [0, Validators.required];
        });
        this.servicesForm = this.formBuilder.group(servicesFieldsForm);
        // this.setupServiceFormChanges();
      }));
    } else {
      Promise.all([new Promise((resolve, reject) => {
        this.subs.push(this.projectsBackend.getProjectsServicesTime(this.data.task.id).subscribe((resp) => {
          this.services = resp.projectsTaskServicesList.map((el) => { return { ...el, name: el.serviceName } });
          const servicesFieldsForm = {};
          this.services.forEach((el) => {
            servicesFieldsForm[el.serviceId] = [el.planned, Validators.required];
          });
          this.servicesForm = this.formBuilder.group(servicesFieldsForm);
          resolve(true);
        }, (err) => {
          reject(err);
        }));
      }), new Promise((resolve, reject) => {
        this.subs.push(this.projectsBackend.getProjectServices(TASKTYPE).subscribe((resp) => {
          this.servicesToAdd = resp;
          resolve(true);
        }, (err) => {
          reject(err)
        }));
      })
      ]).then((resp2) => {
        for (let i = 0; i < this.servicesToAdd.length; i++) {
          let exsistingService = this.services.find(el => el.serviceId === this.servicesToAdd[i].serviceId);
          if (exsistingService) {
            this.servicesToAdd.splice(i, 1);
            i--;
          }
        }
        this.servicesToAddView = [...this.servicesToAdd];
      }, (err) => {
        console.log(err);
      })

    }
    this.subs.push(this.projectsBackend.getProjectTypes().subscribe((resp) => {
      this.projectTypes = resp;
    }));
    this.subs.push(this.projectsBackend.getProjectStages(TASKTYPE).subscribe((resp) => {
      this.projectStages = resp;
    }));
    // this.subs.push(this.editProjectForm.controls.enterCostProperty.valueChanges.subscribe((change) => {
    //   this.toggleDisableFields(change);
    // }))
  }

  dateChanged(el) {
    el.close && el.close();
  }

  addService(service) {
    let control = new UntypedFormControl(0, [Validators.required]);
    this.servicesForm.addControl(service.serviceId, control);
    this.services.push(service);
    const ind = this.servicesToAddView.findIndex((el) => el.serviceId === service.serviceId)
    this.servicesToAddView.splice(ind, 1);
    this.selectedServiceToAdd = null;
  }

  toggleDisableFields(enterCostProperty) {
    if (enterCostProperty) {
      this.editProjectForm.controls.plannedCostsFrom.enable();
      this.editProjectForm.controls.plannedCostsTo.enable();
      this.services && this.services.forEach((el) => {
        this.servicesForm.controls[el.serviceId].disable();
        this.servicesForm.controls[el.serviceId].setValue(0);
        this.servicesForm.controls[el.serviceId].setValidators([]);
        this.servicesForm.controls[el.serviceId].updateValueAndValidity();
      });
    } else {
      this.editProjectForm.controls.plannedCostsFrom.disable();
      this.editProjectForm.controls.plannedCostsTo.disable();
      this.services && this.services.forEach((el) => {
        this.servicesForm.controls[el.serviceId].enable();
        this.servicesForm.controls[el.serviceId].setValidators([Validators.required]);
        this.servicesForm.controls[el.serviceId].updateValueAndValidity();
      });
    }
  }

  get title() {
    return this.editProjectForm.get('title');
  }

  get contractNr() {
    return this.editProjectForm.get('contractNr');
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.stageId == object2.stageId;
  }

  setupServiceFormChanges() {
    this.subs.push(this.servicesForm.valueChanges.subscribe(() => {
      if (!this.editProjectForm.controls.enterCostProperty.value) {
        let plannedCosts = 0;
        this.services && this.services.forEach((el) => {
          plannedCosts += this.servicesForm.controls[el.serviceId].value
        });
        this.editProjectForm.controls.plannedCostsFrom.setValue(plannedCosts);
        this.editProjectForm.controls.plannedCostsFrom.updateValueAndValidity();
        this.editProjectForm.controls.plannedCostsTo.setValue(plannedCosts);
        this.editProjectForm.controls.plannedCostsTo.updateValueAndValidity();
      }
    }));
  }

  buildForm() {
    // const enterCostPropertyValue = this.data.isNew ? true : this.data.task.projectDetails && this.data.task.projectDetails.enterCostProperty;
    this.editProjectForm = this.formBuilder.group({
      title: [this.data.isNew ? null : this.data.task.projectDetails && this.data.task.projectDetails.name, [Validators.required, Validators.maxLength(50)]],
      description: [this.data.isNew ? null : this.data.task.projectDetails && this.data.task.projectDetails.description, Validators.required],
      startDate: [this.data.isNew ? null : (this.data.task.projectDetails && this.data.task.projectDetails.startDate ? new Date(this.data.task.projectDetails && this.data.task.projectDetails.startDate) : null), Validators.required],
      endDate: [this.data.isNew ? null : (this.data.task.projectDetails && this.data.task.projectDetails.endDate ? new Date(this.data.task.projectDetails && this.data.task.projectDetails.endDate) : null), Validators.required],
      projectType: [this.data.isNew ? null : this.data.task.projectDetails && this.data.task.projectDetails.projectTypeId, Validators.required],
      projectStage: [{ value: this.data.isNew ? null : this.data.task.projectDetails && this.data.task.projectDetails.stageId, disabled: this.data.isNew ? true : true }, this.data.isNew ? null : Validators.required], //requiered when editing
      plannedIncomeFrom: [this.data.isNew ? null : this.data.task.projectDetails && this.data.task.projectDetails.incomePlan],
      plannedIncomeTo: [this.data.isNew ? null : this.data.task.projectDetails && this.data.task.projectDetails.incomePlanMax],
      // plannedCostsFrom: [{ value: this.data.isNew ? null : this.data.task.projectDetails && this.data.task.projectDetails.costPlan, disabled: !enterCostPropertyValue }],
      plannedCostsFrom: [this.data.isNew ? null : this.data.task.projectDetails && this.data.task.projectDetails.costPlan],
      // plannedCostsTo: [{ value: this.data.isNew ? null : this.data.task.projectDetails && this.data.task.projectDetails.costPlanMax, disabled: !enterCostPropertyValue }],
      plannedCostsTo: [this.data.isNew ? null : this.data.task.projectDetails && this.data.task.projectDetails.costPlanMax],
      contractNr: [this.data.isNew ? null : this.data.task.projectDetails && this.data.task.projectDetails.contractNr, Validators.maxLength(255)],
      worth: [this.data.isNew ? null : this.data.task.projectDetails && this.data.task.projectDetails.projectValue],
      advance: [this.data.isNew ? null : this.data.task.projectDetails && this.data.task.projectDetails.advance],
      // enterCostProperty: [enterCostPropertyValue],
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  // check() {
  //   console.log('check', this.editProjectForm.valid, this.editProjectForm.invalid, this.servicesForm.valid, this.servicesForm.invalid);
  //   console.log('errors method main', this.getFormErrors(this.editProjectForm));
  //   console.log('errors method service', this.getFormErrors(this.servicesForm));
  // }

  getFormErrors(form: AbstractControl) {
    if (form instanceof UntypedFormControl) {
      return form.errors || null;
    }
    if (form instanceof UntypedFormGroup) {
      const groupErrors = form.errors;
      const formErrors = groupErrors ? { groupErrors } : {};
      Object.keys(form.controls).forEach(key => {
        const error = this.getFormErrors(form.get(key));
        if (error !== null) {
          formErrors[key] = error;
        }
      });
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
  }

  serviceSortFn(propA, propB) {
    if (propA.serviceId < propB.serviceId) {
      return -1;
    }
    if (propA.serviceId > propB.serviceId) {
      return 1;
    }
    return 0;
  }

  submit() {
    const createObj: any = {};
    createObj.taskId = this.data.task.id;
    createObj.name = this.editProjectForm.controls.title.value;
    createObj.description = this.editProjectForm.controls.description.value;
    createObj.type = this.editProjectForm.controls.projectType.value;
    createObj.expenses = this.editProjectForm.controls.plannedCostsFrom.value;
    createObj.expensesMax = this.editProjectForm.controls.plannedCostsTo.value;
    createObj.income = this.editProjectForm.controls.plannedIncomeFrom.value;
    createObj.incomeMax = this.editProjectForm.controls.plannedIncomeTo.value;
    createObj.worth = this.editProjectForm.controls.worth.value;
    createObj.advance = this.editProjectForm.controls.advance.value;
    createObj.contractNr = this.editProjectForm.controls.contractNr.value;
    createObj.projectStart = this.editProjectForm.controls.startDate.value.toLocaleDateString('LT');
    createObj.projectEnd = this.editProjectForm.controls.endDate.value.toLocaleDateString('LT');
    // createObj.enterCostProperty = this.editProjectForm.controls.enterCostProperty.value;


    if (this.data.isNew) {
      createObj.projectServicesList = [];
      this.services && this.services.sort(this.serviceSortFn).forEach((el) => {
        createObj.projectServicesList.push({
          serviceId: el.serviceId,
          length: this.servicesForm.controls[el.serviceId].value,
        });
      });
      this.subs.push(this.projectsBackend.createProject(createObj).subscribe((resp) => {
        this.dialogRef.close(true);
      }))
    } else {
      createObj.projectServicesUpdateList = [];
      createObj.projectServicesList = [];
      this.services && this.services.sort(this.serviceSortFn).forEach((el) => {
        if (el.id) {
          createObj.projectServicesUpdateList.push({
            id: el.id,
            length: this.servicesForm.controls[el.serviceId].value,
          });
        } else {
          createObj.projectServicesList.push({
            serviceId: el.serviceId,
            length: this.servicesForm.controls[el.serviceId].value,
          });
        }
      });
      this.subs.push(this.projectsBackend.updateProject(this.data.task.projectDetails.projectId, createObj).subscribe((resp) => {
        this.dialogRef.close(true);
      }))
    }


  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
