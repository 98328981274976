import { SuggestionsComponent } from './../../../components/suggestions/suggestions.component';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LIST } from '../../../services/lists/lists.data.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import swal from 'sweetalert2';
import { DelegateFormComponent } from '../../delegates/delegate-form/delegate-form.component';
import { generatePassword } from '../../../template/shared/proto/helpers';
import { Location } from '@angular/common';
import { UserBackend } from "../../../backend/user.backend";
import { Subscription } from "rxjs";
import { EMAIL_PATTERN_REGEXP } from '../../../app.constants';

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit, OnDestroy {
  _partners = LIST.PARTNERS;
  _employees = LIST.EMPLOYEES;
  _partnerDelegates = LIST.PARTNER_DELEGATES;

  newUserForm: UntypedFormGroup;
  protected isInserting = false;
  private isCompleted = false;
  private _withDelegate = false;
  private _withDelegateCheckboxEnabled = true;
  private _subscribtions = new Array<Subscription>();
  _selectedPartnerId: number;
  private _delegateForm;
  @ViewChild(DelegateFormComponent) set delegateFormComponent(val: DelegateFormComponent) {
    if (val) {
      this._delegateForm = val.newDelegateForm;
    } else {
      this._delegateForm = undefined;
    }
  }

  @ViewChild('partnerDelegate', { read: SuggestionsComponent }) partnerDelegate: SuggestionsComponent;
  @ViewChild('partner', { read: SuggestionsComponent }) partnerView: SuggestionsComponent;
  @ViewChild('employee', { read: SuggestionsComponent }) employeeView: SuggestionsComponent;

  private delegateForm;

  constructor(private _formBuilder: UntypedFormBuilder,
    private _location: Location,
    private _userBackend: UserBackend,
    private _chg: ChangeDetectorRef) {
    this.buildForm();
  }

  ngOnInit() {
  }

  insertUser(f) {

    this.isInserting = false;
    this.isCompleted = false;

    if (!(this.newUserForm.controls['partnerId'].value || this.newUserForm.controls['employeeId'].value)) {
      swal.fire({ title: "Pasirinkite darbuotoją arba partnerio atstovą, kuriam kuriate prisijungimą prie ECRM!", timer: 2000, showConfirmButton: false, icon: 'error' })
      // .catch(swal.noop);
      return;
    }
    const userObj: any = {};
    const delegateObj: any = {};

    if (this._withDelegate) {
      delegateObj.name = this._delegateForm.value.name;
      delegateObj.lastName = this._delegateForm.value.lastName;
      delegateObj.position = this._delegateForm.value.position;
      delegateObj.email = this.newUserForm.value.email;
      delegateObj.mobileNr = this._delegateForm.value.mobileNr;
      delegateObj.phoneNr = this._delegateForm.value.phoneNr;
      delegateObj.partnerId = this._selectedPartnerId;
      userObj.createDelegate = true;
      userObj.delegate = delegateObj;
    } else {
      userObj.createDelegate = false;
    }

    userObj.userName = this.newUserForm.value.userName;
    userObj.password = this.newUserForm.value.password;
    userObj.email = this.newUserForm.value.email;
    userObj.employeeId = !(this.employeeView && this.employeeView.input && this.employeeView.input.disabled) ? this.newUserForm.value.employeeId : undefined;
    userObj.delegateId = !this._withDelegate && !(this.partnerDelegate && this.partnerDelegate.input && this.partnerDelegate.input.disabled) ? this.newUserForm.value.delegateId : undefined;


    this.isInserting = true;
    this._subscribtions.push(this._userBackend.createUser(userObj).subscribe(
      (res) => this._handleUserInsertion(res),
      (err) => this._handleUserInsertion(err)));
  }

  private _handleUserInsertion(res) {
    let isCreated = false;
    try {
      if (isCreated = parseInt(res.body) > 0) {
        this.isCompleted = true;
        this.isInserting = false;
        this._detectChanges();
        swal.fire({ title: "Vartotojo prisijungimas sukurtas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' })
          // .catch(swal.noop)
          .then(_ => this.goBack());
        return;
      }
    } catch (e) {
    }
    let message = 'Vartotojo sukurti nepavyko.';
    if (res.error && res.error.error === '-1') {
      message = 'Vartotojas privalo būti arba įmones darbuotojas, arba partnerio atstovas.'
    } else if (res.error && res.error.error === '-2') {
      message = 'Vartotojas privalo būti arba įmones darbuotojas, arba partnerio atstovas, bet ne abu.'
    } else if (res.error && res.error.error === '-4') {
      message = 'Nurodytas darbuotojas neegzistuoja.'
    } else if (res.error && res.error.error === '-5') {
      message = 'Nurodytas atstovas neegzistuoja.'
    } else if (res.error && res.error.error === '-9') {
      message = 'Nurodytas asmuo neegzistuoja.'
    }
    swal.fire({ title: message, timer: 4000, showConfirmButton: false, icon: 'error' })
      // .catch(swal.noop)
      .then();
    this.isCompleted = true;
    this.isInserting = false;
    try {
      this._detectChanges();
    } catch (err) { }
  }

  partnerSelected(partnerId) {
    this._selectedPartnerId = partnerId;
    if (!partnerId) {
      this.createDelegateChanged(false);
    }
  }

  employeeSelected(employeeId) {
    if (employeeId) {
      this.partnerDelegate && this.partnerDelegate.input.setDisabledState(true);
      this.partnerView && this.partnerView.input.setDisabledState(true);
      this._withDelegate = false;
      this._withDelegateCheckboxEnabled = false;
    } else {
      this.partnerDelegate && this.partnerDelegate.input.setDisabledState(false);
      this.partnerView && this.partnerView.input.setDisabledState(false);
      this._withDelegate = false;
      this._withDelegateCheckboxEnabled = true;
    }
  }

  delegateSelected(delegateId) {
    if (delegateId) {
      this.employeeView && this.employeeView.input.setDisabledState(true);
      this._withDelegate = false;
      this._withDelegateCheckboxEnabled = false;
    } else {
      this.employeeView && this.employeeView.input.setDisabledState(false);
      this._withDelegateCheckboxEnabled = true;
    }
  }

  buildForm() {
    this.newUserForm = this._formBuilder.group({
      userName: ['', Validators.required],
      password: [generatePassword(8), Validators.required],
      email: [null, [Validators.required, Validators.pattern(EMAIL_PATTERN_REGEXP)]],
      partnerId: [null],
      employeeId: [null],
      delegateId: ['']
    });
  }

  createDelegateChanged(checked) {
    this._withDelegate = checked;
    checked ? this.employeeView && this.employeeView.input.setDisabledState(true) : this.employeeView && this.employeeView.input.setDisabledState(false);
    return this.partnerDelegate ? this.partnerDelegate.input.setDisabledState(checked) : {};
  }

  goBack() {
    this._location.back();
  }

  private _detectChanges() {
    try {
      this._chg.detectChanges();
    } catch (e) {
    }
  }

  ngOnDestroy(): void {
    try {
      this._subscribtions.forEach(s => s.unsubscribe())
    } catch (e) {
    }
  }

}
