import { SplitByPipe } from "../../template/shared/pipes/split-by.pipe";

export function valOrTrhow(obj, key, error) {
  if (!(obj && key) || !obj[key]) {
    throw new Error(error);
  }
  return obj[key];
}

export function strReplaceAt(str, index, newVal) {
  return str.substr(0, index) + newVal + str.substr(index + newVal.length);
}

export function getEmail(val: string) {
  if (typeof val === 'string') {
    const p = new SplitByPipe();
    return p.transform(val, ';');
  }
}
