import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from '../../../dashboards/dashboard.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bad-rated-requests-select',
  templateUrl: './bad-rated-requests-select.component.html',
  styleUrls: ['./bad-rated-requests-select.component.scss']
})
export class BadRatedRequestsSelectComponent implements OnInit, OnDestroy {

  unsub: Subscription;
  selectedValue: number;

  selectOptions = [
    { rating: 0, label: 'Visos' },
    { rating: 3, label: 'Neigiamas' },
    { rating: 2, label: 'Vidutinis' },
    { rating: 1, label: 'Teigiamas' },
  ]

  constructor(
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.unsub = this.dashboardService.ratingSelect$.subscribe({
      next: (rating) => {
        this.selectedValue = rating;
      }
    })
  }

  selectionChange(event) {
    console.log(event.value);
    this.dashboardService.ratingSelect$.next(event.value);
  }

  ngOnDestroy() {
    this.unsub.unsubscribe();
  }

}
