import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { StorageService } from '../../../../template/shared/proto/storage/storage.service';
import { PartnersBackend } from '../../../../backend/partners.backend';
import { convertNumToTime } from '../../../../taskboard/common/functions';

@Component({
  selector: 'partner-products',
  templateUrl: './partnerProducts.component.html',
  styleUrls: ['./partnerProducts.component.scss']
})
export class PartnerProductsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() partnerId;
  private _subscriptions: Array<Subscription> = [];
  _partnerProducts: Subject<Array<any>> = new BehaviorSubject(null);
  columnWidths = {};
  private _colSettings = [];

  constructor(private _storageService: StorageService, private partnersBackend: PartnersBackend) {
  }

  ngOnInit() {
    this._colSettings = this._storageService.get('partenrProducts.colSettings') || [];
    let cs;
    cs = this._colSettings.find((cs) => cs.name == 'product');
    this.columnWidths['product'] = cs ? cs.width : 240;
    cs = this._colSettings.find((cs) => cs.name == 'copyright');
    this.columnWidths['copyright'] = cs ? cs.width : 125;
    cs = this._colSettings.find((cs) => cs.name == 'responseTime');
    this.columnWidths['responseTime'] = cs ? cs.width : 110;
    cs = this._colSettings.find((cs) => cs.name == 'version');
    this.columnWidths['version'] = cs ? cs.width : 75;
    cs = this._colSettings.find((cs) => cs.name == 'workplaces');
    this.columnWidths['workplaces'] = cs ? cs.width : 55;
    cs = this._colSettings.find((cs) => cs.name == 'validity');
    this.columnWidths['validity'] = cs ? cs.width : 85;
    cs = this._colSettings.find((cs) => cs.name == 'process');
    this.columnWidths['process'] = cs ? cs.width : 190;
    cs = this._colSettings.find((cs) => cs.name == 'freeTime');
    this.columnWidths['freeTime'] = cs ? cs.width : 190;
    cs = this._colSettings.find((cs) => cs.name == 'usedTime');
    this.columnWidths['usedTime'] = cs ? cs.width : 190;
    cs = this._colSettings.find((cs) => cs.name == 'notes');
    this.columnWidths['notes'] = cs ? cs.width : 305;
    cs = this._colSettings.find((cs) => cs.name == 'assignee');
    this.columnWidths['assignee'] = cs ? cs.width : 160;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.partnerId && changes.partnerId.currentValue > 0 && changes.partnerId.currentValue != changes.partnerId.previousValue) {
      this._loadData(this.partnerId);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  private _loadData(partnerId: any) {
    this._subscriptions.push(this.partnersBackend.getPartnerProducts(partnerId)
      .subscribe((res: any) => this._partnerProducts.next(res ? res.filter((el) => { return el.validity === 'Galioja' }).map((elm) => {
        const computed: any = {};
        // computed.usedTimeNum = elm.productTimeView.numericLeft ? elm.productTimeView.numericMonth - elm.productTimeView.numericLeft : elm.productTimeView.numericPaid;
        // computed.totalTimeNum = elm.productTimeView.numericLeft ? elm.productTimeView.numericMonth : computed.usedTimeNum;
        // computed.usedTimePerc = computed.totalTimeNum ? computed.usedTimeNum / computed.totalTimeNum * 100 : 0;
        // computed.usedTimeStr = convertNumToTime(computed.usedTimeNum);
        // computed.totalTimeStr = convertNumToTime(computed.totalTimeNum);



        computed.usedTimeNum = elm.productTimeView.numericLeft ? elm.productTimeView.numericMonth * 60 - elm.productTimeView.numericLeft : elm.productTimeView.numericPaid * 60;
        computed.totalTimeNum = elm.productTimeView.numericLeft ? elm.productTimeView.numericMonth * 60 : computed.usedTimeNum;
        computed.usedTimePerc = computed.totalTimeNum ? computed.usedTimeNum / computed.totalTimeNum * 100 : 0;
        computed.paidTime = elm.productTimeView.paidTime;
        // computed.usedTimeStr = computed.usedTimeNum < 0 ? '00:00' : convertNumToTime(computed.usedTimeNum);
        // computed.totalTimeStr = computed.totalTimeNum < 0 ? '00:00' : convertNumToTime(computed.totalTimeNum);
        // console.log('aaaaa', computed.usedTimeNum);
        // computed.usedTimeStr = '00:00';
        // computed.totalTimeStr = '00:00';


        return { ...elm, freeTime: elm.productTimeView.monthHours, usedTime: computed };
      }) : [])));
  }

  _columnResized(event) {
    let cs = this._colSettings.find((cs) => cs.name == event.column.prop);
    if (!cs) {
      this._colSettings.push({
        name: event.column.prop,
        width: event.newValue
      });
    } else {
      cs.width = event.newValue;
    }
    this._storageService.store('partenrProducts.colSettings', this._colSettings);
  }
}
