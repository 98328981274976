import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs'
import { JobsBackend } from "../../../../backend/jobs/jobs.backend";
import { DatePipe } from "@angular/common";
import { StateService } from "../../../../services/state/stateService";
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { TplService } from "../../../services/tpl.service";
import { ModalsService } from "../../../services/modals.service";
import { JobsListEntry } from "../../../models/jobs-list-entry";
import { TaskboardSidebarService } from "../../../services/taskboardSidebar.service";

@Component({
  selector: 'jobs-list',
  templateUrl: './jobsList.component.html',
  styleUrls: ['./jobsList.component.scss']
})
export class JobsListComponent implements OnInit {

  @Input() taskId;
  @Input() jobs: JobsListEntry[];
  @Output() jobClicked: EventEmitter<any> = new EventEmitter();
  @ViewChild(DatatableComponent) dTable: DatatableComponent;
  @ViewChild('isPaidTpl', { static: true }) _isPaidTpl;
  @ViewChild('dpaCodeTpl', { static: true }) _dpaCodeTpl;

  protected _jobs: Subject<JobsListEntry[]> = new BehaviorSubject([]);
  protected _columns;
  _visible = true;


  constructor(
    private _elementRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private jobsBackend: JobsBackend,
    private dPipe: DatePipe,
    private state: StateService,
    public cdr: ChangeDetectorRef,
    private _tplService: TplService,
    private _modalsService: ModalsService,
    private _stateService: StateService,
    private _taskboardService: TaskboardSidebarService
  ) {

  }

  ngOnInit(): void {

    this._columns = [
      { name: 'Data', prop: 'date', width: 143, sortable: true },
      { name: 'Laikas', prop: 'timeRange', width: 140, sortable: true },
      //duration and pricedTime fields have to be as stated below ('pricedTime': shows durationView from job form, and 'duration' shows 'Kliento laikas')
      { name: 'Trukmė', prop: 'pricedTime', width: 125 },
      { name: 'Kliento laikas', prop: 'duration', width: 125 },
      { name: 'Vykdytojas', prop: 'executor', width: 160 },
      { name: 'Suma', prop: 'sumNoVat', width: 118, sortable: true },
      { name: 'Paslauga', prop: 'service', width: 230, sortable: true },
      { name: 'Apmokamas', prop: 'isPaid', width: 120, cellTemplate: this._isPaidTpl, sortable: true },
      { name: 'Numeris', prop: 'dpaCode', width: 160, cellTemplate: this._tplService.dpaCodeTpl, sortable: true },
      { name: 'DPA būsena', prop: 'isFinished', width: 120, cellTemplate: this._tplService.isDpaFinishedTpl, sortable: true },
      { name: 'Išsiuntimo būsena', prop: 'lastSendDate', width: 160, cellTemplate: this._tplService.DPAMailStateTpl, sortable: true },
      { name: 'Aprašymas', prop: 'description', width: 400, sortable: true, cellTemplate: this._tplService.jobsDescriptionAltTpl }
    ];

  }

  private _jobClicked($job) {
    this.jobClicked.emit($job);
  }

  public recalc() {
    this._visible = false;
    try {
      this.cdr.detectChanges();
    } catch (err) { }
    this._visible = true;
    try {
      this.cdr.detectChanges();
    } catch (err) { }
  }
}
