import { Loadable } from './loadable';
import { Directive, Inject, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";

const EMPTY = 0;
const LOADING = 1;
const LOADED = 2;
const ERROR = 3;

const getCondition = (l: Loadable<any>) => !l ? EMPTY : l.isLoading ? LOADING : l.data ? LOADED : l.error ? ERROR : -1;

export class SwitchView {
  private _created = false;

  constructor(
    private _viewContainerRef: ViewContainerRef, private _templateRef: TemplateRef<Object>) { }

  create(ctx?: any): void {
    this._created = true;
    this._viewContainerRef.createEmbeddedView(this._templateRef, ctx);
  }

  destroy(): void {
    this._created = false;
    this._viewContainerRef.clear();
  }

  enforceState(created: boolean) {
    if (created && !this._created) {
      this.create();
    } else if (!created && this._created) {
      this.destroy();
    }
  }
}

@Directive({
  selector: '[loadableData]',
  // providers: [
  //   LoadableDataDirective
  // ]
})
export class LoadableDataDirective<T> implements OnInit {
  private _currentCondition: number;
  private hasView = false;
  private loading: SwitchView;
  private loaded: SwitchView;
  private error: SwitchView;
  private empty: SwitchView;
  private current: SwitchView;

  constructor() {
  }

  @Input() set loadableData(l: Loadable<T>) {
    switch (getCondition(l)) {
      case EMPTY:
        if (this.empty) {
          this._replace(this.empty);
        }
        break;
      case LOADING:
        if (this.loading) {
          this._replace(this.loading);
        }
        break;
      case LOADED:
        if (this.loaded) {
          this._replace(this.loaded);
        }
        break;
      case ERROR:
        if (this.error) {
          this._replace(this.error, { error: l.error });
        }
        break;
    }
  }

  private _replace(newView: SwitchView, ctx?: any) {
    if (!this.current) {
      newView.create(ctx);
      this.current = newView;
    } else {
      this.current.destroy();
      this.current = newView;
      newView.create(ctx);
    }
  }

  ngOnInit() {
  }

  _addLoaded(view: SwitchView) {
    this.loaded = view;
  }

  _addLoading(view: SwitchView) {
    this.loading = view;
  }

  _addError(view: SwitchView) {
    this.error = view;
  }

  _addEmpty(view: SwitchView) {
    this.empty = view;
  }
}


@Directive({ selector: '[stateLoading]' })
export class StateLoadingDirective {
  private hasView = false;
  @Input() stateLoading: any;
  constructor(
    public templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Inject(LoadableDataDirective) loadableData: LoadableDataDirective<any>
  ) {
    loadableData._addLoading(new SwitchView(viewContainer, templateRef));
  }
}


@Directive({ selector: '[stateLoaded]' })
export class StateLoadedDirective {
  private hasView = false;

  constructor(
    public templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Inject(LoadableDataDirective) loadableData: LoadableDataDirective<any>
  ) {
    loadableData._addLoaded(new SwitchView(viewContainer, templateRef));
  }
}

@Directive({ selector: '[stateError]' })
export class StateErrorDirective {
  private hasView = false;

  constructor(
    public templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Inject(LoadableDataDirective) loadableData: LoadableDataDirective<any>) {
    loadableData._addError(new SwitchView(viewContainer, templateRef));
  }

}

@Directive({ selector: '[stateEmpty]' })
export class StateEmptyDirective {
  private hasView = false;

  constructor(
    public templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Inject(LoadableDataDirective) loadableData: LoadableDataDirective<any>) {
    loadableData._addEmpty(new SwitchView(viewContainer, templateRef));
  }

}
