
import { map, filter } from 'rxjs/operators';
import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonDropdownComponent } from './../../../template/shared/components/dropdowns/button-dropdown/button-dropdown.component';
import { StorageService } from './../../../template/shared/proto/storage/storage.service';
import { LIST, ListsDataService } from "../../../services/lists/lists.data.service";
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { VALUE_PROVIDER } from '../../../template/shared/proto/proto';
import { ValueProvider } from '../../../template/shared/proto/common/ValueProvider';

@Component({
  selector: 'dpa-groups-list',
  // template: `
  //       <button-dropdown
  //           [options]="groups | async"
  //           [map]="suggestionMapper"
  //           [itemClass]="'btn-default btn-sm'"
  //           (itemClick) ="_groupSelected($event)">
  //       </button-dropdown>
  //   `,
  template: `
          <div class="btn-group" dropdown>
            <button dropdownToggle type="button" class="btn cursor btn-sm btn-default dropdown-toggle" mat-ripple>
            Grupės <span class="caret"></span>
            </button>
            <!-- <div *dropdownMenu class="dropdown-menu dropdown-menu-left top-align-fix z-index-fix" role="menu"
              (click)="$event.stopPropagation()">
              <div *ngFor="let group of groups | async">
                <ng-container>
                  <mat-checkbox class="column-btn-check cursor"
                    (change)="dpaGroupChange(group.name, $event.checked)"><span
                      class="column-btn-text">{{group.name}}</span></mat-checkbox>
                </ng-container>
              </div>
            </div>
          </div> -->
          <ul *dropdownMenu class="dropdown-menu" role="menu" [ngStyle]="getDropdownStyles()" [ngClass]="menuClass">
            <li role="menuitem" *ngFor="let group of groups" (click)="$event.stopPropagation()">
              <div class="flex">
              <mat-checkbox class="column-btn-check cursor" [(ngModel)]="group.checked"
                            (change)="dpaGroupsChange(group, $event.checked)"><span
                              class="column-btn-text">{{group.name}}</span></mat-checkbox>
              </div>
            </li>
          </ul>
        <!-- <proto-flags-group #flagsDPAGrp [storable]="ctm.id + '.DPAgroups'" class="no-btn-group-margins"
            [storableDefault]="ctm.DPAgroups.flagsGroup" #DPAgroupsStrbl="storable" [flagsGroup]="DPAgroupsStrbl.value"
            (flagsStateChanged)="DPAgroupsStrbl.store(flagsDPAGrp.currentState)" [queryPart]="'groupList'"
            [flagsInput]="groups" [buttonTitle]="'Grupės'">
          </proto-flags-group> -->
    `,
  styleUrls: ['./dpa-groups.component.scss'],
  providers: [
    { provide: VALUE_PROVIDER, useExisting: forwardRef(() => DPAGroupsListComponent) }
  ]
})
export class DPAGroupsListComponent implements OnInit, ValueProvider<String>, OnDestroy {
  @Input() placeholder = 'Įveskite partnerio pavadinimą';
  @Input() ctm;
  @Output() groupSelected: Subject<any> = new Subject();
  suggestionMapper = (p) => p.name;
  protected groups: Array<any> = [];
  private _chgListener: (v: any) => void;
  private _currentGroupIds;
  private _subscriptions = new Array<Subscription>();
  @Input() storageKey;
  @ViewChild(ButtonDropdownComponent) button: ButtonDropdownComponent<any>;
  constructor(private dataService: ListsDataService,
    private _storageService: StorageService,
    private _chg: ChangeDetectorRef) {

  }

  limitDropdownHeight = '500px';

  ngOnInit() {
    const stored = this._storageService.get(this.storageKey, null);
    this._subscriptions.push(this.dataService.loadList(LIST.GROUPS).subscribe((g) => {
      if (stored != null) {
        //filter out group "All groups"
        this.groups = g.map(el => { return { ...el, checked: stored.indexOf(el.groupId) !== -1 } }).filter(el => el.groupId !== -1);
        this._currentGroupIds = this.groups.filter(el => el.checked).map(el => { return el.groupId });
      } else {
        if (g.length > 0) {
          this.groups = g.map((el, index) => { return { ...el, checked: index === 0 } }).filter(el => el.groupId !== -1);
        }
        this._currentGroupIds = this.groups.filter(el => el.checked).map(el => { return el.groupId });
      }
      this.groupSelected.next(this._currentGroupIds?.join(','));
    }));
  }

  dpaGroupsChange(group, checked) {
    setTimeout(() => {
      if (group.groupId === 0 && checked) {
        this.groups.forEach(el => {
          if (el.groupId !== 0) el.checked = false;
        })
      } else if (group.groupId !== 0 && checked) {
        this.groups.forEach(el => {
          if (el.groupId === 0) el.checked = false;
        })
      }
      if (!this.groups.find(el => el.checked)) {
        this.groups.find(el => el.groupId === 0).checked = true;
      }
      if (this._chg) {
        this._chg.detectChanges();
      }
      this._currentGroupIds = this.groups.filter(el => el.checked).map(el => { return el.groupId });
      this._storageService.store(this.storageKey, this._currentGroupIds);
      if (this._chgListener) {
        this._chgListener(this._currentGroupIds);
      }
      // console.log('cgi2', this._currentGroupIds?.join(','));
      this.groupSelected.next(this._currentGroupIds?.join(','));
    }, 0);
  }

  getValueChanges() {
    return this.groupSelected.pipe(filter((g) => g != null));
  }

  getDropdownStyles() {
    if (this.limitDropdownHeight) {
      return { 'overflow': 'auto', 'max-height': this.limitDropdownHeight };
    } else {
      return null;
    }

  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}
