import { COMPANY_ID } from "../app.constants";

export const FINISHED = 2;
export const COMMENTED = 3;
export const WATCHER = 4;
export const PARTICIPANT = 5;
export const STATE_CHANGED = 6;
export const DISQUALIFIED = 7;
export const TRANSFERRED = 8;
export const EXECUTOR = 11;
export const JOB_ENTERED = 12;

const MAX_COMMENT_LENGTH = 170;

export class ActivityLogEntry {
  public readonly actionType: number;
  public readonly type: number;
  public readonly comment: string;
  public readonly product: string;
  public readonly taskId: string;
  public readonly requestId: string;
  public readonly time: Date;
  public readonly before: number;
  public readonly after: number;
  public readonly partner: string;
  public readonly isInternal: boolean;
  public readonly isRequest: boolean;

  public constructor(data) {
    this.taskId = data.taskId;
    this.requestId = data.requestId;
    this.actionType = parseInt(data.actionType);
    this.type = data.type;
    if (data.comment && data.comment.length > MAX_COMMENT_LENGTH) {
      this.comment = data.comment.substring(0, MAX_COMMENT_LENGTH - 3) + '...';
    } else {
      this.comment = data.comment;
    }
    this.product = data.product;
    this.time = new Date(data.date);
    this.before = data.before;
    this.after = data.after;
    this.partner = data.partner;
    this.isInternal = data.partnerId == COMPANY_ID;
    this.isRequest = data.isRequest == 1;
  }
}
