import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

export const BEHAVIOR = 'behavior';
export const DEFAULT = 'd';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private bSubscribtions: Map<string, BehaviorSubject<Object>>;
  private subscribtions: Map<string, Subject<Object>>;
  constructor() {
    this.subscribtions = new Map();
    this.bSubscribtions = new Map();
  }

  public notify(event, data = null, createIfNotExists = false, t = BEHAVIOR) {
    if (this.subscribtions.has(event)) {
      const s = this.subscribtions.get(event);
      s.next(data);
      return s;
    }
    if (this.bSubscribtions.has(event)) {
      this.bSubscribtions.get(event).next(data);
    } else if (createIfNotExists) {
      if (t == BEHAVIOR) {
        let bSubj = new BehaviorSubject(data);
        this.bSubscribtions.set(event, bSubj);
      } else {
        let subj = new Subject();
        this.subscribtions.set(event, subj);
        //TODO: notify on first subscription
      }
    }
  }

  public subscribe(event, callback: Function, t = DEFAULT) {
    let sub: Map<string, Subject<Object>>;
    if (t == DEFAULT) {
      sub = this.subscribtions;
    } else if (t == BEHAVIOR) {
      sub = this.bSubscribtions;
    } else {
      throw "Unknown stream type! -- " + t;
    }

    if (sub.has(event)) {
      return sub.get(event).subscribe((data) => callback(data));
    } else {
      let bs = t == BEHAVIOR ? new BehaviorSubject(null) : t == DEFAULT ? new Subject() : null;
      if (!bs) {
        throw "Unknown stream type! -- " + t;
      }
      this.subscribtions.set(event, bs);
      return (<Observable<any>>bs).subscribe((data) => callback(data));
    }
  }

  public getStream(event: string, t = DEFAULT): Observable<any> {
    if (t == BEHAVIOR) {
      if (this.bSubscribtions.has(event)) {
        return this.bSubscribtions.get(event);
      } else {
        let bs = new BehaviorSubject(null);
        this.bSubscribtions.set(event, bs);
        return bs;
      }
    } else if (t == DEFAULT) {
      if (this.subscribtions.has(event)) {
        return this.subscribtions.get(event);
      } else {
        let s = new Subject();
        this.subscribtions.set(event, s);
        return s;
      }
    }
  }

  public registerStream(subject: Subject<any>) {

  }
}
