import {
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Time, TimeUnit } from "../../../../core/time/types";
import { coalesceNumber, minutesToStr, minutesToTime, parseMinutes, parseTime } from "../../../../core/time/helpers";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { replaceAt } from "../../proto/helpers";

const numToString = (num: number) => num > 9 ? ('' + num) : ('0' + num);
const strToNumbers = (str: string) => {
  const parts = str.split(':');
  if (parts.length >= 2) {
    return [parseInt(parts[0]) || 0, parseInt(parts[1]) || 0];
  } else {
    return [parseInt(parts[0]) || 0, 0];
  }
};

const parseTimeFromInput: (val: string) => Time = (val: string) => {
  if (!val || val.length === 0) {
    return { hours: 0, minutes: 0 };
  }
  if (val.indexOf(':') < 0) {
    return { hours: coalesceNumber(val), minutes: 0 };
  }
  const [hours, mins] = val.split(':');
  return { hours: coalesceNumber(hours), minutes: coalesceNumber(mins) };
};

export type Selection = 'h' | 'm';
export type Format = '24' | '12' | 'u';

const MINS_IN_DAY = 60 * 24;
const MINS_IN_12_HOURS = 60 * 12;

@Directive({
  selector: 'input:[type=time-bar]',
  exportAs: 'timeInput',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimeInputDirective), multi: true },
  ]
})
export class TimeInputDirective implements OnInit, DoCheck, OnChanges {
  @Input() format: Format = '24';

  private _time: Time;
  @Input() set time(val: any) {
    this._time = parseTime(val);
  }

  @Input() mMin: TimeUnit | Time;
  @Input() mMax: TimeUnit | Time;

  @Output() timeChanged: EventEmitter<Time> = new EventEmitter();

  private _currentMins = 0;
  get currentMins(): number {
    return this._currentMins;
  }

  private _first = true;
  set currentMins(n: number) {
    const oldMins = this._currentMins;
    switch (this.format) {
      case '24':
        if (n > MINS_IN_DAY) {
          this._currentMins = n % MINS_IN_DAY;
        } else {
          if (n < 0) {
            this._currentMins = Math.abs((MINS_IN_DAY + n) % MINS_IN_DAY);
          } else {
            this._currentMins = n;
          }
        }
        break;
      case '12':
        if (n > MINS_IN_12_HOURS) {
          this._currentMins = n % MINS_IN_12_HOURS;
        } else {
          if (n < 0) {
            this._currentMins = Math.abs((MINS_IN_12_HOURS + n) % MINS_IN_12_HOURS);
          } else {
            this._currentMins = n;
          }
        }
        break;
      case 'u':
        this._currentMins = Math.max(n, 0);
        break;
    }
    if (this.mMin) {
      const mMins = parseMinutes(this.mMin);
      if (this._currentMins < mMins) {
        this._currentMins = mMins;
      }
    }
    if (this.mMax) {
      const mMins = parseMinutes(this.mMax);
      if (this._currentMins > mMins) {
        this._currentMins = mMins;
      }
    }
    if (oldMins !== this._currentMins && !this._first) {
      this.timeChanged.emit(parseTime(this._currentMins));
    }
    this._first = false;
  }

  get currentMinsAsTime(): { hours: number, minutes: number } {
    return <{ hours: number, minutes: number }>minutesToTime(this.currentMins);
  }

  get currentAsStr(): string {
    return minutesToStr(this.currentMins);
  }

  get element(): HTMLInputElement {
    return this._elementRef.nativeElement;
  }

  get inputVal(): string {
    const el = this.element;
    if (el) {
      return el.value;
    }
    return '00:00';
  }

  set inputVal(val: string) {
    const el = this.element;
    if (el) {
      const v = el.value;
      if (v !== val) {
        el.value = val;
      }
    }
  }

  set value(val: any) {
  }

  get value(): any {
    return 0;
  }

  private _inputIndex = 0;

  private _currentSelection: Selection = 'h';
  set currentSelection(v: 'h' | 'm') {
    this._currentSelection = v;
  }

  get currentSelection(): 'h' | 'm' {
    return this._currentSelection;
  }

  private _mousePressed = false;

  constructor(private _elementRef: ElementRef, private _zone: NgZone) {
  }

  ngOnInit(): void {
    const el = this.element;
    el.addEventListener('input', (ie: Event) => {
      this._setTime(ie);
    }, false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const t = changes['time'];
    if (t) {
      this.currentMins = parseMinutes(t.currentValue);
      this.inputVal = this.currentAsStr;
    }
  }

  ngDoCheck(): void {
  }

  @HostListener('mousedown', ['$event'])
  private _mousedown(e: Event) {
    this._mousePressed = true;
  }

  @HostListener('mouseup', ['$event'])
  private _mouseup(e: Event) {
    this._mousePressed = false;
    this._markByPos(<HTMLInputElement>e.target);
  }

  @HostListener('focus', ['$event'])
  private _markSelection(e: Event) {
    if (!this._mousePressed) {
      this._markByPos(<HTMLInputElement>e.target);
    }
  }

  private _markByPos(input: HTMLInputElement) {
    const pos = input.selectionStart;
    const _divPos = input.value.indexOf(':');
    if (_divPos >= pos) {
      this._mark('h', input);
    } else {
      this._mark('m', input);
    }
    this._inputIndex = 0;
  }

  private _mark(s: Selection, el: HTMLInputElement) {
    const divPos = el.value.indexOf(':');
    if (s === 'h') {
      el.selectionStart = 0;
      el.selectionEnd = divPos;
    } else if (s === 'm') {
      el.selectionStart = divPos + 1;
      el.selectionEnd = el.value.length;
    }
    this.currentSelection = s;
  }

  private _toggleSelection(el: HTMLInputElement) {
    const cs = this.currentSelection;
    if (cs === 'm') {
      this._mark('h', el);
    } else {
      this._mark('m', el);
    }
  }

  @HostListener('keydown', ['$event'])
  private _keydown(event: KeyboardEvent) {
    if (event.key == "ArrowRight" || event.key == "ArrowLeft") {
      this._toggleSelection(<HTMLInputElement>event.target);
      event.preventDefault();
    } else if (["ArrowUp", "ArrowDown"].indexOf(event.key) > -1) {
      const currMins = this.currentMins;
      const currSelection = this.currentSelection;
      if (currSelection === 'h') {
        if (event.key === "ArrowUp") {
          this.currentMins = currMins + 60;
        } else {
          this.currentMins = currMins - 60;
        }
      } else {
        if (event.key === "ArrowUp") {
          this.currentMins = currMins + 1;
        } else {
          this.currentMins = currMins - 1;
        }
      }
      (<HTMLInputElement>event.target).value = this.currentAsStr;
      this._mark(this.currentSelection, <HTMLInputElement>event.target);
      event.preventDefault();
    }
  }

  private _setTime(val: Event) {
    const text = (<HTMLInputElement>val.target).value;
    let { hours, minutes } = <{ hours: number, minutes: number }>parseTimeFromInput(text);
    const ot = this.currentMinsAsTime;
    let hoursOld = ot.hours;
    let minsOld = ot.minutes;
    let i = this._inputIndex;
    let mark = this.currentSelection;
    switch (this.format) {
      case '24':
        if (mark === 'h') {
          if (hours <= 2 && i == 0) {
            hours *= 10;
            i = 1;
          } else if (hours > 2 && i == 0) {
            mark = 'm';
          } else if (i == 1) {
            hours = Math.floor(hoursOld / 10) * 10 + hours;
            mark = 'm';
            i = 0;
          }
        } else {
          if (minutes < 6 && i == 0) {
            minutes *= 10;
            i = 1;
          } else if (i == 1) {
            minutes = Math.floor(minsOld / 10) * 10 + minutes;
            i = 0;
          }
          mark = 'm';
        }
        break;
      case '12':
        break;
      case 'u':
        const maxLength = this.mMax ? (((<any>this.mMax).hours || this.mMax) + '').length : 5;

        if (mark === 'h') {
          let d = parseInt(val['data']);
          d = isNaN(d) ? 0 : d;
          let hrs = hoursOld + '';
          hrs = replaceAt(hrs, d + '', i);
          hours = parseInt(hrs);
          i++;
          if (i >= maxLength) {
            i = 0;
          }
        } else {
          if (minutes < 6 && i == 0) {
            minutes *= 10;
            i = 1;
          } else if (i == 1) {
            minutes = Math.floor(minsOld / 10) * 10 + minutes;
            i = 0;
          }
          mark = 'm';
        }
      default:
        break;
    }
    const mins = parseMinutes({ hours: hours, minutes: minutes });
    this.currentMins = mins;
    this._inputIndex = i;
    const newText = this.currentAsStr;
    this.inputVal = newText;
    this._mark(mark, (<HTMLInputElement>val.target));
  }
}
