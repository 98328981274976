
import { switchMap, pluck } from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { JobsBackend } from "../../../../backend/jobs/jobs.backend";
import { StateService } from '../../../../services/state/stateService';
import { JOB_UPDATED } from '../../../../state/state';
import { TaskboardSidebarService } from '../../../services/taskboardSidebar.service';
import { JobFormModel } from "../../../models/job-form";
import { JobModel } from "../../../models/job-model";
import { EntityView } from "../../../../core/api/response/entity-view";
import { AsyncPipe } from '@angular/common';
import { TaskboardDataService } from '../../../../taskboard/services/taskboard-data.service';
import { AuthService } from '../../../../services/auth.service';
import { REQUESTS, REVIEW, TASKS } from '../../../../taskboard/taskboard.help';

@Component({
  selector: '.content_inner_wrapper',
  templateUrl: './job-review.component.html',
  styleUrls: ['./job-review.component.scss']
})
export class JobReviewComponent implements OnInit, OnDestroy {
  protected job: Subject<JobModel> = new BehaviorSubject(null);
  protected jobFiles: Subject<any> = new BehaviorSubject(null);
  protected jobId: Observable<any>;
  private _subscriptions: Array<Subscription> = [];
  private _job: JobModel;
  private asyncPipe: AsyncPipe;

  constructor(
    private _elementRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private jobsBackend: JobsBackend,
    private sidebarService: TaskboardSidebarService,
    private stateService: StateService,
    private taskboardDataService: TaskboardDataService,
    private cng: ChangeDetectorRef,
    private authService: AuthService
  ) {
    this.asyncPipe = new AsyncPipe(this.cng);
  }

  ngOnInit() {
    this.jobId = this.route.params.pipe(pluck('id'));
    this._subscriptions.push(this.jobId.pipe(switchMap((id) => this.jobsBackend.getJobData(id))).subscribe((res: any) => {
      if (res.success) {
        const d = res.entity;
        this.job.next(d);
        this._job = d;
        if ((<any>d).jobFiles) {
          this.jobFiles.next((<any>d).jobFiles)
        }
      }

    }));
    this._subscriptions.push(this.stateService.subscribe(JOB_UPDATED, (job: any) => {
      this.job.next(job);
      if (job && job.jobFiles) {
        this.jobFiles.next(job.jobFiles);
      }
    }));
  }

  private _editJob(job) {
    // job.description = job.longDescription;
    setTimeout(_ => {
      // if (job.executorId == this.authService.user.personId) {
      this.sidebarService.openJobForm(JobFormModel.editJob(this.taskboardDataService, job));
      // } else {
      //   this.sidebarService.openJobForm(JobFormModel.reviewJobNotOwned(this.taskboardDataService, job));
      // }
    });
  }

  _goToTask() {
    const taskCode: string = this._job.taskCode;
    let type = TASKS;
    if (taskCode.startsWith('wCu')) {
      type = REQUESTS;
    }
    this.router.navigate(['taskboard/' + type + '/' + REVIEW + '/' + this._job.taskId]);
  }

  _goToDpa(dpaId) {
    this.router.navigate(['taskboard/d/v/' + dpaId]);
  }

  dpaChanged(event) {
    const job: any = this.asyncPipe.transform(this.job);
    job.dpaId = event ? event.id : null;
    job.dpaCode = event ? event.code : null;
    this.job.next(job);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

}
