import { AfterContentChecked, AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { pluck } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import swal from 'sweetalert2';
import { EMAIL_PATTERN_REGEXP } from '../../app.constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterContentInit, OnDestroy {

  token: string;
  passwordForm: UntypedFormGroup;
  emailForm: UntypedFormGroup;
  isInserting = false;
  isCompleted = false;
  crm = false;
  isTokenOutdated = false;
  subs: Subscription[] = [];

  hide = true;
  hide2 = true;

  constructor(private _route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private userService: UserService, private router: Router, private chg: ChangeDetectorRef) { }

  ngOnInit() {
    this.userService.logOut(true);
    this._buildForm();
    this._route.params.pipe(pluck('token')).subscribe((token) => {
      this.token = token;
      this.checkTokenOutdated(this.token);
    });
    this._route.queryParams.pipe(pluck('crm')).subscribe((crm) => {
      this.crm = crm === '1' ? true : false;
    });
  }

  private _buildForm() {
    this.passwordForm = this.formBuilder.group({
      password1: [null, [Validators.required, Validators.minLength(7)]],
      password2: [null, [Validators.required, this.checkPasswordMatch.bind(this)]],
    });
    this.emailForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.pattern(EMAIL_PATTERN_REGEXP)]],
    });
  }

  checkTokenOutdated(token) {
    this.subs.push(this.userService.checkPasswordToken(token).subscribe((resp) => {
    },
      (err) => {
        if (err.status === 404) {
          swal.fire({
            title: 'Vartotojas nerastas',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          });
        } else if (err.status === 406) {
          this.isTokenOutdated = true;
        } else {
          swal.fire({
            title: 'Įvyko klaida',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          });
        }
      }
    ))
  }

  recoverPassword() {
    this.isInserting = true;
    this.userService.recoverPassword(this.emailForm.controls.email.value).subscribe((resp) => {
      this.isInserting = false;
      if (resp.status === 200) {
        this.isCompleted = true;
        swal.fire({
          title: 'Slaptažodžio atkūrimo instrukcijos išsiųstos nurodytu elektroniniu paštu',
          timer: 2000,
          showConfirmButton: false,
          icon: 'success'
        });
      }
    },
      (err) => {
        this.isInserting = false;
        if (err.status === 404) {
          swal.fire({
            title: 'Vartotojas nerastas',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          });
        } else {
          swal.fire({
            title: 'Įvyko klaida',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          });
        }
      })
  }

  checkPasswordMatch() {
    if (!this.passwordForm) {
      return null;
    }
    if (this.passwordForm.controls.password1.value === this.passwordForm.controls.password2.value) {
      return null;
    }
    return { passwordsDontMatch: true };
  }

  changePassword() {
    this.isInserting = true;
    this.subs.push(this.userService.changePassword(this.token, this.passwordForm.controls.password1.value).subscribe((resp) => {
      this.isInserting = false;
      if (resp.status === 200) {
        this.isCompleted = true;
        swal.fire({
          title: 'Slaptažodis pakeistas',
          timer: 2000,
          showConfirmButton: false,
          icon: 'success'
        });
        this.gotoLogin();
      }
    },
      (err) => {
        this.isInserting = false;
        if (err.status === 404) {
          swal.fire({
            title: 'Vartotojas nerastas',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          });
        } else if (err.status === 406) {
          swal.fire({
            title: 'Nuoroda yra negaliojanti. Kreipkitės į savo konsultantą',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          });
        } else {
          swal.fire({
            title: 'Įvyko klaida',
            timer: 2000,
            showConfirmButton: false,
            icon: 'error'
          });
        }
      }
    ))
  }

  ngAfterContentInit() {
    this.userService.triggerMenuCheck$.next(null);
  }

  gotoLogin() {
    if (!this.crm) {
      this.router.navigateByUrl('/login');
    } else {
      window.location.href = 'https://crm.prototechnika.lt';
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

}
