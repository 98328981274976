import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'confirmation-list',
  templateUrl: './confirmation-list.component.html',
  styleUrls: ['./confirmation-list.component.scss']
})
export class ConfirmationListComponent implements OnInit {

  _usersConfirmationList: Subject<any> = new Subject();
  _rights: Subject<any> = new Subject();

  constructor() { }

  ngOnInit() {

  }

  confirmUser() {
  }

  rejectUser() {
  }

}
