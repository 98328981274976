import { getInjector } from "../../template/shared/proto/proto";
import { FilesBackend } from "../../backend/files.backend";

const IMAGE_TYPES = ['jpg', 'png', 'jpeg', 'gif', 'bmp'];

export const getFilesDropdownTitle = (files: Array<any>) => files.length +
  ((files.length % 10 == 0 || (files.length > 10 && files.length <= 20))
    ? ' failų'
    : (files.length % 10 == 1)
      ? ' failas'
      : (files.length <= 10 || (files.length % 10 >= 2 && files.length % 10 <= 9))
        ? ' failai'
        : '');
export const mapFileForView = (file) => file.fileName;
//TODO: sutvarkyti sita, kad failas turetu apibrezta tipa
export const getFileType = (file) => (file.name || file.fileName).split('.').pop().toLowerCase();
export const isImage = (file) => IMAGE_TYPES.includes(getFileType(file));

export function downloadFile(file) {
  const injector = getInjector();
  if (!injector) {
    throw new Error("Error downloading file: Can't get FilesBackend without injector.");
  }
  injector.get(FilesBackend).downloadFile(file);
}
