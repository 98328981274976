import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TaskboardComponent } from './taskboard.component';
import { SharedModule } from '../template/shared/shared.module';
import { TaskBoardMenuComponent } from "./taskBoardMenu/taskBoardMenu.component";
import { JobReviewComponent } from "./components/jobs/job-review/job-review.component";
import { ParticipantsTableComponent } from "./components/participants-table/participants-table.component";
import { CommentsComponent } from "./components/comments/comments.component";
// import { CommentsListComponent } from "./components/comments/comments-list/comments-list.component";
import { TaskboardSidebarComponent } from "./taskboard-sidebar/taskboardSidebar.component";
import { TransferFormComponent } from "./components/modals/transfer-form/transfer-form.component";
import { JobsListComponent } from "./components/jobs/jobsList/jobsList.component";
import { PTableRowDirective } from "./components/participants-table/pTableRow.directive";
import { UpdateRequestStateComponent } from "./components/modals/update-request-state/updateRequestState.component";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { PortalModule } from "@angular/cdk/portal"
import { CustomDateAdapter } from "../utils/dateAdapter";
import { GroupsListComponent } from "../components/dropLists/groups-list/groupsList.component";
import { DPAGroupsListComponent } from "../components/dropLists/dpa-groups/dpa-groups.component";
import { PartnerDebtsComponent } from './components/tabs/partner-debts/partnerDebts.component';
import { LicencesListComponent } from './components/tabs/licences-list/licencesList.component';
import { SalesListComponent } from './components/tabs/sales-list/salesList.component';
import { MaintenanceListComponent } from './components/tabs/maintenance-list/maintenanceList.component';
import { tableTypeProvider, TASKBOARD_ROUTES } from './taskboard.routing.module';
import { TaskboardListComponent } from './taskBoardList/taskBoardList.component';
import { TaskboardDataProvider } from './data/taskboardData.provider';
import { DpaControlsComponent } from './components/jobs/dpaControls/dpaControls.component';
import { NearestActionFormComponent } from './components/modals/nearest-action-component/nearestActionForm.component';
import { PartnerInfoComponent } from '../components/partner-info/partnerInfo.component';
import { TaskReviewComponent } from './components/tasks/task-review/task-review.component';
import { NewTaskComponent } from './components/tasks/new-task/new-task.component';
import { NewRequestComponent } from './components/requests/new-request/new-request.component';
import { RequestReviewComponent } from './components/requests/request-review/request-review.component';
import { DpaEditorComponent } from "./components/dpa/dpaEditor/dpaEditor.component";
import { DpaJobsComponent } from "./components/dpa/dpaEditor/dpaJobs/dpaJobs.component";
import { ListModalComponent } from "../template/shared/components/modals/listModal/listModal.component";
import { PartnerProductsComponent } from "./components/tabs/partnerProducts/partnerProducts.component";
import { InfoLoaderComponent } from "../template/shared/components/info-loader/info-loader.component";
import { ImageModalComponent } from "../template/shared/components/modals/image-modal/image-modal.component";
// import { FilesDropdownComponent } from "../components/files/files-dropdown/filesDropdown.component";
import { JobFormComponent } from './components/jobs/job-form/job-form.component';
import { UpdateTaskPriorityComponent } from './components/modals/update-task-priority/update-task-priority.component';
import { CreateUserComponent } from './components/modals/create-user/create-user.component';
import { TplService } from "./services/tpl.service";
import { ModalsService } from "./services/modals.service";
import { TaskboardTemplatesComponent } from './components/templates/taskboard-templates.component';
import { TaskboardDataService } from "./services/taskboard-data.service";
import { TaskTypesComponent } from "../components/dropLists/task-types/task-types.component";
import { UpdateRequestTypeComponent } from './components/modals/update-request-type/update-request-type.component';
import { ChildTasksComponent } from './components/child-tasks/child-tasks.component';
import { TreeModule } from "angular-tree-component";
import { TimersListComponent } from './components/timers-list/timers-list.component';
import { DistributeTaskComponent } from './components/modals/distribute-task/distribute-task.component';
import { ActionModalComponent } from './components/actions-modal/action-modal.component';
import { UpdateTaskDeadlineComponent } from './components/modals/update-task-deadline/update-task-deadline.component';
import { UpdateTaskServiceComponent } from './components/modals/update-service-component/update-task-service.component';
import { PartnerEditorComponent } from './components/partner-editor/partner-editor.component';
import { UpdateRequestProductComponent } from './components/modals/update-request-product/update-request-product-modal.component';
import { AllTimersListComponent } from "./components/all-timers-list/all-timers-list.component";
import { DpaListComponent } from "./components/requests/dpa-list/dpa-list.component";
import { UpdateProjectStageComponent } from "./components/modals/update-project-stage/update-project-stage-modal.component";
import { SelectProjectModalComponent } from "./components/modals/select-project/select-project-modal.component";
import { ProjectsEstimationComponent } from "./components/tabs/projects-estimation/projects-estimation.component";
import { ProjectFieldsComponent } from "./components/project-fields/project-fields.component";
import { SelectChildTaskModalComponent } from "./components/select-child-task-modal/select-child-task-modal.component";
import { UpdateDelegateModalComponent } from "./components/modals/update-delegate-modal/update-delegate-modal.component";
import { DpaTypesComponent } from "./../components/dropLists/dpa-types/dpa-types.component";
import { FileListComponent } from "./components/files-list/file-list.component";
import { QuillModule } from 'ngx-quill';
import { EditTaskDescriptionComponent } from "./components/modals/edit-task-description/edit-task-description.component";
import { MarketingFilterListComponent } from "../components/dropLists/marketing-filter/marketing-filter.component";
import { UpdateTaskPartnerComponent } from "./components/modals/update-task-partner/update-task-partner.component";
import { MarketingParticipansListModalComponent } from "./components/modals/marketing-participans-list/marketing-participans-list.component";
import { SalesRelationsListComponent } from "./components/requests/sales-relations-list/sales-relations-list.component";
import { SalesFieldsModalComponent } from "./components/sales-fields-modal/sales-fields-modal.component";
import { PartnerNoteComponent } from "../components/partner-note/partnerNote.component";

@NgModule({
    declarations: [
        TaskboardComponent,
        PTableRowDirective,
        TaskBoardMenuComponent,
        TaskReviewComponent,
        JobReviewComponent,
        ParticipantsTableComponent,
        PartnerDebtsComponent,
        LicencesListComponent,
        SalesListComponent,
        MaintenanceListComponent,
        NewTaskComponent,
        TaskboardSidebarComponent,
        NewRequestComponent,
        RequestReviewComponent,
        CommentsComponent,
        // CommentsListComponent,
        TransferFormComponent,
        JobsListComponent,
        UpdateRequestStateComponent,
        TaskboardListComponent,
        GroupsListComponent,
        DPAGroupsListComponent,
        DpaControlsComponent,
        NearestActionFormComponent,
        PartnerInfoComponent,
        DpaEditorComponent,
        DpaJobsComponent,
        PartnerProductsComponent,
        // FilesDropdownComponent,
        JobFormComponent,
        UpdateTaskPriorityComponent,
        UpdateRequestProductComponent,
        CreateUserComponent,
        TaskboardTemplatesComponent,
        TaskTypesComponent,
        UpdateRequestTypeComponent,
        ChildTasksComponent,
        TimersListComponent,
        DistributeTaskComponent,
        ActionModalComponent,
        UpdateTaskDeadlineComponent,
        UpdateTaskServiceComponent,
        PartnerEditorComponent,
        AllTimersListComponent,
        DpaListComponent,
        UpdateProjectStageComponent,
        SelectProjectModalComponent,
        ProjectsEstimationComponent,
        ProjectFieldsComponent,
        SelectChildTaskModalComponent,
        UpdateDelegateModalComponent,
        DpaTypesComponent,
        FileListComponent,
        EditTaskDescriptionComponent,
        MarketingFilterListComponent,
        UpdateTaskPartnerComponent,
        MarketingParticipansListModalComponent,
        SalesRelationsListComponent,
        SalesFieldsModalComponent,
        PartnerNoteComponent,
    ],
    imports: [
        SharedModule,
        PortalModule,
        TreeModule.forRoot(),
        QuillModule
    ],
    exports: [
        TaskboardComponent,
        PTableRowDirective,
        TaskBoardMenuComponent,
        TaskReviewComponent,
        JobReviewComponent,
        ParticipantsTableComponent,
        PartnerDebtsComponent,
        LicencesListComponent,
        SalesListComponent,
        MaintenanceListComponent,
        NewTaskComponent,
        TaskboardSidebarComponent,
        NewRequestComponent,
        RequestReviewComponent,
        CommentsComponent,
        // CommentsListComponent,
        TransferFormComponent,
        JobsListComponent,
        UpdateRequestStateComponent,
        TaskboardListComponent,
        GroupsListComponent,
        DPAGroupsListComponent,
        DpaControlsComponent,
        NearestActionFormComponent,
        PartnerInfoComponent,
        DpaEditorComponent,
        DpaJobsComponent,
        PartnerProductsComponent,
        // FilesDropdownComponent,
        JobFormComponent,
        UpdateTaskPriorityComponent,
        UpdateRequestProductComponent,
        CreateUserComponent,
        TaskboardTemplatesComponent,
        TaskTypesComponent,
        UpdateRequestTypeComponent,
        ChildTasksComponent,
        TimersListComponent,
        DistributeTaskComponent,
        ActionModalComponent,
        UpdateTaskDeadlineComponent,
        UpdateTaskServiceComponent,
        PartnerEditorComponent,
        AllTimersListComponent,
        DpaListComponent,
        UpdateProjectStageComponent,
        SelectProjectModalComponent,
        ProjectsEstimationComponent,
        ProjectFieldsComponent,
        SelectChildTaskModalComponent,
        UpdateDelegateModalComponent,
        DpaTypesComponent,
        FileListComponent,
        QuillModule,
        EditTaskDescriptionComponent,
        MarketingFilterListComponent,
        UpdateTaskPartnerComponent,
        MarketingParticipansListModalComponent,
        SalesRelationsListComponent,
        SalesFieldsModalComponent,
        PartnerNoteComponent,
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS, useValue: {
                parse: {
                    dateInput: 'yyyy-MM-dd'
                },
                display: {
                    dateInput: 'yyyy-MM-dd',
                    monthYearLabel: 'y-m',
                    dateA11yLabel: 'y mm - dd',
                    monthYearA11yLabel: 'mm'
                }
            }
        },
        tableTypeProvider,
        // { provide: DateAdapter, useClass: CustomDateAdapter },
        TaskboardDataProvider,
        // TplService,
        ModalsService,
        // TaskboardDataService
    ]
})
export class TaskboardSharedModule {
}
