
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../core/api/api.service';
import { Observable } from 'rxjs';
import { PartnersBackend } from './partners.backend';
import { TasksBackend } from './tasks.backend';
import { getStateBitMask } from '../core/helpers';

@Injectable()
export class SalesBackend {
  constructor(private _api: ApiService, private partnersBackend: PartnersBackend, private tasksBackend: TasksBackend) {
  }

  savedParams: any;

  getSalesList(params): Observable<any[]> {
    const pars: any = {};
    pars.dateTo = params.to;
    pars.dateFrom = params.from;
    pars.startFrom = params.startFrom;
    pars.pageLength = params.pageLength;
    pars.orderBy = params.orderBy;
    pars.orderDir = params.orderDir;
    params.search ? pars.filter = params.search : null;
    params.userId ? pars.userId = params.userId : null;
    pars.statesBitmask = getStateBitMask(params);
    pars.clientTypeStr = params.clientTypes;
    pars.saleTypeStr = params.saleTypes;
    pars.clientNeedStr = params.clinetNeeds;
    pars.executorStr = params.executors;
    pars.productStr = params.products;
    pars.haveWork = 1;
    this.savedParams = pars;
    return <any>this._api.getAuth('tasks/sales', pars).pipe(map((res: any) => res.body));
  }

  getSalesForExport(): Observable<any[]> {
    return <any>this._api.getAuth('tasks/sales', { ...this.savedParams, startFrom: 0, pageLength: 999999 }).pipe(map((res: any) => res.body));
  }

  getSaleTypes() {
    // ECRM_SALE_TYPE
    return <any>this._api.getAuth(`projects/types/4`).pipe(map((res: any) => res.body.entities));
  }

  getSaleClientsNeeds() {
    // ECRM_CLIENT_REQ
    return <any>this._api.getAuth(`projects/types/3`).pipe(map((res: any) => res.body.entities));
  }

  getSalesRelations(taskId) {
    return <any>this._api.getAuth(`tasks/${taskId}/sales/relations`).pipe(map((res: any) => res.body.entities));
  }

}