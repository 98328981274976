import { DPA, DPAAPT, FOR_ME, FOR_MY_GROUP, GROUPDPA, JOBS, REQUESTS, TASKS, TRANSFERED, TYPE } from "../taskboard.help";
import { TableModelProvider } from "../../template/shared/proto/dataTable/serverTable/TableModelProvider";
import { TableModel } from "../../template/shared/proto/dataTable/serverTable/TableModel";
import { Injectable } from "@angular/core";


export interface Params {
  type: string,
  mode?: string,
  p?: string,
  u?: string
}

const mapToRes = (res: Array<any>) => ({ count: res.length > 0 && res[0].count ? res[0].count : 0, data: res });

@Injectable()
export class TaskboardDataProvider implements TableModelProvider {
  constructor() {

  }

  public setCurrentModel(m: any) {
    if (!m || !m[TYPE]) {
      throw "Empty model options not allowed";
    }
  }

  public getCurrentTableId(m: Params): string {
    if (!m || !m.type) {
      throw "Empty model options not allowed";
    }
    switch (m.type) {
      case TASKS:
        if (m.p) {
          return "partnerTasks";
        }
        if (m.u) {
          return "partnerTasks";
        }
        if (!m.mode && !m.p) {
          return "myTasks";
        }

        switch (m.mode) {
          case FOR_ME: return "tasksForMe";
          case FOR_MY_GROUP: return "tasksForMyGroup";
          case TRANSFERED: return "transferedTasks";
        }
      case REQUESTS:
        if (m.p) {
          return "partnerRequests";
        }
        if (!m.mode && !m.p) {
          return "requestsForMe";
        } else if (m.mode) {
          switch (m.mode) {
            case FOR_ME: return "requestsForMe";
            case FOR_MY_GROUP: return "requestsForMyGroup";
            case TRANSFERED: return "transferedRequests";
          }
        }
      case JOBS:
        return "myJobs";
      case DPA:
        return "myDpa";
      case DPAAPT:
        return "myDpaApt";
      case GROUPDPA:
        return "myGroupDpa";
    }
    return "";
  }

  public getTableModel(tId: string): TableModel {
    return null;
  }
}
