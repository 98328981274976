export const thisMonth = () => { let d = new Date(); d.setDate(1); d.setHours(0, 0); return d; };
export const toDbString = (d: Date) => {
  if (d && d instanceof Date) {
    const localeStr = d.toLocaleString();
    const [date] = localeStr.split(',');
    const [month, day, year] = date.split('/');
    let str = '';
    if (year) {
      str += year + '-';
    }
    if (month) {
      str += month;
    }
    if (day) {
      str += '-' + day;
    }
    return str;
  }
  throw new Error('Unknown parameter passed to toDbString: ' + d);
};
