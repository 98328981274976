import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../core/api/api.service';
import swal from 'sweetalert2';
import { UserService } from '../../services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-initial-password',
  templateUrl: './change-initial-password.component.html',
  styleUrls: ['./change-initial-password.component.scss']
})
export class ChangeInitialPasswordComponent implements OnInit, OnDestroy {

  ticket: string;
  ticketId: number;
  newPassword: string;
  newPasswordRepeat: string;
  _subscriptions: Subscription[] = [];

  constructor(private authService: AuthService, private apiService: ApiService, private userService: UserService, private router: Router, private _route: ActivatedRoute) { }

  ngOnInit() {
    this._subscriptions.push(this._route.queryParams.subscribe(params => {
      this.ticket = params['ticket'];
      this.ticketId = params['ticketId'] && parseInt(params['ticketId']);
    }));
  }

  disableSubmit() {
    return !this.newPassword || this.newPassword.length < 7 || this.newPassword !== this.newPasswordRepeat;
  }

  save() {
    this.userService.changeInitialPassword(this.ticket, this.ticketId, this.newPassword).subscribe((res) => {
      swal.fire({
        title: 'Slaptažodis pakeistas sėkmingai',
        allowOutsideClick: false,
        timer: 2000,
        icon: 'success',
        showConfirmButton: false
      }).then(() => {
        this.router.navigateByUrl('/login');
      });
    },
      (err) => {
        swal.fire({
          title: 'Įvyko klaida',
          timer: 2000,
          showConfirmButton: false,
          icon: 'error'
        });
      }
    )
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subs) => {
      subs.unsubscribe();
    });
  }

}
