import { Directive, HostListener, TemplateRef, ViewContainerRef } from '@angular/core';
import { TextBoxComponent } from "./text-box.component";

@Directive({
  selector: '[tbSubmit]'
})
export class TbSubmitDirective {

  constructor(public _viewCntRef: ViewContainerRef, public _templateRef: TemplateRef<any>, public _textBox: TextBoxComponent) {
    if (_textBox) {
      _textBox.addSubmitDirective(this);
    }
  }

  @HostListener('click')
  protected _click() {
  }
}
