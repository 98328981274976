import { Pipe, PipeTransform } from '@angular/core';

const ASCIIFolder = require('./../../../../../node_modules/fold-to-ascii/lib/ascii-folder');

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  // transform(items: any[], term: string, fieldName: string): any {
  //   return items.filter(item => ASCIIFolder.foldReplacing(item[fieldName]).toLowerCase().indexOf(ASCIIFolder.foldReplacing(term).toLowerCase()) !== -1);
  // }

  transform(items: any[] = [], term: string, fieldName: string | string[]): any {
    if (!term || term.trim() == '') {
      return items;
    }
    if (typeof fieldName === 'string') {
      return items.filter(item => item[fieldName] && ASCIIFolder.foldReplacing(item[fieldName].toString()).toLowerCase().indexOf(ASCIIFolder.foldReplacing(term).toLowerCase()) !== -1);
    } else if (Array.isArray(fieldName)) {
      return items.filter(item => {
        let itemFound: Boolean;
        for (let i = 0; i < fieldName.length; i++) {
          if (item[fieldName[i]] && ASCIIFolder.foldReplacing(item[fieldName[i]].toString()).toLowerCase().indexOf(ASCIIFolder.foldReplacing(term).toLowerCase()) !== -1) {
            itemFound = true;
            break;
          }
        }
        return itemFound;
      });
    }
  }

}