
import { filter, map, switchMap } from 'rxjs/operators';
import { ReplaySubject, Subject, Observable, of, Subscription } from 'rxjs';
import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { StateService } from "../../../../services/state/stateService";
import { PartnersBackend } from '../../../../backend/partners.backend';
import { TaskboardSidebarService } from '../../../../taskboard/services/taskboardSidebar.service';
import { compareToToday, notNull } from '../../../../template/shared/proto/helpers';
import { TaskReviewBase } from '../../../common/taskReviewBase';
import { TasksBackend } from '../../../../backend/tasks.backend';
import { Task } from '../../../../models/Task';
import { TaskboardDataService } from "../../../services/taskboard-data.service";
import { TimerService } from "../../../../template/shared/services/timer/timer-service";
import { UserService } from "../../../../services/user/user.service";
import { AuthService } from '../../../../services/auth.service';
import { T_EXECUTOR, T_OWNER } from './../../../../app.constants';
import { ProjectsBackend } from '../../../../backend/projects.backend';
import { REQUESTS, REVIEW, TASKS } from '../../../../taskboard/taskboard.help';
import { TASK_STATE_COMPLETED, TASK_STATE_FINISHED } from '../../../../core/constants';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { NgxPermissionsService } from 'ngx-permissions';
import { SalesBackend } from '../../../../backend/sales.backend';
import { UPDATE_TASKBOARD_LIST } from '../../../../state/state';

const TASK_IS_NOT_REQUEST = 'TASK IS NOT REQUEST';

@Component({
  selector: '.content_inner_wrapper',
  templateUrl: './request-review.component.html',
  styleUrls: ['./request-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestReviewComponent extends TaskReviewBase implements OnInit, OnDestroy, AfterViewInit {
  //Internal state
  protected debts: Subject<Array<any>> = new ReplaySubject(1);
  protected debtsSum: Observable<number>;
  protected lateDebtsSum: Observable<number>;
  public tree;

  protected participants: any[];
  protected participantsCount: number = 0;
  protected participantsProto: any[];
  protected participantsProtoCount: number = 0;
  protected participantsAllCount: number = 0;

  visibleTimers = true;
  projectServiceTimes: any;
  projectTimesView: any;
  hasFiles: boolean = false;
  currentTabId: string;
  updateTabsOrderSubs: Subscription;
  data: any;
  text: string;
  visiblesalesRelationsList = true;
  salesRelationsList: any[] = [];

  TASK_STATE_FINISHED = TASK_STATE_FINISHED;
  TASK_STATE_COMPLETED = TASK_STATE_COMPLETED;
  @ViewChild('tabSet') tabset: TabsetComponent;

  jobsFiltering = {
    flagsGroup: '11',
    rules: {
      '00': '11'
    },
    flags: [
      {
        flag: 'sentDpa',
        label: 'Išsiųstas DPA'
      },
      {
        flag: 'notSentDpa',
        label: 'Neišsiųstas DPA'
      }
    ]
  }

  initJobList = [];
  filteredJobList = [];
  filterJobsState: any;

  compareToToday = compareToToday;

  constructor(
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    protected state: StateService,
    private partnersService: PartnersBackend,
    protected sidebarService: TaskboardSidebarService,
    protected router: Router,
    protected tasksBackend: TasksBackend,
    protected chg: ChangeDetectorRef,
    protected taskboardDataService: TaskboardDataService,
    protected timerService: TimerService,
    protected elementRef: ElementRef,
    protected userService: UserService,
    protected authService: AuthService,
    private _chg: ChangeDetectorRef,
    private projectsBackend: ProjectsBackend,
    public permissionsService: NgxPermissionsService,
    private salesBackend: SalesBackend,
  ) {
    super(dialog,
      route,
      tasksBackend,
      state,
      sidebarService,
      router,
      chg,
      taskboardDataService,
      timerService,
      elementRef.nativeElement,
      userService,
      authService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._setupPartnerDebts();
    // this.getParticipants();
    // this.getFiles();
    this.route.queryParams.subscribe((queryParams) => {
      this.childTasksTabActive = queryParams.childTasks;
    });
    this._subscriptions.push(this.personsChanged.subscribe(() => {
      this.getParticipants();
    }));
    this._subscriptions.push(this._hasTimers$.subscribe(() => {
      this.sortTabs();
    }));
    this._subscriptions.push(this._loadedTaskObs().subscribe((t) => {
      this.getFiles();
      this.getParticipants();
      if (this.updateTabsOrderSubs) this.updateTabsOrderSubs.unsubscribe();
      this.updateTabsOrderSubs = this.task.updateTabsOrder$.subscribe(() => {
        this.getFiles();
      })
    }))
    this._data.subscribe(task => {
      this.data = task;
      this.initJobList = this.data?.data?.jobs || [];
      this.refreshfilterJobs();
    });
  }

  refreshfilterJobs() {
    this.filterJobs(this.filterJobsState);
  }

  filterJobs(state) {
    this.filterJobsState = state;
    let tmpList = this.initJobList;
    this.filterJobsState?.forEach(st => {
      if (st.flag == 'sentDpa' && !st.checked) {
        tmpList = tmpList.filter(job => !job.lastSendDate);
      }
      if (st.flag == 'notSentDpa' && !st.checked) {
        tmpList = tmpList.filter(job => job.lastSendDate);
      }
    })
    this.filteredJobList = tmpList;
  }

  textChanged(text) {
    this.text = text;
  }

  private _setupPartnerDebts() {
    const getDebtsSum = (debts: Array<any>) => debts.reduce((acc, d) => acc + d.quantity, 0.0);
    const getLateDebtsSum = (debts: Array<any>) => debts.reduce((acc, d) => d.daysAfterTerm < 0 ? acc + d.quantity : acc, 0.0);
    this._subscriptions.push(this._partnerId().pipe(switchMap((pc) => this.partnersService.getPartnerDebts(pc))).subscribe((d) => (<Subject<any>>this.debts).next(d)));
    this.debtsSum = this.debts.pipe(map((dl) => getDebtsSum(dl)));
    this.lateDebtsSum = this.debts.pipe(map((dl) => getLateDebtsSum(dl)));
  }

  private getParticipants() {
    // this._subscriptions.push(this._loadedTaskObs().subscribe((t) => {
    this._subscriptions.push(this.tasksBackend.getRequestPersonsByTask(this.task.id).subscribe((persons) => {
      this.participants = persons;
      this.participantsCount = 0;
      this.participants.forEach((participant) => {
        participant.typeId && participant.typeId !== T_OWNER && participant.typeId !== T_EXECUTOR ? this.participantsCount++ : null;
      });
      this.participantsAllCount = this.participantsCount + this.participantsProtoCount;
      try {
        this.chg.detectChanges();
      } catch (e) { }
      this.getPersons = () => { return of(this.participants) };
      this.sortTabs();
    }))
    this._subscriptions.push(this.tasksBackend.getTaskPersonsByTask(this.task.id).subscribe((persons) => {
      this.participantsProto = persons;
      this.participantsProtoCount = 0;
      this.participantsProto.forEach((participant) => {
        participant.typeId && participant.typeId !== T_OWNER && participant.typeId !== T_EXECUTOR ? this.participantsProtoCount++ : null;
      });
      this.participantsAllCount = this.participantsCount + this.participantsProtoCount;
      try {
        this.chg.detectChanges();
      } catch (e) { }
      this.getPersonsProto = () => { return of(this.participantsProto) };
    }))
    // this._subscriptions.push(t.updateTabsOrder$.subscribe(() => {
    //   this.getFiles();
    // }))
    // }));
    this._subscriptions.push(this.salesBackend.getSalesRelations(this.task.id).subscribe((resp) => {
      this.salesRelationsList = resp;
      try {
        this.chg.detectChanges();
      } catch (e) { }
    }))
  }

  ngAfterViewInit() {
    this.sortTabs();
    this._subscriptions.push(this.state.getStream(UPDATE_TASKBOARD_LIST).subscribe((fn) => {
      this.salesRelationsTabOpened();
    }));
  }

  hasPermissionToEditDeadline() {
    return this.permissionsService.hasPermission('crm_negotiator') || this.permissionsService.hasPermission('crm_manager')
  }

  tabChanged(event) {
    this.currentTabId = event.id;
  }

  sortTabs() {
    this.tabset?.tabs.sort((first: TabDirective, second: TabDirective) => { return (first.id > second.id) ? 1 : -1 });
    // this.tabset?.tabs.forEach(tab => {
    //   if (tab.id === this.currentTabId) {
    //     tab.active = true;
    //   }
    // });
    try {
      this.chg.detectChanges();
    } catch (e) { }
  }

  private getFiles() {
    // this._subscriptions.push(this._loadedTaskObs().subscribe((t) => {
    this._subscriptions.push(this.tasksBackend.getFilesList(this.task.id).subscribe((filesList) => {
      this.hasFiles = filesList.length > 0;
      try {
        this.chg.detectChanges();
      } catch (e) { }
      this.sortTabs();
    }))
    // }));
  }

  projectEstimationTabOpened(task) {
    this._subscriptions.push(this.projectsBackend.getProjectsServicesTime(task.id).subscribe((resp) => {
      this.projectServiceTimes = resp.projectsTaskServicesList;
      this.projectTimesView = resp.projectTimesView;
      try {
        this.chg.detectChanges();
      } catch (e) { }
    }))
  }

  //Helpers
  private _partnerId(): Observable<string> //PartnerID
  {
    return this._data.pipe(map((d) => d.data), filter(notNull), filter((r) => r.partnerId != null), map((r) => r.partnerId));
  }

  protected mapTask(t: string | Task): string | Task {
    if (typeof t == "string") {
      return t;
    }
    return t;
  }

  public _childTree(event) {
    this.tree = event;
  }

  gotoProject(task) {
    this.router.navigate(['/projects', task.isRequest ? REQUESTS : TASKS, REVIEW, task.id]);
  }

  refreshTask() {
    this._subscriptions.push(this._updateTask(this.task).subscribe());
  }

  timersRefresh() {
    setTimeout(() => {
      this.visibleTimers = false;
      try {
        this._chg.detectChanges();
      } catch (err) { }
      this.visibleTimers = true;
      try {
        this._chg.detectChanges();
      } catch (err) { }
    }, 0);
  }

  salesRelationsTabOpened() {
    // setTimeout(() => {
    //   this.visiblesalesRelationsList = false;
    //   try {
    //     this._chg.detectChanges();
    //   } catch (err) { }
    //   this.visiblesalesRelationsList = true;
    //   try {
    //     this._chg.detectChanges();
    //   } catch (err) { }
    // }, 0);

    this._subscriptions.push(this.salesBackend.getSalesRelations(this.task.id).subscribe((resp) => {
      this.salesRelationsList = resp;
      try {
        this.chg.detectChanges();
      } catch (e) { }
    }))
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    if (this.updateTabsOrderSubs) this.updateTabsOrderSubs.unsubscribe();
  }
}
