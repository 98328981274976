export const TYPE = 'type';
export const MODE = 'mode';

export const ID = 'id';

export const T_QUERY = 'q';

export const REVIEW = 'v';
export const NEW = 'n';

export const TASKS = 't';
export const REQUESTS = 'r';
export const DPA = 'd';
export const GROUPDPA = 'g';
export const DPAAPT = 'k';
export const JOBS = 'j';

export const BY_ME = 'a';
export const FOR_ME = 'b';
export const FOR_MY_GROUP = 'c';
export const TRANSFERED = 'd';

export const PARTNER = 'p';

export const MYPROJECTS = 'mpr';
export const ALLPROJECTS = 'apr';
export const PROJECTS = 'pr';

export const MARKETING = 'm';
export const MARKETING_CAMPAIGNS = 'mc';
export const SELECTED_MARKETING_TASKS = 'smt';
export const ALL_MARKETING_TASKS = 'amt';
export const INHERIT = 'i';
export const COMMENT_ID = 'cid';
export const NEW_MARKETING_TASK = 'nt';

export const SALES = 's';
