export const STATE_COLORS = {
  1: 'color-state-new',
  5: 'grey',
  6: 'orange',
  10: 'color-state-inprogress',
  11: 'color-state-clientcommented',
  12: 'color-state-owncommented',
  20: 'color-state-done',
  30: 'black',
  40: 'color-state-completed',
  50: 'color-state-distributed',
  60: 'black'
};

export const PRIORITY_COLORS = {
  10: 'color-priority-low',
  20: 'color-priority-medium',
  30: 'color-priority-high',
  40: 'color-priority-critical',
  60: 'color-priority-standart ',
  70: 'color-priority-critical'
};

export const PRIORITY_ABBR = {
  10: 'Ž',
  20: 'V',
  30: 'A',
  40: 'K',
  60: 'S',
  70: 'S'
};

export const PRIORITY_TOOLTIPS = {
  10: 'Žemas',
  20: 'Vidutinis',
  30: 'Aukštas',
  40: 'Kritinis',
  60: 'Standartinis reagavimas',
  70: 'Skubus iškvietimas'
};
