
import { take, map, combineLatest } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { T_DELEGATE, T_EXECUTOR, T_WATCHER } from '../../../app.constants';
import { AuthService } from '../../../services/auth.service';
import swal from 'sweetalert2';

export const NONE = 0;
export const EXECUTOR = 4;
export const WATCHER = 3;
export const DELEGATE = 2;
export const OWNER = 1;

const sortByNameFn = (a, b) => a.name > b.name ? 1 : -1;
const sortByName = (arr: Array<any>) => arr.sort(sortByNameFn);

@Component({
  selector: 'participants-table',
  templateUrl: './participants-table.component.html',
  styleUrls: ['./participants-table.component.scss']
})
export class ParticipantsTableComponent implements OnInit, AfterViewInit, OnDestroy {

  protected EXECUTOR = T_EXECUTOR;
  protected WATCHER = T_WATCHER;
  protected DELEGATE = T_DELEGATE;

  _visiblePersons: Observable<any>;
  private _personsSubj: BehaviorSubject<any> = new BehaviorSubject([]);

  @Input() set persons(p: Array<any>) {
    this._personsSubj.next(p && p.length > 0 ? p.sort(
      (x) => x.typeId != null ? -1 : 1).map(item => { return { ...item, checkedExecutor: item.typeId == EXECUTOR, checkedDelegate: item.typeId == DELEGATE, checkedWatcher: item.typeId == WATCHER } }
      ).filter(item => { return !this.showExecutors ? item.typeId != EXECUTOR && item.typeId != OWNER : true })
      : []);
  }
  get persons(): Array<any> {
    return this._personsSubj.value;
  }
  @Input() markUsersIsCrm = false;
  @Input() limit = 15;
  @Input() maxParticipants = 1;
  @Input() maxWatchers = 999999;
  @Input() maxExecutors = 1;
  @Input() showWatchers = true;
  @Input() showSearch = true;
  @Input() showDelegates = true;
  @Input() showExecutors = false;
  @Input() isLoading = true;
  @Input() enableSelfSelection = false;
  @Output() personsChanged = new EventEmitter<Map<number, Array<any>>>();
  @Output() personChanged = new EventEmitter<{ before: any, after: any }>();

  @ViewChild('participantChkBox', { read: TemplateRef, static: true }) participantChkBoxTpl: TemplateRef<any>;
  @ViewChild('watcherChkBox', { read: TemplateRef, static: true }) watcherChkBoxTpl: TemplateRef<any>;
  @ViewChild('executorChkBox', { read: TemplateRef, static: true }) executorChkBoxTpl: TemplateRef<any>;
  @ViewChild('default', { read: TemplateRef, static: true }) defaultTpl: TemplateRef<any>;
  @ViewChild('defaultHeader', { read: TemplateRef, static: true }) defaultHeaderTpl: TemplateRef<any>;

  @Input() getPersons: () => Observable<Array<any>>;
  @Input() editableMode = false;
  @Input() limitSearchWidth = false;
  searchTerms: Subject<string> = new BehaviorSubject('');

  term: string;
  currentUser: any;


  private personsTblModel;

  private _delegate;
  private _executor;

  constructor(
    private _chg: ChangeDetectorRef,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    this.currentUser = this.authService.user;

    const pTbl = {
      columns: []
    };
    pTbl.columns.push(
      {
        name: '',
        prop: 'name',
      }
    );
    if (this.showExecutors) {
      pTbl.columns.push(
        {
          name: 'Vykdytojas',
          prop: null,
          cellTemplate: this.executorChkBoxTpl
        }
      );
    }
    if (this.showDelegates) {
      pTbl.columns.push(
        {
          name: 'Dalyvis',
          prop: null,
          cellTemplate: this.participantChkBoxTpl
        }
      );
    }
    if (this.showWatchers) {
      pTbl.columns.push(
        {
          name: 'Stebėtojas',
          prop: null,
          cellTemplate: this.watcherChkBoxTpl
        }
      );
    }

    this.personsTblModel = pTbl;
    if (this.getPersons) {
      this.getPersons().subscribe((p) => {
        this.isLoading = false;
        this.persons = p;
      });
    } else {
      throw new Error("'getPersons' must be provided!");
    }
    this._visiblePersons = this._personsSubj.pipe(
      combineLatest(
        this.searchTerms.pipe(map((s) => s.toLowerCase())),
        (p, s) => {
          return p.filter((x) => x && x.name && x.name.toLowerCase().indexOf(s) > -1);
        }));
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  clearSearch() {
    this.term = '';
    this.searchTerms.next(this.term);
  }

  clear() {
    this.persons = this._reset(this.persons);
  }

  private _reset(arr: Array<any>): Array<any> {
    return arr.map((p) => { p.typeId = 0; return p; })
  }

  private _rowClicked(row) {

  }

  private _personSelected(row, checked, t) {
    // console.log(row);
    setTimeout(() => {

      if (t === EXECUTOR && checked) {
        if (this._executor) {
          this._executor.typeId = 0;
          this._executor.checkedExecutor = false;
        }
        this._executor = row;
      }

      const prev = { ...row };
      row.typeId = checked ? t : 0;

      if (t == EXECUTOR) {
        row.checkedExecutor = checked;
        row.checkedDelegate = checked ? false : row.checkedDelegate;
        row.checkedWatcher = checked ? false : row.checkedWatcher;
      } else if (t == DELEGATE) {
        row.checkedExecutor = checked ? false : row.checkedExecutor;
        row.checkedDelegate = checked;
        row.checkedWatcher = checked ? false : row.checkedWatcher;
      } else if (t == WATCHER) {
        row.checkedExecutor = checked ? false : row.checkedExecutor;
        row.checkedDelegate = checked ? false : row.checkedDelegate;
        row.checkedWatcher = checked;
      }

      this.personChanged.emit({ before: prev, after: row });

      this._personsSubj.pipe(take(1),
        map((pl: Array<any>) =>
          pl.length > this.limit ?
            pl.reduce((acc, curr) => {
              const arr = acc[curr.typeId > 0 ? 0 : 1];
              arr.push(curr);
              return acc;
            }, [[], []])
            : pl))
        .subscribe((pl: Array<Array<any>>) => {
          if (pl[0] instanceof Array) {
            this.personsChanged.emit(this.partition(pl[0]));
            this._personsSubj.next([...sortByName(pl[0]), ...sortByName(pl[1])]);
          } else {
            this.personsChanged.emit(this.partition(pl));
          }
          try {
            this._chg.detectChanges();
          } catch (err) { }
        });
    }, 0);
    try {
      this._chg.detectChanges();
    } catch (err) { }
  }

  private partition(persons: Array<any>) {
    const personsByRole = new Map<number, Array<any>>();
    persons.forEach((p) => {
      if (personsByRole.has(p.typeId)) {
        personsByRole.get(p.typeId).push(p);
      } else {
        personsByRole.set(p.typeId, [p]);
      }
    });
    return personsByRole;
  }

  textChanged(text) {
    this.searchTerms.next(text);
  }

  chkBoxDisabled(event) {
    swal.fire({
      title: 'Pasirinkimas negalimas, jūs esate užduoties užsakovas',
      timer: 3000,
      showConfirmButton: false,
      icon: 'info'
    })

  }

}
