export const EMPTY_PARTNER: number = Number.NEGATIVE_INFINITY;
export const EMPTY_JOB: number = Number.NEGATIVE_INFINITY;

export const T_OWNER = 1;
export const T_DELEGATE = 2;
export const T_WATCHER = 3;
export const T_EXECUTOR = 4;

export const UZDUOTIS = 2;
export const UZKLAUSA = 3;
export const EMPLOYEE = 4;
export const DELEGATE = 5;
export const CRM_USER = 6;

export const A_TASK_PERSON = 2;
export const A_TASK_STATE_CHANGED = 3;
export const A_TASK_COMMENTED = 4;

export const COMPANY_ID = 4072;

export const REQUEST_SERVICE_ID = '690061';
export const AUTO_JOBS_SERVICE_ID = '321524';

// email pattern from https://emailregex.com/
export const EMAIL_PATTERN_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
