import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivityLogService } from "../../../services/activityLog.service";
import { Observable, Subscription, BehaviorSubject } from "rxjs";
import { ActivityLogEntry } from "../../../models/ActivityLogEntry";
import { TemplateService } from "../../shared/services/template.service";

@Component({
  selector: "app-offsidebar",
  templateUrl: "./activity-log.component.html",
  styleUrls: ["./activity-log.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ActivityLogComponent implements OnInit, OnDestroy {
  protected logEntries: Observable<Array<ActivityLogEntry>> = new BehaviorSubject([]);
  private _subscriptions: Array<Subscription> = new Array<Subscription>();

  public constructor(private _alService: ActivityLogService,
    private _templateService: TemplateService) {
    this.logEntries = _alService.logEntries;
  }

  ngOnInit(): void {
    this._alService.start();
    this._subscriptions.push(
      this._templateService.activityLogState.subscribe((s) => { })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

}
