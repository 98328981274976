import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'projects-estimation',
  templateUrl: './projects-estimation.component.html',
  styleUrls: ['./projects-estimation.component.scss']
})
export class ProjectsEstimationComponent implements OnInit {
  @Input() projectServiceTimes = [];
  @Input() projectTimesView;

  constructor() {
  }

  ngOnInit() {

  }

}
