
import { map, filter } from 'rxjs/operators';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonDropdownComponent } from '../../../template/shared/components/dropdowns/button-dropdown/button-dropdown.component';
import { StorageService } from '../../../template/shared/proto/storage/storage.service';
import { LIST, ListsDataService } from "../../../services/lists/lists.data.service";
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { VALUE_PROVIDER } from '../../../template/shared/proto/proto';
import { ValueProvider } from '../../../template/shared/proto/common/ValueProvider';

@Component({
  selector: 'marketing-filter-list',
  // template: `
  //       <button-dropdown
  //           [options]="types | async"
  //           [map]="suggestionMapper"
  //           [itemClass]="'btn-default btn-sm'"
  //           (itemClick) ="_groupSelected($event)">
  //       </button-dropdown>
  //   `,
  template: `
          <div class="btn-group" dropdown>
            <button dropdownToggle type="button" class="btn cursor btn-sm btn-default dropdown-toggle" mat-ripple>
            {{buttonTitle}} <span class="caret"></span>
            </button>
            <!-- <div *dropdownMenu class="dropdown-menu dropdown-menu-left top-align-fix z-index-fix" role="menu"
              (click)="$event.stopPropagation()">
              <div *ngFor="let group of types | async">
                <ng-container>
                  <mat-checkbox class="column-btn-check cursor"
                    (change)="dpaGroupChange(group.name, $event.checked)"><span
                      class="column-btn-text">{{group.name}}</span></mat-checkbox>
                </ng-container>
              </div>
            </div>
          </div> -->
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-no-wrap dropdown-menu-limit-width" role="menu" [ngStyle]="getDropdownStyles()" [ngClass]="menuClass">
          <li role="menuitem" (click)="$event.stopPropagation()" *ngIf="showSearch">
          <div class="df">
          <mat-checkbox class="column-btn-check cursor ch-width" [ngModel]="allChecked"
                            (click)="checkAll(allChecked)"></mat-checkbox>
          <input type="text" class="form-control form-control-sm search-field" placeholder="Filtruoti..."
        [(ngModel)]="searchField">
          </div>
        <span class="search-icon">
        <i class="zmdi zmdi-search"></i>
      </span>
          </li>
            <li role="menuitem" *ngFor="let group of types | filter: searchField: 'name'" (click)="$event.stopPropagation()">
              <div class="flex">
              <mat-checkbox class="column-btn-check cursor" [(ngModel)]="group.checked"
                            (change)="dpaGroupsChange(group, $event.checked)"><span
                              class="column-btn-text">{{group.name}}</span></mat-checkbox>
              </div>
            </li>
          </ul>

          <!-- <div *dropdownMenu
      class="dropdown-menu dropdown-menu-right top-align-fix z-index-fix limit-dropdown-height-fix violation-chart-dropdown-menu-width-fix"
      role="menu" (click)="$event.stopPropagation()">
      <input type="text" class="form-control form-control-sm search-field" placeholder="Filtruoti..."
        [(ngModel)]="searchField">
      <span class="search-icon">
        <i class="zmdi zmdi-search"></i>
      </span>
      <div *ngFor="let user of users | filter: searchField: 'name'">
        <mat-checkbox class="column-btn-check cursor" [checked]="user.selected"
          [disabled]="!user.selected && maxReached" (change)="userselected(user, $event.checked)">{{user.name}}
        </mat-checkbox>
      </div>
    </div> -->

        <!-- <proto-flags-group #flagsDPAGrp [storable]="ctm.id + '.DPAgroups'" class="no-btn-group-margins"
            [storableDefault]="ctm.DPAgroups.flagsGroup" #DPAgroupsStrbl="storable" [flagsGroup]="DPAgroupsStrbl.value"
            (flagsStateChanged)="DPAgroupsStrbl.store(flagsDPAGrp.currentState)" [queryPart]="'groupList'"
            [flagsInput]="types" [buttonTitle]="'Grupės'">
          </proto-flags-group> -->
    `,
  styleUrls: ['./marketing-filter.component.scss'],
  providers: [
    { provide: VALUE_PROVIDER, useExisting: forwardRef(() => MarketingFilterListComponent) }
  ]
})
export class MarketingFilterListComponent implements OnInit, ValueProvider<String>, OnDestroy {
  @Input() listType: 'sources' | 'clientTypes' | 'salesProducts' | 'salesTypes' | 'salesClientsNeeds' | 'users' | 'streams';
  @Input() ctm;
  @Input() source: 'marketing' | 'sales' = 'sales';
  @Output() typeSelected: Subject<any> = new Subject();
  idName: string = 'typeId';
  suggestionMapper = (p) => p.name;
  protected types: Array<any> = [];
  private _chgListener: (v: any) => void;
  private _currentTypeIds;
  private _subscriptions = new Array<Subscription>();
  @Input() storageKey;
  @ViewChild(ButtonDropdownComponent) button: ButtonDropdownComponent<any>;
  buttonTitle: string;
  constructor(private dataService: ListsDataService,
    private _storageService: StorageService,
    private _chg: ChangeDetectorRef) {

  }

  limitDropdownHeight = '500px';
  menuClass = null;
  searchField: string;
  showSearch = false;
  allowEmpty = false;
  @Input() initialSelectedState = false;

  get allChecked() {
    return !this.types?.find(el => !el.checked);
  }

  ngOnInit() {
    const stored = this._storageService.get(this.storageKey, null);
    let list;
    if (this.listType === 'sources') {
      list = LIST.MARKETING_SOURCES_LIST;
      this.buttonTitle = 'Šaltinis';
      // this.idName = 'typeId';
    }
    if (this.listType === 'clientTypes') {
      list = LIST.MARKETING_CLIENT_TYPES_LIST;
      if (this.source === 'marketing') {
        this.buttonTitle = 'Kliento tipai';
      } else if (this.source === 'sales') {
        this.buttonTitle = 'Klientai';
      }
      // this.idName = 'typeId';
      this.allowEmpty = true;
    }
    if (this.listType === 'salesProducts') {
      list = LIST.SALES_PRODUCTS_LIST;
      this.buttonTitle = 'Produktai';
      this.idName = 'id';
      this.showSearch = true;
      this.allowEmpty = true;
    }
    if (this.listType === 'salesTypes') {
      list = LIST.SALES_TYPES_LIST;
      this.buttonTitle = 'Tipai';
      // this.idName = 'typeId';
      this.allowEmpty = true;
    }
    if (this.listType === 'salesClientsNeeds') {
      list = LIST.SALES_CLIENTS_NEEDS_LIST;
      this.buttonTitle = 'Poreikis';
      // this.idName = 'typeId';
      this.allowEmpty = true;
    }
    if (this.listType === 'users') {
      list = LIST.USERS_LIST;
      this.buttonTitle = 'Vykdytojai';
      this.idName = 'laPersonId';
      this.showSearch = true;
      this.allowEmpty = true;
    }
    if (this.listType === 'streams') {
      list = LIST.STREAMS_LIST;
      this.buttonTitle = 'Srautai';
      this.idName = 'typeId';
      this.showSearch = true;
      this.allowEmpty = true;
    }
    this._subscriptions.push(this.dataService.loadList(list).subscribe((g) => {
      if (stored != null) {
        //filter out group "All types"
        const types = g.map(el => { return { ...el, checked: stored.indexOf(el[this.idName]) !== -1 } }).filter(el => el[this.idName] !== -1);
        if (list === LIST.MARKETING_SOURCES_LIST) types.unshift({ typeId: 1, name: 'Nenurodyta', checked: stored.indexOf(1) !== -1 })
        this.types = types;
        this._currentTypeIds = this.types.filter(el => el.checked).map(el => { return el[this.idName] });
      } else {
        if (g.length > 0) {
          const types = g.map((el, index) => { return { ...el, checked: this.initialSelectedState ? true : false } }).filter(el => el[this.idName] !== -1);
          if (list === LIST.MARKETING_SOURCES_LIST) types.unshift({ typeId: 1, name: 'Nenurodyta', checked: true })
          this.types = types;
        }
        this._currentTypeIds = this.types.filter(el => el.checked).map(el => { return el[this.idName] });
      }
      this.typeSelected.next(this._currentTypeIds?.join(','));
    }));
  }

  dpaGroupsChange(group, checked) {
    setTimeout(() => {
      // if (group[this.idName] === 0 && checked) {
      //   this.types.forEach(el => {
      //     if (el[this.idName] !== 0) el.checked = false;
      //   })
      // } else if (group[this.idName] !== 0 && checked) {
      //   this.types.forEach(el => {
      //     if (el[this.idName] === 0) el.checked = false;
      //   })
      // }
      if (!this.types.find(el => el.checked) && !this.allowEmpty) {
        this.types[0].checked = true;
      }
      if (this._chg) {
        this._chg.detectChanges();
      }
      this._currentTypeIds = this.types.filter(el => el.checked).map(el => { return el[this.idName] });
      this._storageService.store(this.storageKey, this._currentTypeIds);
      if (this._chgListener) {
        this._chgListener(this._currentTypeIds);
      }
      this.typeSelected.next(this._currentTypeIds?.join(','));
    }, 0);
  }

  checkAll(checked) {
    if (checked) {
      this.types?.forEach(el => el.checked = false);
      !this.allowEmpty ? this.types[0].checked = true : null;
    } else {
      this.types?.forEach(el => el.checked = true);
    }
    this._currentTypeIds = this.types.filter(el => el.checked).map(el => { return el[this.idName] });
    this._storageService.store(this.storageKey, this._currentTypeIds);
    if (this._chgListener) {
      this._chgListener(this._currentTypeIds);
    }
    this.typeSelected.next(this._currentTypeIds?.join(','));
  }

  getValueChanges() {
    return this.typeSelected.pipe(filter((g) => g != null));
  }

  getDropdownStyles() {
    if (this.limitDropdownHeight) {
      return { 'overflow': 'auto', 'max-height': this.limitDropdownHeight };
    } else {
      return null;
    }

  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}
