import { AfterViewInit, Component, Input, OnInit } from "@angular/core"
import { PageLengthDirective } from "./pageLength.directive";
import { Query } from "../../Query";

@Component({
  selector: "page-length",
  template: `<ng-content></ng-content>`,
})
export class PageLengthComponent implements OnInit, AfterViewInit {
  @Input() query: Query;

  constructor(private plDirective: PageLengthDirective) {
  }

  ngOnInit(): void {
    if (this.plDirective) {
    }
  }

  ngAfterViewInit(): void {
  }
}





