import {
  AfterContentInit,
  AfterViewInit,
  ContentChildren,
  Directive,
  Host,
  Inject,
  OnInit,
  Optional,
  QueryList,
  Self,
  ViewChildren
} from "@angular/core";
import { VALUE_PROVIDER } from "../proto";
import { StorableDirective } from "./storable.directive";

export const LOCAL = 'local';
export const SESSION = 'session';
export const UNTIL_BACK = 'back';

@Directive({
  exportAs: 'store',
  selector: '[store]',
})
export class StoreDirective implements OnInit, AfterViewInit, AfterContentInit {
  @ViewChildren(StorableDirective) _viewStorables: QueryList<StorableDirective>;
  @ContentChildren(StorableDirective) _contentStorables: QueryList<StorableDirective>;

  constructor(@Inject(VALUE_PROVIDER) @Optional() @Host() @Self() private _hostStorable: StorableDirective) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  ngAfterContentInit(): void {
  }
}
