import { Injectable } from '@angular/core';
import { UserBackend } from './backend/user.backend';


@Injectable()
export class TokenCheckService {

  chooseExecutorCheckTime = 5 * 60 * 1000; // 5 minutes
  // chooseExecutorCheckTime = 60 * 1000; // 5 minutes
  chooseExecutorTimeThreshold = 20 * 60 * 1000; // 10 minutes
  // chooseExecutorTimeThreshold = 2 * 60 * 1000; // 20 minutes
  // chooseExecutorInitTimeThreshold = 3 * 60 * 100; // 3 minutes

  lastRequestLocalstorageKey = 'ecrm.lastTokenCheck';
  initiateChooseExecutorStartKey = 'ecrm.executorChooseTimeStart';
  initiateChooseExecutorEndKey = 'ecrm.executorChooseTimeEnd';


  startExecutorChooseTime;

  checkToInitiateChooseExecutorInterval: any;
  chooseExecutorTimeout: any;

  checkTokenValidityInterval: any;
  checkTokenValidityIntervalTime = 10 * 60 * 1000; // 10 minutes
  // checkTokenValidityIntervalTime = 30 * 1000; // 10 minutes

  stateOn = false;

  constructor(
    private userBackend: UserBackend,
  ) {
    addEventListener('storage', this.competeToChooseExecutor.bind(this), false);
    userBackend._api.logout$.subscribe(() => {
      this.stopTokenChecks();
    });
  }

  initiateChooseExecutor() {
    this.startExecutorChooseTime = new Date().getTime();
    const initiateChooseExecutorStart = +localStorage.getItem(this.initiateChooseExecutorStartKey);
    // console.log('initiateChooseExecutorStart', initiateChooseExecutorStart);
    // if initiation started in last 20 seconds dont do it again
    if (!initiateChooseExecutorStart || this.startExecutorChooseTime - initiateChooseExecutorStart > 20 * 1000) { // 20 sec
      // console.log('Im initiator');
      localStorage.setItem(this.initiateChooseExecutorStartKey, this.startExecutorChooseTime.toString());
      const event = new StorageEvent('storage', {
        key: this.initiateChooseExecutorStartKey,
        oldValue: '',
        newValue: this.startExecutorChooseTime.toString(),
      });
      dispatchEvent(event);
    }
  }

  executorChosen() {
    // console.log('executor chosen');
    this.dotokenCkeckRequest();
    this.checkTokenValidityInterval = setInterval(() => {
      this.dotokenCkeckRequest();
    }, this.checkTokenValidityIntervalTime);
  }

  dotokenCkeckRequest() {
    localStorage.setItem(this.lastRequestLocalstorageKey, new Date().getTime().toString());
    const subs = this.userBackend.getCheckToken().subscribe(() => {
      subs.unsubscribe();
    }, err => {
      subs.unsubscribe();
    })
  }

  competeToChooseExecutor(event) {
    if (!this.stateOn) return;
    if (event.key === this.initiateChooseExecutorStartKey) {
      // console.log('compete', event, this.stateOn);
      const randomTime = Math.random() * 60 * 1000; // max 1 minute to choose executor
      // console.log('randomTime', randomTime);
      this.checkToInitiateChooseExecutorInterval && clearInterval(this.checkToInitiateChooseExecutorInterval);
      this.chooseExecutorTimeout = setTimeout(() => {
        localStorage.setItem(this.initiateChooseExecutorEndKey, new Date().getTime().toString());
        this.executorChosen();
        this.checkToInitiateChooseExecutor();
        // }, 1000 + randomTime);
      }, randomTime);
    }
    if (event.key === this.initiateChooseExecutorEndKey) {
      // console.log('end timeout', event, this.stateOn);
      this.chooseExecutorTimeout && clearTimeout(this.chooseExecutorTimeout);
      this.checkToInitiateChooseExecutorInterval && clearInterval(this.checkToInitiateChooseExecutorInterval);
      this.checkToInitiateChooseExecutor();
    }
  }

  checkToInitiateChooseExecutor() {
    const randomTime = Math.random() * 60 * 1000;
    this.checkToInitiateChooseExecutorInterval = setInterval(() => {
      const lastRequestTime = +localStorage.getItem(this.lastRequestLocalstorageKey);
      const currentTime = new Date().getTime();
      if (!lastRequestTime || (currentTime - lastRequestTime > this.chooseExecutorTimeThreshold)) {
        // const lastInitiateChooseExecutorStart = +localStorage.getItem(this.initiateChooseExecutorStartKey);
        // if (!lastInitiateChooseExecutorStart || currentTime - lastInitiateChooseExecutorStart > this.chooseExecutorInitTimeThreshold)
        this.initiateChooseExecutor();
      }
    }, this.chooseExecutorCheckTime + randomTime);
  }

  startTokenChecks() {
    this.stopTokenChecks();
    this.stateOn = true;
    this.checkToInitiateChooseExecutor();
    // console.log('start');
  }

  stopTokenChecks() {
    this.stateOn = false;
    this.checkToInitiateChooseExecutorInterval && clearInterval(this.checkToInitiateChooseExecutorInterval);
    this.checkTokenValidityInterval && clearInterval(this.checkTokenValidityInterval);
    this.chooseExecutorTimeout && clearTimeout(this.chooseExecutorTimeout);
    // console.log('stop');
  }

}
